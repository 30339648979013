import {
  extractKey,
  QueryClientContext,
  useApiMutation,
  useApiQuery,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {SearchInput} from 'common/components/SearchInput'
import {useCheckBox} from 'common/hooks/useCheckBox'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {useSearchValue} from 'common/hooks/useSearchValue'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {isAndroid, isIOS} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {NewOrderQuickGrid} from 'new_order/components/NewOrderQuickGrid'
import {NewOrderQuickShopCartGrid} from 'new_order/components/NewOrderQuickShopCartGrid'
import {
  getDataConfigForActiveCategory,
  getDataConfigForAddItemsToCart,
} from 'new_order/new_order_helpers'
import {NewOrderProducts, newOrderSlice} from 'new_order/new_order_slice'
import {CategoryItem} from 'new_order/new_order_types'
import {SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID} from 'shop_cart/shop_cart_constants'
import {
  getDataConfigForCart,
  getDataConfigForShopCartItemsCount,
} from 'shop_cart/shop_cart_helpers'
import {ShopCartAddItemsParams} from 'shop_cart/shop_cart_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

export const NewOrderQuick: React.FC = () => {
  const sxStyles = applyStyles()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient({context: QueryClientContext})
  const {t} = useTranslation()
  const isShopCartEnabled = useAppSelector(
    (state) => state.login.isShopCartEnabled
  )
  const products = useAppSelector((state) => state.newOrder.products)
  const {
    searchValue,
    handleChangeSearchValue,
    handlePressDeleteIcon,
    resultSearchValue,
  } = useSearchValue({})

  const isModalSpinnerVisible = useAppSelector(
    (state) => state.layout.isModalSpinnerVisible
  )

  const shopCartGridConfig = useAppSelector(
    (state) => state[SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID].gridConfig
  )

  const dataConfigForActiveCategory = getDataConfigForActiveCategory()
  const {data} = useApiQuery<CategoryItem[]>(
    extractKey({
      params: dataConfigForActiveCategory.params,
      url: dataConfigForActiveCategory.url,
    }),
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...dataConfigForActiveCategory,
      },
    },
    true
  )

  const getConfig = useMemo(() => {
    return data ? data.map((item) => ({id: item.id, title: item.name})) : []
  }, [data])

  const {renderCheckBoxes, actualActiveIdx} = useCheckBox(getConfig)

  const conditionForProductCategory = useMemo(() => {
    return {
      params: {
        where: {
          ...(actualActiveIdx && actualActiveIdx.length > 0
            ? {productCategoryId: actualActiveIdx}
            : {}),
        },
      },
    }
  }, [actualActiveIdx])

  const prepareProductsToSaveToCart = useCallback(
    (productsToSave: NewOrderProducts) => {
      const result: ShopCartAddItemsParams[] = []
      Object.keys(productsToSave).forEach((item) => {
        if (item !== undefined && productsToSave[item].amount > 0) {
          result.push({
            amount: productsToSave[item].amount,
            unitOfMeasure: productsToSave[item].unitOfMeasure,
            productId: item,
          })
        }
      })
      return {items: result}
    },
    []
  )

  const dataConfigForAddItemsToCart = getDataConfigForAddItemsToCart(
    prepareProductsToSaveToCart(products)
  )

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const handleSuccessAddItems = (response: ResponseSuccess) => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.setDataProcessing({
        dataProcessing: false,
        stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    const configForShopCart = getDataConfigForCart()
    const shopCartKey = extractKey({
      url: configForShopCart.url,
    })

    const configForShopCartItemsCount = getDataConfigForShopCartItemsCount()
    const shopCartItemsCountKey = extractKey({
      params: configForShopCartItemsCount.params,
      url: configForShopCartItemsCount.url,
    })
    queryClient.invalidateQueries(shopCartKey)
    queryClient.invalidateQueries(shopCartItemsCountKey)

    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
    dispatch(newOrderSlice.actions.resetProducts())
  }

  const {mutate} = useApiMutation(
    extractKey({
      data: dataConfigForAddItemsToCart.data,
      method: dataConfigForAddItemsToCart.method,
      url: dataConfigForAddItemsToCart.url,
    }),
    undefined,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessAddItems,
        onError: handleError,
      },
      axiosConfig: {
        ...dataConfigForAddItemsToCart,
      },
    },
    true
  )

  const isAddDisabled = useCallback(
    (): boolean =>
      !dataConfigForAddItemsToCart.data?.items?.length ||
      dataConfigForAddItemsToCart.data.items.length === 0,
    [dataConfigForAddItemsToCart]
  )

  const handlePressAddToCart = useCallback(() => {
    if (!isAddDisabled()) {
      mutate()
    }
  }, [mutate, isAddDisabled])

  const handlePressClearProducts = useCallback(() => {
    dispatch(newOrderSlice.actions.resetProducts())
  }, [dispatch])

  const isSmallDevice = useIsNarrowScreen(1)

  return (
    <View sx={sxStyles.container}>
      <View sx={sxStyles.searchWrapper}>
        <SearchInput
          onPressDeleteIcon={handlePressDeleteIcon}
          value={searchValue}
          onChangeText={handleChangeSearchValue}
          placeholder={t('newOrder.quick.placeholder')}
          disabled={isModalSpinnerVisible}
        />
      </View>
      {isShopCartEnabled && !(isAndroid || isIOS || isSmallDevice) ? (
        <NewOrderQuickShopCartGrid
          _shopCartGridConfigLoaded={shopCartGridConfig?.loaded}
        />
      ) : null}
      {data ? renderCheckBoxes() : null}
      <NewOrderQuickGrid
        resultSearchValue={resultSearchValue}
        dataServiceConfig={conditionForProductCategory}
        onPressAddToCart={handlePressAddToCart}
        onPressClearProducts={handlePressClearProducts}
        isAddDisabled={isAddDisabled()}
        isLoading={false}
      />
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    paddingX: 1,
  },
  searchWrapper: {
    flex: 1,
    alignSelf: [null, null, 'flex-start'],
  },
})
