import {extractKey, useApiQuery} from '@sincersoft/fe-core'

import {getDataConfigForConfig} from 'layout/layout_helpers'
import {Config} from 'layout/layout_types'

export const useGetConfig = (): Config => {
  const dataForConfig = getDataConfigForConfig()

  const configKey = extractKey({
    url: dataForConfig.url,
  })

  const {data} = useApiQuery<Config>(
    configKey,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...dataForConfig,
      },
    },
    false
  )

  return {
    privatePolicyUrl: data?.privatePolicyUrl ?? '',
    beBuildVersion: data?.beBuildVersion ?? '',
    beBuildDate: data?.beBuildDate ?? '',
  }
}
