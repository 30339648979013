import {DripsyCustomTheme} from 'dripsy'
import React from 'react'

import {LabelValue} from 'common/components/LabelValue'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  isDate?: boolean
  label: string
  numberOfLinesForValue?: number
  translatePrefix?: string
  value: string | number
}

export const OrderDetailInfoItemComponent: React.FC<Props> = ({
  isDate,
  label,
  numberOfLinesForValue,
  translatePrefix,
  value,
}) => {
  const sxStyles = useThemeSxStyles(applyStyles)
  return (
    <LabelValue
      isDate={isDate}
      label={label}
      labelStyle={sxStyles.label}
      numberOfLinesForValue={numberOfLinesForValue}
      translatePrefix={translatePrefix}
      value={value}
      valueStyle={sxStyles.value}
      wrapperStyle={sxStyles.wrapper}
    />
  )
}

export const OrderDetailInfoItem = React.memo(OrderDetailInfoItemComponent)

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  wrapper: {
    flex: 1,
    paddingTop: [3, null, 5],
    justifyContent: ['space-between', null, null, 'flex-start'],
  },
  label: {
    ...theme.text.body1Bold,
    color: 'secondaryColor700',
    fontSize: [3, null, 4],
    flex: 0.55,
  },
  value: {
    flex: 1,
    textAlign: 'right',
    color: 'grayColor700',
    paddingLeft: [2],
    paddingRight: [0, null, null, 4],
    ...theme.text.body1,
    fontSize: [3, null, 4],
  },
})
