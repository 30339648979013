import {yupResolver} from '@hookform/resolvers/yup'
import {useFocusEffect} from '@react-navigation/native'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSx, View} from 'dripsy'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {prepareOptionsFromStringArray} from 'common/data_helpers'
import {
  DOT_AND_COMMA_REG_EXP,
  checkForDot,
  INTEGER_MEASUREMENT_UNITS,
} from 'common/grid_renderers_helpers'
import {formatStringToNumber} from 'common/number_helpers'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {formatToFormItemId} from 'common/validation_helper'
import {ApiError} from 'error/error_helper'
import {InputController} from 'form/components/InputController'
import {SelectController} from 'form/components/SelectController'
import {shopCartAmountAndUnitSchema} from 'form/form_schemas'
import {NativeGridItemHeader} from 'grid/components/NativeGridItemHeader'
import {CellRendererParams} from 'grid/grid_types'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID} from 'shop_cart/shop_cart_constants'
import {
  getDataConfigForCart,
  getDataConfigForUpdateShopCartItem,
} from 'shop_cart/shop_cart_helpers'
import {shopCartSlice} from 'shop_cart/shop_cart_slice'
import {
  ResponseShopCartItem,
  ShopCartItem,
  UpdateShopCartItem,
} from 'shop_cart/shop_cart_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

interface ShopCartUnitOfMeasureForm {
  unitOfMeasure: string
  amount: string
}

export const ShopCartAmountAndUnitCellRenderer: React.FC<
  CellRendererParams
> = ({data, isSmall, colDef}) => {
  const dispatch = useAppDispatch()
  const sx = useSx()
  const {t} = useTranslation()
  const sxStyles = applyStyles(isSmall)
  const queryClient = useQueryClient({context: QueryClientContext})

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    reset,
    trigger,
    formState: {errors},
  } = useForm<ShopCartUnitOfMeasureForm>({
    resolver: yupResolver(shopCartAmountAndUnitSchema),
  })

  useEffect(() => {
    reset({
      unitOfMeasure: data.unitOfMeasure,
      amount: data.amount,
    })
  }, [data, reset])

  const amountValue = watch('amount')
  const amountError = errors.amount
  const shopCartItemId = useMemo(
    () => formatToFormItemId(data.id, 'amount'),
    [data.id]
  )
  const options = useMemo(
    () => prepareOptionsFromStringArray(data?.productId?.unitOptions),
    [data?.productId?.unitOptions]
  )

  const gridData: ShopCartItem[] = useAppSelector(
    (state) => state.grid_shopCart.data
  )
  const savedItemInRedux = useMemo(
    () => gridData.find((item) => item?.id === data.id),
    [data.id, gridData]
  )

  const savedUnitOfMeasure = savedItemInRedux?.unitOfMeasure

  useEffect(() => {
    if (savedUnitOfMeasure) {
      trigger('amount')
    }
  }, [savedUnitOfMeasure, trigger])

  const setOrDeleteErrorState = useCallback(
    (isError: boolean) => {
      if (isError) {
        dispatch(
          shopCartSlice.actions.setErrorState({
            id: shopCartItemId,
            name: data.productId.name,
          })
        )
      } else {
        dispatch(
          shopCartSlice.actions.deleteErrorState({
            id: shopCartItemId,
          })
        )
      }
    },
    [dispatch, shopCartItemId, data.productId.name]
  )

  useFocusEffect(
    useCallback(() => {
      const checkAmount = async () => {
        const isAmountValid = await trigger('amount')
        setOrDeleteErrorState(!isAmountValid)
      }
      checkAmount()
    }, [trigger, setOrDeleteErrorState])
  )

  const handleSuccessUpdate = (response: ResponseShopCartItem) => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.setDataProcessing({
        dataProcessing: false,
        stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    const configForShopCart = getDataConfigForCart()
    const shopCartKey = extractKey({
      url: configForShopCart.url,
    })
    queryClient.invalidateQueries(shopCartKey)

    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const configForUpdateShopCartItem =
    getDataConfigForUpdateShopCartItem<UpdateShopCartItem>({
      id: data.id,
      amount: checkForDot(
        formatStringToNumber(getValues().amount),
        getValues().unitOfMeasure
      ),
      unitOfMeasure: getValues().unitOfMeasure,
    })

  useEffect(() => {
    setOrDeleteErrorState(!!amountError)
  }, [amountError, setOrDeleteErrorState])

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const {mutate} = useApiMutation(
    extractKey({
      data: configForUpdateShopCartItem.data,
      method: configForUpdateShopCartItem.method,
      url: configForUpdateShopCartItem.url,
    }),
    configForUpdateShopCartItem.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdate,
        onError: handleError,
      },
      axiosConfig: {
        ...configForUpdateShopCartItem,
      },
    },
    true
  )

  const handleUpdateUnitItem = useCallback(
    (valObject, value) => {
      if (value !== data.unitOfMeasure) {
        mutate()
        dispatch(
          gridSlice.actions.setDataProcessing({
            dataProcessing: true,
            stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
          })
        )
      }
    },
    [data, dispatch, mutate]
  )

  const handleUpdateAmountItem = useCallback(
    (valueObject, value) => {
      if (formatStringToNumber(value) !== data.amount) {
        mutate()
        dispatch(
          gridSlice.actions.setDataProcessing({
            dataProcessing: true,
            stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
          })
        )
      }
    },
    [mutate, dispatch, data]
  )

  const handleDebounceUpdate = useMemo(
    () => debounce(handleSubmit(handleUpdateAmountItem), 1000),
    [handleSubmit, handleUpdateAmountItem]
  )

  useEffect(() => {
    amountValue && handleDebounceUpdate(amountValue)
  }, [amountValue, handleDebounceUpdate])

  const handleKeyPress = (e) => {
    if (
      INTEGER_MEASUREMENT_UNITS.includes(getValues().unitOfMeasure) &&
      DOT_AND_COMMA_REG_EXP.test(e.key)
    ) {
      e.preventDefault()
    }
  }

  return (
    data && (
      <View sx={sxStyles.containerWrapper}>
        <View sx={sxStyles.amountWrapper}>
          {isSmall && colDef?.cellRendererParams?.headerName ? (
            <NativeGridItemHeader
              value={`${t(colDef.cellRendererParams.headerName)}:`}
            />
          ) : null}
          <View
            sx={{
              ...sxStyles.container,
              ...(data ? sxStyles.productSetContainer : {}),
            }}
          >
            <View sx={sxStyles.inputWrapper}>
              <View sx={sxStyles.input}>
                <InputController
                  control={control}
                  id={'amount'}
                  size={'small'}
                  maxLength={7}
                  textStyle={sx(sxStyles.textStyles)}
                  isColumn={false}
                  hideErrorInput
                  isNumber
                  showErrorInputBorder={!!errors.amount}
                  error={errors.amount}
                  selectTextOnFocus
                  onKeyPress={handleKeyPress}
                />
              </View>
              <View sx={sxStyles.select}>
                {data && data.productId && data.productId.unitOptions ? (
                  <SelectController
                    onInputChange={handleSubmit(handleUpdateUnitItem)}
                    control={control}
                    error={errors.unitOfMeasure}
                    name={'unitOfMeasure'}
                    displayLabelProperty={'name'}
                    idProperty={'name'}
                    options={options}
                    selectInputStyle={sxStyles.selectInputStyle}
                    size={'small'}
                  />
                ) : null}
              </View>
            </View>
          </View>
        </View>
      </View>
    )
  )
}

const applyStyles = (isSmall?: boolean): ObjectsOfSxProps => ({
  containerWrapper: {
    flex: 1,
    flexDirection: isSmall ? 'column' : 'row',
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: ['flex-end', null, 'center'],
    justifyContent: 'flex-end',
  },
  productSetContainer: {
    height: isWeb ? 'inherit' : '100%',
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textStyle: {
    maxWidth: '80%',
  },
  input: {
    marginRight: 1,
    width: 80,
  },
  select: {
    marginRight: 1,
    maxWidth: [105, null, 85],
  },
  selectInputStyle: {
    maxWidth: [105, null, 85],
    minWidth: [105, null, 85],
  },
  shoppingCartIcon: {
    color: 'grayColor500',
    height: 24,
    width: 24,
  },
  buttonWrapper: {
    height: '100%',
    width: '20%',
    paddingRight: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  priceForUnitWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    ...(isSmall ? {flex: 1, pt: 1} : {minWidth: 140}),
  },
  amountWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
})
