import {View} from 'dripsy'
import React from 'react'
import {Control, FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {RegisterForm} from './RegisterForm'
import {CheckboxController} from 'form/components/CheckboxController'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {
  FORM_FIELDS,
  SUBSCRIBE_TO_PRICE_LIST_FORM_CHECKBOX,
  USER_SHOP_FORM_FIELDS,
} from 'register/register_constants'

interface RegisterFormsProps {
  errors: FieldErrors
  control: Control<any>
}

export const RegisterForms: React.FC<RegisterFormsProps> = ({
  errors,
  control,
}) => {
  const sxStyles = applyStyles()
  const {t} = useTranslation()

  return (
    <View sx={sxStyles.forms}>
      <View sx={sxStyles.formWrapper}>
        <View sx={sxStyles.regFormWrapper}>
          <RegisterForm
            formFields={FORM_FIELDS}
            control={control}
            errors={errors}
            isColumn
          >
            <View sx={sxStyles.checkBoxWrapper}>
              <CheckboxController
                control={control}
                id={SUBSCRIBE_TO_PRICE_LIST_FORM_CHECKBOX.id}
                title={t(SUBSCRIBE_TO_PRICE_LIST_FORM_CHECKBOX.label)}
              />
            </View>
          </RegisterForm>
        </View>
      </View>
      <View sx={sxStyles.shopFormWrapper}>
        <RegisterForm
          formFields={USER_SHOP_FORM_FIELDS}
          control={control}
          errors={errors}
          title={'shop.title'}
        />
      </View>
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  forms: {
    backgroundColor: 'primaryColor50',
    paddingY: 2,
    paddingX: [2, null, 5, 7],
    flexDirection: ['column' as const, null, 'row' as const],
  },
  formWrapper: {
    flex: 1,
  },
  regFormWrapper: {
    flex: [null, null, 1],
    width: ['100%', null, '80%', '60%'],
  },
  shopFormWrapper: {
    flex: [null, null, 1.2, 1],
    paddingLeft: [null, null, null, 5],
  },
  checkBoxWrapper: {
    marginTop: 2,
    marginBottom: 4,
  },
})
