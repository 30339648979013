export const SUBJECT = 'subject'
export const SCREEN = 'screen'
export const DESCRIPTION = 'description'

export const REPORT_OPTIONS_TRANSLATE_PREFIX = 'report.options'

export const USER_GROUPED_REPORT_PAGE_OPTIONS = [
  {
    title: 'userOrder.title',
    items: [
      {name: 'userOrder.new'},
      {name: 'userOrder.category'},
      {name: 'userOrder.products'},
      {name: 'userOrder.productDetail'},
      {name: 'userOrder.quick'},
    ],
  },
  {
    title: 'userOrders.title',
    items: [{name: 'userOrders.orders'}, {name: 'userOrders.orderDetail'}],
  },
  {
    title: 'userPriceList.title',
    items: [{name: 'userPriceList.priceList'}],
  },
  {
    title: 'userShopCart.title',
    items: [{name: 'userShopCart.shopCart'}],
  },
  {
    title: 'userProfile.title',
    items: [
      {name: 'userProfile.profile'},
      {name: 'userProfile.changePassword'},
    ],
  },
  {
    title: 'userReport.title',
    items: [{name: 'userReport.report'}],
  },
  {
    title: 'notLoggedIn.title',
    items: [
      {name: 'notLoggedIn.login'},
      {name: 'notLoggedIn.register'},
      {name: 'notLoggedIn.forgottenPassword'},
      {name: 'notLoggedIn.resetPassword'},
      {name: 'notLoggedIn.confirmRegister'},
      {name: 'notLoggedIn.submitRegister'},
    ],
  },
  {name: 'unknown'},
]

export const ADMIN_GROUPED_REPORT_PAGE_OPTIONS = [
  {
    title: 'adminUsers.title',
    items: [{name: 'adminUsers.users'}, {name: 'adminUsers.userDetail'}],
  },
  {
    title: 'adminOrders.title',
    items: [{name: 'adminOrders.orders'}, {name: 'adminOrders.orderDetail'}],
  },
  {
    title: 'adminProducts.title',
    items: [
      {name: 'adminProducts.products'},
      {name: 'adminProducts.productDetail'},
    ],
  },
  {
    title: 'adminCategory.title',
    items: [
      {name: 'adminCategory.category'},
      {name: 'adminCategory.categoryDetail'},
    ],
  },
  {
    title: 'adminPriceList.title',
    items: [
      {name: 'adminPriceList.priceList'},
      {name: 'adminPriceList.priceListDetail'},
    ],
  },
  {
    title: 'adminProfile.title',
    items: [
      {name: 'adminProfile.profile'},
      {name: 'adminProfile.changePassword'},
    ],
  },
  {
    title: 'adminReport.title',
    items: [{name: 'adminReport.report'}],
  },
  ...USER_GROUPED_REPORT_PAGE_OPTIONS,
]
