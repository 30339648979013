import {MaterialIcons} from '@expo/vector-icons'
import {Layout, MenuItem, OverflowMenu} from '@ui-kitten/components'
import {Text, useDripsyTheme, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Linking, StyleSheet, TouchableOpacity} from 'react-native'

import {Icon} from 'common/components/Icon'
import {useGetConfig} from 'common/hooks/useGetConfig'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface HeaderMenuProps {
  onPressLogout: () => void
  onPressProfile: () => void
  onPressReport: () => void
  text?: string
}

export const HeaderMenu: React.FC<HeaderMenuProps> = ({
  text,
  onPressLogout,
  onPressProfile,
  onPressReport,
}) => {
  const {t} = useTranslation()
  const dripsyTheme = useDripsyTheme()
  const [visible, setVisible] = React.useState(false)

  const {privatePolicyUrl} = useGetConfig()

  const onItemSelect = () => {
    setVisible(false)
  }

  const handlePressToggleButton = () => {
    setVisible(true)
  }

  const renderIcon = (
    nameIcon: keyof typeof MaterialIcons.glyphMap,
    onPressIcon: () => void
  ) => {
    return (
      <Icon
        name={nameIcon}
        color={dripsyTheme.theme.colors.grayColor500}
        onPress={onPressIcon}
        propsStyle={styles.iconStyle}
      />
    )
  }

  const renderToggleButton = () => (
    <TouchableOpacity
      onPress={handlePressToggleButton}
      accessibilityRole={'menu'}
    >
      <View sx={sxStyles.button}>
        {text && (
          <Text sx={sxStyles.name} numberOfLines={1} variant={'body2'}>
            {text}
          </Text>
        )}
        <Icon
          name={'keyboard-arrow-down'}
          onPress={handlePressToggleButton}
          color={dripsyTheme.theme.colors.grayColor500}
        />
      </View>
    </TouchableOpacity>
  )

  const handlePressIcon = (cb: () => void) => {
    setVisible(false)
    cb()
  }

  const handlePressPdpp = () => {
    if (privatePolicyUrl !== '') {
      Linking.openURL(privatePolicyUrl)
    }
  }

  return (
    <Layout level='1'>
      <OverflowMenu
        anchor={renderToggleButton}
        visible={visible}
        onSelect={onItemSelect}
        onBackdropPress={() => setVisible(false)}
      >
        <MenuItem
          title={`${t('menu.profile')}`}
          onPress={onPressProfile}
          accessoryLeft={() =>
            renderIcon('account-circle', () => handlePressIcon(onPressProfile))
          }
        />
        <MenuItem
          title={`${t('menu.report')}`}
          onPress={onPressReport}
          accessoryLeft={() =>
            renderIcon('report', () => handlePressIcon(onPressReport))
          }
        />
        <MenuItem
          title={`${t('menu.pdpp')}`}
          onPress={handlePressPdpp}
          accessoryLeft={() =>
            renderIcon('policy', () => handlePressIcon(handlePressPdpp))
          }
        />
        <MenuItem
          title={`${t('menu.logout')}`}
          onPress={onPressLogout}
          accessoryLeft={() =>
            renderIcon('logout', () => handlePressIcon(onPressLogout))
          }
        />
      </OverflowMenu>
    </Layout>
  )
}

const sxStyles: ObjectsOfSxProps = {
  name: {
    color: 'black',
    paddingRight: 1,
    fontSize: [3, 2, null, 3],
    maxWidth: 180,
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
  },
}

const styles = StyleSheet.create({
  iconStyle: {
    margin: -4,
  },
})
