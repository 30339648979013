import React from 'react'

import {useNotifications} from 'common/hooks/useNotifications'

interface Props {
  children: React.ReactNode
}

export const NotificationProvider: React.FC<Props> = ({children}) => {
  useNotifications()
  return <>{children}</>
}
