import {useNavigation} from '@react-navigation/native'
import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import {Text, View} from 'dripsy'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ConfirmModal} from 'common/components/ConfirmModal'
import {IconButton} from 'common/components/IconButton'
import {setNavigationTimeout} from 'common/navigation_thunks'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {AuthorizedNavigationProp, ObjectsOfSxProps} from 'layout/layout_types'
import {getDataConfigForRemoveOrder} from 'order/order_helpers'
import {useAppDispatch} from 'store/redux_hooks'

interface Props {
  orderId: number | string
}

const OrderDeleteButtonComponent: React.FC<Props> = ({orderId}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigation = useNavigation<AuthorizedNavigationProp>()
  const [modalVisible, setModalVisible] = useState(false)

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const handleSuccess = (response: ResponseSuccess) => {
    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
    dispatch(
      setNavigationTimeout({
        cb: () => {
          navigation.navigate('NavAdminOrderScreen', {
            screen: 'AdminOrderScreen',
          })
        },
      })
    )
  }

  const configForDeleteOrder = getDataConfigForRemoveOrder(orderId)

  const deleteOrderQuery = useApiMutation(
    extractKey({
      method: configForDeleteOrder.method,
      url: configForDeleteOrder.url,
    }),
    undefined,
    {
      reactMutationOptions: {
        onError: handleError,
        onSuccess: handleSuccess,
      },
      axiosConfig: {
        ...configForDeleteOrder,
      },
    },
    true
  )

  const handlePressDeleteConfirm = () => {
    setModalVisible(false)
    deleteOrderQuery.mutate()
  }

  const handlePressCancel = () => {
    setModalVisible(false)
  }

  const handlePressDelete = () => {
    setModalVisible(true)
  }
  return (
    <View sx={sxStyles.button}>
      <IconButton
        iconName={'close'}
        left
        title={t('button.removeOrder')}
        onPress={handlePressDelete}
        color={'secondaryColor500'}
        status={'danger'}
        size={'small'}
      />
      <ConfirmModal
        modalVisible={modalVisible}
        onPressCancel={handlePressCancel}
        onPressConfirm={handlePressDeleteConfirm}
      >
        <View sx={sxStyles.modalContent}>
          <Text sx={sxStyles.title} variant={'body1Bold'}>
            {t('order.delete.text')}
          </Text>
          <Text sx={sxStyles.infoText} variant={'caption'}>
            {t('order.delete.textInfo')}
          </Text>
        </View>
      </ConfirmModal>
    </View>
  )
}

export const OrderDeleteButton = React.memo(OrderDeleteButtonComponent)

const sxStyles: ObjectsOfSxProps = {
  button: {
    alignItems: 'center',
    marginY: 2,
  },
}
