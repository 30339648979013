import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {View} from 'dripsy'
import React from 'react'
import {Platform} from 'react-native'

import {Api} from 'config/app_config'
import {ConfirmRegisterEmail} from 'confirm_register/components/ConfirmRegisterEmail'
import {ContentLayout} from 'layout/components/ContentLayout'
import {ErrorBoundary} from 'layout/components/ErrorBoundary'
import {ScreenKeyboardAwareScrollView} from 'layout/components/ScreenKeyboardAwareScrollView'
import {ObjectsOfSxProps, RootStackParamList} from 'layout/layout_types'

type Props = NativeStackScreenProps<
  RootStackParamList,
  'DeleteAccountRequestScreen'
>

export const DeleteAccountRequestScreen: React.FC<Props> = ({navigation}) => {
  const sxStyles = applyStyles()

  return (
    <ScreenKeyboardAwareScrollView
      enableScroll={Platform.OS === 'android'}
      scrollOnlyWithKeyboardOpen
      shouldPersistTaps={'never'}
    >
      <ErrorBoundary>
        <View sx={sxStyles.containerWrapper}>
          <ContentLayout>
            <ConfirmRegisterEmail
              buttonTitle={'button.deleteAccount'}
              keyUrl={Api.deleteAccountRequest}
              successMessage={'response.success.user.deleteAccountRequest'}
              navigation={navigation}
              title={'deleteAccountRequest.title'}
              text={'deleteAccountRequest.text'}
              errorCode={'response.error.user.forgotten'}
            />
          </ContentLayout>
        </View>
      </ErrorBoundary>
    </ScreenKeyboardAwareScrollView>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  containerWrapper: {
    flex: 1,
  },
})
