import {QueryDefaultInput, QueryInput} from '@sincersoft/fe-core'

import {PageableResponse} from 'common/types'
import {Api} from 'config/app_config'
import {
  EnumPriceList,
  EnumPriceListType,
  PriceListExport,
} from 'price_list/price_list_types'

export const ENUM_PRICE_LIST: QueryDefaultInput = {
  url: Api.priceList,
  params: {
    isActive: true,
    populate: false,
    select: 'id,name',
  },
}

export function getDataConfigForEnumPriceList<TData>(
  type: EnumPriceListType
): QueryInput<TData> {
  return {
    ...ENUM_PRICE_LIST,
    params: {
      ...ENUM_PRICE_LIST.params,
      type,
    },
  }
}

export const PRICE_LIST: QueryDefaultInput = {
  url: Api.priceList,
  params: {
    populate: false,
    where: {
      isActive: true,
    },
  },
}

export function getDataConfigForPriceList<TData>(
  id: string | number
): QueryInput<TData> {
  return {
    ...PRICE_LIST,
    url: `${PRICE_LIST.url}/${id}`,
  }
}

export function getDataConfigForSavePriceList<TData>(
  data: TData,
  priceListId?: string | number
): QueryInput<TData> {
  return {
    url: `${Api.priceList}/${priceListId}`,
    method: 'PATCH',
    data,
  }
}

export function getDataConfigForUploadPriceList(
  timeZone: string,
  isPromo?: boolean
): QueryInput {
  return {
    url: Api.uploadPriceList,
    method: 'POST',
    params: {
      timeZone,
      ...(isPromo ? {isPromo} : {}),
    },
  }
}

export function getDataConfigForPriceListExportRequest<TData>(
  data: TData
): QueryInput<TData> {
  return {
    url: `${Api.priceListExportRequest}`,
    method: 'POST',
    data,
  }
}

export function getPriceListCombinationLabel(
  priceLists: PriceListExport,
  standardPriceListOptions: PageableResponse<EnumPriceList> | undefined,
  actionPriceListOptions: PageableResponse<EnumPriceList> | undefined
) {
  let result = ''
  if (priceLists.priceListStandard) {
    const standardOptions = standardPriceListOptions?.rowsData ?? []
    const standard = standardOptions.find(
      (item) => +(item.id ?? 0) === priceLists.priceListStandard
    )
    if (standard) {
      result += standard.name
    }
    const actionOptions = actionPriceListOptions?.rowsData ?? []
    const action = actionOptions.find(
      (item) => +(item.id ?? 0) === priceLists.priceListActionPrimary
    )
    if (action) {
      result += `+${action.name}`
    }
  }
  return result
}
