import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {Button, Icon, IconProps, Input} from '@ui-kitten/components'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React, {useCallback, useEffect, useRef} from 'react'

import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {formatToFormItemId} from 'common/validation_helper'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {ShopCartCustomItemRowItem} from 'shop_cart/components/ShopCartCustomItemRowItem'
import {
  UNIT_OF_MEASURE_OPTIONS,
  CUSTOM_ITEMS_HEADER_COUNT,
  CUSTOM_ITEMS_HEADER_NAME,
  CUSTOM_ITEMS_HEADER_PRICE_FOR_UNIT,
  CUSTOM_ITEMS_HEADER_UNIT_OF_MEASURE,
  NAME_ID,
  AMOUNT_ID,
  UNIT_OF_MEASURE_ID,
  PRICE_ID,
} from 'shop_cart/shop_cart_constants'
import {getDataConfigForDeleteCustomItem} from 'shop_cart/shop_cart_helpers'
import {shopCartSlice} from 'shop_cart/shop_cart_slice'
import {CustomItem, ErrorState} from 'shop_cart/shop_cart_types'
import {useAppDispatch} from 'store/redux_hooks'

interface ShopCartCustomItemRowProps {
  onPressDelete: (idx: number) => void
  onUpdateItem: () => void
  index: number
  customItemId: number
  customItem: CustomItem
  updateErrorState: (errorState: ErrorState) => void
  isNewRowAndFocus: boolean
  onChangeIsNewRow: () => void
}

const ShopCartCustomItemRowComponent: React.FC<ShopCartCustomItemRowProps> = ({
  onPressDelete,
  onUpdateItem,
  index,
  customItemId,
  customItem,
  updateErrorState,
  isNewRowAndFocus,
  onChangeIsNewRow,
}) => {
  const sxStyles = useThemeSxStyles(applyStyles)
  const dispatch = useAppDispatch()

  const ref = useRef<Input>(null)

  useEffect(() => {
    if (ref && ref.current) {
      // Fixed problem on Android devices with open keyboard after focus - setTimeout
      setTimeout(() => {
        ref?.current?.focus()
      }, 100)
    }
  }, [ref])

  const DeleteIcon = useCallback(
    (props: IconProps) => <Icon {...props} name='delete' pack={'material'} />,
    []
  )

  const handleSuccessDelete = async (response: ResponseSuccess) => {
    dispatch(
      shopCartSlice.actions.deleteErrorState({
        id: customItemId,
        deleteAllById: true,
      })
    )
    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
    onPressDelete(index)
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const configForDeleteCustomItem =
    getDataConfigForDeleteCustomItem(customItemId)

  const {mutate} = useApiMutation(
    extractKey({
      method: configForDeleteCustomItem.method,
      url: configForDeleteCustomItem.url,
    }),
    {},
    {
      reactMutationOptions: {
        onSuccess: handleSuccessDelete,
        onError: handleError,
      },
      axiosConfig: {
        ...configForDeleteCustomItem,
      },
    },
    true
  )

  const handlePressDelete = useCallback(() => {
    mutate()
  }, [mutate])

  return (
    <View sx={sxStyles.row}>
      <ShopCartCustomItemRowItem
        id={formatToFormItemId(customItemId, NAME_ID)}
        itemId={'name'}
        label={CUSTOM_ITEMS_HEADER_NAME}
        customItemId={customItemId}
        value={customItem.name}
        onUpdateItem={onUpdateItem}
        updateErrorState={updateErrorState}
        isRequired
        inputRef={isNewRowAndFocus ? ref : undefined}
        isNewRowAndFocus={isNewRowAndFocus}
        onBlur={onChangeIsNewRow}
      />
      <ShopCartCustomItemRowItem
        id={formatToFormItemId(customItemId, AMOUNT_ID)}
        itemId={'amount'}
        isNumber
        label={CUSTOM_ITEMS_HEADER_COUNT}
        customItemId={customItemId}
        value={customItem.amount}
        onUpdateItem={onUpdateItem}
        updateErrorState={updateErrorState}
        unitOfMeasure={customItem.unitOfMeasure}
        isAmount
        isRequired
        selectTextOnFocus
      />
      <ShopCartCustomItemRowItem
        id={formatToFormItemId(customItemId, UNIT_OF_MEASURE_ID)}
        itemId={'unitOfMeasure'}
        label={CUSTOM_ITEMS_HEADER_UNIT_OF_MEASURE}
        selectItems={UNIT_OF_MEASURE_OPTIONS}
        customItemId={customItemId}
        value={customItem.unitOfMeasure}
        onUpdateItem={onUpdateItem}
        isRequired
      />
      <ShopCartCustomItemRowItem
        id={formatToFormItemId(customItemId, PRICE_ID)}
        itemId={'price'}
        isNumber
        isCurrency
        label={CUSTOM_ITEMS_HEADER_PRICE_FOR_UNIT}
        maxLength={7}
        customItemId={customItemId}
        value={customItem.price}
        onUpdateItem={onUpdateItem}
        isRequired={false}
      />
      <View sx={sxStyles.buttonWrapper}>
        <Button
          appearance='ghost'
          size='small'
          status='danger'
          accessoryLeft={DeleteIcon}
          onPress={handlePressDelete}
        />
      </View>
    </View>
  )
}

export const ShopCartCustomItemRow = React.memo(ShopCartCustomItemRowComponent)

const applyStyles = (): ObjectsOfSxProps => ({
  row: {
    flex: 1,
    p: 1,
    m: 1,
    flexDirection: ['column', null, 'row'],
    borderWidth: 1,
    borderColor: 'primaryColor100',
  },
  buttonWrapper: {
    flex: [1, null],
    alignItems: 'flex-end',
  },
})
