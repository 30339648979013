import {View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Icon} from 'common/components/Icon'
import {IconButton} from 'common/components/IconButton'
import {useExportToPdf} from 'common/hooks/useExportToPdf'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {ObjectsOfSxProps} from 'layout/layout_types'

type Props = {
  apiUrl: string
  apiParams?: Record<string, any>
  fileName: string
  buttonText?: string
  buttonTextSuffix?: string
  isDisabled?: boolean
}

export const DownloadPdfButton: React.FC<Props> = ({
  apiUrl,
  apiParams,
  fileName,
  buttonText,
  buttonTextSuffix,
  isDisabled,
}) => {
  const {t} = useTranslation()
  const {handleOnPressExport} = useExportToPdf(apiUrl, fileName, apiParams)
  const isSmallDevice = useIsNarrowScreen(1)

  return (
    <View sx={sxStyles.container}>
      <View sx={sxStyles.wrapper}>
        <View sx={sxStyles.buttonPosition}>
          <IconButton
            iconName={'file-download'}
            left
            title={`${t(buttonText ?? 'button.export')}${
              buttonTextSuffix ? ` ${buttonTextSuffix}` : ''
            }`}
            onPress={handleOnPressExport}
            color={'primaryColor500'}
            disabled={isDisabled}
            isMediumButton
            size={'small'}
          />
        </View>
      </View>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    // TODO: fix the first padding in array, its supposed to be 7px not 10px
    paddingTop: [2, null, 28],
    paddingRight: 2,
    right: 0,
  },
  wrapper: {
    flex: 1,
  },
  icon: {
    // padding: [0, null, 1],
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'primaryColor200',
  },
  buttonPosition: {
    flexDirection: 'row',
  },
}
