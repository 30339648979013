import {createAsyncThunk} from '@reduxjs/toolkit'

import {VERSION_REDUCER_STATE_ID} from './version_constants'
import {versionSlice} from './version_slice'
import {refreshApp} from 'login/login_helpers'
import {VersionHashFile} from 'web_version/version_types'

export const setNewVersion = createAsyncThunk(
  VERSION_REDUCER_STATE_ID,
  async (payload: VersionHashFile['versionHash'], {dispatch, getState}) => {
    const {lastAppVersion} = getState()[VERSION_REDUCER_STATE_ID]
    const currentAppVersion = payload
    if (
      lastAppVersion &&
      lastAppVersion !== '' &&
      lastAppVersion !== currentAppVersion
    ) {
      dispatch(
        versionSlice.actions.setNewVersionAvailable({
          newVersionAvailable: true,
        })
      )
    }

    if (currentAppVersion) {
      dispatch(
        versionSlice.actions.setLastAppVersion({
          lastAppVersion: currentAppVersion,
        })
      )
    }
  }
)

export const loadNewVersion = createAsyncThunk(
  VERSION_REDUCER_STATE_ID,
  async (_payload: never, {dispatch}) => {
    dispatch(
      versionSlice.actions.setNewVersionAvailable({newVersionAvailable: false})
    )
    // reload application to load new version
    refreshApp()
  }
)
