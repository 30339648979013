import {BottomTabScreenProps} from '@react-navigation/bottom-tabs'
import {CompositeScreenProps} from '@react-navigation/native'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PriceListScreen} from './PriceListScreen'
import {
  AuthorizedTabParamList,
  PriceListStackParamList,
  RootStackParamList,
} from 'layout/layout_types'

const PriceListStack = createNativeStackNavigator<PriceListStackParamList>()

type Props = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList, 'NavPriceListScreen'>,
  NativeStackScreenProps<RootStackParamList>
>

export const NavPriceListScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <PriceListStack.Navigator
      initialRouteName={'PriceListScreen'}
      screenOptions={{headerShown: false}}
    >
      <PriceListStack.Screen
        name={'PriceListScreen'}
        component={PriceListScreen}
        options={{title: t('routes.priceList')}}
      />
    </PriceListStack.Navigator>
  )
}
