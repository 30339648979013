import {BottomTabScreenProps} from '@react-navigation/bottom-tabs'
import {CompositeScreenProps} from '@react-navigation/native'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ProductScreen} from './ProductScreen'
import {
  AuthorizedTabParamList,
  ProductStackParamList,
  RootStackParamList,
} from 'layout/layout_types'
import {ProductDetailScreen} from 'product/components/ProductDetailScreen'

const ProductStack = createNativeStackNavigator<ProductStackParamList>()

type Props = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList, 'NavProductScreen'>,
  NativeStackScreenProps<RootStackParamList>
>

export const NavProductScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <ProductStack.Navigator
      initialRouteName={'ProductScreen'}
      screenOptions={{headerShown: false}}
    >
      <ProductStack.Screen
        name={'ProductScreen'}
        component={ProductScreen}
        options={{title: t('routes.product')}}
      />
      <ProductStack.Screen
        name={'ProductDetailScreen'}
        component={ProductDetailScreen}
        initialParams={{productId: '1'}}
        options={{title: t('routes.product')}}
      />
    </ProductStack.Navigator>
  )
}
