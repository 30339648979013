import {
  extractKey,
  QueryClientContext,
  useApiMutation,
  useApiQuery,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import {find} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ConfirmModal} from 'common/components/ConfirmModal'
import {IconButton} from 'common/components/IconButton'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError} from 'error/error_helper'
import {layoutSlice} from 'layout/layout_slice'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {loginSlice} from 'login/login_slice'
import {NewOrderShopItem} from 'new_order/components/NewOrderShopItem'
import {getDataConfigForUserShops} from 'new_order/new_order_helpers'
import {SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID} from 'shop_cart/shop_cart_constants'
import {getDataConfigForUpdateShopCart} from 'shop_cart/shop_cart_helpers'
import {UpdateShopCartUserShopId} from 'shop_cart/shop_cart_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'
import {Shop} from 'user/user_types'

interface Props {
  activeShopId?: number
  isInRow?: boolean
  isSmallDevice: boolean
}

export const ChangeShopInShopCart: React.FC<Props> = ({
  activeShopId,
  isInRow,
  isSmallDevice,
}) => {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [pickedShop, setPickedShop] = useState<number>(activeShopId ?? -1)
  const queryClient = useQueryClient({context: QueryClientContext})
  const sxStyles = applyStyles(isInRow)

  const userId = useAppSelector((state) => state.login.loginUser?.id)
  const configForUserShops = getDataConfigForUserShops(userId)

  const {data} = useApiQuery<Shop[]>(
    extractKey({
      params: configForUserShops.params,
      url: configForUserShops.url,
    }),
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForUserShops,
      },
    },
    true
  )

  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const configForUpdateShopCart =
    getDataConfigForUpdateShopCart<UpdateShopCartUserShopId>({
      userShopId: pickedShop,
    })

  const handleSuccessUpdateShop = () => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    queryClient.invalidateQueries()
    setConfirmModalVisible(false)
    dispatch(layoutSlice.actions.setModalSpinnerHidden())
    // TODO: missing messageCode
    dispatch(SnackbarHelpers.getSnackBarSuccess('shopCart.success.changeShop'))
  }
  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(layoutSlice.actions.setModalSpinnerHidden())
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const {mutate, isLoading} = useApiMutation(
    extractKey({
      data: configForUpdateShopCart.data,
      method: configForUpdateShopCart.method,
      url: configForUpdateShopCart.url,
    }),
    configForUpdateShopCart.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdateShop,
        onError: handleError,
      },
      axiosConfig: {
        ...configForUpdateShopCart,
      },
    },
    true
  )

  const handlePressConfirm = () => {
    if (pickedShop === activeShopId) {
      setConfirmModalVisible(false)
    } else {
      mutate()
      dispatch(layoutSlice.actions.setModalSpinnerVisible())
      const findShop = find(data, {id: pickedShop})
      if (data && findShop) {
        dispatch(loginSlice.actions.setActiveShop({shop: findShop}))
      }
    }
  }

  const handlePressCancel = useCallback(() => {
    setConfirmModalVisible(false)
  }, [setConfirmModalVisible])

  const handlePressButton = useCallback(() => {
    setConfirmModalVisible(true)
  }, [setConfirmModalVisible])

  const handlePressShop = useCallback(
    (id: number, isActiveShop: boolean) => {
      if (!isActiveShop) {
        setPickedShop(id)
      }
    },
    [setPickedShop]
  )

  return data && data.length > 1 ? (
    <View sx={sxStyles.buttonWrapper}>
      <IconButton
        iconName={'storefront'}
        left
        title={isSmallDevice ? '' : t('button.changeShop')}
        onPress={handlePressButton}
        color={isSmallDevice ? undefined : 'primaryColor500'}
        appearance={isSmallDevice ? 'ghost' : 'outline'}
        isLoading={isLoading}
        size={isSmallDevice ? 'medium' : 'small'}
        style={isSmallDevice ? styles.button : undefined}
      />
      <ConfirmModal
        isLoading={isLoading}
        isDisabledConfirm={pickedShop < 0}
        modalVisible={confirmModalVisible}
        onPressCancel={handlePressCancel}
        onPressConfirm={handlePressConfirm}
        text={t('shop.text')}
      >
        <View sx={sxStyles.wrapper}>
          {data &&
            data.map((shop) => (
              <View key={shop.id} sx={sxStyles.shopItemWrapper}>
                <NewOrderShopItem
                  id={shop.id}
                  city={shop.city}
                  isActive={pickedShop === shop.id}
                  shop={shop.name}
                  street={shop.street}
                  onPress={handlePressShop}
                />
              </View>
            ))}
        </View>
      </ConfirmModal>
    </View>
  ) : null
}

const styles = {
  button: {
    width: 25,
  },
}

const applyStyles = (isInRow?: boolean): ObjectsOfSxProps => ({
  buttonWrapper: {
    paddingBottom: isInRow ? 0 : [0, null, 2],
    paddingRight: isInRow ? 2 : 0,
  },
  wrapper: {
    alignItems: 'center',
  },
  shopItemWrapper: {
    flex: 1,
    justifyContent: 'center',
  },
})
