import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {
  LoginState,
  LoginPayload,
  ActiveShopPayload,
  IsShopCartEnabledPayload,
  ShopCartItemsCountPayload,
  UpdateLoginUserPayload,
} from './login_types'

const initialState: LoginState = {
  isLoggedIn: false,
  loginUser: null,
  activeShop: null,
  isSetShop: false,
  isShopCartEnabled: false,
  shopCartItemsCount: 0,
}

const checkIsShopEnabled = (loginUser: LoginPayload['loginUser']): boolean => {
  let result = false
  if (
    loginUser &&
    loginUser.userStatus === 'confirmed' &&
    loginUser.priceListStandard
  ) {
    result = true
  }
  return result
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoggedOut: (state) => {
      state.isLoggedIn = false
      state.loginUser = null
    },
    setLoggedIn: (state, action: PayloadAction<LoginPayload>) => {
      state.isLoggedIn = true
      state.loginUser = action.payload.loginUser
      state.isShopCartEnabled = checkIsShopEnabled(action.payload.loginUser)
    },
    updateLoginUser: (state, action: PayloadAction<UpdateLoginUserPayload>) => {
      state.loginUser = {...state.loginUser, ...action.payload.user}
    },
    setActiveShop: (state, action: PayloadAction<ActiveShopPayload>) => {
      state.activeShop = action.payload.shop
      state.isSetShop = action.payload.isSetShop ?? false
    },
    setIsShopCartEnabled: (
      state,
      action: PayloadAction<IsShopCartEnabledPayload>
    ) => {
      state.isShopCartEnabled = action.payload.isShopCartEnabled
    },
    setShopCartCount: (
      state,
      action: PayloadAction<ShopCartItemsCountPayload>
    ) => {
      state.shopCartItemsCount = action.payload.shopCartItemsCount
    },
  },
})
