import {useDripsyTheme, View} from 'dripsy'
import React from 'react'

import {Icon} from 'common/components/Icon'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {isAndroid, isIOS} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

const NewOrderProductListItemShopIconComponent: React.FC = () => {
  const dripsyTheme = useDripsyTheme()
  const sxStyles = applyStyles()
  const isSmall = useIsNarrowScreen(2)
  const isSmallDevice = isAndroid || isIOS || isSmall

  return (
    <View sx={sxStyles.shopCartIcon}>
      <Icon
        name={'shopping-cart'}
        color={dripsyTheme.theme.colors.primaryColor300}
        size={isSmallDevice ? 13 : 18}
      />
    </View>
  )
}

export const NewOrderProductListItemShopIcon = React.memo(
  NewOrderProductListItemShopIconComponent
)

const applyStyles = (): ObjectsOfSxProps => ({
  shopCartIcon: {
    position: 'absolute',
    right: 0,
  },
})
