import {CompositeScreenProps, useNavigation} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
// import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import React, {useCallback /*, useEffect*/} from 'react'
import {useTranslation} from 'react-i18next'
// import {useDispatch} from 'react-redux'

// import {ProductItem} from '../new_order_types'
import {Platform} from 'react-native'

import {NewOrderQuick} from './NewOrderQuick'
import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {AuthorizedLayoutDeleteShopCart} from 'layout/components/AuthorizedLayoutDeleteShopCart'
import {ScreenKeyboardAwareScrollView} from 'layout/components/ScreenKeyboardAwareScrollView'
// import {layoutSlice} from 'layout/layout_slice'
import {
  AuthorizedNavigationProp,
  AuthorizedScreenProps,
  NewOrderQuickStackParamList,
} from 'layout/layout_types'
import {QUICK_PRODUCT_INSTANCE_REDUCER_STATE_GRID_ID} from 'new_order/new_order_constants'
// import {getDataConfigForActiveProducts} from 'new_order/new_order_helpers'
import {SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID} from 'shop_cart/shop_cart_constants'

type Props = CompositeScreenProps<
  NativeStackScreenProps<NewOrderQuickStackParamList, 'NewOrderQuickScreen'>,
  AuthorizedScreenProps
>

export const NewOrderQuickScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  // const dispatch = useDispatch()

  // const configForActiveProducts = getDataConfigForActiveProducts()

  // const productItemsKey = extractKey({
  //   params: configForActiveProducts.params,
  //   url: configForActiveProducts.url,
  // })
  // const {
  //   data = [],
  //   isLoading,
  //   isStale,
  // } = useApiQuery<ProductItem[]>(
  //   productItemsKey,
  //   undefined,
  //   {
  //     reactQueryOptions: {},
  //     axiosConfig: {
  //       ...configForActiveProducts,
  //     },
  //   },
  //   true
  // )

  const shopCartActionButtons = useCallback(
    () => (
      <AuthorizedLayoutDeleteShopCart
        displayDeleteShopCart
        displayGoToShopCart
      />
    ),
    []
  )

  useRefetchQuery(
    [
      // {key: productItemsKey},
      {key: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
      {key: QUICK_PRODUCT_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
    ]
    // isStale
  )

  // useEffect(() => {
  //   if (data && !isLoading) {
  //     dispatch(layoutSlice.actions.setModalSpinnerHidden())
  //   } else {
  //     dispatch(layoutSlice.actions.setModalSpinnerVisible())
  //   }
  // }, [isLoading, data, dispatch])

  const navigation = useNavigation<AuthorizedNavigationProp>()

  return (
    <>
      <Header hasBackButton />
      <ScreenKeyboardAwareScrollView>
        <AuthorizedLayout
          displayActualShop
          subTitle={t('newOrder.quick.subTitle')}
          title={t('newOrder.title')}
          shopCartActionButtons={shopCartActionButtons}
          breadcrumbs={
            <Breadcrumbs>
              <BreadcrumbItem
                label={t('newOrder.order')}
                onPress={() => {
                  navigation.navigate('NavNewOrderScreen', {
                    screen: 'NewOrderScreen',
                  })
                }}
              />
              <BreadcrumbItem label={t('newOrder.quick.subTitle')} isLast />
            </Breadcrumbs>
          }
        >
          {<NewOrderQuick />}
        </AuthorizedLayout>
      </ScreenKeyboardAwareScrollView>
    </>
  )
}
