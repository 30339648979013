type SnackbarColor = 'success' | 'error'

export interface SnackbarData {
  text: string
  color: SnackbarColor
  textParams?: string
}

export interface SnackbarState {
  isVisible: Record<SnackbarType, boolean>
  data: Record<SnackbarType, SnackbarData | undefined>
}

export const SnackbarType = {
  AUTHORIZED: 'AUTHORIZED',
  UNAUTHORIZED: 'UNAUTHORIZED',
} as const
export type SnackbarType = typeof SnackbarType[keyof typeof SnackbarType]

export interface IsVisiblePayload {
  type: SnackbarType
  isVisible: boolean
  data?: SnackbarData
}
