import {useSx, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {IconButton} from 'common/components/IconButton'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  collapsed: boolean
  isFilterActive: boolean
  onPressCollapsed: () => void
  onPressCheckAll: () => void
  onPressUnCheckAll: () => void
}

const CheckBoxButtonsComponent: React.FC<Props> = ({
  collapsed,
  isFilterActive,
  onPressCheckAll,
  onPressUnCheckAll,
  onPressCollapsed,
}) => {
  const {t} = useTranslation()
  const sx = useSx()
  const isSmall = useIsNarrowScreen(0)
  return (
    <View sx={sxStyles.checkBoxButtonWrapper}>
      <View sx={sxStyles.mainButtonWrapper}>
        <IconButton
          color={'primaryColor500'}
          title={t('button.filter')}
          onPress={onPressCollapsed}
          appearance={isFilterActive ? 'filled' : 'outline'}
          iconName={collapsed ? 'menu' : 'sort'}
          size={isSmall ? 'small' : 'medium'}
          left
          style={sx(sxStyles.buttonPaddings)}
        />
      </View>
      {collapsed ? null : (
        <View sx={sxStyles.filterButtons}>
          <View sx={sxStyles.button}>
            <IconButton
              color={'primaryColor200'}
              title={t('button.all')}
              onPress={onPressCheckAll}
              appearance={'outline'}
              iconName={'check-box'}
              size={isSmall ? 'small' : 'medium'}
              left
              style={sx(sxStyles.buttonPaddings)}
            />
          </View>
          <View sx={sxStyles.button}>
            <IconButton
              color={'primaryColor200'}
              title={t('button.cancel')}
              onPress={onPressUnCheckAll}
              appearance={'outline'}
              iconName={'check-box-outline-blank'}
              size={isSmall ? 'small' : 'medium'}
              left
              style={sx(sxStyles.buttonPaddings)}
            />
          </View>
        </View>
      )}
    </View>
  )
}

export const CheckBoxButtons = React.memo(CheckBoxButtonsComponent)

const sxStyles: ObjectsOfSxProps = {
  checkBoxButtonWrapper: {
    flexDirection: 'row',
    mb: 1,
  },
  mainButtonWrapper: {
    flexDirection: ['column', 'row', 'row'],
    pt: [2, null, 2],
    pr: 2,
    alignSelf: ['center', 'flex-start', 'flex-start'],
  },
  buttonPaddings: {
    paddingX: 1,
    paddingY: 1,
  },
  buttons: {
    flexDirection: ['column', null, 'row'],
  },
  filterButtons: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  button: {
    pt: [2, null, 2],
    pb: [0, null, 1],
    pr: 2,
    alignSelf: [null, null, 'flex-start'],
  },
}
