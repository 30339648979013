export const EMAIL = 'email'
export const PASSWORD = 'password'

export const UserRole = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  CUSTOMER: 3,
} as const
export type UserRole = typeof UserRole[keyof typeof UserRole]

export const EmailStatus = {
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
} as const
export type EmailStatus = typeof EmailStatus[keyof typeof EmailStatus]

export const UserStatus = {
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
} as const
export type UserStatus = typeof UserStatus[keyof typeof UserStatus]

export const LOGIN_PREFIX = 'login/'
export const LOGIN_AND_STORE_TOKEN = LOGIN_PREFIX + 'loginAndStoreToken'
export const LOGOUT_CLEAN_DATA = LOGIN_PREFIX + 'logoutCleanData'
