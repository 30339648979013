import {extractKey, QueryClientContext, useApiQuery} from '@sincersoft/fe-core'
import {useQueryClient} from '@tanstack/react-query'
import {useEffect} from 'react'

import {loginSlice} from 'login/login_slice'
import {getDataConfigForCanOrder} from 'order/order_helpers'
import {useAppDispatch} from 'store/redux_hooks'

interface CanOrderResponse {
  canOrder: boolean
}

export const useCanOrder = (isAdmin: boolean): boolean => {
  const configForCanOrder = getDataConfigForCanOrder()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient({context: QueryClientContext})

  const canOrderKey = extractKey({
    url: configForCanOrder.url,
  })
  const {data, isSuccess, isStale, isLoading} = useApiQuery<CanOrderResponse>(
    canOrderKey,
    undefined,
    {
      reactQueryOptions: {
        staleTime: 30000,
      },
      axiosConfig: {
        ...configForCanOrder,
      },
    },
    true
  )

  useEffect(() => {
    if (isStale && !isAdmin) {
      queryClient.invalidateQueries(canOrderKey)
    }
  }, [canOrderKey, isAdmin, isStale, queryClient])

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(
        loginSlice.actions.setIsShopCartEnabled({
          isShopCartEnabled: data.canOrder,
        })
      )
    }
  }, [data, isSuccess, dispatch])

  return isLoading
}
