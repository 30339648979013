import {QueryDefaultInput, QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'
import {UserRole} from 'login/login_constants'

export const USER: QueryDefaultInput = {
  url: Api.user,
}

export const USER_SHOP: QueryDefaultInput = {
  url: Api.userShop,
}

export function getDataConfigForCustomers<TData>(): QueryDefaultInput<TData> {
  return {
    ...USER,
    params: {
      where: {
        role: UserRole.CUSTOMER,
      },
    },
  }
}

export function getDataConfigForUserById<TData>(
  userId?: string | number
): QueryDefaultInput<TData> {
  return {
    ...USER,
    url: `${USER.url}/${userId}`,
  }
}

export function getDataConfigForUserShopDetail<TData>(
  shopId: string | number | null
): QueryDefaultInput<TData> {
  return {
    ...USER_SHOP,
    url: `${USER_SHOP.url}/${shopId}`,
    params: {
      isActive: true,
    },
  }
}

export function getDataConfigForSaveUser<TData>(
  data: TData,
  userId?: string | number
): QueryInput<TData> {
  return {
    url: `${Api.user}/${userId}`,
    method: 'PATCH',
    data,
  }
}

export function getDataConfigForSearch<TData>(
  url: string,
  value: string
): QueryInput<TData> {
  return {
    url,
    params: {
      query: value,
    },
  }
}
