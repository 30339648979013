import {yupResolver} from '@hookform/resolvers/yup'
import {ReducerUtils} from '@sincersoft/core'
import {
  extractGridKey,
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useCallback} from 'react'
import {useForm} from 'react-hook-form'

import {SnackbarHelpers} from 'common/snackbar_helpers'
import {Api} from 'config/app_config'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {shopSchema} from 'form/form_schemas'
import {
  getDataConfigForSaveOrEditUserShop,
  getDataConfigForUserShops,
} from 'new_order/new_order_helpers'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'
import {ADMIN_USER_INSTANCE_REDUCER_STATE_GRID_ID} from 'user/user_constants'
import {Shop, UseFormForUserShopForm, UserShopForm} from 'user/user_types'
import {UserRole} from 'login/login_constants'

interface UpdateShopResponse extends ResponseSuccess {
  updatedUserShop?: Shop
}

interface UseFormForUserShop extends UseFormForUserShopForm {
  handlePressSaveUserShop: () => void
}

export const useFormForUserShop = (
  userId?: number,
  userPrimaryShopId?: number | null
): UseFormForUserShop => {
  const dispatch = useAppDispatch()

  const activeShopId =
    useAppSelector((state) =>
      state.login.loginUser?.role === UserRole.CUSTOMER
        ? state.login.activeShop
        : undefined
    )?.id ?? userPrimaryShopId
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: {errors},
    trigger,
  } = useForm<UserShopForm>({
    resolver: yupResolver(shopSchema),
  })

  const configForUserShops = getDataConfigForUserShops(userId)

  const queryClient = useQueryClient({context: QueryClientContext})

  const refreshGrid = (saveData: Shop) => {
    const userShopGridKey = extractGridKey(Api.userShop, {
      params: {userId, isActive: true, populate: false},
    })
    const allData = queryClient.getQueryData<Shop[]>(userShopGridKey) ?? []
    const updatedData = ReducerUtils.updateObjectInArray(
      allData,
      saveData,
      saveData.id,
      'id',
      ''
    )
    queryClient.setQueryData(userShopGridKey, updatedData)
  }

  const handleSuccessResponse = async (shop: Shop) => {
    const keyUserShops = extractKey({
      params: configForUserShops.params,
      url: configForUserShops.url,
    })
    saveQueryReset()
    refreshGrid(shop)
    await queryClient.invalidateQueries(keyUserShops)
    dispatch(
      SnackbarHelpers.getSnackBarSuccess('response.success.user.userAndShop')
    )
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_USER_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
  }

  const handleSuccessSave = (saveData: UpdateShopResponse) => {
    if (saveData.updatedUserShop) {
      handleSuccessResponse(saveData.updatedUserShop)
    }
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const preparedData = (values: any) => {
    return {
      ...values,
      ...(values?.companyVatNumber
        ? {companyVatNumber: values.companyVatNumber.toUpperCase()}
        : {}),
    }
  }

  const configForSaveOrEdit = getDataConfigForSaveOrEditUserShop(
    true,
    preparedData(getValues()),
    userId,
    activeShopId ?? null
  )

  const {reset: saveQueryReset, mutate} = useApiMutation(
    extractKey({
      data: configForSaveOrEdit.data,
      method: configForSaveOrEdit.method,
      params: configForSaveOrEdit.params,
      url: configForSaveOrEdit.url,
    }),
    configForSaveOrEdit.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessSave,
        onError: handleError,
      },
      axiosConfig: {
        ...configForSaveOrEdit,
      },
    },
    true
  )

  const handlePressSaveUserShop = useCallback(() => {
    mutate()
  }, [mutate])

  return {
    handleUserShopSubmit: handleSubmit,
    userShopControl: control,
    userShopGetValues: getValues,
    userShopReset: reset,
    userShopErrors: errors,
    userShopTrigger: trigger,
    handlePressSaveUserShop,
  }
}
