import {CheckBox} from '@ui-kitten/components'
import {Text, View} from 'dripsy'
import React from 'react'

import {ObjectsOfSxProps} from 'layout/layout_types'

type Props = {
  id: string
  isDisabled?: boolean
  isChecked: boolean
  onChange: (id: string, value: boolean) => void
  title: string
}

export const CheckBoxItemComponent: React.FC<Props> = ({
  id,
  isDisabled = false,
  isChecked,
  onChange,
  title,
}) => {
  const handleChange = (value: boolean) => {
    onChange(id, value)
  }
  return (
    <View sx={sxStyles.checkBox}>
      <CheckBox
        checked={isChecked}
        onChange={handleChange}
        disabled={isDisabled}
      >
        <Text sx={sxStyles.text}>{title}</Text>
      </CheckBox>
    </View>
  )
}

export const CheckBoxItem = React.memo(CheckBoxItemComponent)

const sxStyles: ObjectsOfSxProps = {
  checkBox: {
    py: 1,
  },
  text: {
    flex: 1,
  },
}
