import {SxProp, View} from 'dripsy'
import React from 'react'

import {CheckBoxItem} from 'common/components/CheckBoxItem'
import {CheckBoxData} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'

type Props = {
  checkBoxData: CheckBoxData
  onChange: (id: string, value: boolean) => void
  containerStyle?: SxProp
  isDisabled?: boolean
}

export const CheckBoxes: React.FC<Props> = ({
  checkBoxData,
  isDisabled,
  onChange,
  containerStyle,
}) => (
  <View sx={{...sxStyles.container, ...containerStyle}}>
    {Object.keys(checkBoxData).map((key) => (
      <View key={key} sx={sxStyles.checkBoxWrapper}>
        <CheckBoxItem
          id={key}
          isChecked={checkBoxData[key].isChecked}
          title={checkBoxData[key].title}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      </View>
    ))}
  </View>
)

const sxStyles: ObjectsOfSxProps = {
  container: {
    flexDirection: ['column', null, 'row'],
    flexWrap: [null, null, 'wrap'],
    p: 1,
    mt: 0,
    borderWidth: 1,
    borderTopWidth: [0, null, 1],
    borderColor: 'primaryColor500',
    backgroundColor: 'grayColor50',
  },
  checkBoxWrapper: {
    width: [null, null, '33%'],
  },
}
