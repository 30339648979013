import {Icon, IconProps, Input, InputProps} from '@ui-kitten/components'
import {View, Text, SxProp, useSx} from 'dripsy'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {TouchableWithoutFeedback} from 'react-native'

import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {isAndroid, isIOS} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

export interface UiKInputProps extends InputProps {
  label?: string
  isSecured?: boolean
  isColumn?: boolean
  isNumber?: boolean
  labelStyle?: SxProp
  textStyle?: any
  showErrorInputBorder?: boolean
  inputRef?: React.RefObject<Input>
}

export const UiKInput: React.FC<UiKInputProps> = (props) => {
  const {
    label,
    isSecured,
    isColumn,
    labelStyle,
    textStyle,
    isNumber,
    inputRef,
    ...rest
  } = props
  const isSmall = useIsNarrowScreen(2)
  const isSmallDevice = isAndroid || isIOS || isSmall
  const sx = useSx()
  const {t} = useTranslation()
  const sxStyles = applyStyles({isColumn, labelStyle}, isSmallDevice)

  const inputLabel = useCallback(
    () => (
      <View sx={sxStyles.textWrapper}>
        {label && (
          <Text sx={sxStyles.label} variant={'h6'}>
            {t(label)}
          </Text>
        )}
      </View>
    ),
    [label, sxStyles, t]
  )
  const [secureTextEntry, setSecureTextEntry] = React.useState(isSecured)

  const toggleSecureEntry = () => {
    setSecureTextEntry(!secureTextEntry)
  }

  const renderIcon = (iconProps: IconProps) => (
    <TouchableWithoutFeedback
      onPress={toggleSecureEntry}
      accessibilityRole={'button'}
    >
      <View>
        <Icon
          {...iconProps}
          name={secureTextEntry ? 'visibility-off' : 'visibility'}
          pack={'material'}
        />
      </View>
    </TouchableWithoutFeedback>
  )

  return (
    <View sx={sxStyles.input}>
      {!isColumn && !isSmallDevice && label && inputLabel()}
      <Input
        ref={inputRef}
        accessoryRight={isSecured ? renderIcon : undefined}
        keyboardType={isNumber ? 'numeric' : 'default'}
        size={'medium'}
        {...rest}
        secureTextEntry={secureTextEntry}
        style={{
          ...(isColumn ? {} : sx(sxStyles.inputStyle)),
          ...(props.showErrorInputBorder ? sx(sxStyles.errorInputBorder) : {}),
        }}
        label={label && (isColumn || isSmallDevice) ? inputLabel : undefined}
        textStyle={{
          ...(textStyle ? textStyle : {}),
          ...(isNumber ? {textAlign: 'right', width: '100%'} : {width: '100%'}),
        }}
      />
    </View>
  )
}

const applyStyles = (
  props: Partial<UiKInputProps>,
  isSmallDevice: boolean
): ObjectsOfSxProps => ({
  input: {
    ...(props.isColumn
      ? {}
      : {
          flexDirection: 'row' as const,
          // TODO: refactor - width: '100%' disrupt layout of input with icon
          width: '100%',
          alignSelf: 'center',
          justifyContent: 'space-between',
        }),
    paddingTop: 1,
  },
  textWrapper: {
    ...(props.isColumn
      ? {}
      : {flex: 1, alignSelf: isSmallDevice ? 'flex-start' : 'center'}),
  },
  label: {
    paddingBottom: props.isColumn ? 1 : 0,
    fontSize: [3, null, 4],
    lineHeight: [3, null, 4],
    color: 'black',
    ...props.labelStyle,
  },
  inputStyle: {
    flex: 1,
    textAlign: 'right',
  },
  errorInputBorder: {
    borderWidth: 2,
    borderColor: 'secondaryColor400',
  },
})
