import * as eva from '@eva-design/eva'

import Theme from './app_theme.json'

export const uikTheme = {
  ...eva,
  'color-basic-100': Theme.colors.grayColor50,
  'color-basic-200': Theme.colors.grayColor100,
  'color-basic-300': Theme.colors.grayColor200,
  'color-basic-focus': Theme.colors.grayColor200,
  'color-basic-transparent-300': Theme.colors.grayColor200,
  'color-basic-400': Theme.colors.grayColor300,
  'color-basic-500': Theme.colors.grayColor400,
  'color-basic-600': Theme.colors.grayColor500,
  'color-basic-700': Theme.colors.grayColor600,
  'color-basic-800': Theme.colors.grayColor700,
  'color-basic-900': Theme.colors.grayColor800,
  'color-basic-1000': Theme.colors.grayColor900,
  // 'color-basic-1100': colors.colorBasic1100,
  'color-primary-100': Theme.colors.primaryColor100,
  'color-primary-200': Theme.colors.primaryColor200,
  'color-primary-300': Theme.colors.primaryColor300,
  'color-primary-400': Theme.colors.primaryColor400,
  'color-primary-500': Theme.colors.primaryColor500,
  'color-primary-600': Theme.colors.primaryColor600,
  'color-primary-700': Theme.colors.primaryColor700,
  'color-primary-800': Theme.colors.primaryColor800,
  'color-primary-900': Theme.colors.primaryColor900,
  'color-primary-transparent-100': 'rgba(255, 255, 255, 0.08)',
  'color-primary-transparent-200': 'rgba(150, 255, 180, 0.16)',
  'color-primary-transparent-300': 'rgba(150, 255, 180, 0.24)',
  'color-primary-transparent-400': 'rgba(150, 255, 180, 0.32)',
  'color-primary-transparent-500': 'rgba(150, 255, 180, 0.4)',
  'color-primary-transparent-600': 'rgba(150, 255, 180, 0.48)',
  // 'color-success-100': colors.colorSuccess100,
  // 'color-success-200': colors.colorSuccess200,
  // 'color-success-300': colors.colorSuccess300,
  // 'color-success-400': colors.colorSuccess400,
  // 'color-success-500': colors.colorSuccess500,
  // 'color-success-600': colors.colorSuccess600,
  // 'color-success-700': colors.colorSuccess700,
  // 'color-success-800': colors.colorSuccess800,
  // 'color-success-900': colors.colorSuccess900,
  // 'color-success-transparent-100': colors.colorSuccessTransparent100,
  // 'color-success-transparent-200': colors.colorSuccessTransparent200,
  // 'color-success-transparent-300': colors.colorSuccessTransparent300,
  // 'color-success-transparent-400': colors.colorSuccessTransparent400,
  // 'color-success-transparent-500': colors.colorSuccessTransparent500,
  // 'color-success-transparent-600': colors.colorSuccessTransparent600,
  // 'color-info-100': colors.colorInfo100,
  // 'color-info-200': colors.colorInfo200,
  // 'color-info-300': colors.colorInfo300,
  // 'color-info-400': colors.colorInfo400,
  // 'color-info-500': colors.colorInfo500,
  // 'color-info-600': colors.colorInfo600,
  // 'color-info-700': colors.colorInfo700,
  // 'color-info-800': colors.colorInfo800,
  // 'color-info-900': colors.colorInfo900,
  // 'color-info-transparent-100': colors.colorInfoTransparent100,
  // 'color-info-transparent-200': colors.colorInfoTransparent200,
  // 'color-info-transparent-300': colors.colorInfoTransparent300,
  // 'color-info-transparent-400': colors.colorInfoTransparent400,
  // 'color-info-transparent-500': colors.colorInfoTransparent500,
  // 'color-info-transparent-600': colors.colorInfoTransparent600,
  // 'color-warning-100': colors.colorWarning100,
  // 'color-warning-200': colors.colorWarning200,
  // 'color-warning-300': colors.colorWarning300,
  // 'color-warning-400': colors.colorWarning400,
  // 'color-warning-500': colors.colorWarning500,
  // 'color-warning-600': colors.colorWarning600,
  // 'color-warning-700': colors.colorWarning700,
  // 'color-warning-800': colors.colorWarning800,
  // 'color-warning-900': colors.colorWarning900,
  // 'color-warning-transparent-100': colors.colorWarningTransparent100,
  // 'color-warning-transparent-200': colors.colorWarningTransparent200,
  // 'color-warning-transparent-300': colors.colorWarningTransparent300,
  // 'color-warning-transparent-400': colors.colorWarningTransparent400,
  // 'color-warning-transparent-500': colors.colorWarningTransparent500,
  // 'color-warning-transparent-600': colors.colorWarningTransparent600,
  'color-danger-100': Theme.colors.secondaryColor100,
  'color-danger-200': Theme.colors.secondaryColor200,
  'color-danger-300': Theme.colors.secondaryColor300,
  'color-danger-400': Theme.colors.secondaryColor400,
  'color-danger-500': Theme.colors.secondaryColor500,
  'color-danger-600': Theme.colors.secondaryColor600,
  'color-danger-700': Theme.colors.secondaryColor700,
  'color-danger-800': Theme.colors.secondaryColor800,
  'color-danger-900': Theme.colors.secondaryColor900,
  'color-danger-transparent-100': 'rgba(255, 255, 255, 0.08)',
  'color-danger-transparent-200': 'rgba(255, 128, 128, 0.16)',
  'color-danger-transparent-300': 'rgba(255, 128, 128, 0.24)',
  'color-danger-transparent-400': 'rgba(255, 128, 128, 0.32)',
  'color-danger-transparent-500': 'rgba(255, 128, 128, 0.4)',
  'color-danger-transparent-600': 'rgba(255, 128, 128, 0.48)',
}
