import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {UserState, ModalVisiblePayload} from './user_constants'

const initialState: UserState = {
  modalVisible: false,
  isModalModeEdit: true,
  shopIdForModal: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setModalVisible: (state, action: PayloadAction<ModalVisiblePayload>) => {
      state.modalVisible = action.payload.visible
      state.isModalModeEdit = action.payload.isEdit
      state.shopIdForModal = action.payload.shopId
    },
  },
})
