import {StrictTheme, ThemeMappingType} from '@eva-design/dss'

interface CustomMapping {
  strict: StrictTheme
  components: ThemeMappingType
}

export const UiKittenCustomMapping: CustomMapping = {
  strict: {
    ['text-font-family']: 'Montserrat',
  },
  components: {
    ListItem: {
      meta: {
        scope: 'all',
        parameters: {},
        appearances: {},
        variantGroups: {},
        states: {},
      },
      appearances: {
        default: {
          mapping: {
            backgroundColor: 'white',
            paddingHorizontal: 5,
            paddingVertical: 5,
          },
        },
      },
    },
  },
}
