import {View, Text} from 'dripsy'
import React from 'react'

import {ColumnValue} from 'common/components/ColumnValue'
import {CountryFlag} from 'common/components/CountryFlag'
import {GRID_ROW_HEADER} from 'grid/components/grid_constants'
import {CellRendererParams} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'

export const CountryFlagCellRenderer: React.FC<CellRendererParams> = ({
  value,
  isSmall,
}) => {
  const sxStyles = applyStyles(isSmall)
  const renderFlag = () => <CountryFlag countryName={value} height={18} />
  return value ? (
    isSmall ? (
      <View sx={sxStyles.container}>{renderFlag()}</View>
    ) : (
      <Text sx={sxStyles.container}>{renderFlag()}</Text>
    )
  ) : (
    <ColumnValue value={'item.noCountry'} isSmall={isSmall} isTranslate />
  )
}

const applyStyles = (isSmall?: boolean): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    justifyContent: 'center',
    lineHeight: GRID_ROW_HEADER,
    ...(isSmall
      ? {
          alignItems: 'flex-end',
        }
      : {
          alignItems: 'center',
          textAlignVertical: 'center',
        }),
  },
})
