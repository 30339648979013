import {BottomTabScreenProps} from '@react-navigation/bottom-tabs'
import {CompositeScreenProps} from '@react-navigation/native'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ShopCartScreen} from './ShopCartScreen'
import {
  AuthorizedTabParamList,
  RootStackParamList,
  ShopCartStackParamList,
} from 'layout/layout_types'
import {ShopCartOrderProductDetailScreen} from 'shop_cart/components/ShopCartOrderProductDetailScreen'

const ShopCartStack = createNativeStackNavigator<ShopCartStackParamList>()

type Props = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList, 'NavShopCartScreen'>,
  NativeStackScreenProps<RootStackParamList>
>

export const NavShopCartScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <ShopCartStack.Navigator
      initialRouteName={'ShopCartScreen'}
      screenOptions={{headerShown: false}}
    >
      <ShopCartStack.Screen
        name={'ShopCartScreen'}
        component={ShopCartScreen}
        options={{title: t('routes.shopCart')}}
      />
      <ShopCartStack.Screen
        name={'ShopCartDetailProductScreen'}
        component={ShopCartOrderProductDetailScreen}
        options={{title: t('newOrder.title')}}
      />
    </ShopCartStack.Navigator>
  )
}
