import {UseMutateFunction} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {isEmpty} from 'lodash'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {SnackbarHelpers} from 'common/snackbar_helpers'
import {
  checkIfExists,
  ColumnWithRowNames,
  formatColumnNameFromId,
  formatTranslatedColumns,
} from 'common/validation_helper'
import {ResponseSuccess} from 'error/error_helper'
import {ErrorStateString} from 'shop_cart/shop_cart_types'
import {useAppDispatch} from 'store/redux_hooks'

export const useValidateForm = () => {
  const dispatch = useAppDispatch()
  const {t} = useTranslation()

  const validateFormColumnValues = useCallback(
    (
      errorState: ErrorStateString,
      queryMutate: UseMutateFunction<ResponseSuccess, AxiosError> | null,
      translationPrefix: string,
      hasRows = false
    ) => {
      if (isEmpty(errorState)) {
        // if errorState is empty call queryMutate for save the data
        if (queryMutate) {
          queryMutate()
        }
      } else {
        // prepare columnNames for snackBar
        let columnNames: string[] = []
        let columnNamesWithRows: ColumnWithRowNames = {}
        Object.keys(errorState).forEach((item) => {
          const itemColumnName = formatColumnNameFromId(item)
          const columnNameExists = checkIfExists(columnNames, itemColumnName)

          if (hasRows) {
            const rowNameExists = columnNamesWithRows[itemColumnName]
              ? checkIfExists(
                  columnNamesWithRows[itemColumnName],
                  errorState[item]
                )
              : -1

            if (rowNameExists < 0) {
              columnNamesWithRows = {
                ...columnNamesWithRows,
                [itemColumnName]: [
                  ...(columnNamesWithRows[itemColumnName]
                    ? columnNamesWithRows[itemColumnName]
                    : []),
                  errorState[item],
                ],
              }
            }
          }
          if (columnNameExists < 0) {
            columnNames = [...columnNames, itemColumnName]
          }
        })
        // display snackBar with translate columnNames
        dispatch(
          SnackbarHelpers.getSnackBarErrorWithText(
            `${translationPrefix}.errorFormat`,
            true,
            formatTranslatedColumns(
              t,
              columnNames,
              translationPrefix,
              columnNamesWithRows
            )
          )
        )
      }
    },
    [dispatch, t]
  )

  return {
    validateFormColumnValues,
  } as const
}
