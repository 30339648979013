import React from 'react'

interface Props {
  children: React.ReactNode
}

export const UpdateProvider: React.FC<Props> = (props) => {
  const {children} = props
  return <>{children}</>
}
