import {View, Text, DripsyCustomTheme, useSx} from 'dripsy'
import {isEmpty} from 'lodash'
import React from 'react'
import {Control, FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {NativeSyntheticEvent, TextInputKeyPressEventData} from 'react-native'

import {IconButton} from 'common/components/IconButton'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {InputController} from 'form/components/InputController'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {EMAIL, PASSWORD} from 'login/login_constants'
// import {Notice} from 'common/components/Notice'

interface LoginFormProps {
  control: Control<any>
  displayNotice: boolean
  errors: FieldErrors
  handlePressLogin: () => void
  handlePressForgottenPassword: () => void
  handlePressDeleteAccount: () => void
  isLoading: boolean
  noticeText: string
  handleKeyPress: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
}

export const LoginForm: React.FC<LoginFormProps> = ({
  control,
  // displayNotice,
  errors,
  handlePressLogin,
  handlePressForgottenPassword,
  handlePressDeleteAccount,
  isLoading,
  // noticeText,
  handleKeyPress,
}) => {
  const sxStyles = useThemeSxStyles(applyStyles)
  const sx = useSx()
  const {t} = useTranslation()
  return (
    <View sx={sxStyles.containerWrapper}>
      <View sx={sxStyles.container}>
        <Text sx={sxStyles.title} variant={'h6Bold'}>
          {t('login.title')}
        </Text>
        <View
          sx={sxStyles.formWrapper}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          accessibilityRole={isWeb ? 'form' : 'none'}
        >
          <InputController
            control={control}
            id={EMAIL}
            isColumn
            label={'login.loginLabel'}
            error={errors[EMAIL]}
            hideErrorInput={isEmpty(errors[EMAIL])}
            onKeyPress={handleKeyPress}
            keyboardType={'email-address'}
            autoCapitalize={'none'}
          />
          <InputController
            control={control}
            id={PASSWORD}
            isColumn
            label={'login.passwordLabel'}
            error={errors[PASSWORD]}
            hideErrorInput={isEmpty(errors[PASSWORD])}
            isSecured
            onKeyPress={handleKeyPress}
            autoCapitalize={'none'}
          />
        </View>
        {/*{displayNotice && <Notice color={'error'} text={noticeText} />}*/}
        <View sx={sxStyles.buttons}>
          <IconButton
            iconName={'person'}
            left
            isMediumButton
            color={'primaryColor500'}
            onPress={handlePressLogin}
            title={t('button.loginUser')}
            isLoading={isLoading}
            size={'medium'}
            style={sx(sxStyles.loginButton)}
          />
          <View sx={sxStyles.secondButtons}>
            <View sx={sxStyles.buttonTopPadding}>
              <IconButton
                iconName={'restore'}
                left
                isMediumButton
                color={'grayColor800'}
                onPress={handlePressForgottenPassword}
                title={t('button.forgottenPassword')}
                appearance={'ghost'}
                status={'basic'}
                size={'medium'}
              />
            </View>
            <View sx={sxStyles.buttonTopPadding}>
              <IconButton
                iconName={'person-remove-alt-1'}
                left
                isMediumButton
                color={'grayColor800'}
                onPress={handlePressDeleteAccount}
                title={t('button.deleteAccount')}
                appearance={'ghost'}
                status={'basic'}
                size={'medium'}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  containerWrapper: {
    backgroundColor: 'primaryColor100',
    flex: [1, null, 1],
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column' as const,
    alignSelf: [null, 'center'],
    width: ['100%'],
    maxWidth: [null, 380],
    paddingX: 2,
  },
  formWrapper: {
    alignItems: 'center',
    // TODO: flex string value causes an error on iOS
    // flex: [1, null, 'unset'],
    flex: [1, null, null],
    justifyContent: 'center',
    width: '100%',
  },
  buttons: {
    flexDirection: ['row-reverse' as const, null, 'column' as const],
    justifyContent: ['space-between', null, 'center'],
    paddingBottom: [2, null, 4],
    paddingTop: [6, null, 7, null, 8],
    alignItems: 'center',
  },
  title: {
    alignSelf: 'center',
    color: 'primaryColor700',
    paddingTop: 4,
    paddingBottom: 3,
    fontSize: [theme.text.h6Bold.fontSize, null, theme.text.h4Bold.fontSize],
  },
  input: {
    paddingBottom: 5,
    flex: 1,
    width: '100%',
  },
  buttonTopPadding: {
    paddingTop: [null, null, 6],
  },
  secondButtons: {
    flexDirection: ['column' as const],
    paddingTop: [null, null, 6],
  },
})
