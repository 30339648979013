import {QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'

export function getDataConfigForReport<TData>(data: TData): QueryInput<TData> {
  return {
    url: Api.userReport,
    method: 'POST',
    data,
  }
}
