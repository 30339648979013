import {Text, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {IconButton} from 'common/components/IconButton'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {refreshApp} from 'login/login_helpers'
const ErrorBoundaryContentComponent = () => {
  const sxStyles = applyStyles()
  const {t} = useTranslation()

  const handlePressRefresh = () => {
    refreshApp()
  }

  return (
    <View sx={sxStyles.contentWrapper}>
      <View sx={sxStyles.content}>
        <Text sx={sxStyles.title} variant={'h6Bold'}>
          {t('errorBoundary.title')}
        </Text>
        <Text sx={sxStyles.subTitle} variant={'body2Bold'}>
          {t('errorBoundary.subTitle')}
        </Text>
        <View sx={sxStyles.button}>
          <IconButton
            iconName={'refresh'}
            left
            title={t('button.refresh')}
            onPress={handlePressRefresh}
            color={'secondaryColor500'}
            status={'danger'}
            size={'small'}
          />
        </View>
      </View>
    </View>
  )
}

export const ErrorBoundaryContent = React.memo(ErrorBoundaryContentComponent)

const applyStyles = (): ObjectsOfSxProps => ({
  contentWrapper: {
    flex: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    px: [3, null, 4],
    width: [300, 500, 600],
    alignSelf: 'center',
  },
  content: {
    backgroundColor: 'white',
    borderColor: 'secondaryColor400',
    borderWidth: 1,
    borderRadius: 5,
  },
  title: {
    px: 4,
    pt: 4,
    pb: 2,
    color: 'secondaryColor500',
    textAlign: 'center',
  },
  subTitle: {
    px: 4,
    py: 2,
    color: 'secondaryColor500',
    textAlign: 'center',
  },
  button: {
    alignItems: 'center',
    py: 2,
  },
})
