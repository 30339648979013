import {yupResolver} from '@hookform/resolvers/yup'
import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {SortUtils} from '@sincersoft/core'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
  useApiQuery,
} from '@sincersoft/fe-core'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {useValidateForm} from 'common/hooks/useValidateForm'
import {setNavigationTimeout} from 'common/navigation_thunks'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {shopCartCustomerNoteSchema} from 'form/form_schemas'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {AuthorizedLayoutDeleteShopCart} from 'layout/components/AuthorizedLayoutDeleteShopCart'
import {AuthorizedLayoutSaveToTemplate} from 'layout/components/AuthorizedLayoutSaveToTemplate'
import {ScreenKeyboardAwareScrollView} from 'layout/components/ScreenKeyboardAwareScrollView'
import {
  AuthorizedScreenProps,
  ShopCartStackParamList,
} from 'layout/layout_types'
import {getDataConfigForCreateOrder} from 'order/order_helpers'
import {ShopCartContent} from 'shop_cart/components/ShopCartContent'
import {ShopCartCustomItemsContent} from 'shop_cart/components/ShopCartCustomItemsContent'
import {ShopCartEmpty} from 'shop_cart/components/ShopCartEmpty'
import {ShopCartGrids} from 'shop_cart/components/ShopCartGrids'
import {SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID} from 'shop_cart/shop_cart_constants'
import {
  getDataConfigForCart,
  getDataConfigForCreateCustomItem,
  getDataConfigForShopCartItemsCount,
  getDataConfigForUpdateShopCart,
} from 'shop_cart/shop_cart_helpers'
import {ShopCart, UpdateShopCartCustomerNote} from 'shop_cart/shop_cart_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

const DATA_FOR_CREATE_CUSTOM_ITEM = {
  amount: 0,
  unitOfMeasure: 'kg',
}

type Props = CompositeScreenProps<
  NativeStackScreenProps<ShopCartStackParamList, 'ShopCartScreen'>,
  AuthorizedScreenProps
>

export const ShopCartScreen: React.FC<Props> = ({navigation, route}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {validateFormColumnValues} = useValidateForm()
  const queryClient = useQueryClient({context: QueryClientContext})
  const isShopCartEnabled = useAppSelector(
    (state) => state.login.isShopCartEnabled
  )
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    resolver: yupResolver(shopCartCustomerNoteSchema),
  })

  const customerNote = watch('customerNote')

  const dataProcessing = useAppSelector(
    (state) => state[SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID].dataProcessing
  )

  const errorState = useAppSelector((state) => state.shopCart.errorState)

  const configForShopCart = getDataConfigForCart()
  const shopCartKey = extractKey({
    url: configForShopCart.url,
  })
  const {data, isStale} = useApiQuery<ShopCart>(
    shopCartKey,
    undefined,
    {
      axiosConfig: {
        ...configForShopCart,
      },
    },
    true
  )

  const configForUpdateShopCartCustomerNote =
    getDataConfigForUpdateShopCart<UpdateShopCartCustomerNote>({
      customerNote: customerNote ?? data?.customerNote ?? '',
    })

  const configForShopCartItemsCount = getDataConfigForShopCartItemsCount()

  const invalidateQueries = useCallback(() => {
    const queryShopCartKey = extractKey({
      url: configForShopCart.url,
    })
    queryClient.invalidateQueries(queryShopCartKey)
    const shopCartItemsCountKey = extractKey({
      params: configForShopCartItemsCount.params,
      url: configForShopCartItemsCount.url,
    })
    queryClient.invalidateQueries(shopCartItemsCountKey)
  }, [queryClient, configForShopCartItemsCount, configForShopCart])

  const handleSuccessUpdate = async (response: ResponseSuccess) => {
    invalidateQueries()
    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const handleSuccessCreate = async (response: ResponseSuccess) => {
    await queryClient.refetchQueries(
      extractKey({
        url: configForShopCart.url,
      })
    )
    invalidateQueries()
    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
    dispatch(
      setNavigationTimeout({
        cb: () => {
          navigation.navigate('NavNewOrderScreen', {
            screen: 'NewOrderScreen',
          })
        },
      })
    )
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const updateCustomerNoteQuery = useApiMutation(
    extractKey({
      data: configForUpdateShopCartCustomerNote.data,
      method: configForUpdateShopCartCustomerNote.method,
      url: configForUpdateShopCartCustomerNote.url,
    }),
    configForUpdateShopCartCustomerNote.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdate,
        onError: handleError,
      },
      axiosConfig: {
        ...configForUpdateShopCartCustomerNote,
      },
    },
    true
  )
  const timeZone = {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}
  const configForCreateOrder = getDataConfigForCreateOrder(timeZone)

  const {mutate: createOrderQueryMutate, isLoading: createOrderQueryIsLoading} =
    useApiMutation(
      extractKey({
        method: configForCreateOrder.method,
        url: configForCreateOrder.url,
      }),
      configForCreateOrder.data,
      {
        reactMutationOptions: {
          onSuccess: handleSuccessCreate,
          onError: handleError,
        },
        axiosConfig: {
          ...configForCreateOrder,
        },
      },
      true
    )

  useEffect(() => {
    if (data) {
      reset({
        customerNote: data.customerNote,
      })
    }
  }, [data, reset])

  const getSubTitle = () => {
    return {
      shopInfo: `\n ${data?.userShopId.name}, ${data?.userShopId.city}, ${data?.userShopId.street} ${data?.userShopId.streetNumber}`,
    }
  }

  const handleBlurCustomerNote = useCallback(() => {
    if (isDirty) {
      updateCustomerNoteQuery.mutate()
    }
  }, [isDirty, updateCustomerNoteQuery])

  const handlePressOrder = useCallback(async () => {
    validateFormColumnValues(
      errorState,
      createOrderQueryMutate,
      'shopCart.validation',
      true
    )
  }, [createOrderQueryMutate, errorState, validateFormColumnValues])

  const handlePressRoute = useCallback(() => {
    navigation.navigate('NavNewOrderScreen', {
      screen: 'NewOrderScreen',
    })
  }, [navigation])

  useRefetchQuery(
    [
      {key: shopCartKey},
      {key: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
    ],
    isStale
  )

  const shopCartActionButtons = useCallback(
    () => (
      <>
        <AuthorizedLayoutDeleteShopCart
          activeShopId={data?.userShopId.id}
          deleteShopCartWithNavigate
          displayDeleteShopCart
          isRow
        />
        {data && (data.cartItems.length > 0 || data.customItems.length > 0) && (
          <AuthorizedLayoutSaveToTemplate isInShopCart />
        )}
      </>
    ),
    [data]
  )

  const configForCreateCustomItem = getDataConfigForCreateCustomItem({
    ...DATA_FOR_CREATE_CUSTOM_ITEM,
    name: t('shopCart.customItems.defaultName'),
  })

  const {mutate: createCustomQueryMutate} = useApiMutation(
    extractKey({
      method: configForCreateCustomItem.method,
      url: configForCreateCustomItem.url,
    }),
    configForCreateCustomItem.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdate,
        onError: handleError,
      },
      axiosConfig: {
        ...configForCreateCustomItem,
      },
    },
    true
  )

  const handlePressAddNewRow = useCallback(() => {
    createCustomQueryMutate()
  }, [createCustomQueryMutate])

  const handlePressDeleteRow = useCallback(() => {
    invalidateQueries()
  }, [invalidateQueries])

  const handleUpdateItem = useCallback(() => {
    invalidateQueries()
  }, [invalidateQueries])

  const sortedCustomItems = useMemo(
    () =>
      data?.customItems
        ? SortUtils.sortDataByMultipleColumns(data?.customItems, [
            {
              colId: 'id',
              sort: 'asc',
            },
          ])
        : [],
    [data?.customItems]
  )

  const isDisabledButton = useMemo(
    () => dataProcessing || updateCustomerNoteQuery.isLoading,
    [dataProcessing, updateCustomerNoteQuery.isLoading]
  )

  return (
    <>
      <ScreenKeyboardAwareScrollView>
        <Header />
        <AuthorizedLayout
          title={'shopCart.title'}
          subTitle={data ? 'shopCart.subTitle' : undefined}
          subTitleValue={data ? getSubTitle() : undefined}
          shopCartActionButtons={shopCartActionButtons}
        >
          {data ? (
            <>
              {data.cartItems.length > 0 ? (
                <ShopCartGrids />
              ) : (
                <ShopCartEmpty navigation={navigation} route={route} />
              )}
              <ShopCartCustomItemsContent
                onPressAddNewRow={handlePressAddNewRow}
                onPressDeleteRow={handlePressDeleteRow}
                onUpdateItem={handleUpdateItem}
                customItems={sortedCustomItems}
              />
              {data.cartItems.length > 0 || data.customItems.length > 0 ? (
                <ShopCartContent
                  control={control}
                  isDisabled={isDisabledButton}
                  isShopCartEnabled={isShopCartEnabled}
                  isLoading={createOrderQueryIsLoading}
                  onPressOrder={handlePressOrder}
                  onBlurCustomerNote={handleSubmit(handleBlurCustomerNote)}
                  totalPrice={data?.totalPrice ?? 0}
                  onPressRoute={handlePressRoute}
                />
              ) : null}
            </>
          ) : (
            <ShopCartEmpty navigation={navigation} route={route} />
          )}
        </AuthorizedLayout>
      </ScreenKeyboardAwareScrollView>
    </>
  )
}
