import {
  createBottomTabNavigator,
  BottomTabBarProps,
} from '@react-navigation/bottom-tabs'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {useDripsyTheme} from 'dripsy'
import React, {useCallback} from 'react'
import {useWindowDimensions} from 'react-native'

import {AuthorizedTabParamList, RootStackParamList} from '../layout_types'
import {isBottomBarVisible} from 'common/dimension_helpers'
import {useCanOrder} from 'common/hooks/useCanOrder'
import {useGetItemCount} from 'common/hooks/useGetItemCount'
import {AdminRoutes, ClientRoutes} from 'header/header_constants'
import {BottomTabBar} from 'layout/components/BottomTabBar'
import {UserRole} from 'login/login_constants'
import {NavNewOrderScreen} from 'new_order/components/NavNewOrderScreen'
import {NavAdminOrderScreen} from 'order/components/NavAdminOrderScreen'
import {NavOrderScreen} from 'order/components/NavOrderScreen'
import {NavOrderTemplateListScreen} from 'order_template/components/NavOrderTemplateListScreen'
import {NavAdminPriceListScreen} from 'price_list/components/NavAdminPriceListScreen'
import {NavPriceListScreen} from 'price_list/components/NavPriceListScreen'
import {NavProductScreen} from 'product/components/NavProductScreen'
import {NavProductCategoryScreen} from 'product_category/components/NavProductCategoryScreen'
import {NavProfileScreen} from 'profile/components/NavProfileScreen'
import {NavReportScreen} from 'report/components/NavReportScreen'
import {NavShopCartScreen} from 'shop_cart/components/NavShopCartScreen'
import {useAppSelector} from 'store/redux_hooks'
import {NavUserScreen} from 'user/components/NavUserScreen'

const TabNavigator = createBottomTabNavigator<AuthorizedTabParamList>()

type Props = NativeStackScreenProps<
  RootStackParamList,
  'NavAuthorizedHomeScreen'
>

export const NavAuthorizedHomeScreen: React.FC<Props> = () => {
  const windowDimensions = useWindowDimensions()
  const {theme} = useDripsyTheme()

  const userRole = useAppSelector((state) => state.login.loginUser?.role)
  const isShopCartEnabled = useAppSelector(
    (state) => state.login.isShopCartEnabled
  )

  const isAdmin =
    userRole === UserRole.SUPER_ADMIN || userRole === UserRole.ADMIN

  const canOrderIsLoading = useCanOrder(isAdmin)
  const getItemCountIsLoading = useGetItemCount()

  const tabBar = useCallback(
    (props: BottomTabBarProps) => (
      <BottomTabBar
        routes={isAdmin ? AdminRoutes : ClientRoutes}
        isShopCartEnabled={isShopCartEnabled}
        {...props}
      />
    ),
    [isAdmin, isShopCartEnabled]
  )

  return getItemCountIsLoading || canOrderIsLoading ? null : (
    <TabNavigator.Navigator
      screenOptions={{headerShown: false}}
      tabBar={(props) =>
        isBottomBarVisible(theme, windowDimensions.width) ? tabBar(props) : null
      }
    >
      {isAdmin ? (
        <>
          <TabNavigator.Screen
            name={'NavAdminOrderScreen'}
            component={NavAdminOrderScreen}
          />
          <TabNavigator.Screen
            name={'NavUserScreen'}
            component={NavUserScreen}
          />
          <TabNavigator.Screen
            name={'NavProductScreen'}
            component={NavProductScreen}
          />
          <TabNavigator.Screen
            name={'NavProductCategoryScreen'}
            component={NavProductCategoryScreen}
          />
          <TabNavigator.Screen
            name={'NavAdminPriceListScreen'}
            component={NavAdminPriceListScreen}
          />
        </>
      ) : (
        <>
          <TabNavigator.Screen
            name={'NavNewOrderScreen'}
            component={NavNewOrderScreen}
          />
          <TabNavigator.Screen
            name={'NavOrderScreen'}
            component={NavOrderScreen}
            options={{unmountOnBlur: true}}
          />
          <TabNavigator.Screen
            name={'NavOrderTemplateListScreen'}
            component={NavOrderTemplateListScreen}
          />
          <TabNavigator.Screen
            name={'NavPriceListScreen'}
            component={NavPriceListScreen}
          />
          <TabNavigator.Screen
            name={'NavShopCartScreen'}
            component={NavShopCartScreen}
          />
        </>
      )}
      <TabNavigator.Screen
        name={'NavProfileScreen'}
        component={NavProfileScreen}
      />
      <TabNavigator.Screen
        name={'NavReportScreen'}
        component={NavReportScreen}
      />
    </TabNavigator.Navigator>
  )
}
