import {View} from 'dripsy'
import React, {useCallback, useState} from 'react'
import {Platform, StyleSheet} from 'react-native'
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'

import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  withoutWrapper?: boolean
  children: React.ReactNode
  enableScroll?: boolean
  scrollOnlyWithKeyboardOpen?: boolean
  shouldPersistTaps?: boolean | 'always' | 'never' | 'handled' | undefined
}

export const ScreenKeyboardAwareScrollView: React.FC<Props> = ({
  children,
  withoutWrapper,
  enableScroll = false,
  shouldPersistTaps = 'always',
  scrollOnlyWithKeyboardOpen = false,
}) => {
  const [enableScrollWithKeyboard, setEnableScrollWithKeyboard] =
    useState<boolean>(enableScroll)

  const handleKeyboardWillShow = useCallback(() => {
    if (scrollOnlyWithKeyboardOpen && !enableScroll) {
      setEnableScrollWithKeyboard(true)
    }
  }, [enableScroll, scrollOnlyWithKeyboardOpen])
  const handleKeyboardWillHide = useCallback(() => {
    if (scrollOnlyWithKeyboardOpen) {
      setEnableScrollWithKeyboard(false)
    }
  }, [scrollOnlyWithKeyboardOpen])

  const content = () => (
    <KeyboardAwareScrollView
      enableOnAndroid={true}
      style={styles.scrollView}
      // TODO: following line disables scrolling
      contentContainerStyle={styles.scrollViewContent}
      extraScrollHeight={-0}
      // added because on login screen it sometimes didn't scroll properly and user wouldn't be able to see input
      keyboardOpeningTime={0}
      // added property to control where we can scroll KeyboardAwareScrollView
      scrollEnabled={enableScrollWithKeyboard}
      // added property to handle taps, this should be set to true when we disable scrolling of KeyboardAwareScrollView.
      keyboardShouldPersistTaps={shouldPersistTaps}
      onKeyboardWillShow={
        Platform.OS === 'ios' ? handleKeyboardWillShow : undefined
      }
      onKeyboardWillHide={
        Platform.OS === 'ios' ? handleKeyboardWillHide : undefined
      }
    >
      {children}
    </KeyboardAwareScrollView>
  )
  return withoutWrapper ? (
    content()
  ) : (
    <View sx={sxStyles.container}>{content()}</View>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  scrollViewContent: {
    flexGrow: 1,
  },
})

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
  },
}
