import {BottomTabScreenProps} from '@react-navigation/bottom-tabs'
import {CompositeScreenProps} from '@react-navigation/native'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ProfileScreen} from './ProfileScreen'
import {
  AuthorizedTabParamList,
  ProfileStackParamList,
  RootStackParamList,
} from 'layout/layout_types'
import {ProfileChangePasswordScreen} from 'profile/components/ProfileChangePasswordScreen'

const ProfileStack = createNativeStackNavigator<ProfileStackParamList>()

type Props = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList, 'NavProfileScreen'>,
  NativeStackScreenProps<RootStackParamList>
>

export const NavProfileScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <ProfileStack.Navigator
      initialRouteName={'ProfileScreen'}
      screenOptions={{headerShown: false}}
    >
      <ProfileStack.Screen
        name={'ProfileScreen'}
        component={ProfileScreen}
        options={{title: t('routes.profile')}}
      />
      <ProfileStack.Screen
        name={'ChangePasswordScreen'}
        component={ProfileChangePasswordScreen}
        options={{title: t('routes.changePassword')}}
      />
    </ProfileStack.Navigator>
  )
}
