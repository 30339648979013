import {yupResolver} from '@hookform/resolvers/yup'
import {TypOrderBy} from '@sincersoft/core'
import {Modal} from '@ui-kitten/components'
import {View, Text} from 'dripsy'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {IconButton} from 'common/components/IconButton'
import {SelectController} from 'form/components/SelectController'
import {sortSchema} from 'form/form_schemas'
import {DEFAULT_SORT_TYPE} from 'grid/components/grid_constants'
import {Sort} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'

export interface SortForm {
  columnName: string | null
  type: Sort
}

interface NoticeProps {
  modalVisible: boolean
  onPressCancelSort: () => void
  onPressHideModal: () => void
  onPressSort: (columnName: string, typeOrderBy: TypOrderBy) => void
  sortingOptions?: Sort[]
  translatePrefix?: string
}

export const SortModal: React.FC<NoticeProps> = ({
  modalVisible,
  onPressCancelSort,
  onPressHideModal,
  onPressSort,
  sortingOptions,
  translatePrefix,
}) => {
  const {t} = useTranslation()

  // const [isLoading, setisLoading] = useState(false)

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: {errors},
  } = useForm<SortForm>({
    defaultValues: {
      type: DEFAULT_SORT_TYPE[0].id,
    },
    resolver: yupResolver(sortSchema),
  })

  const columnNameValue = watch('columnName')

  const onPresSort = () => {
    const data = getValues()
    // setIsLoading(true)
    if (data.columnName && data.type) {
      onPressSort(data.columnName, data.type)
    }
  }

  const handlePressCancel = () => {
    onPressCancelSort()
    reset({
      columnName: null,
      type: DEFAULT_SORT_TYPE[0].id,
    })
    onPressHideModal()
  }

  const handlePressBack = () => {
    onPressHideModal()
  }
  //TODO: add isSmall and apply style only if device is small
  return (
    <Modal
      visible={modalVisible}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => onPressHideModal()}
    >
      <View sx={sxStyles.container}>
        <Text sx={sxStyles.text} variant={'body1Bold'}>
          {t('sort.title')}
        </Text>
        {sortingOptions && (
          <SelectController
            name={'columnName'}
            idProperty={'id'}
            displayLabelProperty={'name'}
            control={control}
            label={t('sort.columnName')}
            options={sortingOptions}
            translatePrefix={translatePrefix}
            error={errors.columnName}
            hideErrorInput={false}
          />
        )}
        <SelectController
          name={'type'}
          idProperty={'id'}
          displayLabelProperty={'name'}
          control={control}
          label={t('sort.type')}
          options={DEFAULT_SORT_TYPE}
          translatePrefix={'sort'}
          hideErrorInput={false}
        />
        <View sx={sxStyles.buttons}>
          <IconButton
            iconName={'arrow-back'}
            left
            title={t('button.back')}
            onPress={handlePressBack}
            color={'primaryColor500'}
            appearance={'outline'}
            size={'small'}
            style={styles.icon}
          />
          <IconButton
            iconName={'refresh'}
            left
            title={t('button.refresh')}
            onPress={handlePressCancel}
            color={'secondaryColor500'}
            appearance={'ghost'}
            status={'danger'}
            size={'small'}
            disabled={!columnNameValue}
            style={styles.icon}
          />
          <IconButton
            iconName={'sort'}
            left
            title={t('button.sort')}
            onPress={handleSubmit(onPresSort)}
            // isLoading={isLoading}
            color={'primaryColor500'}
            size={'small'}
            style={styles.icon}
          />
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  icon: {
    paddingHorizontal: 0,
    marginHorizontal: 3,
  },
})

const sxStyles: ObjectsOfSxProps = {
  container: {
    alignSelf: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    minWidth: 250,
    maxWidth: [300, 320, 500],
    padding: 2,
    marginY: 2,
    width: '100%',
    borderRadius: 15,
  },
  text: {
    textAlign: 'center',
    flex: 1,
    mt: 2,
    color: 'primaryColor500',
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
  },
  buttons: {
    marginTop: 2,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}
