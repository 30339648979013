import {yupResolver} from '@hookform/resolvers/yup'
import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
  useApiQuery,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {Api} from 'config/app_config'
import {ApiError} from 'error/error_helper'
import {administratorNoteSchema} from 'form/form_schemas'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {AuthorizedLayoutExportButton} from 'layout/components/AuthorizedLayoutExportButton'
import {ScreenKeyboardAwareScrollView} from 'layout/components/ScreenKeyboardAwareScrollView'
import {
  AdminOrderStackParamList,
  AuthorizedScreenProps,
  ObjectsOfSxProps,
} from 'layout/layout_types'
import {OrderDeleteButton} from 'order/components/OrderDeleteButton'
import {OrderDetailCustomItems} from 'order/components/OrderDetailCustomItems'
import {OrderDetailInfo} from 'order/components/OrderDetailInfo'
import {OrderDetailItems} from 'order/components/OrderDetailItems'
import {
  ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID,
  ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
} from 'order/order_constants'
import {
  getDataConfigForOrderDetail,
  getDataConfigForUpdateAdminNote,
} from 'order/order_helpers'
import {OrderDetail} from 'order/order_types'
import {UpdateAdminNote} from 'shop_cart/shop_cart_types'
import {useAppDispatch} from 'store/redux_hooks'

type Props = CompositeScreenProps<
  NativeStackScreenProps<AdminOrderStackParamList, 'AdminOrderDetailScreen'>,
  AuthorizedScreenProps
>

export const AdminOrderDetailScreen: React.FC<Props> = ({
  route,
  navigation,
}) => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient({context: QueryClientContext})
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    resolver: yupResolver(administratorNoteSchema),
  })
  const administratorNote = watch('administratorNote')
  const {t} = useTranslation()

  const dataConfigForPriceListDetail = getDataConfigForOrderDetail(
    route.params.id
  )
  const orderDetailKey = extractKey({
    params: dataConfigForPriceListDetail.params,
    url: dataConfigForPriceListDetail.url,
  })

  const {data, isStale} = useApiQuery<OrderDetail>(
    orderDetailKey,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...dataConfigForPriceListDetail,
      },
    },
    true
  )

  useEffect(() => {
    if (data) {
      reset({
        administratorNote: data.administratorNote,
      })
    }
  }, [data, reset])

  const configForUpdateAdminNote =
    getDataConfigForUpdateAdminNote<UpdateAdminNote>(route.params.id, {
      administratorNote:
        administratorNote ?? (data && data ? data.administratorNote : ''),
    })

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const refreshOrderGrid = () => {
    dispatch(
      gridSlice.actions.setGridPagination({
        pagination: {
          page: 1,
        },
        stateId: ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.resetGridData({
        customPagination: true,
        stateId: ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    queryClient.invalidateQueries(orderDetailKey)
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
  }

  const handleSuccessShipOrder = () => {
    refreshOrderGrid()
    navigation.navigate('NavAdminOrderScreen', {screen: 'AdminOrderScreen'})
  }

  const handleSuccessUpdateAdminQuery = () => {
    refreshOrderGrid()
    dispatch(SnackbarHelpers.getSnackBarSuccess('order.update.success'))
  }

  const updateAdminNoteQuery = useApiMutation(
    extractKey({
      data: configForUpdateAdminNote.data,
      method: configForUpdateAdminNote.method,
      url: configForUpdateAdminNote.url,
    }),
    configForUpdateAdminNote.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdateAdminQuery,
        onError: handleError,
      },
      axiosConfig: {
        ...configForUpdateAdminNote,
      },
    },
    true
  )

  const handleBlurAdminNote = () => {
    if (isDirty) {
      updateAdminNoteQuery.mutate()
    }
  }

  useRefetchQuery(
    [
      {key: orderDetailKey},
      {key: ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
    ],
    isStale
  )

  const apiParams = useMemo(
    () => ({
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
    []
  )

  const actionButtons = useCallback(
    () => (
      <AuthorizedLayoutExportButton
        apiUrl={`${Api.order}/${Api.downloadAction}/${data?.documentIdentifier}`}
        fileName={`objednavka_${data?.ident}.pdf`}
        buttonText={'button.downloadOrder'}
        apiParams={apiParams}
      />
    ),
    [data]
  )

  return (
    <>
      <Header hasBackButton />
      <ScreenKeyboardAwareScrollView>
        <AuthorizedLayout
          title={'order.detail.adminTitle'}
          titleValue={{order: data && data.ident}}
          subTitle={'order.detail.adminSubTitle'}
          subTitleValue={{
            user: data && data.userId.fullName,
            shop: data && data.userShopId.company,
          }}
          actionButtons={actionButtons}
          breadcrumbs={
            <Breadcrumbs>
              <BreadcrumbItem
                label={t('routes.order')}
                onPress={() => {
                  navigation.navigate('NavAdminOrderScreen', {
                    screen: 'AdminOrderScreen',
                  })
                }}
              />
              <BreadcrumbItem
                label={t('order.detail.adminBreadcrumbsTitle', {
                  order: data && data.ident,
                })}
                isLast
              />
            </Breadcrumbs>
          }
        >
          {data ? (
            <>
              <View sx={{marginX: 1}}>
                <OrderDetailInfo
                  data={data}
                  isAdmin
                  control={control}
                  onBlurNote={handleSubmit(handleBlurAdminNote)}
                />
                <OrderDetailItems orderId={data.id} isAdmin />
                <OrderDetailCustomItems orderId={data.id} isAdmin />
              </View>
              <View sx={sxStyles.buttonWrapper}>
                <OrderDeleteButton orderId={data.id} />
              </View>
            </>
          ) : null}
        </AuthorizedLayout>
      </ScreenKeyboardAwareScrollView>
    </>
  )
}

const sxStyles: ObjectsOfSxProps = {
  buttonWrapper: {
    gap: 2,
    flexDirection: 'row',
    justifyContent: 'center',
  },
}
