import {hasFlag} from 'country-flag-icons'
import {Text, Image, SxProp, DripsyCustomTheme, useSx} from 'dripsy'
import React from 'react'
import {SvgUri} from 'react-native-svg'

import {COUNTRY_CODE_MAP, getCode} from 'common/country_flag_helper'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {isWeb} from 'layout/layout_constants'

interface Props {
  countryName: keyof typeof COUNTRY_CODE_MAP | ''
  countryCode?: string
  height?: number
  width?: number
  sxFallbackText?: SxProp
}

function getWidth(width?: number, height?: number) {
  // country flags are prepared in 3x2 resolution
  return width ?? ((height || 6) / 2) * 3
}

function getHeight(width?: number, height?: number) {
  // country flags are prepared in 3x2 resolution
  return height ?? ((width || 9) / 3) * 2
}

export const CountryFlag: React.FC<Props> = (props) => {
  const {countryCode, countryName, sxFallbackText, width, height} = props

  const sx = useSx()
  const sxStyles = useThemeSxStyles(getSxStyles, props)

  const code = getCode(countryName, countryCode)
  return hasFlag(code) ? (
    isWeb ? (
      <Image
        sx={{...sxStyles.image, ...sxStyles.imageBorder}}
        source={{
          uri: `https://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`,
        }}
      />
    ) : (
      <SvgUri
        width={getWidth(width, height)}
        height={getHeight(width, height)}
        // TODO: not working (black is missing for some flags e.g. Belgium - BE)
        fill={'black'}
        stroke={'black'}
        uri={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`}
        style={sx(sxStyles.imageBorder)}
      />
    )
  ) : (
    <Text sx={sxFallbackText} numberOfLines={1}>
      {countryName}
    </Text>
  )
}

const getSxStyles = (theme: DripsyCustomTheme, props: Props) => ({
  imageBorder: {
    borderWidth: 1,
    borderColor: 'grayColor400',
  },
  image: {
    height: getHeight(props.width, props.height),
    width: getWidth(props.width, props.height),
  },
})
