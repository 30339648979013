import {View, Text} from 'dripsy'
import React, {useCallback} from 'react'
import {Control} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {Button} from 'common/components/Button'
import {SelectController} from 'form/components/SelectController'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface NewOrderItemTemplateProps {
  onPress?: () => void
  isDisabled?: boolean
  isDisabledAll: boolean
  control: Control<any>
  options: any[]
}

const NewOrderItemTemplateComponent: React.FC<NewOrderItemTemplateProps> = ({
  control,
  onPress,
  isDisabled,
  isDisabledAll,
  options,
}) => {
  const {t} = useTranslation()
  const sxStyles = applyStyles(isDisabledAll)

  const handlePress = useCallback(() => {
    if (!isDisabled && onPress) {
      onPress()
    }
  }, [onPress, isDisabled])

  return (
    <View sx={sxStyles.container}>
      <View sx={sxStyles.content}>
        <Text sx={sxStyles.title}>{t('newOrder.createTemplate')}</Text>
        <View>
          <SelectController
            name={'template'}
            idProperty={'id'}
            displayLabelProperty={'name'}
            control={control}
            label={''}
            options={options}
            hideErrorInput={true}
            selectStyle={sxStyles.selectWrapper}
          />
        </View>
        <Button
          color={'primaryColor500'}
          title={t('button.create')}
          onPress={handlePress}
          appearance={'filled'}
          disabled={isDisabled}
          size={'small'}
        />
      </View>
    </View>
  )
}

export const NewOrderItemTemplate = React.memo(NewOrderItemTemplateComponent)

const applyStyles = (isDisabledAll: boolean): ObjectsOfSxProps => ({
  container: {
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: isDisabledAll ? 'grayColor300' : 'grayColor400',
    borderRadius: 15,
    my: 3,
    mx: [0, 3],
  },
  content: {
    alignItems: 'center',
    m: [6],
    flex: 1,
    flexDirection: 'column',
  },
  selectWrapper: {
    flex: [0, 1],
    width: [isWeb ? 230 : '100%', null, 300],
    py: 2,
  },
  title: {
    color: isDisabledAll ? 'grayColor300' : 'grayColor500',
  },
})
