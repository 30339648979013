import {
  GRID_REDUCER_STATE_ID,
  GridContainer,
  gridSlice,
  IGridDynamicComponentsFactory,
} from '@sincersoft/fe-grid'
import {Icon, IconProps} from '@ui-kitten/components'
import {AxiosRequestConfig} from 'axios'
import {useDripsyTheme, View} from 'dripsy'
import {isArray} from 'lodash'
import React, {CSSProperties, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Dimensions, EmitterSubscription, ScaledSize} from 'react-native'

import 'ag-grid-community/dist/styles/ag-grid.css'
// import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import './GridWrapper.css'

import {NativeGridContainer} from './NativeGridContainer'
import {SearchInput} from 'common/components/SearchInput'
import {useSearchValue} from 'common/hooks/useSearchValue'
import commonGridConfig from 'grid/common_grid_config.json'
import {GridHeader} from 'grid/components/GridHeader'
import {LoadingIndicator} from 'grid/components/LoadingIndicator'
import {Pagination} from 'grid/components/Pagination'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {useAppDispatch} from 'store/redux_hooks'

import {ReducerUtils} from '@sincersoft/core'

const GRID_THEME = 'ag-theme-material'

const ReloadIcon: React.FC<IconProps> = (props) => (
  <Icon
    {...props}
    name='refresh'
    pack={'material'}
    style={{...props.style, ...sxStyles.reloadIcon}}
  />
)

interface GridWrapperProps {
  dataServiceConfig?: AxiosRequestConfig
  dataServiceUrl: string
  hasModalSpinner?: boolean
  hasSearchInput?: boolean
  headerButton?: React.ReactNode
  plainGridConfig: any
  stateId: string
  dynamicComponentsFactory?: IGridDynamicComponentsFactory
  onPressItem?: (id: string) => void
  placeholder?: string
  title?: string
  disableSorting?: boolean
  showResetSortButton?: boolean
  currentPage?: number
  customPagination: boolean
}

export const GridWrapper: React.FC<GridWrapperProps> = ({
  dataServiceConfig,
  dataServiceUrl,
  hasModalSpinner,
  hasSearchInput,
  headerButton,
  plainGridConfig,
  stateId,
  dynamicComponentsFactory,
  onPressItem,
  placeholder,
  title,
  disableSorting = false,
  showResetSortButton,
  currentPage,
  customPagination,
}) => {
  const [dimensions, setDimensions] = useState<ScaledSize>(
    Dimensions.get('window')
  )

  const dimensionChangeEventSubscription = useRef<EmitterSubscription>()

  const {
    searchValue,
    handleChangeSearchValue,
    handlePressDeleteIcon,
    resultSearchValue,
  } = useSearchValue({stateId, customPagination})

  const translateService = useTranslation()

  // TODO: change  translateService to "t" for grid. (nativeGrid)
  // Disable react-hooks/exhaustive-deps eslint-rule bcs
  /* eslint-disable react-hooks/exhaustive-deps */
  const memoTranslateService = useMemo(() => {
    return translateService
  }, [])
  /* eslint-enable */

  const {theme} = useDripsyTheme()

  useEffect(() => {
    const handleDimensionChange = ({
      window,
    }: {
      window: ScaledSize
      screen: ScaledSize
    }) => {
      setDimensions(window)
    }

    dimensionChangeEventSubscription.current = Dimensions.addEventListener(
      'change',
      handleDimensionChange
    )
    return () => {
      dimensionChangeEventSubscription.current?.remove()
    }
  })

  const extendedDataServiceConfig = useMemo(
    () =>
      hasSearchInput
        ? {
            ...dataServiceConfig,
            params: {
              ...dataServiceConfig?.params,
              ...(resultSearchValue === ''
                ? {}
                : {_fulltext: resultSearchValue}),
            },
          }
        : dataServiceConfig,
    [hasSearchInput, dataServiceConfig, resultSearchValue]
  )

  const memoizedPlainGridConfig = useMemo(
    () => plainGridConfig,
    [plainGridConfig]
  )

  const enhancedCommonGridConfig = useMemo(
    () => ({
      ...commonGridConfig,
      defaultColDef: {
        ...commonGridConfig.defaultColDef,
        suppressKeyboardEvent: () => true,
      },
    }),
    []
  )

  const windowWidth = dimensions.width
  const showMobileGrid =
    windowWidth <=
    parseFloat(
      theme.breakpoints &&
        isArray(theme.breakpoints) &&
        theme.breakpoints.length >= 2
        ? theme.breakpoints[1]
        : '768'
    )

  return (
    <View sx={sxStyles.container}>
      <GridHeader title={title}>
        {hasSearchInput ? (
          <View sx={sxStyles.searchWrapper}>
            <SearchInput
              onPressDeleteIcon={handlePressDeleteIcon}
              value={searchValue}
              onChangeText={handleChangeSearchValue}
              wrapperStyle={title ? sxStyles.alignSearch : {}}
              placeholder={placeholder}
            />
          </View>
        ) : null}
        {headerButton}
      </GridHeader>
      {showMobileGrid ? (
        <NativeGridContainer
          translateService={memoTranslateService}
          dataServiceUrl={dataServiceUrl}
          dataServiceConfig={extendedDataServiceConfig}
          hasModalSpinner={hasModalSpinner}
          plainGridConfig={memoizedPlainGridConfig}
          stateId={stateId}
          dynamicComponentsFactory={dynamicComponentsFactory}
          onPressItem={onPressItem}
          commonGridConfig={commonGridConfig}
          disableSorting={disableSorting}
          customPagination={customPagination}
          currentPage={currentPage}
        />
      ) : (
        <GridContainer
          translateService={memoTranslateService}
          dataServiceUrl={dataServiceUrl}
          dataServiceConfig={extendedDataServiceConfig}
          plainGridConfig={memoizedPlainGridConfig}
          stateId={stateId}
          dynamicComponentsFactory={dynamicComponentsFactory}
          gridClass={GRID_THEME}
          showResetSortButton={showResetSortButton}
          commonGridConfig={enhancedCommonGridConfig}
          resetSortButtonStyle={rbsStyles}
          resetSortAccessoryLeft={ReloadIcon}
          LoadingIndicator={LoadingIndicator}
          currentPage={currentPage}
          customPagination={customPagination}
          PaginationComponent={Pagination}
        />
      )}
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    // flex: 1,
  },
  searchWrapper: {
    flex: 1,
    flexDirection: ['column', null, 'row'],
  },
  alignSearch: {
    alignSelf: ['center', null, 'flex-end'],
  },
  reloadIcon: {
    color: '#046240FF',
    fontSize: 16,
    marginRight: '8px',
  },
}
const rbsStyles: CSSProperties = {
  width: '',
}
