import {ReducerUtils} from '@sincersoft/core'
import {GRID_REDUCER_STATE_ID} from '@sincersoft/fe-grid'
import {AxiosRequestConfig} from 'axios'
import {isEmpty} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'

import {GridProjectDynamicComponentsFactory} from '../grid_project_dynamic_components_factory'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {GridWrapper} from 'grid/components/GridWrapper'
import {transformPaginationToSailsParams} from 'grid/grid_helpers'
import {
  ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID,
  ADMIN_ORDER_INSTANCE_REDUCER_STATE_ID,
} from 'order/order_constants'
import {useAppSelector} from 'store/redux_hooks'

interface GridProps {
  dataServiceConfig?: AxiosRequestConfig
  dataServiceUrl: string
  hasModalSpinner?: boolean
  hasSearchInput?: boolean
  headerButton?: React.ReactNode
  plainGridConfig: any
  stateId: string
  onPressItem?: (id: string) => void
  placeholder?: string
  title?: string
  disableSorting?: boolean
  showResetSortButton?: boolean
  currentPage?: number
  customPagination?: boolean
}

export const Grid: React.FC<GridProps> = ({
  dataServiceConfig,
  dataServiceUrl,
  hasModalSpinner,
  hasSearchInput,
  headerButton,
  plainGridConfig,
  stateId,
  onPressItem,
  placeholder,
  title,
  disableSorting = false,
  showResetSortButton = true,
  currentPage,
  customPagination = false,
}) => {
  const gridStateId = ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    stateId
  )

  const statePagination = useAppSelector(
    (state) => state[gridStateId].pagination
  )

  const stateSort = useAppSelector((state) => state[gridStateId].sort)

  const [sailsParams, setSailsParams] = useState<
    {limit: number; skip: number} | undefined
  >(undefined)

  useEffect(() => {
    const transformedParams = transformPaginationToSailsParams(statePagination)
    setSailsParams(transformedParams)
  }, [statePagination])

  const sort = useMemo(
    () => (isEmpty(stateSort) ? {} : {sort: JSON.stringify(stateSort)}),
    [stateSort]
  )

  const enhancedDataServiceConfig = useMemo(() => {
    return customPagination
      ? {
          ...dataServiceConfig,
          params: {
            ...dataServiceConfig?.params,
            limit: sailsParams?.limit,
            skip: sailsParams?.skip,
            ...sort,
          },
        }
      : {...dataServiceConfig}
  }, [customPagination, dataServiceConfig, sailsParams, sort])

  return enhancedDataServiceConfig && !isEmpty(sailsParams) ? (
    <GridWrapper
      dataServiceConfig={enhancedDataServiceConfig}
      dataServiceUrl={dataServiceUrl}
      hasModalSpinner={hasModalSpinner}
      hasSearchInput={hasSearchInput}
      headerButton={headerButton}
      plainGridConfig={plainGridConfig}
      stateId={stateId}
      dynamicComponentsFactory={GridProjectDynamicComponentsFactory}
      onPressItem={onPressItem}
      placeholder={placeholder}
      title={title}
      disableSorting={disableSorting}
      showResetSortButton={showResetSortButton}
      currentPage={currentPage}
      customPagination={customPagination}
    />
  ) : null
}
