import {DripsyCustomTheme, Text, View} from 'dripsy'
import React from 'react'

import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface NewOrderProductListItem {
  name: string
}

const NewOrderProductListItemTitleComponent: React.FC<
  NewOrderProductListItem
> = ({name}) => {
  const sxStyles = useThemeSxStyles(applyStyles)
  return (
    <View sx={sxStyles.titleContainer}>
      <Text sx={sxStyles.title} numberOfLines={2} variant={'body2Bold'}>
        {name}
      </Text>
    </View>
  )
}

export const NewOrderProductListItemTitle = React.memo(
  NewOrderProductListItemTitleComponent
)

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  title: {
    textAlign: 'center',
    color: 'grayColor600',
    fontSize: [
      theme.text.captionBold.fontSize,
      null,
      theme.text.body2Bold.fontSize,
    ],
    letterSpacing: [
      theme.text.captionBold.letterSpacing,
      null,
      theme.text.body2Bold.letterSpacing,
    ],
    lineHeight: [2, null, 3],
    alignItems: 'center',
  },
  titleContainer: {
    height: 40,
    paddingRight: [1, null, 0],
    alignItems: 'center',
    justifyContent: 'center',
  },
})
