import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {Text, View} from 'dripsy'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {NewShopForm} from './NewShopForm'
import {SHOP_FORM_FIELDS} from '../user_constants'
import {Shop, UseFormForUserShopForm} from '../user_types'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {getDataConfigForUserShopDetail} from 'user/user_helpers'

interface UserDetailContainerProps extends UseFormForUserShopForm {
  userId: string | number
  shopId: string | number | null
}

export const EditShopForm: React.FC<UserDetailContainerProps> = ({
  shopId,
  userShopReset,
  userShopErrors,
  userShopControl,
}) => {
  const {t} = useTranslation()

  const configUserShopDetail = getDataConfigForUserShopDetail(shopId)

  const {data, isSuccess, remove, isLoading, refetch} = useApiQuery<Shop>(
    extractKey({
      params: configUserShopDetail.params,
      url: configUserShopDetail.url,
    }),
    undefined,
    {
      reactQueryOptions: {
        enabled: false,
      },
      axiosConfig: {
        ...configUserShopDetail,
      },
    },
    true
  )

  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      userShopReset(data)
    }
  }, [data, isSuccess, isLoading, userShopReset])

  useEffect(() => {
    if (shopId) {
      refetch()
    }
  }, [shopId, refetch])

  useEffect(() => {
    return () => {
      remove()
    }
  }, [remove])

  return (
    <View sx={sxStyles.container}>
      <Text sx={sxStyles.title} variant={'h6Bold'}>
        {t('userShop.editTitle')}
      </Text>
      <NewShopForm
        formFields={SHOP_FORM_FIELDS}
        control={userShopControl}
        errors={userShopErrors}
      />
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignSelf: 'center',
    minWidth: ['100%', 550, 800],
    maxWidth: ['98%', 550, 800],
    maxHeight: '100%',
    marginY: [1, null, 0],
    paddingY: [2],
    paddingX: [2, 2, 6],
  },
  title: {
    color: 'secondaryColor600',
    fontSize: [2, null, 5],
    paddingBottom: [2, null, 4],
    alignSelf: 'center',
  },
}
