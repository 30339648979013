import {QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'

export const CHECK_LOGIN: QueryInput = {
  url: Api.logout,
  method: 'POST',
}

export function getDataConfigForLogout<TData>(): QueryInput<TData> {
  return CHECK_LOGIN
}
