import {Radio, RadioGroup} from '@ui-kitten/components'
import {View, useSx} from 'dripsy'
import React from 'react'
import {Control} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {CreateOrderTemplateInputs} from './CreateOrderTemplateInputs'
import {DefaultSelectOptions} from 'form/form_types'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {CreateOrderTemplateHeader} from 'order_template/components/CreateOrderTemplateHeader'

interface Props {
  templates: DefaultSelectOptions[]
  control: Control<any>
  selectedRadio: number
  setSelectedRadio: (index: number) => void
}

const CreateOrderTemplateComponent: React.FC<Props> = ({
  templates,
  control,
  selectedRadio,
  setSelectedRadio,
}) => {
  const {t} = useTranslation()
  const sx = useSx()

  return (
    <View sx={sxStyles.container}>
      <CreateOrderTemplateHeader />
      <View sx={sxStyles.radioGroupWrapper}>
        <RadioGroup
          selectedIndex={selectedRadio}
          onChange={(index) => setSelectedRadio(index)}
          style={sx(sxStyles.radioGroup)}
        >
          <Radio style={sx(sxStyles.radio)}>
            {t('orderTemplate.createNew')}
          </Radio>
          <Radio style={sx(sxStyles.radio)} disabled={templates.length < 1}>
            {t('orderTemplate.updateExists')}
          </Radio>
        </RadioGroup>
      </View>
      <CreateOrderTemplateInputs
        templates={templates}
        selectedRadio={selectedRadio}
        control={control}
      />
    </View>
  )
}

export const CreateOrderTemplate = React.memo(CreateOrderTemplateComponent)

const sxStyles: ObjectsOfSxProps = {
  container: {
    minWidth: [280, 300, 460],
    mx: [0, null, 2],
  },
  radioGroupWrapper: {
    alignItems: ['center', null],
    mb: 2,
    width: '100%',
  },
  radioGroup: {
    flexDirection: ['column', null, 'row'],
  },
  radio: {},
}
