import {BottomTabScreenProps} from '@react-navigation/bottom-tabs'
import {CompositeScreenProps} from '@react-navigation/native'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {OrderDetailScreen} from './OrderDetailScreen'
import {OrderScreen} from './OrderScreen'
import {
  AuthorizedTabParamList,
  OrderStackParamList,
  RootStackParamList,
} from 'layout/layout_types'
import {OrderProductDetailScreen} from 'order/components/OrderProductDetailScreen'

const OrderStack = createNativeStackNavigator<OrderStackParamList>()

type Props = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList, 'NavOrderScreen'>,
  NativeStackScreenProps<RootStackParamList>
>

export const NavOrderScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <OrderStack.Navigator
      initialRouteName={'OrderScreen'}
      screenOptions={{headerShown: false}}
    >
      <OrderStack.Screen
        name={'OrderScreen'}
        component={OrderScreen}
        options={{title: t('routes.order')}}
      />
      <OrderStack.Screen
        name={'OrderDetailScreen'}
        component={OrderDetailScreen}
        initialParams={{id: '1'}}
        options={{title: t('routes.order')}}
      />
      <OrderStack.Screen
        name={'OrderProductDetailScreen'}
        component={OrderProductDetailScreen}
        initialParams={{orderId: '1', orderItemId: '1'}}
        options={{title: t('routes.order')}}
      />
    </OrderStack.Navigator>
  )
}
