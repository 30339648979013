import {DripsyCustomTheme} from 'dripsy'
import React from 'react'

import {LabelValue} from 'common/components/LabelValue'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  label: string
  translatePrefix?: string
  value?: string | number
  children?: React.ReactNode
}

export const ProductDetailInfoItemComponent: React.FC<Props> = ({
  label,
  translatePrefix,
  value,
  children,
}) => {
  const sxStyles = useThemeSxStyles(applyStyles)
  return (
    <LabelValue
      label={label}
      labelStyle={sxStyles.label}
      translatePrefix={translatePrefix}
      value={value}
      valueStyle={sxStyles.value}
      wrapperStyle={sxStyles.wrapper}
    >
      {children}
    </LabelValue>
  )
}

export const ProductDetailInfoItem = React.memo(ProductDetailInfoItemComponent)

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  wrapper: {
    paddingBottom: [3, null, 5],
  },
  label: {
    ...theme.text.body2,
    color: 'grayColor700',
    fontSize: [2, null, 3],
  },
  value: {
    color: 'grayColor800',
    ...theme.text.body2,
    fontSize: [2, null, 3],
  },
})
