import {View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {NewOrderCategoryListItem} from './NewOrderCategoryListItem'
import {NewOrderList} from './NewOrderList'
import {NotFoundItem} from './NotFoundItem'
import {usePagination} from 'common/hooks/usePagination'
import {makeUrlWithQueryParams} from 'common/url_helpers'
import {BIG_PAGINATION_SIZE} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {CategoryItem} from 'new_order/new_order_types'
import {getUrlAndParamsForProductCategoryImage} from 'product_category/product_category_helpers'

interface NewOrderCategoryProps {
  filteredList?: []
  onPressItem: (id: string) => void
  isFiltered: boolean
  list: CategoryItem[]
}

export const NewOrderCategory: React.FC<NewOrderCategoryProps> = ({
  filteredList,
  onPressItem,
  isFiltered,
  list,
}) => {
  const {t} = useTranslation()
  const {paginationSize, renderPaginationButton} =
    usePagination(BIG_PAGINATION_SIZE)
  const sxStyles = applyStyles()
  return (
    <View sx={sxStyles.container}>
      {!isFiltered && list ? (
        <>
          <NewOrderList>
            {list.slice(0, paginationSize).map((item) => {
              const urlAndParams = getUrlAndParamsForProductCategoryImage(
                item.id,
                item.entityImageVersion
              )
              return (
                <NewOrderCategoryListItem
                  key={item.id}
                  imageUrlWithParams={makeUrlWithQueryParams(
                    urlAndParams.url,
                    urlAndParams.urlParams
                  )}
                  hasImage={item.entityImageId !== null}
                  name={item.name}
                  onPress={() => onPressItem(item.id)}
                />
              )
            })}
          </NewOrderList>
          {renderPaginationButton(list.length)}
        </>
      ) : null}
      {isFiltered ? (
        filteredList && filteredList.length > 0 ? null : (
          <NotFoundItem title={t('newOrder.notFound')} />
        )
      ) : null}
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
  },
})
