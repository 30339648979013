import {useNavigation} from '@react-navigation/native'
import {isEmpty} from 'lodash'
import React, {useCallback} from 'react'

import {NameAndCategoryLink} from 'common/components/NameAndCategoryLink'
import {CellRendererParams} from 'grid/grid_types'
import {AuthorizedNavigationProp} from 'layout/layout_types'
import {getNavigateOptionByLinkType} from 'order/order_helpers'
import {ProductDetailParentLinkType} from 'order/order_types'

export const NameAndCategoryCellRenderer: React.FC<CellRendererParams> = ({
  value,
  data,
  isSmall,
  colDef,
}) => {
  const navigation = useNavigation<AuthorizedNavigationProp>()
  const {
    productCategoryNameProperty,
    link,
  }: {productCategoryNameProperty: string; link?: ProductDetailParentLinkType} =
    colDef?.cellRendererParams ? colDef.cellRendererParams : null

  const handlePress = useCallback(() => {
    const {route, options} = getNavigateOptionByLinkType(data, link)
    if (route && !isEmpty(options)) {
      navigation.navigate(route, options)
    }
  }, [data, navigation, link])

  return (
    <NameAndCategoryLink
      data={data}
      value={value}
      onPress={handlePress}
      isSmall={isSmall}
      hasLink={!!link}
      productCategoryNameProperty={productCategoryNameProperty}
    />
  )
}
