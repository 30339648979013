import {View} from 'dripsy'
import React from 'react'

import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  children: React.ReactNode
}

export const ModalKeyboardAwareScrollWrapper: React.FC<Props> = ({
  children,
}) => {
  const sxStyles = applyStyles()
  return <View sx={sxStyles.containerWrapper}>{children}</View>
}
const applyStyles = (): ObjectsOfSxProps => ({
  containerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
