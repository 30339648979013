import {NavigationContainer} from '@react-navigation/native'
import {extractKey, QueryClientContext} from '@sincersoft/fe-core'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {Platform} from 'react-native'

import {NotificationProvider} from 'common/components/NotificationProvider'
import {removeNavigationTimeout} from 'common/navigation_thunks'
import {LinkingConfig} from 'config/linking_config'
import {AppNavigator} from 'layout/components/AppNavigator'
import {getDataConfigForCanOrder} from 'order/order_helpers'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'
import {FetchNewAppVersion} from 'web_version/components/FetchNewAppVersion'

export const AppNavigatorContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const configCanOrder = getDataConfigForCanOrder()
  const queryClient = useQueryClient({context: QueryClientContext})
  const canOrderKey = extractKey({
    url: configCanOrder.url,
  })

  const timeoutId = useAppSelector((state) => state.layout.timeoutId)

  return (
    <NavigationContainer
      linking={LinkingConfig}
      onStateChange={() => {
        if (timeoutId) {
          dispatch(removeNavigationTimeout({timeoutId}))
        }
        queryClient.invalidateQueries(canOrderKey)
        // hide snackbar on state change
        dispatch(
          snackbarSlice.actions.setIsVisible({
            type: SnackbarType.AUTHORIZED,
            isVisible: false,
          })
        )
        dispatch(
          snackbarSlice.actions.setIsVisible({
            type: SnackbarType.UNAUTHORIZED,
            isVisible: false,
          })
        )
      }}
    >
      <NotificationProvider>
        {Platform.OS === 'web' && <FetchNewAppVersion />}
        <AppNavigator />
      </NotificationProvider>
    </NavigationContainer>
  )
}
