import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {useEffect} from 'react'

import {loginSlice} from 'login/login_slice'
import {getDataConfigForShopCartItemsCount} from 'shop_cart/shop_cart_helpers'
import {ShopCartItemsCount} from 'shop_cart/shop_cart_types'
import {useAppDispatch} from 'store/redux_hooks'

export const useGetItemCount = (): boolean => {
  const dispatch = useAppDispatch()

  const dataConfigForShopCartItemsCount = getDataConfigForShopCartItemsCount()
  const itemCountsKey = extractKey({
    params: dataConfigForShopCartItemsCount.params,
    url: dataConfigForShopCartItemsCount.url,
  })

  const {
    data: iCountData,
    isSuccess: iCountIsSuccess,
    isLoading,
  } = useApiQuery<ShopCartItemsCount>(
    itemCountsKey,
    undefined,
    {
      axiosConfig: {
        ...dataConfigForShopCartItemsCount,
      },
    },
    true
  )

  useEffect(() => {
    if (iCountData && iCountIsSuccess) {
      dispatch(
        loginSlice.actions.setShopCartCount({
          shopCartItemsCount: iCountData.count,
        })
      )
    }
  }, [iCountData, iCountIsSuccess, dispatch])

  return isLoading
}
