import {MaterialIcons} from '@expo/vector-icons'
import React from 'react'
import {ImageStyle, StyleProp, StyleSheet} from 'react-native'

export const materialIconsMapping = {
  name: 'material',
  icons: createIconsMap(),
}

function createIconsMap() {
  return new Proxy(
    {},
    {
      get(target, name) {
        return IconProvider(name)
      },
    }
  )
}

const IconProvider = (name: string | symbol) => ({
  toReactElement: (props: any) => MaterialIcon({name, ...props}),
})

function MaterialIcon({
  name,
  style,
}: {
  name: keyof typeof MaterialIcons.glyphMap
  style: StyleProp<ImageStyle>
}) {
  const {height, tintColor, ...iconStyle} = StyleSheet.flatten(style)
  return (
    <MaterialIcons
      name={name}
      size={Number(height)}
      color={tintColor}
      style={iconStyle}
    />
  )
}
