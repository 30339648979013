import {DocumentPickerResult} from 'expo-document-picker'
import {ImagePickerResult} from 'expo-image-picker'
import {forEach} from 'lodash'
import mime from 'mime'

import {isWeb} from 'layout/layout_constants'
import {
  isDocumentResultSuccess,
  isImagePickerResultSuccess,
} from 'price_list/price_list_types'

export function normalizeUri(uri: string): string {
  return uri.replace('/%40gpm-voz%2Fgpm-voz/', '/%2540gpm-voz%252Fgpm-voz/')
}

export function prepareFormDataForUpload(
  pickerResult: DocumentPickerResult | undefined,
  dataParams?: Record<string, any>
): FormData {
  const formData = new FormData()
  if (isDocumentResultSuccess(pickerResult)) {
    forEach(dataParams, (value, key) => {
      formData.append(key, value.toString())
    })
    // get 0 asset from assets
    const pickerAsset = pickerResult.assets[0]
    if (pickerAsset.file) {
      // if document picker returns file, upload file
      formData.append('file', pickerAsset.file)
    } else {
      // otherwise upload file object with uri, name and type
      formData.append('file', {
        uri: normalizeUri(pickerAsset.uri),
        name: pickerAsset.name,
        // IMPORTANT: type is required for Android, otherwise NetworkError is returned!
        type: mime.getType(pickerAsset.uri),
      } as any)
    }
  }
  return formData
}

export function prepareFormDataForUploadImage(
  pickerResult: ImagePickerResult | undefined
): FormData {
  const formData = new FormData()
  if (isImagePickerResultSuccess(pickerResult)) {
    formData.append('height', pickerResult.assets[0].height.toString())
    formData.append('width', pickerResult.assets[0].width.toString())
    const mimeType = mime.getType(pickerResult.assets[0].uri)
    const base64Prefix = `data:${mimeType};base64,`
    // in case of web the result uri contains base64 data uri
    const base64Uri = isWeb
      ? pickerResult.assets[0].uri
      : `${base64Prefix}${pickerResult.assets[0].base64}`
    formData.append('base64Uri', base64Uri)
  }
  return formData
}
