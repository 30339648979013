import {Text} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ObjectsOfSxProps} from 'layout/layout_types'

interface ConfirmRegisterTitleWithTextProps {
  title: string
  text?: string
}

export const ConfirmRegisterTitleWithText: React.FC<
  ConfirmRegisterTitleWithTextProps
> = ({title, text}) => {
  const {t} = useTranslation()
  const sxStyles = applyStyles()

  return (
    <>
      <Text sx={sxStyles.title} variant={'h5Bold'}>
        {t(title)}
      </Text>
      {text && (
        <Text sx={sxStyles.text} variant={'body1'}>
          {t(text)}
        </Text>
      )}
    </>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  title: {
    fontSize: [5, null, 6],
    lineHeight: [5, null, 6],
    color: 'primaryColor600',
    textAlign: 'center' as const,
  },
  text: {
    paddingTop: [4, null, 5],
    textAlign: 'center' as const,
  },
})
