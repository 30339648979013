import {yupResolver} from '@hookform/resolvers/yup'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'

import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {SelectController} from 'form/components/SelectController'
import {orderStatusSchema} from 'form/form_schemas'
import {CellRendererParams} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {
  ADMIN_ORDER_CUSTOM_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
  ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID,
  ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
  OrderStatusOptions,
} from 'order/order_constants'
import {
  getDataConfigForOrderDetail,
  getDataConfigForUpdateOrderItem,
} from 'order/order_helpers'
import {UpdateOrderItemInOrders} from 'order/order_types'
import {getDataConfigForUpdateCustomItem} from 'shop_cart/shop_cart_helpers'
import {useAppDispatch} from 'store/redux_hooks'

interface StatusSelect {
  status: string
}

interface OrderStatusForm {
  status: string
}

export const OrderStatusCellRenderer: React.FC<CellRendererParams> = ({
  data,
  colDef,
}) => {
  const queryClient = useQueryClient({context: QueryClientContext})
  const dispatch = useAppDispatch()

  const isCustomItem =
    colDef?.cellRendererParams && colDef.cellRendererParams.isCustomItem
      ? colDef.cellRendererParams?.isCustomItem
      : false

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: {errors},
  } = useForm<OrderStatusForm>({
    resolver: yupResolver(orderStatusSchema),
  })

  const handleUpdateItem = (itemValue: StatusSelect) => {
    const currentStatus = isCustomItem
      ? data.adminStatus ?? data.status
      : data.status

    if (itemValue.status !== currentStatus) {
      mutate()
      dispatch(
        gridSlice.actions.setDataProcessing({
          dataProcessing: true,
          stateId: ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
        })
      )
    }
  }

  useEffect(() => {
    reset({
      status: isCustomItem ? data.adminStatus ?? data.status : data.status,
    })
  }, [data, reset, isCustomItem])

  const handleSuccessUpdate = (response: ResponseSuccess) => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_ORDER_CUSTOM_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.setDataProcessing({
        dataProcessing: false,
        stateId: ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    const dataConfigForOrderDetail = getDataConfigForOrderDetail(
      data.orderId.id
    )
    const orderDetailKey = extractKey({
      params: dataConfigForOrderDetail.params,
      url: dataConfigForOrderDetail.url,
    })
    queryClient.invalidateQueries(orderDetailKey)

    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const configForUpdateOrderItem = isCustomItem
    ? getDataConfigForUpdateCustomItem(data.id, {
        status: getValues().status,
      })
    : getDataConfigForUpdateOrderItem<UpdateOrderItemInOrders>({
        id: data.id,
        status: getValues().status,
      })

  const {mutate} = useApiMutation(
    extractKey({
      data: configForUpdateOrderItem.data,
      method: configForUpdateOrderItem.method,
      url: configForUpdateOrderItem.url,
    }),
    configForUpdateOrderItem.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdate,
        onError: handleError,
      },
      axiosConfig: {
        ...configForUpdateOrderItem,
      },
    },
    true
  )

  return (
    <View sx={sxStyles.container}>
      <SelectController
        onInputChange={handleSubmit(handleUpdateItem)}
        control={control}
        error={errors.status}
        name={'status'}
        displayLabelProperty={'name'}
        idProperty={'name'}
        options={OrderStatusOptions}
        size={'small'}
        translatePrefix={'order.detail.grid.column'}
      />
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: ['flex-end', null, 'center'],
    maxWidth: 150,
  },
}
