import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {AuthorizedScreenProps, ProductStackParamList} from 'layout/layout_types'
import {ProductDetailContainer} from 'new_order/components/ProductDetailContainer'
import {getDataConfigForProductDetail} from 'new_order/new_order_helpers'
import {ProductItem} from 'new_order/new_order_types'

type Props = CompositeScreenProps<
  NativeStackScreenProps<ProductStackParamList, 'ProductDetailScreen'>,
  AuthorizedScreenProps
>

export const ProductDetailScreen: React.FC<Props> = ({route, navigation}) => {
  const {t} = useTranslation()

  const productId = route.params.id || route.params.productId
  const configForProductDetail = getDataConfigForProductDetail(productId)

  const keyForQuery = extractKey({
    url: configForProductDetail.url,
  })

  const {data, isLoading, isStale} = useApiQuery<ProductItem>(
    keyForQuery,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForProductDetail,
      },
    },
    true
  )

  useRefetchQuery([{key: keyForQuery}], isStale)

  return data && !isLoading ? (
    <ProductDetailContainer
      navigation={navigation}
      route={route}
      isAdminScreen={true}
      keyForInvalidateQuery={keyForQuery}
      breadcrumbs={
        <Breadcrumbs>
          <BreadcrumbItem
            label={t('product.title')}
            onPress={() => {
              navigation.navigate('NavProductScreen', {
                screen: 'ProductScreen',
              })
            }}
          />
          <BreadcrumbItem
            label={t('product.breadcrumbsTitle', {
              product: data?.name,
            })}
            isLast
          />
        </Breadcrumbs>
      }
    />
  ) : null
}
