import {yupResolver} from '@hookform/resolvers/yup'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {View} from 'dripsy'
import React from 'react'
import {useForm} from 'react-hook-form'
import {
  NativeSyntheticEvent,
  Platform,
  TextInputKeyPressEventData,
} from 'react-native'

import {LoginForm} from './LoginForm'
import {LoginIntro} from './LoginIntro'
import {useLogin} from 'common/hooks/useLogin'
import {LoginSchema} from 'form/form_schemas'
import {ErrorBoundary} from 'layout/components/ErrorBoundary'
import {ScreenKeyboardAwareScrollView} from 'layout/components/ScreenKeyboardAwareScrollView'
import {ObjectsOfSxProps, RootStackParamList} from 'layout/layout_types'
import {EMAIL, PASSWORD} from 'login/login_constants'

type Props = NativeStackScreenProps<RootStackParamList, 'LoginScreen'>

interface LoginScreenForm {
  email: string
  password: string
}

export const LoginScreen: React.FC<Props> = ({navigation}) => {
  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: {errors},
  } = useForm<LoginScreenForm>({
    resolver: yupResolver(LoginSchema),
  })

  const {mutate, isLoading, isError, error} = useLogin(getValues())

  const sxStyles = applyStyles()

  const handlePressRegister = () => {
    // Uncomment for disabling registration. Disable the navigation under this block of code.
    // dispatch(
    //   snackbarSlice.actions.setIsVisible({
    //     type: SnackbarType.UNAUTHORIZED,
    //     isVisible: true,
    //     data: {
    //       color: 'error',
    //       text: 'registrationDisabled',
    //     },
    //   })
    // )
    navigation.navigate('RegisterScreen')
  }

  const handlePressLogin = async () => {
    mutate()
  }

  const handlePressKey = async (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    if (e.nativeEvent.key === 'Enter') {
      e.preventDefault()
      await trigger([EMAIL, PASSWORD]).then((isWithoutError) => {
        if (isWithoutError) {
          mutate()
        }
      })
    }
  }

  const handlePressForgottenPassword = () => {
    navigation.navigate('ForgottenPasswordScreen')
  }

  const handlePressDeleteAccount = () => {
    navigation.navigate('DeleteAccountRequestScreen')
  }

  return (
    <ScreenKeyboardAwareScrollView
      enableScroll={Platform.OS === 'android'}
      scrollOnlyWithKeyboardOpen
      shouldPersistTaps={'never'}
      withoutWrapper
    >
      <ErrorBoundary>
        <View sx={sxStyles.container}>
          <LoginIntro handlePressRegister={handlePressRegister} />
          <LoginForm
            handlePressLogin={handleSubmit(handlePressLogin)}
            handlePressForgottenPassword={handlePressForgottenPassword}
            handlePressDeleteAccount={handlePressDeleteAccount}
            handleKeyPress={handlePressKey}
            control={control}
            errors={errors}
            noticeText={
              error?.response?.data?.messageCode ||
              'response.error.user.invalidCredentials'
            }
            isLoading={isLoading}
            displayNotice={isError && !isLoading}
          />
        </View>
      </ErrorBoundary>
    </ScreenKeyboardAwareScrollView>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    minHeight: '100%',
    flexDirection: [
      'column-reverse' as const,
      undefined,
      undefined,
      'row' as const,
    ],
  },
})
