const BASIC_INTERVAL = 1000 * 60 * 10
const DAY_INTERVAL = 1000 * 60 * 60 * 24

export const ActivityTimer = () => {
  let globalTimerId = 0
  const timers = new Map()

  const maybeExecuteTimerCallback = ({
    timerId,
    forced = false,
  }: {
    timerId: number
    forced: boolean
  }) => {
    const timer = timers.get(timerId)

    if (timer === undefined) {
      return
    }

    const {
      callback,
      interval,
      forcedInterval,
      forcedIntervalId,
      lastExecution,
    } = timer
    const intervalToCheckFor = forced ? forcedInterval : interval
    const now = Date.now()

    if (now - lastExecution < intervalToCheckFor) {
      return
    }

    const newTimer = {
      ...timer,
      lastExecution: now,
    }

    if (forcedIntervalId !== undefined) {
      window.clearInterval(forcedIntervalId)
      newTimer.forcedIntervalId = window.setInterval(() => {
        maybeExecuteTimerCallback({timerId, forced: true})
      }, forcedInterval)
    }

    timers.set(timerId, newTimer)
    callback({forced, timerId})
  }

  const setInterval = ({callback, interval, forcedInterval} = {}) => {
    const timerId = globalTimerId

    if (typeof callback !== 'function' || typeof interval !== 'number') {
      return undefined
    }

    const timer = {
      callback,
      interval,
      lastExecution: Date.now(),
    }

    if (forcedInterval !== undefined) {
      timer.forcedInterval = forcedInterval
      timer.forcedIntervalId = window.setInterval(() => {
        maybeExecuteTimerCallback({timerId, forced: true})
      }, forcedInterval)
    }

    timers.set(timerId, timer)
    globalTimerId += 1
    return timerId
  }

  const clearInterval = (timerId) => {
    const timer = timers.get(timerId)

    if (timer === undefined) {
      return
    }

    const {forcedIntervalId} = timer

    if (forcedIntervalId !== undefined) {
      window.clearInterval(forcedIntervalId)
    }

    timers.delete(timerId)
  }

  const runTimersCheck = () => {
    timers.forEach((_timer, timerId) => {
      maybeExecuteTimerCallback({timerId})
    })
  }

  return {
    BASIC_INTERVAL,
    DAY_INTERVAL,
    setInterval,
    clearInterval,
    runTimersCheck,
  }
}
