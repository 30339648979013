import {yupResolver} from '@hookform/resolvers/yup'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {Text, View} from 'dripsy'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {BasicLinkText} from 'common/components/BasicLinkText'
import {ColumnPriceValue} from 'common/components/ColumnPriceValue'
import {CustomPriceModal} from 'common/components/CustomPriceModal'
import {IconButton} from 'common/components/IconButton'
import {Link} from 'common/components/Link'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {formatNumberToString, formatStringToNumber} from 'common/number_helpers'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError} from 'error/error_helper'
import {customItemPriceSchema} from 'form/form_schemas'
import {isAndroid, isIOS} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {
  MAX_PRICE_VALUE,
  MIN_PRICE_VALUE,
  SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
} from 'shop_cart/shop_cart_constants'
import {
  getDataConfigForCart,
  getDataConfigForCartItem,
  getDataConfigForCreateCustomPrice,
  getDataConfigForDeleteCustomPrice,
  getDataConfigForUpdateCustomPrice,
} from 'shop_cart/shop_cart_helpers'
import {ResponseShopCartItem} from 'shop_cart/shop_cart_types'
import {useAppDispatch} from 'store/redux_hooks'

interface Props {
  customPrice?: string
  customPriceId?: number
  cartItemId?: number
  productName: string
}

export const CustomPriceColumn: React.FC<Props> = ({
  customPrice,
  customPriceId,
  cartItemId,
  productName,
}) => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient({context: QueryClientContext})
  const {t} = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)
  const isSmall = useIsNarrowScreen(2)
  const isSmallDevice = isAndroid || isIOS || isSmall
  const sxStyles = applyStyles(isSmallDevice)

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {
      price: customPrice,
    },
    resolver: yupResolver(customItemPriceSchema('price', true)),
  })

  useEffect(() => {
    reset({
      price: customPrice,
    })
  }, [reset, customPrice])

  const priceValue = watch('price')

  const handleChangePriceValue = useCallback(
    (value?: string) => {
      if (value) {
        const value2Digits = formatNumberToString(formatStringToNumber(value), {
          currency: 'EUR',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
        if (value !== value2Digits) {
          reset({price: value2Digits})
        }
      }
    },
    [reset]
  )

  const handleDebounceChangePriceValue = useMemo(() => {
    return debounce(handleChangePriceValue, 1000)
  }, [handleChangePriceValue])

  useEffect(() => {
    handleDebounceChangePriceValue(priceValue)
  }, [priceValue, handleDebounceChangePriceValue])

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const configForCreateCustomPrice =
    customPrice && customPriceId
      ? getDataConfigForUpdateCustomPrice(customPriceId, {
          price: priceValue ? formatStringToNumber(priceValue) : 0,
        })
      : getDataConfigForCreateCustomPrice({
          cartItemId,
          price: priceValue ? formatStringToNumber(priceValue) : 0,
        })

  const handleSuccess = (response: ResponseShopCartItem) => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.setDataProcessing({
        dataProcessing: false,
        stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    const configForShopCart = getDataConfigForCart()
    const shopCartKey = extractKey({
      url: configForShopCart.url,
    })
    const configForShopCartItems = getDataConfigForCartItem()
    const shopCartItemsKey = extractKey({
      url: configForShopCartItems.url,
    })
    queryClient.invalidateQueries(shopCartItemsKey)
    queryClient.invalidateQueries(shopCartKey)

    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
    handleHideModal()
  }

  const {mutate: createCustomPriceQueryMutate} = useApiMutation(
    extractKey({
      method: configForCreateCustomPrice.method,
      url: configForCreateCustomPrice.url,
    }),
    configForCreateCustomPrice.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccess,
        onError: handleError,
      },
      axiosConfig: {
        ...configForCreateCustomPrice,
      },
    },
    true
  )

  const configForDeleteCustomPrice =
    getDataConfigForDeleteCustomPrice(customPriceId)

  const {mutate: deleteCustomPriceQueryMutate} = useApiMutation(
    extractKey({
      method: configForDeleteCustomPrice.method,
      url: configForDeleteCustomPrice.url,
    }),
    {},
    {
      reactMutationOptions: {
        onSuccess: handleSuccess,
        onError: handleError,
      },
      axiosConfig: {
        ...configForDeleteCustomPrice,
      },
    },
    true
  )

  const handleOpenModal = useCallback(() => {
    setModalVisible(true)
  }, [])

  const handleHideModal = useCallback(() => {
    setModalVisible(false)
  }, [])

  const handlePressSave = useCallback(() => {
    createCustomPriceQueryMutate()
  }, [createCustomPriceQueryMutate])

  const handlePressDelete = useCallback(() => {
    if (customPriceId) {
      deleteCustomPriceQueryMutate()
    }
  }, [customPriceId, deleteCustomPriceQueryMutate])

  const handleDebounceSubmit = useMemo(
    () =>
      debounce(
        handleSubmit(() => null),
        1000
      ),
    [handleSubmit]
  )

  useEffect(() => {
    if (priceValue) {
      handleDebounceSubmit()
    }
  }, [handleDebounceSubmit, priceValue])

  const isDisabledConfirmButton = useMemo(() => {
    let result = false
    if (priceValue) {
      const formattedPriceValue = formatStringToNumber(priceValue)
      if (
        formattedPriceValue >= MIN_PRICE_VALUE &&
        formattedPriceValue <= MAX_PRICE_VALUE
      ) {
        if (customPrice) {
          result = formattedPriceValue === formatStringToNumber(customPrice)
        }
      } else {
        result = true
      }
    } else {
      result = true
    }
    return result
  }, [priceValue, customPrice])

  return (
    <View sx={sxStyles.text}>
      {customPrice ? (
        <>
          <IconButton
            appearance='ghost'
            size='small'
            status='warning'
            iconName={'edit'}
            left
            onPress={handleOpenModal}
          />
          <ColumnPriceValue
            value={formatStringToNumber(customPrice)}
            isSmall={isSmallDevice}
          />
        </>
      ) : (
        <>
          <Text variant={'caption'}>{' - ('}</Text>
          <Link onPress={handleOpenModal}>
            {(pressableProps) => {
              return (
                <BasicLinkText
                  {...pressableProps}
                  text={t('customPrice.linkText')}
                />
              )
            }}
          </Link>
          <Text variant={'caption'}>{')'}</Text>
        </>
      )}
      <CustomPriceModal
        modalVisible={modalVisible}
        onPressCancel={handleHideModal}
        onPressConfirm={handleSubmit(handlePressSave)}
        onPressDelete={handlePressDelete}
        displayRemove={!!customPrice}
        isDisabledConfirm={isDisabledConfirmButton}
        control={control}
        errors={errors}
        productName={productName}
      />
    </View>
  )
}
const applyStyles = (isSmall?: boolean): ObjectsOfSxProps => ({
  text: {
    // vertical centering - line-height should be equal to the height of cell
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...(isSmall
      ? {}
      : {
          textAlignVertical: 'center',
        }),
  },
  textStyles: {
    textAlign: 'end',
  },
})
