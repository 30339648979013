import {MaterialIcons} from '@expo/vector-icons'
import {extractKey, QueryInput, useApiMutation} from '@sincersoft/fe-core'
import {ButtonProps} from '@ui-kitten/components'
import {EvaStatus} from '@ui-kitten/components/devsupport'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import {DocumentPickerOptions, DocumentPickerResult} from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Icon} from 'common/components/Icon'
import {IconButton} from 'common/components/IconButton'
import {useDocumentPicker} from 'common/hooks/useDocumentPicker'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {requestStoragePermission} from 'common/permission_helpers'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {normalizeUri, prepareFormDataForUpload} from 'common/upload_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {Colors} from 'grid/grid_types'
import {isAndroid, isWeb} from 'layout/layout_constants'
import {layoutSlice} from 'layout/layout_slice'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {isDocumentResultSuccess} from 'price_list/price_list_types'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch} from 'store/redux_hooks'

type Props = {
  activateModalSpinner?: boolean
  dataConfig: QueryInput<File>
  buttonText?: string
  onSuccessUpload?: (response?: any) => void
  pickerOptions?: DocumentPickerOptions
  specialIcon?: keyof typeof MaterialIcons.glyphMap
  color?: Colors
  status?: EvaStatus
  appearance?: ButtonProps['appearance']
}

export const DocumentPicker: React.FC<Props> = ({
  activateModalSpinner,
  buttonText,
  dataConfig,
  onSuccessUpload,
  pickerOptions,
  color,
  specialIcon,
  status,
  appearance,
}) => {
  const sxStyles = applyStyles(color)
  const isSmallDevice = useIsNarrowScreen(1)
  const {t} = useTranslation()
  const handlePicker = useDocumentPicker(pickerOptions)
  const [uploadFileResult, setUploadFileResult] =
    useState<DocumentPickerResult>()
  const [readyToSave, setReadyToSave] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const handleSuccess = (response: ResponseSuccess) => {
    reset()
    if (onSuccessUpload) {
      onSuccessUpload(response)
    }

    if (activateModalSpinner) {
      dispatch(layoutSlice.actions.setModalSpinnerHidden())
    }
    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    if (activateModalSpinner) {
      dispatch(layoutSlice.actions.setModalSpinnerHidden())
    }
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const dataConfigWithData = useMemo(
    () => ({
      ...dataConfig,
      data: prepareFormDataForUpload(uploadFileResult),
    }),
    [dataConfig, uploadFileResult]
  )

  const {isLoading, mutate, reset} = useApiMutation(
    extractKey({
      url: dataConfigWithData.url,
      data: dataConfigWithData.data,
      method: dataConfigWithData.method,
    }),
    dataConfigWithData.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccess,
        onError: handleError,
      },
      axiosConfig: {
        // axios automatically transforms FormData to json, see this:
        // https://github.com/bmuenzenmeyer/axios-1.0.0-migration-guide?tab=readme-ov-file#multipart-form-data-is-no-longer-automatically-set
        ...(isAndroid
          ? {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          : {}),
        ...dataConfigWithData,
      },
    },
    true
  )

  useEffect(() => {
    // if data are ready to save, call mutate to save, reset readyToSave
    if (dataConfigWithData.data && readyToSave) {
      mutate()
      setReadyToSave(false)
    }
  }, [dataConfigWithData, readyToSave, mutate])

  const handleOnPressPicker = async () => {
    let uploadingFailed = false
    try {
      // request permissions first
      const hasPermissions = await requestStoragePermission()
      if (hasPermissions) {
        // open document picker dialog
        const result = await handlePicker()

        // propagate document picker dialog result to the component
        await setUploadFileResult(result)

        if (isDocumentResultSuccess(result)) {
          // normalize file uri for all platforms
          const fileUri = normalizeUri(result.assets[0].uri || '')
          const fileInfo = isWeb ? null : await FileSystem.getInfoAsync(fileUri)
          if (isWeb || fileInfo?.exists) {
            // if file exists, call upload request
            if (activateModalSpinner) {
              dispatch(layoutSlice.actions.setModalSpinnerVisible())
            }
            // set readyToSave instead of mutate - problem with prepareFormDataForUpload (old data)
            setReadyToSave(true)
          } else {
            uploadingFailed = true
          }
        }
      } else {
        uploadingFailed = true
      }
    } catch (error) {
      console.error('error', error)
      uploadingFailed = true
    }

    if (uploadingFailed) {
      // show error in snackbar if anything has failed
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.AUTHORIZED,
        isVisible: true,
        data: {
          color: 'error',
          text: 'priceList.error.upload',
        },
      })
    }
  }
  return (
    <View sx={sxStyles.wrapper}>
      {isSmallDevice ? (
        <View sx={sxStyles.icon}>
          <Icon
            onPress={handleOnPressPicker}
            name={specialIcon ?? 'upload-file'}
            color={color}
          />
        </View>
      ) : (
        <IconButton
          iconName={specialIcon ?? 'file-upload'}
          left
          title={t(buttonText ?? 'button.uploadFile')}
          onPress={handleOnPressPicker}
          color={color ?? 'primaryColor500'}
          isLoading={isLoading}
          size={'small'}
          status={status}
          appearance={appearance}
        />
      )}
    </View>
  )
}
const applyStyles = (color?: Colors): ObjectsOfSxProps => ({
  wrapper: {
    flex: 1,
    marginRight: 2,
  },
  icon: {
    padding: [0, null, 1],
    borderWidth: 1,
    borderRadius: 5,
    borderColor: color ?? 'primaryColor200',
  },
})
