import {Icon} from '@ui-kitten/components'
import {View, Text, DripsyCustomTheme} from 'dripsy'
import React from 'react'

import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {PressableStateProps} from 'theme/style_helper'

interface Props {
  label: string
  isLast?: boolean
  pressableProps?: PressableStateProps
}

export const BreadcrumbItemContent: React.FC<Props> = ({
  label,
  isLast,
  pressableProps,
}) => {
  const sxStyles = useThemeSxStyles(applyStyles, isLast)

  return (
    <Text sx={sxStyles.text} {...pressableProps}>
      <Text sx={sxStyles.label}>{label}</Text>
      {!isLast ? (
        <View sx={sxStyles.icon}>
          <Icon name={'chevron-right'} pack={'material'} size={5} />
        </View>
      ) : null}
    </Text>
  )
}

const applyStyles = (
  _theme: DripsyCustomTheme,
  isLast?: boolean
): ObjectsOfSxProps => ({
  text: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    color: '#023B26',
    fontFamily: 'Montserrat-Bold',
    fontSize: 12,
    cursor: isLast ? 'default' : 'pointer',
  },
  label: {
    textDecorationLine: isLast ? 'none' : 'underline',
  },
  icon: {
    marginX: 0.5,
    justifyContent: 'end',
  },
})
