import React from 'react'
import {Pressable, PressableStateCallbackType} from 'react-native'

import {PressableStateProps} from 'theme/style_helper'

interface Props {
  disabled?: boolean
  onPress: () => void
  children(props: PressableStateProps): JSX.Element
}

export const Link: React.FC<Props> = (props) => {
  const {disabled, onPress, children} = props
  return (
    <Pressable
      accessibilityRole={'link'}
      delayLongPress={750}
      onPress={onPress}
    >
      {(pressableState: PressableStateCallbackType) =>
        children({pressableState, disabled})
      }
    </Pressable>
  )
}
