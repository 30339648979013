import {yupResolver} from '@hookform/resolvers/yup'
import {Icon as UKIcon} from '@ui-kitten/components'
import {useSx, View} from 'dripsy'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {NativeSyntheticEvent, TextInputChangeEventData} from 'react-native'

import {ColumnWithDiffUnitValue} from 'common/components/ColumnWithDiffUnitValue'
import {
  calculateProductPrice,
  prepareOptionsFromStringArray,
} from 'common/data_helpers'
import {
  DOT_AND_COMMA_REG_EXP,
  checkForDot,
  INTEGER_MEASUREMENT_UNITS,
} from 'common/grid_renderers_helpers'
import {formatStringToNumber} from 'common/number_helpers'
import {InputController} from 'form/components/InputController'
import {SelectController} from 'form/components/SelectController'
import {quickShopSchema} from 'form/form_schemas'
import {NativeGridItemHeader} from 'grid/components/NativeGridItemHeader'
import {CellRendererParams} from 'grid/grid_types'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {newOrderSlice} from 'new_order/new_order_slice'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

export const QuickOrderAmountCellRenderer: React.FC<CellRendererParams> = ({
  data,
  isSmall,
  colDef,
}) => {
  const dispatch = useAppDispatch()
  const sx = useSx()
  const {t} = useTranslation()

  const sxStyles = applyStyles(isSmall)
  const resetNewOrder = useAppSelector((state) => state.newOrder.reset)

  const productId = data?.id
  const product = useAppSelector((state) =>
    data ? state.newOrder.products[data.id] : undefined
  )

  const {control, watch, reset} = useForm({
    resolver: yupResolver(quickShopSchema),
  })
  const amountValue = watch('amount')
  const unitOfMeasureValue = watch('unitOfMeasure')

  const resetForm = useCallback(() => {
    reset({
      amount: 0,
      unitOfMeasure:
        data.unitOptions && data.unitOptions.length > 0
          ? data.unitOptions[0]
          : data.unitOfMeasure,
    })
  }, [reset, data])

  // reset form when all products data are reset in redux slice (in parent component)
  useEffect(() => {
    if (resetNewOrder) {
      // reset after send products to shopCart
      resetForm()
      dispatch(newOrderSlice.actions.setReset(false))
    }
  }, [resetNewOrder, dispatch, resetForm])

  // when row data or product in redux slice has changed, reset form to data or product value
  useEffect(() => {
    if (data) {
      if (product) {
        reset({
          amount: product.amount,
          unitOfMeasure: product.unitOfMeasure,
        })
      } else {
        resetForm()
      }
    }
  }, [data, reset, product, resetForm])

  // dispatch new (possibly empty) value to redux slice
  const setProductAmountValue = useMemo(
    () => (value: number) => {
      if (value > 0) {
        dispatch(
          newOrderSlice.actions.setProductAmount({
            id: productId,
            unitOfMeasure: unitOfMeasureValue,
            amount: formatStringToNumber(value),
          })
        )
      } else {
        dispatch(newOrderSlice.actions.deleteProduct({id: productId}))
      }
    },
    [dispatch, productId, unitOfMeasureValue]
  )

  // debounced version of setProductAmountValue
  const setProductAmountValueDebounce = useMemo(
    () => debounce(setProductAmountValue, 1000),
    [setProductAmountValue]
  )

  // dispatch change of product amount and unit of measure to redux slice.
  // NOTE: dispatching delete product to redux slice is not handled here but in handleChangeAmountValue
  useEffect(() => {
    if (amountValue) {
      const formattedAmountValue = checkForDot(
        formatStringToNumber(amountValue),
        unitOfMeasureValue
      )

      if (
        !resetNewOrder &&
        (!product || (product && product.amount !== formattedAmountValue))
      ) {
        setProductAmountValueDebounce(formattedAmountValue)
      }
    }
  }, [
    amountValue,
    setProductAmountValueDebounce,
    product,
    resetNewOrder,
    unitOfMeasureValue,
  ])

  // dispatch change of product unit of measure to redux slice (only when product is already in slice)
  useEffect(() => {
    if (
      productId &&
      unitOfMeasureValue &&
      product &&
      product.unitOfMeasure !== unitOfMeasureValue
    ) {
      dispatch(
        newOrderSlice.actions.setProductUnitOfMeasure({
          id: productId,
          unitOfMeasure: unitOfMeasureValue,
        })
      )
    }
  }, [unitOfMeasureValue, dispatch, productId, product])

  const options = useMemo(
    () => prepareOptionsFromStringArray(data?.unitOptions),
    [data?.unitOptions]
  )

  // When new product amount is empty and product was already in slice dispatch delete product to redux slice
  const handleChangeAmountValue = useCallback(
    (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
      if (
        amountValue &&
        e.nativeEvent.text === '' &&
        product &&
        product.amount
      ) {
        setProductAmountValueDebounce(0)
      }
    },
    [amountValue, setProductAmountValueDebounce, product]
  )

  const calculatedProductPrice = useMemo(() => {
    const calculatedPrice =
      unitOfMeasureValue && data
        ? calculateProductPrice(unitOfMeasureValue, data)
        : null
    // null for ColumnWidthDiffUnitValue, value (0) - return "Ina mj"
    return calculatedPrice === 0 ? null : calculatedPrice
  }, [unitOfMeasureValue, data])

  const handleKeyPress = (e) => {
    if (
      INTEGER_MEASUREMENT_UNITS.includes(unitOfMeasureValue) &&
      DOT_AND_COMMA_REG_EXP.test(e.key)
    ) {
      e.preventDefault()
    }
  }

  return (
    data && (
      <View sx={sxStyles.containerWrapper}>
        <View sx={sxStyles.amountWrapper}>
          {isSmall && colDef?.cellRendererParams?.headerName ? (
            <NativeGridItemHeader
              value={`${t(colDef.cellRendererParams.headerName)}:`}
            />
          ) : null}
          <View
            sx={{
              ...sxStyles.container,
              ...(product ? sxStyles.productSetContainer : {}),
            }}
          >
            {product && (
              <View sx={sxStyles.buttonWrapper}>
                <UKIcon
                  name={'shopping-cart'}
                  pack={'material'}
                  style={sx(sxStyles.shoppingCartIcon)}
                />
              </View>
            )}
            <View sx={sxStyles.inputWrapper}>
              <View sx={sxStyles.input}>
                <InputController
                  control={control}
                  id={'amount'}
                  size={'small'}
                  maxLength={7}
                  isColumn={true}
                  hideErrorInput
                  textStyle={sx(sxStyles.textStyle)}
                  isNumber
                  selectTextOnFocus
                  onChange={handleChangeAmountValue}
                  onKeyPress={handleKeyPress}
                />
              </View>
              <View sx={sxStyles.select}>
                <SelectController
                  control={control}
                  name={'unitOfMeasure'}
                  displayLabelProperty={'name'}
                  idProperty={'name'}
                  options={options}
                  selectInputStyle={sxStyles.selectInputStyle}
                  size={'small'}
                  hideErrorInput
                />
              </View>
            </View>
          </View>
        </View>
        <View sx={sxStyles.priceForUnitWrapper}>
          {isSmall && colDef?.cellRendererParams?.priceHeaderName ? (
            <NativeGridItemHeader
              value={`${t(colDef.cellRendererParams.priceHeaderName)}:`}
            />
          ) : null}
          <ColumnWithDiffUnitValue
            value={data?.price ? calculatedProductPrice : null}
            isSmall={isSmall}
            unitOfMeasure={data.unitOfMeasure}
            withCurrency
            selectedUnitOfMeasure={unitOfMeasureValue}
            packingAmount={data.packingAmount}
          />
        </View>
      </View>
    )
  )
}

const applyStyles = (isSmall?: boolean): ObjectsOfSxProps => ({
  containerWrapper: {
    flex: 1,
    flexDirection: isSmall ? 'column' : 'row',
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: ['flex-end', null, 'center'],
    justifyContent: 'flex-end',
  },
  productSetContainer: {
    height: isWeb ? 'inherit' : '100%',
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textStyle: {
    maxWidth: '80%',
  },
  input: {
    marginRight: 1,
    width: 80,
  },
  select: {
    marginRight: 1,
    maxWidth: [105, null, 85],
  },
  selectInputStyle: {
    maxWidth: [105, null, 85],
    minWidth: [105, null, 85],
  },
  shoppingCartIcon: {
    color: 'grayColor500',
    height: 24,
    width: 24,
  },
  buttonWrapper: {
    height: '100%',
    width: '20%',
    paddingRight: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  priceForUnitWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    ...(isSmall ? {flex: 1, pt: 1} : {minWidth: 65}),
  },
  amountWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
})
