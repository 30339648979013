import {Modal} from '@ui-kitten/components'
import {View, Text, useSx, DripsyCustomTheme} from 'dripsy'
import React from 'react'
import {Control, FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {IconButton} from 'common/components/IconButton'
import {ModalContentWrapper} from 'common/components/ModalContentWrapper'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {InputController} from 'form/components/InputController'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  displayRemove: boolean
  isDisabledConfirm: boolean
  modalVisible: boolean
  onPressCancel: () => void
  onPressConfirm: () => void
  onPressDelete: () => void
  control: Control<any>
  errors: FieldErrors
  productName: string
}

export const CustomPriceModal: React.FC<Props> = ({
  isDisabledConfirm,
  // isLoading,
  displayRemove,
  modalVisible,
  onPressCancel,
  onPressConfirm,
  onPressDelete,
  control,
  errors,
  productName,
}) => {
  const sx = useSx()
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)

  return (
    <Modal
      visible={modalVisible}
      backdropStyle={styles.backdrop}
      onBackdropPress={onPressCancel}
      style={styles.container}
    >
      <ModalContentWrapper
        withKeyboardAwareScroll
        withoutWrapperScreenKeyboardAwareAScroll
      >
        <View sx={sxStyles.container}>
          <Text sx={sxStyles.text} variant={'body1Bold'}>
            {t('customPrice.modalTitle')}
          </Text>
          <View sx={sxStyles.productNameWrapper}>
            <Text variant={'captionBold'}>{t('customPrice.productName')}</Text>
            <Text variant={'captionBold'}>{productName}</Text>
          </View>
          <InputController
            control={control}
            id={'price'}
            isColumn
            label={t('customPrice.label')}
            error={errors.price}
            hideErrorInput={!errors.price}
            size={'small'}
            labelStyle={sxStyles.label}
            maxLength={7}
            textStyle={sx(sxStyles.textStyles)}
            showErrorInputBorder={!!errors.price}
            isNumber
            isCurrency
          />
          {displayRemove && onPressDelete && (
            <View sx={sxStyles.removeButton}>
              <IconButton
                iconName={'close'}
                left
                title={t('button.removeSetPrice')}
                onPress={onPressDelete}
                color={'secondaryColor500'}
                appearance={'outline'}
                status={'danger'}
                size={'small'}
                // isLoading={isLoading}
              />
            </View>
          )}
          <View sx={sxStyles.buttons}>
            <IconButton
              iconName={'arrow-back'}
              left
              title={t('button.cancel')}
              onPress={onPressCancel}
              color={'primaryColor500'}
              appearance={'outline'}
              size={'small'}
              // disabled={isLoading}
            />
            <IconButton
              iconName={'check'}
              left
              title={t('button.save')}
              onPress={onPressConfirm}
              color={'primaryColor500'}
              size={'small'}
              disabled={isDisabledConfirm}
              // isLoading={isLoading}
            />
          </View>
        </View>
      </ModalContentWrapper>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 300,
    minHeight: 192,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
})

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  container: {
    alignSelf: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 15,
    maxWidth: [300, 320, 500],
    padding: 2,
    marginY: 2,
    width: '100%',
  },
  text: {
    marginTop: 2,
    marginBottom: 5,
    paddingX: 4,
    textAlign: 'center',
    color: 'primaryColor500',
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    mt: 3,
  },
  removeButton: {
    mt: 2,
    alignItems: 'center',
  },
  productNameWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    py: 1,
  },
  label: {
    ...theme.text.captionBold,
  },
})
