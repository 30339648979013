export const COUNTRY_CODE_MAP = {
  Ascension: 'AC',
  Andora: 'AD',
  ['Spojené arabské emiráty']: 'AE',
  Afganistan: 'AF',
  'Antigua a Barbuda': 'AG',
  Anguilla: 'AI',
  ['Albánsko']: 'AL',
  ['Arménsko']: 'AM',
  Angola: 'AO',
  ['Antarktída']: 'AQ',
  ['Argentína']: 'AR',
  ['Americká Samoa']: 'AS',
  ['Rakúsko']: 'AT',
  ['Austrália']: 'AU',
  Aruba: 'AW',
  Alandy: 'AX',
  ['Azerbajdžan']: 'AZ',
  'Bosna a Hercegovina': 'BA',
  Barbados: 'BB',
  ['Bangladéš']: 'BD',
  Belgicko: 'BE',
  Burkina: 'BF',
  Bulharsko: 'BG',
  Bahrajn: 'BH',
  Burundi: 'BI',
  Benin: 'BJ',
  ['Svätý Bartolomej']: 'BL',
  Bermudy: 'BM',
  Brunej: 'BN',
  ['Bolívia']: 'BO',
  ['Bonaire, Svätý Eustach a Saba']: 'BQ',
  ['Brazília']: 'BR',
  Bahamy: 'BS',
  ['Bhután']: 'BT',
  'Bouvetov ostrov': 'BV',
  Botswana: 'BW',
  Bielorusko: 'BY',
  Belize: 'BZ',
  Kanada: 'CA',
  ['Kokosové ostrovy']: 'CC',
  ['Konžská demokratická republika']: 'CD',
  ['Stredoafrická republika']: 'CF',
  Kongo: 'CG',
  ['Švajčiarsko']: 'CH',
  ['Pobrežie Slonoviny']: 'CI',
  'Cookove ostrovy': 'CK',
  ['Čile']: 'CL',
  Kamerun: 'CM',
  ['Čína']: 'CN',
  Kolumbia: 'CO',
  Kostarika: 'CR',
  Kuba: 'CU',
  Kapverdy: 'CV',
  ['Curaçao']: 'CW',
  ['Vianočný ostrov']: 'CX',
  Cyprus: 'CY',
  ['Česká republika']: 'CZ',
  Nemecko: 'DE',
  ['Džibutsko']: 'DJ',
  ['Dánsko']: 'DK',
  Dominika: 'DM',
  ['Dominikánska republika']: 'DO',
  ['Alžírsko']: 'DZ',
  ['Ekvádor']: 'EC',
  ['Estónsko']: 'EE',
  Egypt: 'EG',
  ['Západná Sahara']: 'EH',
  Eritrea: 'ER',
  ['Španielsko']: 'ES',
  ['Etiópia']: 'ET',
  ['Európska únia']: 'EU',
  ['Fínsko']: 'FI',
  ['Fidži']: 'FJ',
  Falklandy: 'FK',
  ['Mikronézia']: 'FM',
  ['Faerské ostrovy']: 'FO',
  ['Francúzsko']: 'FR',
  Gabon: 'GA',
  ['Spojené kráľovstvo']: 'GB',
  Grenada: 'GD',
  ['Gruzínsko']: 'GE',
  ['Francúzska Guyana']: 'GF',
  Guernsey: 'GG',
  Ghana: 'GH',
  ['Gibraltár']: 'GI',
  ['Grónsko']: 'GL',
  Gambia: 'GM',
  Guinea: 'GN',
  Guadeloupe: 'GP',
  ['Rovníková Guinea']: 'GQ',
  ['Grécko']: 'GR',
  ['Južná Georgia a Južné Sandwichove ostrovy']: 'GS',
  Guatemala: 'GT',
  Guam: 'GU',
  'Guinea-Bissau': 'GW',
  Guyana: 'GY',
  Hongkong: 'HK',
  'Heardov ostrov': 'HM',
  Honduras: 'HN',
  ['Chorvátsko']: 'HR',
  Haiti: 'HT',
  ['Maďarsko']: 'HU',
  ['Indonézia']: 'ID',
  ['Írsko']: 'IE',
  Izrael: 'IL',
  Man: 'IM',
  India: 'IN',
  ['Britské indickooceánske územie']: 'IO',
  Irak: 'IQ',
  ['Irán']: 'IR',
  Island: 'IS',
  Taliansko: 'IT',
  Jersey: 'JE',
  Jamajka: 'JM',
  ['Jordánsko']: 'JO',
  Japonsko: 'JP',
  ['Keňa']: 'KE',
  Kirgizsko: 'KG',
  ['Kambodža']: 'KH',
  Kiribati: 'KI',
  Komory: 'KM',
  ['Svätý Krištof a Nevis']: 'KN',
  ['Kórejská ľudovodemokratická republika (KĽDR)']: 'KP',
  ['Kórejská republika']: 'KR',
  Kuvajt: 'KW',
  'Kajmanie ostrovy': 'KY',
  Kazachstan: 'KZ',
  Laos: 'LA',
  Libanon: 'LB',
  ['Svätá Lucia']: 'LC',
  ['Lichtenštajnsko']: 'LI',
  ['Srí Lanka']: 'LK',
  ['Libéria']: 'LR',
  Lesotho: 'LS',
  Litva: 'LT',
  Luxembursko: 'LU',
  ['Lotyšsko']: 'LV',
  ['Líbya']: 'LY',
  Maroko: 'MA',
  Monako: 'MC',
  Moldavsko: 'MD',
  ['Čierna Hora']: 'ME',
  ['Svätý Martin']: 'MF',
  Madagaskar: 'MG',
  'Marshallove ostrovy': 'MH',
  ['Severné Macedónsko']: 'MK',
  Mali: 'ML',
  Mjanmarsko: 'MM',
  Mongolsko: 'MN',
  Macao: 'MO',
  ['Severné Mariány']: 'MP',
  Martinik: 'MQ',
  ['Mauritánia']: 'MR',
  Montserrat: 'MS',
  Malta: 'MT',
  ['Maurícius']: 'MU',
  Maldivy: 'MV',
  Malawi: 'MW',
  Mexiko: 'MX',
  Malajzia: 'MY',
  Mozambik: 'MZ',
  ['Namíbia']: 'NA',
  ['Nová Kaledónia']: 'NC',
  Niger: 'NE',
  Norfolk: 'NF',
  ['Nigéria']: 'NG',
  Nikaragua: 'NI',
  Holandsko: 'NL',
  ['Nórsko']: 'NO',
  ['Nepál']: 'NP',
  Nauru: 'NR',
  Niue: 'NU',
  ['Nový Zéland']: 'NZ',
  ['Omán']: 'OM',
  Panama: 'PA',
  Peru: 'PE',
  ['Francúzska Polynézia']: 'PF',
  ['Papua-Nová Guinea']: 'PG',
  ['Filipíny']: 'PH',
  Pakistan: 'PK',
  ['Poľsko']: 'PL',
  'Saint Pierre a Miquelon': 'PM',
  'Pitcairnove ostrovy': 'PN',
  Portoriko: 'PR',
  ['Palestína']: 'PS',
  Portugalsko: 'PT',
  Palau: 'PW',
  Paraguaj: 'PY',
  Katar: 'QA',
  ['Réunion']: 'RE',
  Rumunsko: 'RO',
  Srbsko: 'RS',
  Rusko: 'RU',
  Rwanda: 'RW',
  ['Saudská Arábia']: 'SA',
  ['Šalamúnove ostrovy']: 'SB',
  Seychely: 'SC',
  ['Sudán']: 'SD',
  ['Švédsko']: 'SE',
  Singapur: 'SG',
  ['Svätá Helena']: 'SH',
  'Sierra Leone': 'SI',
  Svalbard: 'SJ',
  ['Slovenská republika']: 'SK',
  Slovinsko: 'SL',
  ['San Maríno']: 'SM',
  Senegal: 'SN',
  ['Somálsko']: 'SO',
  Surinam: 'SR',
  ['Južný Sudán']: 'SS',
  ['Svätý Tomáš a Princov ostrov']: 'ST',
  ['Salvádor']: 'SV',
  ['Svätý Maarten']: 'SX',
  ['Sýria']: 'SY',
  Svazijsko: 'SZ',
  'Tristan da Cunha': 'TA',
  'Turks a Caicos': 'TC',
  ['Čad']: 'TD',
  ['Francúzske južné územie']: 'TF',
  Togo: 'TG',
  Thajsko: 'TH',
  ['Tadžikistan']: 'TJ',
  Tokelau: 'TK',
  ['Východný Timor']: 'TL',
  ['Turkménsko']: 'TM',
  Tunisko: 'TN',
  Tonga: 'TO',
  Turecko: 'TR',
  'Trinidad a Tobago': 'TT',
  Tuvalu: 'TV',
  Taiwan: 'TW',
  ['Tanzánia']: 'TZ',
  Ukrajina: 'UA',
  Uganda: 'UG',
  ['Menšie odľahlé ostrovy USA']: 'UM',
  ['Spojené štáty, USA']: 'US',
  Uruguaj: 'UY',
  Uzbekistan: 'UZ',
  ['Vatikán']: 'VA',
  ['Svätý Vincent a Grenadíny']: 'VC',
  Venezuela: 'VE',
  ['Britské Panenské ostrovy']: 'VG',
  ['Americké Panenské ostrovy']: 'VI',
  Vietnam: 'VN',
  Vanuatu: 'VU',
  'Wallis a Futuna': 'WF',
  Samoa: 'WS',
  Kosovo: 'XK',
  Jemen: 'YE',
  Mayotte: 'YT',
  ['Južná Afrika']: 'ZA',
  Zambia: 'ZM',
  Zimbabwe: 'ZW',
}

function normalizeCountryName(countryName: string) {
  return countryName.toLowerCase().trim().replace(' ', '_')
}

export function getCode(
  countryName: keyof typeof COUNTRY_CODE_MAP | '',
  countryCode?: string
): string {
  let code: string | undefined =
    countryCode ?? (countryName ? COUNTRY_CODE_MAP[countryName] : undefined)
  if (!code) {
    code =
      Object.keys(COUNTRY_CODE_MAP)
        .map((key) => normalizeCountryName(key))
        .find((item) => item === normalizeCountryName(countryName)) || ''
  }
  return code.toUpperCase()
}
