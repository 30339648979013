import {ApiClient} from '@sincersoft/fe-core'
import * as FileSystem from 'expo-file-system'
import * as IntentLauncher from 'expo-intent-launcher'
import {Linking} from 'react-native'

import {makeUrlWithQueryParams} from 'common/url_helpers'
import {isAndroid} from 'layout/layout_constants'

type UseExportToPdfHandleOnPressExport = (isJson?: boolean) => void

export type UseExportToPdfReturn = {
  handleOnPressExport: UseExportToPdfHandleOnPressExport
}

export const useExportToPdf = (
  apiUrl: string,
  fileName: string,
  apiParams?: Record<string, any>
): UseExportToPdfReturn => {
  const accessToken = ApiClient._storageDataUtils.get('access_token')

  const downloadForAndroid = async (url: string) => {
    try {
      const downloadResumable = FileSystem.createDownloadResumable(
        url,
        FileSystem.documentDirectory + fileName,
        {
          headers: {
            Pragma: 'no-cache',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      const response = await downloadResumable.downloadAsync()
      if (response) {
        FileSystem.getContentUriAsync(response.uri).then((cUri) => {
          IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
            data: cUri,
            flags: 1,
            type: 'application/pdf',
          })
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const createAndOpenUrl = (url: string) => {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url)
      } else {
        console.info("Don't know how to open URI: " + url)
      }
    })
  }

  const handleOnPressExport: UseExportToPdfHandleOnPressExport = async (
    isJson
  ) => {
    const urlWithParams = makeUrlWithQueryParams(apiUrl, apiParams)
    if (isAndroid) {
      if (isJson) {
        createAndOpenUrl(urlWithParams)
      } else {
        // TODO: check this past comment - Problem with expo-file-system and pdf files (corrupted)
        await downloadForAndroid(urlWithParams)
      }
    } else {
      createAndOpenUrl(urlWithParams)
    }
  }

  return {
    handleOnPressExport,
  }
}
