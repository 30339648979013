import {useLinkTo} from '@react-navigation/native'
import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import * as Device from 'expo-device'
import {Subscription} from 'expo-modules-core'
import * as Notifications from 'expo-notifications'
import {useState, useEffect, useRef, useCallback} from 'react'
import {Platform} from 'react-native'

import {
  getDataConfigForRegisterDevice,
  registerForPushNotificationsAsync,
} from 'common/notification_helper'
import {isWeb} from 'layout/layout_constants'
import {layoutSlice} from 'layout/layout_slice'
import {
  isNotificationResponseData,
  NotificationResponseData,
} from 'layout/layout_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

export type RegisterDeviceData = {
  expoPushToken: string
  platformOs: string
  deviceBrand: string
  deviceManufacturer: string
  deviceModelName: string
  deviceModelId: string
  deviceOsVersion: string
  deviceType: string
}

export function useNotifications(): void {
  const [expoPushToken, setExpoPushToken] = useState<string | undefined>('')
  const [registerDevice, setRegisterDevice] = useState<string>('')
  const notificationListener = useRef<Subscription>()
  const responseListener = useRef<Subscription>()
  const linkTo = useLinkTo()
  const dispatch = useAppDispatch()
  const loginUser = useAppSelector((state) => state.login.loginUser)

  const getDeviceInfo = useCallback((): RegisterDeviceData | undefined => {
    return expoPushToken
      ? {
          deviceBrand: Device.brand ?? '',
          deviceManufacturer: Device.manufacturer ?? '',
          deviceModelId: Device.modelId ?? '',
          deviceModelName: Device.modelName ?? '',
          deviceOsVersion: Device.osVersion ?? '',
          deviceType: registerDevice,
          platformOs: Platform.OS,
          expoPushToken,
        }
      : undefined
  }, [expoPushToken, registerDevice])

  const handleSuccessNotification = useCallback(
    (response: NotificationResponseData) => {
      linkTo(response.link)
    },
    [linkTo]
  )

  const registerDeviceConfig = getDataConfigForRegisterDevice<
    RegisterDeviceData | undefined
  >(getDeviceInfo())

  const {mutate} = useApiMutation(
    extractKey({
      data: registerDeviceConfig.data,
      method: registerDeviceConfig.method,
      url: registerDeviceConfig.url,
    }),
    undefined,
    {
      reactMutationOptions: {
        // onSuccess: (response) => console.log('Response from B:', response),
        // onError: (e) => console.log('Error from B:', e),
      },
      axiosConfig: {
        ...registerDeviceConfig,
      },
    },
    true
  )

  useEffect(() => {
    if (registerDevice !== '' && expoPushToken && loginUser) {
      mutate()
    }
  }, [registerDevice, expoPushToken, mutate, loginUser])

  useEffect(() => {
    if (!isWeb) {
      registerForPushNotificationsAsync().then((token) =>
        setExpoPushToken(token)
      )

      // This listener is fired whenever a notification is received while the app is foregrounded
      notificationListener.current =
        Notifications.addNotificationReceivedListener((notification) => {
          if (
            notification.request.content.data &&
            isNotificationResponseData(notification.request.content.data)
          ) {
            dispatch(
              layoutSlice.actions.setNotificationData(
                notification.request.content.data
              )
            )
          } else {
            console.warn(
              'Push notification data is missing link property or has inappropriate format.'
            )
          }
        })

      // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
      responseListener.current =
        Notifications.addNotificationResponseReceivedListener((response) => {
          if (
            response.notification.request.content.data &&
            isNotificationResponseData(
              response.notification.request.content.data
            )
          ) {
            handleSuccessNotification(
              response.notification.request.content.data
            )
          } else {
            console.warn(
              'Push notification data is missing link property or has inappropriate format.'
            )
          }
        })

      return () => {
        if (notificationListener.current) {
          Notifications.removeNotificationSubscription(
            notificationListener.current
          )
        }
        if (responseListener.current) {
          Notifications.removeNotificationSubscription(responseListener.current)
        }
      }
    }
  }, [handleSuccessNotification, dispatch])

  useEffect(() => {
    if (expoPushToken && registerDevice === '') {
      const getDeviceType = async () => {
        const deviceType = await Device.getDeviceTypeAsync()
        setRegisterDevice(Device.DeviceType[deviceType])
      }

      getDeviceType()
    }
  }, [expoPushToken, registerDevice, setRegisterDevice])
}
