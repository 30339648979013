import {yupResolver} from '@hookform/resolvers/yup'
import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {setNavigationTimeout} from 'common/navigation_thunks'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {ChangePasswordSchema} from 'form/form_schemas'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {ScreenKeyboardAwareScrollView} from 'layout/components/ScreenKeyboardAwareScrollView'
import {AuthorizedScreenProps, ProfileStackParamList} from 'layout/layout_types'
import {ProfileChangePasswordForm} from 'profile/components/ProfileChangePasswordForm'
import {CHANGE_PASSWORD_FORM_FIELDS} from 'profile/profile_constants'
import {getDataConfigForChangePassword} from 'profile/profile_helpers'
import {ChangePassword} from 'profile/profile_types'
import {SHORT_SNACKBAR_DURATION} from 'snackbar/snackbar_constants'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

type Props = CompositeScreenProps<
  NativeStackScreenProps<ProfileStackParamList, 'ProfileScreen'>,
  AuthorizedScreenProps
>

export const ProfileChangePasswordScreen: React.FC<Props> = ({navigation}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const userId = useAppSelector((state) => state.login.loginUser?.id)

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<ChangePassword>({
    resolver: yupResolver(ChangePasswordSchema),
  })

  const handleSuccess = (response: ResponseSuccess) => {
    dispatch(
      setNavigationTimeout({
        cb: () => {
          navigation.navigate('ProfileScreen')
        },
        timeoutInMs: SHORT_SNACKBAR_DURATION,
      })
    )

    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const getDataToSave = (actualValue: ChangePassword): ChangePassword => {
    return {
      id: userId || null,
      oldPassword: actualValue.oldPassword,
      newPassword: actualValue.newPassword,
    }
  }

  const configForChangePassword =
    getDataConfigForChangePassword<ChangePassword>(getDataToSave(getValues()))

  const {reset, mutate, isLoading} = useApiMutation(
    extractKey({
      data: configForChangePassword.data,
      params: configForChangePassword.params,
      method: configForChangePassword.method,
      url: configForChangePassword.url,
    }),
    configForChangePassword.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccess,
        onError: handleError,
      },
      axiosConfig: {
        ...configForChangePassword,
      },
    },
    true
  )

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])

  const handlePressChange = () => {
    mutate()
  }

  const handlePressCancel = () => {
    navigation.goBack()
  }

  return (
    <>
      <Header hasBackButton />
      <ScreenKeyboardAwareScrollView>
        <AuthorizedLayout
          title={t('profile.changePassword.title')}
          breadcrumbs={
            <Breadcrumbs>
              <BreadcrumbItem
                label={t('profile.title')}
                onPress={() => {
                  navigation.navigate('NavProfileScreen', {
                    screen: 'ProfileScreen',
                  })
                }}
              />
              <BreadcrumbItem
                label={t('profile.changePassword.title')}
                isLast
              />
            </Breadcrumbs>
          }
        >
          <ProfileChangePasswordForm
            onPressChange={handleSubmit(handlePressChange)}
            onPressCancel={handlePressCancel}
            control={control}
            errors={errors}
            formFields={CHANGE_PASSWORD_FORM_FIELDS}
            isLoading={isLoading}
          />
        </AuthorizedLayout>
      </ScreenKeyboardAwareScrollView>
    </>
  )
}
