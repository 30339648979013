import {View, Image} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {IconButton} from 'common/components/IconButton'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface LoginIntroProps {
  handlePressRegister: () => void
}

export const LoginIntro: React.FC<LoginIntroProps> = ({
  handlePressRegister,
}) => {
  const sxStyles = applyStyles()
  const {t} = useTranslation()

  return (
    <View sx={sxStyles.container}>
      <View sx={sxStyles.imgWrapper}>
        <Image
          source={
            isWeb ? require('assets/logo.svg') : require('assets/logo.png')
          }
          sx={sxStyles.image}
          resizeMode={'contain'}
        />
      </View>
      <View sx={sxStyles.button}>
        <IconButton
          iconName={'person-add-alt-1'}
          left
          color={'secondaryColor600'}
          onPress={handlePressRegister}
          title={t('button.register')}
          appearance={'outline'}
          status={'danger'}
        />
      </View>
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    flexDirection: 'column' as const,
    backgroundColor: 'white',
  },
  image: {
    flex: 1,
    maxHeight: [130, null, 360],
    height: [230, null, 360],
    width: '100%',
    mt: 5,
  },
  imgWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  button: {
    position: 'absolute' as const,
    top: [3, null, 5],
    left: [null, null, 4],
    right: [3, null, 'auto'],
  },
})
