// import {CategoryItem, ImageItem} from 'new_order/new_order_types'
import {ProductDetailParentStackScreenProps} from 'layout/layout_types'
import {ProductItem, UnitOfMeasure} from 'new_order/new_order_types'
import {Shop, User} from 'user/user_types'

export interface OrderItem {
  name: string
  // class: string
  // description: string
  // id: string
  // image: null
  // isActive: boolean
  // packing: string
  // code: string
  // price: string
  // priceForUnitOfMeasure: string
  // productCategoryId: CategoryItem
  // entityImageId: ImageItem
  // unitOfMeasure: string
  // worksheetName: string
  adminPrice: number | null
  adminStatus: number | null
  amount: number
  createdAt: number
  customPrice: []
  id: number
  orderId: number
  price?: number
  productId: ProductItem
  status: string
  totalPrice: number
  unitOfMeasure: UnitOfMeasure
  updatedAt: number
}

export interface OrderDetail {
  createdAt: number
  updatedAt: number
  id: number | string
  ident: string
  customerNote: string
  administratorNote: string
  totalPrice: number
  userId: User
  userShopId: Shop
  documentIdentifier: string
  priceListActionPrimary: string
  priceListActionSecondary: string
  priceListStandard: string
}

export interface PriceListsIds {
  priceListActionPrimary?: string
  priceListActionSecondary?: string
  priceListStandard?: string
}

export interface UpdateOrderItem {
  productId: number
  amount?: number | string
  unitOfMeasure?: string
}

export interface UpdateOrderItemInOrders {
  id: number | string
  amount?: number | string
  unitOfMeasure?: string
  status?: string
  price?: number
}

export interface UpdateOrderItemAmount {
  id?: number | string
  amount: number
}

export enum ProductDetailParentLinkType {
  ShopCartScreen = 'ShopCartScreen',
  QuickOrderScreen = 'QuickOrderScreen',
  OrderDetailScreen = 'OrderDetailScreen',
  AdminOrderDetailScreen = 'AdminOrderDetailScreen',
  AdminProductDetailScreen = 'AdminProductDetailScreen',
}

export enum OrderProductBreadcrumbLinkType {
  AdminOrderScreen = 'AdminOrderScreen',
  OrderScreen = 'QuickOrderScreen',
  AdminOrderDetailScreen = 'AdminOrderDetailScreen',
  OrderDetailScreen = 'OrderDetailScreen',
}

export interface ProductDetailScreenTypeAndParams {
  screen: ProductDetailParentStackScreenProps['route']['name']
  params?: ProductDetailParentStackScreenProps['route']['params']
}
