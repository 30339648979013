import {Text, View, SxProp} from 'dripsy'
import React from 'react'

import {ObjectsOfSxProps} from 'layout/layout_types'
import {getPressableStyle, PressableStateProps} from 'theme/style_helper'

interface OwnProps {
  text: string
  textStyle?: SxProp
  containerStyle?: SxProp
}

type Props = OwnProps & PressableStateProps

export const BasicLinkText: React.FC<Props> = ({
  pressableState,
  containerStyle,
  textStyle,
  text,
}) => {
  const sxStyles = applyStyles()
  return (
    <View sx={{...sxStyles.container, ...containerStyle}}>
      <Text
        sx={{
          ...textStyle,
          ...sxStyles.text,
          ...getPressableStyle(pressableState, {
            pressed: sxStyles.pressed,
            focused: sxStyles.focused,
            hovered: sxStyles.hovered,
          }),
        }}
        numberOfLines={1}
        variant={'captionBold'}
      >
        {text}
      </Text>
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
  },
  text: {
    color: 'primaryColor800',
    textDecorationLine: 'underline',
  },
  focused: {
    color: 'primaryColor300',
  },
  hovered: {
    color: 'primaryColor300',
  },
  pressed: {
    color: 'secondaryColor600',
  },
})
