import {QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'

export const REGISTER: QueryInput = {
  url: Api.register,
  method: 'POST',
}

export function getDataConfigForRegister<TData>(
  data: TData
): QueryInput<TData> {
  return {
    ...REGISTER,
    data,
  }
}
