import {NavigationProp} from '@react-navigation/core/lib/typescript/src/types'
import {useNavigation, useRoute} from '@react-navigation/native'
import {ParamListBase} from '@react-navigation/routers'

type Keyof<T extends Record<string, any | undefined>> = Extract<keyof T, string>

export const useNavigateWithReset = <
  P extends ParamListBase,
  T extends NavigationProp<P>
>(): {
  navigate: (navRouteName: Keyof<P>, childScreenName: Keyof<P>) => void
} => {
  const navigation = useNavigation<T>()
  const route = useRoute()

  const navigate = (navRouteName: Keyof<P>, childScreenName: Keyof<P>) => {
    if (route.name === childScreenName) {
      navigation.reset({
        index: 0,
        routes: [{name: navRouteName}],
      })
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigation.navigate({
        name: navRouteName,
        params: {
          screen: childScreenName,
        },
      })
    }
  }

  return {navigate}
}
