import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {View} from 'dripsy'
import React from 'react'

import {DeleteAccount} from 'delete_account/components/DeleteAccount'
import {ContentLayout} from 'layout/components/ContentLayout'
import {ObjectsOfSxProps, RootStackParamList} from 'layout/layout_types'

type Props = NativeStackScreenProps<RootStackParamList, 'DeleteAccountScreen'>

export const DeleteAccountScreen: React.FC<Props> = ({navigation, route}) => {
  const sxStyles = applyStyles()

  return (
    <View sx={sxStyles.containerWrapper}>
      <ContentLayout>
        <DeleteAccount
          navigation={navigation}
          text={'deleteAccount.text'}
          title={'deleteAccount.title'}
          token={route.params.token}
        />
      </ContentLayout>
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  containerWrapper: {
    flex: 1,
    backgroundColor: 'primaryColor100',
  },
})
