import {COUNTRY_CODE_MAP} from 'common/country_flag_helper'

export interface CategoryItem {
  code: string
  createdAt: number
  id: string
  image: null
  isActive: boolean
  name: string
  updatedAt: number
  entityImageId: ImageItem
  entityImageVersion: number
}

export interface ImageItem {
  code: string
  id: string
}

export type UnitOfMeasure = 'g' | 'kg' | 'ks' | 'bal'

export enum ButtonOptions {
  all = 'all',
  lastMonth = 'lastMonth',
  lastYear = 'lastYear',
  lastThreeYears = 'lastThreeYears',
}

export interface DataForChart {
  x: number
  y: number | null
}

export interface ProductItem {
  class: string
  description: string
  id: string
  image: null
  isActive: boolean
  name: string
  origin: keyof typeof COUNTRY_CODE_MAP | ''
  packing: string
  code: string
  packingAmount?: number
  price: number
  priceForUnitOfMeasure: string
  productCategoryId: CategoryItem | string
  entityImageId: ImageItem
  unitOfMeasure: UnitOfMeasure
  unitOptions: UnitOfMeasure[]
  worksheetName: string
  amount?: number
  shopCartId?: number
  shopCartUnitOfMeasure?: UnitOfMeasure
  entityImageVersion: number
}

export interface ProductPriceListChartDataItem {
  price: number | null
  date: number
}

export interface ProductPriceListChart {
  priceListStandardName: string | null
  priceListActionPrimaryName: string | null
  priceListActionSecondaryName: string | null
  labels: number[]
  data: ProductPriceListChartDataItem[]
}

export type ChartColorFn = (opacity: number) => string

export interface UpdateOrderUnitOfMeasurePayload {
  id: string
  unitOfMeasure: string
}

export interface UpdateOrderAmountPayload
  extends UpdateOrderUnitOfMeasurePayload {
  amount: number
}

export interface DeleteProductPayload {
  id: number
}
