import {useIsFocused} from '@react-navigation/native'
import {QueryClientContext} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {QueryKey, useQueryClient} from '@tanstack/react-query'
import {isString} from 'lodash'
import {useEffect} from 'react'

import {usePrevious} from 'common/hooks/usePrevious'
import {useAppDispatch} from 'store/redux_hooks'

interface Keys {
  key: string | string[] | QueryKey
  isGrid?: boolean
}

export const useRefetchQuery = (keys: Keys[], isStale?: boolean) => {
  const dispatch = useAppDispatch()
  const isFocused = useIsFocused()
  const prevIsFocused = usePrevious<boolean>(isFocused)
  const queryClient = useQueryClient({context: QueryClientContext})

  useEffect(() => {
    if (!prevIsFocused && isFocused) {
      keys.forEach((keyItem) => {
        if (keyItem.isGrid) {
          if (isString(keyItem.key)) {
            dispatch(
              gridSlice.actions.setRefreshNeeded({
                refreshNeeded: true,
                stateId: keyItem.key,
              })
            )
          }
        } else if (isStale) {
          queryClient.invalidateQueries(
            Array.isArray(keyItem.key) ? keyItem.key : [keyItem.key]
          )
        }
      })
    }
  }, [isFocused, prevIsFocused, isStale, keys, queryClient, dispatch])
}
