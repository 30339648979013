import * as Font from 'expo-font'
import {useEffect, useState} from 'react'

const customFonts = {
  ['Montserrat']: require('assets/fonts/Montserrat-Medium.ttf'),
  ['Montserrat-Bold']: require('assets/fonts/Montserrat-Bold.ttf'),
}

export const useLoadFonts = (): boolean => {
  const [isFontsLoaded, setIsFontsLoaded] = useState(false)

  useEffect(() => {
    const loadFontsAsync = async () => {
      await Font.loadAsync(customFonts)
      setIsFontsLoaded(true)
    }
    loadFontsAsync()
  }, [])

  return isFontsLoaded
}
