import {ReducerUtils} from '@sincersoft/core'
import {
  extractGridKey,
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {Text, View} from 'dripsy'
import React from 'react'

import {IconButton} from 'common/components/IconButton'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {Api} from 'config/app_config'
import {ApiError} from 'error/error_helper'
import {CellRendererParams} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {getDataConfigForDeleteUserShop} from 'new_order/new_order_helpers'
import {getDataConfigForCart} from 'shop_cart/shop_cart_helpers'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'
import {USER_SHOP_INSTANCE_REDUCER_STATE_GRID_ID} from 'user/user_constants'
import {getDataConfigForUserById} from 'user/user_helpers'
import {ResponseUserShop, Shop} from 'user/user_types'

export const UserShopActionCellRenderer: React.FC<CellRendererParams> = ({
  data,
  isSmall,
}) => {
  const queryClient = useQueryClient({context: QueryClientContext})
  const activeShop = useAppSelector((state) => state.login.activeShop)
  const dispatch = useAppDispatch()

  const handleSuccessDelete = (deletedData: ResponseUserShop) => {
    const configForShopCart = getDataConfigForCart()
    const shopCartKey = extractKey({
      url: configForShopCart.url,
    })
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: USER_SHOP_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    const userShopGridKey = extractGridKey(Api.userShop, {
      params: {
        userId: deletedData.deletedUserShop.userId,
        isActive: true,
        populate: false,
        isPrimary: false,
      },
    })
    const allData = queryClient.getQueryData<Shop[]>(userShopGridKey) ?? []
    const updatedData = ReducerUtils.deleteObjectInArray(
      allData,
      deletedData.deletedUserShop?.id,
      'id',
      ''
    )
    queryClient.setQueryData(userShopGridKey, updatedData)
    if (activeShop && activeShop.id === deletedData.deletedUserShop?.id) {
      queryClient.invalidateQueries(shopCartKey)
    }

    if (updatedData.length === 0) {
      const configForUserById = getDataConfigForUserById(data.userId)

      const userDetailKey = extractKey({
        params: configForUserById.params,
        url: configForUserById.url,
      })

      queryClient.invalidateQueries(userDetailKey)
    }

    dispatch(SnackbarHelpers.getSnackBarSuccess(deletedData.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const configForDeleteUserShop = getDataConfigForDeleteUserShop(data.id)

  const {mutate} = useApiMutation(
    extractKey({
      method: configForDeleteUserShop.method,
      url: configForDeleteUserShop.url,
    }),
    undefined,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessDelete,
        onError: handleError,
      },
      axiosConfig: {
        ...configForDeleteUserShop,
      },
    },
    true
  )

  const handleDeleteUserShop = () => {
    mutate()
  }

  const renderButtons = () => (
    <>
      {data?.isPrimary ? null : (
        <IconButton
          iconName={'delete'}
          left
          appearance={'ghost'}
          size={'small'}
          status={'danger'}
          onPress={handleDeleteUserShop}
        />
      )}
    </>
  )

  return isSmall ? (
    <View sx={sxStyles.container}>{renderButtons()}</View>
  ) : (
    <Text sx={sxStyles.container}>{renderButtons()}</Text>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}
