import {QueryInputAsArray} from '@sincersoft/fe-core'
import {View} from 'dripsy'
import React from 'react'

import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {makeUrlWithQueryParams} from 'common/url_helpers'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {ProductDetailImgAndTitle} from 'new_order/components/ProductDetailImgAndTitle'
import {CategoryItem} from 'new_order/new_order_types'
import {UploadImageButton} from 'product/components/UploadImageButton'
import {ProductCategoryDetailInfo} from 'product_category/components/ProductCategoryDetailInfo'
import {
  getDataConfigForUploadProductCategoryImage,
  getUrlAndParamsForProductCategoryImage,
} from 'product_category/product_category_helpers'

interface ProductDetailProps {
  data: CategoryItem
  keyForInvalidateQuery?: QueryInputAsArray
}

export const ProductCategoryDetail: React.FC<ProductDetailProps> = ({
  data,
  keyForInvalidateQuery,
}) => {
  const sxStyles = useThemeSxStyles(applyStyles)

  const urlAndParams = getUrlAndParamsForProductCategoryImage(
    data.id,
    data.entityImageVersion
  )

  return (
    <View sx={sxStyles.container}>
      <ProductCategoryDetailInfo name={data.name} code={data.code} />
      <ProductDetailImgAndTitle
        hasImage={data.entityImageId != null}
        imageUrlWithParams={makeUrlWithQueryParams(
          urlAndParams.url,
          urlAndParams.urlParams
        )}
      />
      <View sx={sxStyles.button}>
        <UploadImageButton
          dataConfig={getDataConfigForUploadProductCategoryImage(data.id)}
          keyForInvalidateQuery={keyForInvalidateQuery}
        />
      </View>
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    borderWidth: 1,
    borderColor: 'primaryColor300',
    borderRadius: 10,
    marginX: [3, null, 7],
    paddingX: [2, null, 3],
    paddingTop: [3, null, 7],
  },
  button: {
    alignItems: 'center',
    paddingBottom: [4, null, 5],
    paddingTop: [3, null, 4],
  },
})
