import {formatCurrency} from '@sincersoft/core'
import {DripsyCustomTheme, View} from 'dripsy'
import React from 'react'
import {FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {InputController} from 'form/components/InputController'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {OrderDetailInfoItem} from 'order/components/OrderDetailInfoItem'
import {OrderDetail} from 'order/order_types'

interface ProductDetailInfoProps {
  data: OrderDetail
  control?: any
  errors?: FieldErrors
  onBlurNote?: () => void
  isAdmin: boolean
}

export const OrderDetailInfo: React.FC<ProductDetailInfoProps> = ({
  data,
  control,
  isAdmin,
  onBlurNote,
}) => {
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)
  return (
    <View sx={sxStyles.container}>
      <View sx={sxStyles.firstPart}>
        <OrderDetailInfoItem
          label={t('order.detail.shop')}
          value={data.userShopId.name}
        />
        <OrderDetailInfoItem
          label={t('order.detail.date')}
          value={data.createdAt}
          isDate
        />
        <OrderDetailInfoItem
          label={t('order.detail.price')}
          value={formatCurrency(data.totalPrice ?? 0, 'EUR', {
            locale: 'sk-SK',
            style: 'currency',
            maximumFractionDigits: 2,
          })}
        />
      </View>
      <OrderDetailInfoItem
        label={t('order.detail.userNote')}
        value={data.customerNote}
        numberOfLinesForValue={4}
      />
      {isAdmin && control && onBlurNote ? (
        <InputController
          control={control}
          label={t('order.detail.adminNote')}
          isColumn
          id={'administratorNote'}
          multiline
          numberOfLines={4}
          maxLength={400}
          labelStyle={sxStyles.label}
          onBlur={onBlurNote}
        />
      ) : (
        <OrderDetailInfoItem
          label={t('order.detail.adminNote')}
          value={data.administratorNote}
          numberOfLinesForValue={4}
        />
      )}
    </View>
  )
}

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  container: {
    mx: 1,
    flex: [null, null, 1],
    borderWidth: 1,
    borderColor: 'primaryColor300',
    borderRadius: 20,
    backgroundColor: 'primaryColor50',
    paddingX: [3],
    paddingY: [4],
  },
  firstPart: {
    flexDirection: ['column', null, null, 'row'],
  },
  wrapper: {
    flex: 1,
    paddingTop: [3, null, 5],
    justifyContent: ['space-between', null, null, 'flex-start'],
  },
  label: {
    ...theme.text.body1Bold,
    color: 'secondaryColor700',
    fontSize: [3, null, 4],
    flex: 0.55,
  },
  value: {
    flex: 1,
    textAlign: 'right',
    color: 'grayColor700',
    paddingLeft: [2],
    paddingRight: [0, null, null, 4],
    ...theme.text.body1,
    fontSize: [3, null, 4],
  },
  noteValue: {
    flex: 1,
    color: 'grayColor700',
    paddingLeft: [2],
    paddingRight: [0, null, null, 4],
    ...theme.text.body1,
    fontSize: [3, null, 4],
  },
})
