import {AxiosError} from 'axios'

export interface ResponseSuccess {
  messageCode: string
  message: string
}

export interface ApiError extends ResponseSuccess {
  errorCode: string
  error?: ApiError
  technicalErrorMessage?: string
}

export function getErrorMessageCode(
  error: AxiosError<ApiError>,
  errorCode?: string
): string | undefined {
  let messageCode =
    errorCode ??
    (error?.response?.data?.error?.messageCode ||
      error?.response?.data?.messageCode)
  if (
    error &&
    error.message &&
    error.message.toLowerCase() === 'network error'
  ) {
    // network or application server is down
    messageCode = 'response.error.common.networkError'
  }
  return messageCode
}
