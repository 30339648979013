import {formatCurrency} from '@sincersoft/core'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSx, View} from 'dripsy'
import {isObject} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TouchableOpacity} from 'react-native'

import {Button} from 'common/components/Button'
import {ConfirmModal} from 'common/components/ConfirmModal'
import {calculateProductPrice} from 'common/data_helpers'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {layoutSlice} from 'layout/layout_slice'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {NewOrderProductListItemCountry} from 'new_order/components/NewOrderProductListItemCountry'
import {NewOrderProductListItemHeader} from 'new_order/components/NewOrderProductListItemHeader'
import {NewOrderProductListItemInfo} from 'new_order/components/NewOrderProductListItemInfo'
import {NewOrderProductListItemShopIcon} from 'new_order/components/NewOrderProductListItemShopIcon'
import {getDataConfigForAddingItemToCart} from 'new_order/new_order_helpers'
import {ImageItem, ProductItem} from 'new_order/new_order_types'
import {getDataConfigForDeleteShopCartItem} from 'shop_cart/shop_cart_helpers'
import {UpdateShopCartItem} from 'shop_cart/shop_cart_types'
import {useAppDispatch} from 'store/redux_hooks'

interface NewOrderProductListItem {
  isButtonsDisabled: boolean
  isShopCartEnabled: boolean
  item: ProductItem
  onPress: (id: string) => void
  onLastRendered?: (name: string) => void
  productCategoryEntityImageId: ImageItem | null
  productCategoryEntityVersion: number
}

const NewOrderProductListItemComponent: React.FC<NewOrderProductListItem> = ({
  isButtonsDisabled,
  isShopCartEnabled,
  item,
  onPress,
  onLastRendered,
  productCategoryEntityImageId,
  productCategoryEntityVersion,
}) => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient({context: QueryClientContext})

  useEffect(() => {
    if (onLastRendered) {
      onLastRendered(item.name)
    }
  }, [onLastRendered, item.name])

  const [modalVisible, setModalVisible] = useState(false)

  const handlePressItem = useCallback(() => {
    onPress(item.id)
  }, [onPress, item.id])

  const configForAddingItemToCart =
    getDataConfigForAddingItemToCart<UpdateShopCartItem>(
      {
        amount: 1,
        unitOfMeasure: item.unitOfMeasure,
        productId: item.id,
      },
      !!item.shopCartId
    )

  const handleSuccessUpdateOrAdd = async (response: ResponseSuccess) => {
    // dispatch(
    //   gridSlice.actions.setRefreshNeeded({
    //     refreshNeeded: true,
    //     stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
    //   })
    // )
    await queryClient.invalidateQueries()
    dispatch(layoutSlice.actions.setModalSpinnerHidden())
    // } else {
    //   const configForShopCartItem = getDataConfigForCartItem()
    //   const shopCartItemKey = extractKey({
    //     url: configForShopCartItem.url,
    //   })
    //   const allData =
    //     queryClient.getQueryData<ShopCartItem[]>(shopCartItemKey) ?? []
    //   console.log(allData, response)
    //   let updatedData = allData || []
    //   updatedData = ReducerUtils.updateObjectInArray(
    //     allData,
    //     response?.cartItem,
    //     response?.cartItem.id,
    //     'id',
    //     ''
    //   )
    //
    //   queryClient.setQueryData(shopCartItemKey, updatedData)
    //   const configForShopCart = getDataConfigForCart()
    //   const shopCartKey = extractKey({
    //     url: configForShopCart.url,
    //   })
    //   queryClient.invalidateQueries(shopCartKey)
    // }
    // queryClient.invalidateQueries(
    //   dispatch(
    //     gridSlice.actions.setRefreshNeeded({
    //       refreshNeeded: true,
    //       stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
    //     })
    //   )
    // }
    dispatch(
      // SnackbarHelpers.getSnackBarSuccess(
      //   item.shopCartId ? 'shopCart.success.edit' : 'shopCart.success.add'
      // )
      SnackbarHelpers.getSnackBarSuccess(response.messageCode)
    )
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(layoutSlice.actions.setModalSpinnerHidden())
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const {mutate} = useApiMutation(
    extractKey({
      url: configForAddingItemToCart.url,
      data: configForAddingItemToCart.data,
    }),
    configForAddingItemToCart.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdateOrAdd,
        onError: handleError,
      },
      axiosConfig: {
        ...configForAddingItemToCart,
      },
    },
    true
  )

  const {t} = useTranslation()
  const hasImage =
    item.entityImageId !== null || productCategoryEntityImageId !== null
  const hasOwnImage = item.entityImageId !== null
  const sxStyles = applyStyles(!!item.shopCartId)

  const sendRequest = useCallback(() => {
    dispatch(layoutSlice.actions.setModalSpinnerVisible())
    mutate()
  }, [mutate, dispatch])

  const handleUpdateOrAddIem = useCallback(() => {
    if (!isButtonsDisabled) {
      sendRequest()
    }
  }, [sendRequest, isButtonsDisabled])

  const configForDeleteShopCartItem = getDataConfigForDeleteShopCartItem({
    id: item.shopCartId,
  })

  const {mutate: deleteMutate} = useApiMutation(
    extractKey({
      data: configForDeleteShopCartItem.data,
      method: configForDeleteShopCartItem.method,
      url: configForDeleteShopCartItem.url,
    }),
    configForDeleteShopCartItem.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdateOrAdd,
        onError: handleError,
      },
      axiosConfig: {
        ...configForDeleteShopCartItem,
      },
    },
    true
  )

  const getCalculatedProductPrice = useMemo(() => {
    return calculateProductPrice(item.unitOfMeasure, item)
  }, [item])

  const handlePressModalCancel = () => {
    setModalVisible(false)
  }
  const handlePressModalConfirm = () => {
    deleteMutate()
    setModalVisible(false)
    dispatch(layoutSlice.actions.setModalSpinnerVisible())
  }

  const handlePressDelete = () => {
    if (!isButtonsDisabled) {
      setModalVisible(true)
    }
  }

  const sx = useSx()

  return (
    <>
      {item ? (
        <TouchableOpacity
          onPress={handlePressItem}
          accessibilityRole={'button'}
          style={sx(sxStyles.container)}
        >
          <NewOrderProductListItemHeader
            onPressItem={handlePressItem}
            itemId={
              hasOwnImage
                ? item.id
                : isObject(item.productCategoryId)
                ? item.productCategoryId.id
                : item.productCategoryId
            }
            itemName={item.name}
            itemImageVersion={
              hasOwnImage
                ? item.entityImageVersion
                : productCategoryEntityVersion
            }
            hasImage={hasImage}
            hasOwnImage={hasOwnImage}
          />
          <View sx={sxStyles.content}>
            {item && item.origin !== '' ? (
              <NewOrderProductListItemCountry
                isInShop={!!item.shopCartId}
                origin={item.origin}
              />
            ) : null}
            <NewOrderProductListItemInfo
              name={item.name}
              description={item.description}
              itemClass={item.class}
              packing={item.packing}
              price={
                item.price && getCalculatedProductPrice
                  ? formatCurrency(getCalculatedProductPrice, 'EUR', {
                      locale: 'sk-SK',
                      style: 'currency',
                      maximumFractionDigits: 2,
                    })
                  : '-'
              }
              unitOptions={item.unitOptions}
              handlePress={handlePressItem}
            />
            {isShopCartEnabled ? (
              <View sx={sxStyles.buttons}>
                {item.shopCartId ? (
                  <Button
                    color={'secondaryColor400'}
                    title={t('button.removeFromShop')}
                    onPress={handlePressDelete}
                    appearance={'outline'}
                    size={'tiny'}
                    status={'danger'}
                  />
                ) : (
                  <Button
                    color={'primaryColor500'}
                    title={t('button.addToShop')}
                    onPress={handleUpdateOrAddIem}
                    appearance={'outline'}
                    size={'tiny'}
                  />
                )}
              </View>
            ) : null}
          </View>
          {item.shopCartId ? <NewOrderProductListItemShopIcon /> : null}
        </TouchableOpacity>
      ) : null}
      <ConfirmModal
        modalVisible={modalVisible}
        onPressConfirm={handlePressModalConfirm}
        onPressCancel={handlePressModalCancel}
        text={t('newOrder.deleteText', {
          productName: item.name,
        })}
        buttonConfirmText={t('button.delete')}
        isDisabledBackdropPress
      />
    </>
  )
}

export const NewOrderProductListItem = React.memo(
  NewOrderProductListItemComponent
)

const applyStyles = (isInShop?: boolean): ObjectsOfSxProps => ({
  container: {
    flexDirection: 'column',
    marginX: [1, null, null, 2],
    marginY: [1, null, 2],
    borderWidth: 2,
    borderColor: isInShop ? 'primaryColor300' : 'grayColor300',
    backgroundColor: isInShop ? 'primaryColor50' : 'white',
    borderRadius: 7,
    maxWidth: ['46%', '31%', 204],
    minWidth: ['46%', '31%', 204],
  },
  content: {
    flex: 1,
    borderTopWidth: 1,
    borderColor: 'grayColor300',
    paddingX: [1, null, 3],
    paddingY: [1],
  },
  labelValue: {
    fontSize: [1, null, 2],
  },
  wrapper: {
    marginY: 1,
  },
  titleWrapper: {
    display: ['none', null, 'flex'],
  },
  descriptionWithClass: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  description: {
    color: 'grayColor600',
    fontSize: [1, null, 2],
  },
  class: {
    color: 'grayColor700',
    fontSize: [1, null, 2],
  },
  buttons: {
    py: 1,
  },
})
