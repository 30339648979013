import {BottomTabScreenProps} from '@react-navigation/bottom-tabs'
import {CompositeScreenProps} from '@react-navigation/native'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ProductCategoryDetailScreen} from './ProductCategoryDetailScreen'
import {ProductCategoryScreen} from './ProductCategoryScreen'
import {
  AuthorizedTabParamList,
  ProductCategoryStackParamList,
  RootStackParamList,
} from 'layout/layout_types'

const CategoryStack =
  createNativeStackNavigator<ProductCategoryStackParamList>()

type Props = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList, 'NavProductCategoryScreen'>,
  NativeStackScreenProps<RootStackParamList>
>

export const NavProductCategoryScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <CategoryStack.Navigator
      initialRouteName={'ProductCategoryScreen'}
      screenOptions={{headerShown: false}}
    >
      <CategoryStack.Screen
        name={'ProductCategoryScreen'}
        component={ProductCategoryScreen}
        options={{title: t('routes.category')}}
      />
      <CategoryStack.Screen
        name={'ProductCategoryDetailScreen'}
        component={ProductCategoryDetailScreen}
        options={{title: t('routes.category')}}
      />
    </CategoryStack.Navigator>
  )
}
