import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {formatToFormItemId} from 'common/validation_helper'
import {REQUIRED_IDS} from 'shop_cart/shop_cart_constants'
import {ErrorStateString} from 'shop_cart/shop_cart_types'

interface IdPayload {
  id: string | number
  deleteAllById?: boolean
}

interface IdPayloadWithName extends IdPayload {
  name: string
}

export interface ShopCartState {
  errorState: ErrorStateString
}

const initialState: ShopCartState = {
  errorState: {},
}

export const shopCartSlice = createSlice({
  name: 'newOrder',
  initialState,
  reducers: {
    setErrorState: (state, action: PayloadAction<IdPayloadWithName>) => {
      state.errorState = {
        ...state.errorState,
        [action.payload.id]: action.payload.name,
      }
    },
    resetErrorState: (state) => {
      state.errorState = {}
    },
    deleteErrorState: (state, action: PayloadAction<IdPayload>) => {
      if (action.payload.deleteAllById) {
        REQUIRED_IDS.forEach((item) => {
          const itemId = formatToFormItemId(action.payload.id, item)
          if (state.errorState[itemId]) {
            delete state.errorState[itemId]
          }
        })
      } else if (state.errorState[action.payload.id]) {
        delete state.errorState[action.payload.id]
      }
    },
  },
})
