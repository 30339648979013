import {View} from 'dripsy'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {IconButton} from 'common/components/IconButton'
import {Colors} from 'grid/grid_types'

interface Props {
  isActive: boolean
  activeColor: Colors
  color: Colors
  onPress: (id: string) => void
  title: string
  id: string
}

const FilterButtonComponent: React.FC<Props> = (props) => {
  const {activeColor, color, id, isActive, onPress, title} = props

  const {t} = useTranslation()
  const sxStyles = getSxStyles()

  const handlePressFilterButton = useCallback(() => {
    onPress(id)
  }, [onPress, id])

  return (
    <View sx={sxStyles.button}>
      <IconButton
        title={t(title)}
        color={isActive ? activeColor : color}
        appearance={isActive ? 'filled' : 'outline'}
        onPress={handlePressFilterButton}
        size={'small'}
      />
    </View>
  )
}

export const FilterButton = React.memo(FilterButtonComponent)

const getSxStyles = () => ({
  button: {
    pr: 1,
    pb: 1,
  },
})
