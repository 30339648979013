import {DripsyCustomTheme, View} from 'dripsy'
import React from 'react'
import {Control} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {InputController} from 'form/components/InputController'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {ShopCartContentPriceAndButton} from 'shop_cart/components/ShopCartContentPriceAndButton'

type Props = {
  control: Control<any>
  isDisabled: boolean
  isLoading: boolean
  isShopCartEnabled: boolean
  onBlurCustomerNote: () => void
  onPressOrder: () => void
  onPressRoute: () => void
  totalPrice?: number
}

const ShopCartContentComponent: React.FC<Props> = ({
  totalPrice,
  onPressOrder,
  onPressRoute,
  isDisabled,
  isLoading,
  isShopCartEnabled,
  control,
  onBlurCustomerNote,
}) => {
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)

  return (
    <View sx={sxStyles.content}>
      <View sx={sxStyles.leftPart}>
        <InputController
          label={t('shopCart.customerNote')}
          isColumn
          control={control}
          id={'customerNote'}
          multiline
          numberOfLines={4}
          maxLength={200}
          onBlur={onBlurCustomerNote}
          labelStyle={sxStyles.inputLabel}
        />
      </View>
      <View sx={sxStyles.rightPart}>
        <ShopCartContentPriceAndButton
          isDisabled={isDisabled}
          isLoading={isLoading}
          isShopCartEnabled={isShopCartEnabled}
          onPressOrder={onPressOrder}
          totalPrice={totalPrice}
          onPressRoute={onPressRoute}
        />
      </View>
    </View>
  )
}

export const ShopCartContent = React.memo(ShopCartContentComponent)

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  content: {
    marginTop: 3,
    borderTopWidth: 2,
    borderTopColor: 'grayColor300',
    flex: 1,
    py: 3,
    px: [3, null, 2],
    flexDirection: ['column', 'column', 'column', 'row'],
  },
  rightPart: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: [1, 4, 6, null, 8],
  },
  leftPart: {
    marginRight: [1, null, 8],
    flex: [1, null, null, null, 1.2],
  },
  priceLabel: {
    ...theme.text.body1Bold,
    color: 'grayColor700',
    alignSelf: 'center',
  },
  priceValue: {
    color: 'primaryColor400',
    ...theme.text.h5Bold,
  },
  inputLabel: {
    ...theme.text.body2Bold,
    color: 'grayColor700',
  },
})
