import {View} from 'dripsy'
import React from 'react'
import ContentLoader, {Rect} from 'common/react_content_loader'
import {StyleSheet} from 'react-native'

import {ObjectsOfSxProps} from 'layout/layout_types'

const Item = () => (
  <ContentLoader style={styles.contentItem}>
    <Rect x={'10'} y={'10'} rx={'5'} ry={'5'} width={'100%'} height={'100%'} />
  </ContentLoader>
)

export const NativeGridSkeletonComponent: React.FC = () => {
  return (
    <View sx={sxStyles.wrapper}>
      {Array.from(Array(10)).map((item, idx) => (
        <View key={idx} sx={sxStyles.item}>
          <Item />
        </View>
      ))}
    </View>
  )
}

export const NativeGridSkeleton = React.memo(NativeGridSkeletonComponent)

const styles = StyleSheet.create({
  contentItem: {
    flex: 1,
  },
})

const sxStyles: ObjectsOfSxProps = {
  wrapper: {
    flex: 1,
    flexDirection: 'column',
  },
  item: {
    height: 60,
  },
}
