import {DateUtils} from '@sincersoft/core'
import {
  Datepicker,
  Icon,
  IconProps,
  NativeDateService,
} from '@ui-kitten/components'
import {EvaSize} from '@ui-kitten/components/devsupport'
import {View, Text, SxProp, useSx} from 'dripsy'
import React, {useCallback} from 'react'
import {
  Controller,
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {TouchableWithoutFeedback} from 'react-native'

import {DisabledLabelValue} from 'common/components/DisabledLabelValue'
import {InputErrorMessage} from 'common/components/InputErrorMessage'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {isAndroid, isIOS} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface InputControllerProps {
  label: string
  control: Control<any>
  error?: FieldError | Merge<FieldError, FieldErrorsImpl>
  id: string
  hideErrorInput?: boolean
  size?: EvaSize
  labelStyle?: SxProp
  isColumn?: boolean
  isDisabled?: boolean
  onPressDeleteIcon?: (id: string) => void
}

const PLACEHOLDER = new Date()

export const DateController: React.FC<InputControllerProps> = ({
  control,
  error,
  id,
  isColumn,
  isDisabled,
  label,
  labelStyle,
  hideErrorInput = false,
  onPressDeleteIcon,
  ...restProps
}) => {
  const {t} = useTranslation()
  const isSmall = useIsNarrowScreen(2)
  const sx = useSx()
  const isSmallDevice = isAndroid || isIOS || isSmall
  const sxStyles = applyStyles(isColumn, isSmallDevice, labelStyle)
  const CalendarIcon = useCallback(
    (props: IconProps) => (
      <Icon {...props} name='calendar-today' pack={'material'} />
    ),
    []
  )

  const handlePressDelete = useCallback(() => {
    if (onPressDeleteIcon) {
      onPressDeleteIcon(id)
    }
  }, [onPressDeleteIcon, id])

  const DeleteIcon = useCallback(
    (props: IconProps) => (
      <TouchableWithoutFeedback
        onPress={handlePressDelete}
        accessibilityLabel={t('accessibility.deleteDate.label')}
        accessibilityHint={t('accessibility.deleteDate.hint')}
      >
        <View>
          <Icon {...props} name={'clear'} />
        </View>
      </TouchableWithoutFeedback>
    ),
    [handlePressDelete, t]
  )

  const text = useCallback(
    () => (
      <View sx={sxStyles.textWrapper}>
        {label && (
          <Text sx={sxStyles.label} variant={'h6'}>
            {t(label)}
          </Text>
        )}
      </View>
    ),
    [sxStyles, label, t]
  )

  const PlaceHolder = useCallback(
    () => (
      <Text sx={sxStyles.placeholder} variant={'body2'}>
        {DateUtils.format(PLACEHOLDER, DateUtils.DATE_ONLY_FORMAT)}
      </Text>
    ),
    [sxStyles]
  )
  const formatDateService = new NativeDateService('sk', {format: 'DD.MM.YYYY'})
  //TODO: focus on input
  return (
    <View sx={sxStyles.container}>
      <Controller
        control={control}
        render={({field: {onChange, value}}) => {
          const onSelect = (date: Date) => {
            onChange(date)
          }
          return isDisabled ? (
            <DisabledLabelValue
              {...restProps}
              label={label}
              labelStyle={labelStyle}
              value={value}
              isDate
              isColumn={isColumn}
            />
          ) : (
            <View sx={sxStyles.date}>
              {!isColumn && !isSmallDevice && label && text()}
              <Datepicker
                placeholder={PlaceHolder}
                date={value == null ? undefined : value}
                onSelect={onSelect}
                accessoryRight={value ? DeleteIcon : CalendarIcon}
                style={sx(sxStyles.datePicker)}
                disabled={isDisabled}
                label={label && (isColumn || isSmallDevice) ? text : undefined}
                dateService={formatDateService}
              />
            </View>
          )
        }}
        name={id}
        defaultValue={null}
      />
      {hideErrorInput ? null : <InputErrorMessage error={error} />}
    </View>
  )
}

const applyStyles = (
  isColumn: boolean | undefined,
  isSmallDevice: boolean,
  labelStyle?: SxProp
): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
  },
  date: {
    ...(isColumn
      ? {}
      : {
          flexDirection: 'row' as const,
          // TODO: refactor - width: '100%' disrupt layout of input with icon
          width: '100%',
          alignSelf: 'center',
          justifyContent: 'space-between',
        }),
    paddingTop: 1,
  },
  textWrapper: {
    ...(isColumn
      ? {}
      : {flex: 1, alignSelf: isSmallDevice ? 'flex-start' : 'center'}),
  },
  label: {
    paddingBottom: isColumn ? 1 : 0,
    fontSize: [3, null, 4],
    lineHeight: [3, null, 4],
    color: 'black',
    ...labelStyle,
  },
  placeholder: {
    paddingLeft: 1,
    color: 'grayColor300',
  },
  datePicker: {
    flex: 1,
  },
})
