import {AxiosRequestConfig} from 'axios'
import React from 'react'

import gridConfig from '../admin_user_grid_config.json'
import {Grid} from 'grid/components/Grid'

interface Props {
  dataServiceConfig?: AxiosRequestConfig
  dataServiceUrl: string
  stateId: string
  onPressItem?: (id: string) => void
  placeholder?: string
  customPagination?: boolean
}

export const UserGrid: React.FC<Props> = (props) => {
  return (
    <Grid
      dataServiceUrl={props.dataServiceUrl}
      dataServiceConfig={props.dataServiceConfig}
      hasSearchInput
      plainGridConfig={gridConfig}
      stateId={props.stateId}
      onPressItem={props.onPressItem}
      placeholder={props.placeholder}
      customPagination={props.customPagination}
    />
  )
}
