import {ScrollView, View} from 'dripsy'
import React, {useMemo} from 'react'
import {Control, FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {UserDetailForm} from './UserDetailForm'
import {UseFormForUserShopForm, User} from '../user_types'
import {IconButton} from 'common/components/IconButton'
import {PageableResponse} from 'common/types'
import {CheckboxController} from 'form/components/CheckboxController'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {UserRole} from 'login/login_constants'
import {EnumPriceList} from 'price_list/price_list_types'
import {
  FormFields,
  SUBSCRIBE_TO_PRICE_LIST_FORM_CHECKBOX,
} from 'register/register_constants'
import {EditShopForm} from 'user/components/EditShopForm'
import {UserDetailShop} from 'user/components/UserDetailShop'

interface UserDetailProps extends UseFormForUserShopForm {
  data: User
  onPressSave: () => void
  control: Control<any>
  errors: FieldErrors
  formFields: FormFields[]
  isLoading: boolean
  actionPriceListOptions?: PageableResponse<EnumPriceList>
  standardPriceListOptions?: PageableResponse<EnumPriceList>
  children?: React.ReactNode
  primaryShopId: number | null
}

export const UserDetail: React.FC<UserDetailProps> = ({
  actionPriceListOptions,
  data,
  formFields,
  onPressSave,
  control,
  errors,
  isLoading,
  standardPriceListOptions,
  children,
  primaryShopId,
  ...userShopProps
}) => {
  const {t} = useTranslation()
  const sxStyles = applyStyles()

  const haveUserOldShops = useMemo(
    () =>
      data?.userShops.some(
        (userShop) => !userShop.isPrimary && userShop.isActive
      ),
    [data]
  )

  return (
    <ScrollView sx={sxStyles.container}>
      <UserDetailForm
        control={control}
        errors={errors}
        formFields={formFields}
        actionPriceListOptions={actionPriceListOptions}
        standardPriceListOptions={standardPriceListOptions}
      />
      {data.role === UserRole.CUSTOMER && (
        <>
          <View sx={sxStyles.checkboxWrapper}>
            <CheckboxController
              control={control}
              id={SUBSCRIBE_TO_PRICE_LIST_FORM_CHECKBOX.id}
              title={t(SUBSCRIBE_TO_PRICE_LIST_FORM_CHECKBOX.label)}
            />
          </View>
          <EditShopForm
            userId={data.id}
            shopId={primaryShopId}
            {...userShopProps}
          />
          <UserDetailShop
            userId={data.id}
            haveUserOldShops={haveUserOldShops}
          />
        </>
      )}
      {children}
      <View sx={sxStyles.button}>
        <IconButton
          iconName={'save'}
          left
          title={t('button.save')}
          onPress={onPressSave}
          color={'primaryColor500'}
          isLoading={isLoading}
          size={'small'}
        />
      </View>
    </ScrollView>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    borderWidth: [1, null, 2],
    borderColor: 'grayColor300',
    borderRadius: 10,
    marginX: [3, null, 7],
    paddingX: [2, null, 3],
    paddingTop: [3, null, 5],
    marginBottom: 2,
  },
  button: {
    alignSelf: 'flex-end',
    paddingRight: [2, null, 8],
    paddingY: [3, null, 5],
  },
  checkboxWrapper: {
    paddingLeft: [1, null, 1],
    paddingTop: [1, null, 2],
  },
})
