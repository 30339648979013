import {Text, useDripsyTheme, useSx, View} from 'dripsy'
import React, {useCallback} from 'react'
import {TouchableOpacity} from 'react-native'

import {Icon} from 'common/components/Icon'
import {ClientRoutes} from 'header/header_constants'
import {LinkProps} from 'header/header_types'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface BottomTabBarProps {
  iconName?: LinkProps['icon']
  isFocused: boolean
  isShopCartEnabled: boolean
  label: string
  onPressTabBarItem: (
    routeName: string,
    routeKey: string,
    isFocused: boolean
  ) => void
  routeKey: string
  routeName: string
  shopCartCountValue?: number
}

const BottomTabBarItemComponent: React.FC<BottomTabBarProps> = ({
  isFocused,
  isShopCartEnabled,
  label,
  onPressTabBarItem,
  routeName,
  routeKey,
  iconName,
  shopCartCountValue,
}) => {
  const dripsyTheme = useDripsyTheme()
  const sxStyles = applyStyles()
  const sx = useSx()

  const handlePressTabBarItem = useCallback(() => {
    onPressTabBarItem(routeName, routeKey, isFocused)
  }, [onPressTabBarItem, routeName, routeKey, isFocused])

  return (
    <TouchableOpacity
      key={routeName}
      accessibilityRole={'button'}
      accessibilityState={{selected: isFocused ? true : undefined}}
      onPress={handlePressTabBarItem}
      style={sx(sxStyles.wrapper)}
    >
      <View>
        {iconName && (
          <Icon
            name={iconName}
            color={
              isFocused
                ? dripsyTheme.theme.colors.primaryColor500
                : routeName === ClientRoutes.shopCart.route &&
                  !isShopCartEnabled
                ? dripsyTheme.theme.colors.grayColor300
                : dripsyTheme.theme.colors.grayColor500
            }
            onPress={handlePressTabBarItem}
          />
        )}
        {shopCartCountValue ? (
          <View sx={sxStyles.shopCartItemsCount}>
            <Text sx={sxStyles.shopCartItemsCountText}>
              {shopCartCountValue}
            </Text>
          </View>
        ) : null}
      </View>
      <Text
        sx={{
          ...sxStyles.title,
          ...(isFocused
            ? sxStyles.titleFocused
            : !isShopCartEnabled && routeName === ClientRoutes.shopCart.route
            ? sxStyles.titleDisabled
            : {}),
        }}
        variant={'textMenu'}
      >
        {label}
      </Text>
    </TouchableOpacity>
  )
}

export const BottomTabBarItem = React.memo(BottomTabBarItemComponent)

const applyStyles = (): ObjectsOfSxProps => ({
  wrapper: {
    flex: 1,
    alignItems: 'center',
  },
  title: {
    color: 'grayColor500',
    textAlign: 'center',
  },
  titleFocused: {
    color: 'primaryColor500',
  },
  titleDisabled: {
    color: 'grayColor300',
  },
  shopCartItemsCount: {
    backgroundColor: 'primaryColor100',
    position: 'absolute',
    borderRadius: 15,
    borderWidth: 2,
    borderColor: 'primaryColor400',
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    aspectRatio: 1,
    top: 2,
  },
  shopCartItemsCountText: {
    color: 'primaryColor600',
  },
})
