import {ReducerUtils} from '@sincersoft/core'
import {GRID_REDUCER_STATE_ID, gridSlice} from '@sincersoft/fe-grid'
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

import {FilterButtons} from 'common/components/FilterButtons'
import {ActiveFilterIds, Condition, ConfigFilterButtons} from 'grid/grid_types'

type UseRenderFilterButtons = () => JSX.Element | null

type UsePaginationReturn = {
  activeFilterIds: ActiveFilterIds
  getFilterCondition: () => Condition[]
  renderFilterButtons: UseRenderFilterButtons
}

export const useFilterButtons = (
  config: ConfigFilterButtons[],
  customPagination?: boolean,
  stateId?: string
): UsePaginationReturn => {
  const [activeFilterIds, setActiveFilterIds] = useState<ActiveFilterIds>({})
  const gridStateId = stateId
    ? ReducerUtils.getInstanceReducerId(GRID_REDUCER_STATE_ID, stateId)
    : ''
  const dispatch = useDispatch()

  useEffect(() => {
    const result: ActiveFilterIds = {}
    config.forEach((item) => {
      result[item.id] = {
        isActive: item.defaultActive || false,
        condition: item.condition,
      }
    })
    setActiveFilterIds(result)
  }, [config, setActiveFilterIds])

  const handleSetActiveFilterIds = useCallback(
    (id: string) => {
      setActiveFilterIds({
        ...(activeFilterIds ? activeFilterIds : {}),
        [id]: {
          ...activeFilterIds[id],
          isActive:
            activeFilterIds && activeFilterIds[id]
              ? !activeFilterIds[id].isActive
              : true,
        },
      })
      if (customPagination && gridStateId !== '') {
        dispatch(
          gridSlice.actions.resetGridData({
            customPagination: customPagination,
            stateId: gridStateId,
          })
        )
      }
    },
    [activeFilterIds, customPagination, gridStateId, dispatch]
  )

  const getFilterCondition = (): Condition[] => {
    let condition: Condition[] = []

    Object.keys(activeFilterIds).forEach((name) => {
      if (activeFilterIds[name] && activeFilterIds[name].isActive) {
        condition = [...condition, ...activeFilterIds[name].condition]
      }
    })
    return condition
  }

  const renderFilterButtons: UseRenderFilterButtons = () => {
    return (
      <FilterButtons
        config={config}
        activeFilterIds={activeFilterIds}
        onActiveFilter={handleSetActiveFilterIds}
      />
    )
  }

  return {
    activeFilterIds,
    renderFilterButtons,
    getFilterCondition,
  }
}
