import {Text, useDripsyTheme, View} from 'dripsy'
import React from 'react'
import {TouchableOpacity} from 'react-native'

import {Icon} from 'common/components/Icon'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface NewOrderShopItemProps {
  city: string
  id?: number
  isActive: boolean
  onPress?: (value: number, isActive: boolean) => void
  shop: string
  street: string
}

const NewOrderShopItemComponent: React.FC<NewOrderShopItemProps> = ({
  city,
  id,
  isActive,
  onPress,
  shop,
  street,
}) => {
  const dripsyTheme = useDripsyTheme()
  const sxStyles = applyStyles(isActive)
  const handlePress = () => {
    if (onPress && (id || id === 0)) {
      onPress(id, isActive)
    }
  }
  const content = (
    <View sx={sxStyles.container}>
      <View sx={sxStyles.content}>
        <Icon
          name={'storefront'}
          color={
            isActive
              ? dripsyTheme.theme.colors.primaryColor500
              : dripsyTheme.theme.colors.grayColor500
          }
          onPress={onPress ? handlePress : undefined}
        />
        <Text numberOfLines={2} sx={sxStyles.shop} variant={'captionBold'}>
          {shop}
        </Text>
        <Text numberOfLines={1} sx={sxStyles.text} variant={'caption'}>
          {city}
        </Text>
        <Text numberOfLines={1} sx={sxStyles.text} variant={'caption'}>
          {street}
        </Text>
      </View>
    </View>
  )
  return onPress ? (
    <TouchableOpacity onPress={handlePress} accessibilityRole={'imagebutton'}>
      {content}
    </TouchableOpacity>
  ) : (
    content
  )
}

export const NewOrderShopItem = React.memo(NewOrderShopItemComponent)

const applyStyles = (isActive: boolean): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    marginX: [1, null, 4, 1],
    borderWidth: 2,
    borderColor: isActive ? 'primaryColor600' : 'grayColor400',
    borderRadius: 7,
    minWidth: [100, null, 150],
    maxWidth: [100, null, 150],
    marginY: 1,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 1,
  },
  shop: {
    textAlign: 'center',
    color: isActive ? 'primaryColor500' : 'grayColor500',
    paddingX: 1,
    fontSize: [1, null, 2],
  },
  text: {
    color: 'grayColor600',
    display: ['none', null, 'flex'],
    marginTop: 1,
    textAlign: 'center',
    paddingX: 1,
  },
})
