import React from 'react'

import {ModalKeyboardAwareScrollWrapper} from 'common/components/ModalKeyboardAwareScrollWrapper'
import {ScreenKeyboardAwareScrollView} from 'layout/components/ScreenKeyboardAwareScrollView'

interface Props {
  children?: React.ReactNode
  withKeyboardAwareScroll?: boolean
  withoutWrapperScreenKeyboardAwareAScroll?: boolean
}

export const ModalContentWrapper: React.FC<Props> = ({
  children,
  withKeyboardAwareScroll,
  withoutWrapperScreenKeyboardAwareAScroll,
}) => {
  return withKeyboardAwareScroll ? (
    <ScreenKeyboardAwareScrollView
      withoutWrapper={withoutWrapperScreenKeyboardAwareAScroll}
    >
      <ModalKeyboardAwareScrollWrapper>
        {children}
      </ModalKeyboardAwareScrollWrapper>
    </ScreenKeyboardAwareScrollView>
  ) : (
    <>{children}</>
  )
}
