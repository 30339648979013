import {CheckBox, CheckBoxProps} from '@ui-kitten/components'
import {Text, useSx, View} from 'dripsy'
import React from 'react'
import {Control, useController} from 'react-hook-form'

import {ObjectsOfSxProps} from 'layout/layout_types'

interface CheckboxControllerProps extends CheckBoxProps {
  control: Control<any>
  id: string
  title?: string
}

const CheckboxControllerComponent: React.FC<CheckboxControllerProps> = ({
  control,
  id,
  title,
  ...restProps
}) => {
  const {field} = useController({
    name: id,
    control,
  })
  const sx = useSx()
  const sxStyles = applyStyles()
  return (
    <View sx={sxStyles.checkboxWrapper}>
      <CheckBox
        onChange={field.onChange}
        checked={field.value}
        style={sx(sxStyles.checkbox)}
        {...restProps}
      >
        {title && <Text sx={sxStyles.text}>{title}</Text>}
      </CheckBox>
    </View>
  )
}

export const CheckboxController = React.memo(CheckboxControllerComponent)

const applyStyles = (): ObjectsOfSxProps => ({
  checkboxWrapper: {
    alignItems: 'flex-start',
  },
  checkbox: {
    p: 14,
  },
  text: {
    flex: 1,
  },
})
