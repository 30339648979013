import {yupResolver} from '@hookform/resolvers/yup'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {View, useSx} from 'dripsy'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'

import {formatStringToNumber} from 'common/number_helpers'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {InputController} from 'form/components/InputController'
import {shopCartAmountSchema} from 'form/form_schemas'
import {CellRendererParams} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {
  ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID,
  ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
} from 'order/order_constants'
import {
  getDataConfigForOrderDetail,
  getDataConfigForUpdateOrderItem,
} from 'order/order_helpers'
import {UpdateOrderItemAmount, UpdateOrderItemInOrders} from 'order/order_types'
import {getDataConfigForUpdateCustomItem} from 'shop_cart/shop_cart_helpers'
import {useAppDispatch} from 'store/redux_hooks'

export const OrderAmountCellRenderer: React.FC<CellRendererParams> = ({
  data,
  colDef,
}) => {
  const queryClient = useQueryClient({context: QueryClientContext})
  const dispatch = useAppDispatch()
  const sx = useSx()

  const isCustomItem =
    colDef?.cellRendererParams && colDef.cellRendererParams.isCustomItem
      ? colDef.cellRendererParams?.isCustomItem
      : false

  const {control, handleSubmit, watch, reset} = useForm<UpdateOrderItemAmount>({
    resolver: yupResolver(shopCartAmountSchema),
  })
  const amountValue = watch('amount')

  const configForUpdateOrderItem = isCustomItem
    ? getDataConfigForUpdateCustomItem(data.id, {
        amount: formatStringToNumber(amountValue),
      })
    : getDataConfigForUpdateOrderItem<UpdateOrderItemInOrders>({
        id: data.id,
        amount: formatStringToNumber(amountValue),
      })

  const handleSuccessUpdate = (response: ResponseSuccess) => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.setDataProcessing({
        dataProcessing: false,
        stateId: ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    const dataConfigForOrderDetail = getDataConfigForOrderDetail(
      data.orderId.id
    )
    const orderDetailKey = extractKey({
      params: dataConfigForOrderDetail.params,
      url: dataConfigForOrderDetail.url,
    })
    queryClient.invalidateQueries(orderDetailKey)

    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const {mutate} = useApiMutation(
    extractKey({
      data: configForUpdateOrderItem.data,
      method: configForUpdateOrderItem.method,
      url: configForUpdateOrderItem.url,
    }),
    configForUpdateOrderItem.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdate,
        onError: handleError,
      },
      axiosConfig: {
        ...configForUpdateOrderItem,
      },
    },
    true
  )

  const handleUpdateItem = useCallback(
    (valObject: UpdateOrderItemAmount, itemValue) => {
      if (formatStringToNumber(itemValue) !== data.amount) {
        mutate()
        dispatch(
          gridSlice.actions.setDataProcessing({
            dataProcessing: true,
            stateId: ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
          })
        )
      }
    },
    [mutate, dispatch, data]
  )

  const handleDebounceUpdate = useMemo(
    () => debounce(handleSubmit(handleUpdateItem), 1000),
    [handleSubmit, handleUpdateItem]
  )

  useEffect(() => {
    amountValue && handleDebounceUpdate(amountValue)
  }, [amountValue, handleDebounceUpdate])

  useEffect(() => {
    reset({
      amount: data.amount,
    })
  }, [data, reset])

  return (
    <View sx={sxStyles.wrapper}>
      <View sx={sxStyles.container}>
        <InputController
          control={control}
          id={'amount'}
          size={'small'}
          maxLength={7}
          textStyle={sx(sxStyles.textStyles)}
          isColumn={false}
          hideErrorInput
          isNumber
          selectTextOnFocus
        />
      </View>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  wrapper: {
    flex: 1,
    alignItems: 'flex-end',
  },
  container: {
    flex: 1,
    alignItems: 'flex-end',
    maxWidth: 110,
  },
  textStyles: {
    maxWidth: '80%',
  },
}
