import {QueryDefaultInput, QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'
import {ImageUrlWithParamsReturnType} from 'product_category/product_category_types'

export const PARAMS_ACTIVE = {
  params: {
    isActive: true,
  },
}

export const DATA_CONFIG_ACTIVE_CATEGORY = {
  url: Api.productCategory,
  params: {...PARAMS_ACTIVE.params, sort: 'code ASC', populate: false},
}

export function getDataConfigForActiveCategoryById<TData>(
  categoryId: string | number
): QueryDefaultInput<TData> {
  return {
    ...DATA_CONFIG_ACTIVE_CATEGORY,
    url: `${DATA_CONFIG_ACTIVE_CATEGORY.url}/${categoryId}`,
  }
}

export function getDataConfigForUploadProductCategoryImage(
  productCategoryId: string | number
): QueryInput {
  return {
    url: `${Api.productCategory}/${productCategoryId}/${Api.uploadImageAction}`,
    method: 'POST',
  }
}

export function getUrlAndParamsForProductCategoryImage(
  productCategoryId: string | number,
  imageVersion = 1
): ImageUrlWithParamsReturnType {
  return {
    url: `${Api.productCategory}/${productCategoryId}/${Api.getImageAction}`,
    urlParams: {...(imageVersion ? {version: imageVersion} : {})},
  }
}

export function getDataConfigForGetProductCategoryImageThumbnail(
  productCategoryId: string | number
): QueryInput {
  return {
    url: `${Api.productCategory}/${productCategoryId}/${Api.getImageThumbnailAction}`,
    method: 'GET',
  }
}
