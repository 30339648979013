import {createAsyncThunk} from '@reduxjs/toolkit'
import {StorageDataUtils} from '@sincersoft/native-core'

import {LOGIN_AND_STORE_TOKEN, LOGOUT_CLEAN_DATA} from './login_constants'
import {loginSlice} from 'login/login_slice'
import {LoginAndStoreTokenPayload} from 'login/login_types'

// thunk first parameter for payloadCreator - object
export const loginAndStoreToken = createAsyncThunk(
  LOGIN_AND_STORE_TOKEN,
  async (payload: LoginAndStoreTokenPayload, thunkAPI) => {
    if (payload.token) {
      await StorageDataUtils.set('access_token', payload.token, {
        cookieSerializeOptions: {
          path: '/',
        },
      })
    }
    return thunkAPI.dispatch(
      loginSlice.actions.setLoggedIn({loginUser: payload.loginUser})
    )
  }
)

export const logoutCleanData = createAsyncThunk(
  LOGOUT_CLEAN_DATA,
  async (_payload = undefined, thunkAPI) => {
    await StorageDataUtils.reset('access_token', {
      cookieSerializeOptions: {
        path: '/',
      },
    })
    return thunkAPI.dispatch(loginSlice.actions.setLoggedOut())
  }
)
