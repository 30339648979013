import {Currency, formatCurrency} from '@sincersoft/core'
import {AxiosRequestConfig} from 'axios'
import {isObject, isEmpty, omit, pick} from 'lodash'

import {ProductItem, UnitOfMeasure} from 'new_order/new_order_types'

export function getReferenceDataId(data: {id: number} | number): number {
  return isObject(data) ? data.id : data
}

export interface DefaultNameOptions {
  name: string
}

export function prepareOptionsFromStringArray(
  data?: string[]
): DefaultNameOptions[] {
  return data
    ? data.map((item) => {
        return {
          name: item,
        }
      })
    : []
}

export function calculateProductPrice(
  selectedUnitOfMeasure: UnitOfMeasure | string,
  item: ProductItem
): number {
  let result = 0
  if (selectedUnitOfMeasure === 'g' && item.unitOfMeasure === 'kg') {
    // set price for grams
    result = item.price / 1000
  } else if (selectedUnitOfMeasure === 'bal' && item.packingAmount) {
    // set price for packing
    result = item.price * item.packingAmount
  } else if (selectedUnitOfMeasure === item.unitOfMeasure) {
    // set price for default unit of measure
    result = item.price
  }
  return result
}

export function recalculateValueIfThreshold(
  value: number,
  valueThreshold: number
): number {
  if (value <= valueThreshold) {
    return value * 100
  }
  return value
}

const _changeFormatCurrency = (
  value: number,
  currency: Currency = 'EUR'
): string => {
  return formatCurrency(value, currency, {
    locale: 'sk-SK',
    style: 'currency',
    maximumFractionDigits: 2,
  })
}

export function getFormattedCurrency(
  value: number,
  valueWithThreshold: number
) {
  let result = ''
  if (value <= valueWithThreshold) {
    result = _changeFormatCurrency(
      recalculateValueIfThreshold(value, valueWithThreshold),
      'EURO_CENT'
    )
  } else {
    result = _changeFormatCurrency(value)
  }
  return result
}

export type QueryCriteriaPropertyValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | {[key: string]: QueryCriteriaPropertyValue}

export interface BaseQueryCriteria {
  where?: Record<string, QueryCriteriaPropertyValue>
  sort?: string
  limit?: number
  skip?: number
  populate?: string | false
}

interface QueryCriteria extends BaseQueryCriteria {
  [key: string]: QueryCriteriaPropertyValue
}

export function normalizeQueryCriteria(queryCriteria: QueryCriteria) {
  let result = queryCriteria
  if (queryCriteria) {
    const RESERVED_WHERE_SPECIFIC_PROPERTIES = [
      'where',
      'sort',
      'limit',
      'skip',
      '_fulltext',
    ]
    const OTHER_RESERVED_PROPERTIES = ['populate']
    result = pick(queryCriteria, RESERVED_WHERE_SPECIFIC_PROPERTIES)
    if (isEmpty(result)) {
      // does not have any "where" words, add rest of criteria directly
      result = queryCriteria
    } else {
      // has some "where" words, add rest of criteria inside where
      result = {
        ...result,
        where: {
          // add content of where
          ...result.where,
          // add rest of criteria without OTHER_RESERVED_PROPERTIES
          ...omit(
            queryCriteria,
            RESERVED_WHERE_SPECIFIC_PROPERTIES.concat(OTHER_RESERVED_PROPERTIES)
          ),
        },
        // add other reserved properties
        ...pick(queryCriteria, OTHER_RESERVED_PROPERTIES),
      }
    }
  }
  return result
}

export function normalizeDataServiceConfig(
  dataServiceConfig: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...dataServiceConfig,
    params: normalizeQueryCriteria(dataServiceConfig.params),
  }
}
