import {
  getDocumentAsync,
  DocumentPickerResult,
  DocumentPickerOptions,
} from 'expo-document-picker'

export const useDocumentPicker = (namedParameters?: DocumentPickerOptions) => {
  return async (): Promise<DocumentPickerResult> => {
    return await getDocumentAsync(namedParameters)
  }
}
