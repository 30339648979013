import {ITooltipParams} from 'ag-grid-community'
import {SxProp, Text, View} from 'dripsy'
import React from 'react'

import {ColumnPriceValue} from 'common/components/ColumnPriceValue'
import {getFormattedCurrency} from 'common/data_helpers'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {PRICE_THRESHOLD_VALUE} from 'product/product_constants'

type Props = ITooltipParams

export const PriceTooltipComponent: React.FC<Props> = (props) => {
  const sxStyles = applyStyles()

  const formatValue = (): string => {
    let result = '-'

    if (props?.value != null) {
      result = getFormattedCurrency(props?.value, PRICE_THRESHOLD_VALUE)
    }
    return result
  }
  const value = formatValue()

  return typeof props?.value === 'string' ? (
    <View>
      <Text sx={sxStyles.agTooltip}>{props?.value}</Text>
    </View>
  ) : (
    <View>
      <Text sx={sxStyles.agTooltip}>{value}</Text>
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  agTooltip: {
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
    backgroundColor: 'var(--ag-header-background-color, #fff)',
    color: 'var(--ag-foreground-color, rgba(0, 0, 0, 0.87))',
    fontFamily: 'Montserrat',
    fontSize: '13px',
    padding: '8px',
    borderRadius: '2px',
    transition: 'opacity 1s',
    whiteSpace: 'normal',
  },
})
