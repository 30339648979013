import {extractKey, useApiQuery} from '@sincersoft/fe-core'

import {getDataConfigForProductDetail} from 'new_order/new_order_helpers'
import {ProductItem} from 'new_order/new_order_types'

export const useGetProductDetail = (
  id: string | number
): ProductItem | undefined => {
  const configForProductDetail = getDataConfigForProductDetail(id)

  const newOrderProductDetailKey = extractKey({
    params: configForProductDetail.params,
    url: configForProductDetail.url,
  })

  const {data} = useApiQuery<ProductItem>(
    newOrderProductDetailKey,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForProductDetail,
      },
    },
    true
  )

  return data
}
