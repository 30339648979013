import {makeTheme} from 'dripsy'
import {ScaledSize} from 'react-native'

import appTheme from './app_theme.json'

export const themeFn = (_windowDimensions: ScaledSize) => {
  return makeTheme(appTheme)
}

type AppTheme = ReturnType<typeof themeFn>

declare module 'dripsy' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DripsyCustomTheme extends AppTheme {}
}
