import React, {useMemo} from 'react'

import {ColumnWithDiffUnitValue} from 'common/components/ColumnWithDiffUnitValue'
import {CellRendererParams} from 'grid/grid_types'

export const CurrencyCellRenderer: React.FC<CellRendererParams> = ({
  value,
  isSmall,
  data,
}) => {
  return (
    <ColumnWithDiffUnitValue
      value={value}
      isSmall={isSmall}
      unitOfMeasure={
        data?.productId ? data.productId.unitOfMeasure : data.unitOfMeasure
      }
      withCurrency
      selectedUnitOfMeasure={data?.productId ? data.unitOfMeasure : undefined}
      packingAmount={
        data?.productId ? data.productId.packingAmount : data.packingAmount
      }
    />
  )
}
