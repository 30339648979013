import React, {useEffect} from 'react'

import {NativeGridItem} from 'grid/components/NativeGridItem'

interface Props {
  data: any[]
  gridConfig: any
  isLastRendered?: boolean
  // Intl/i18n
  translateService: any
  onIsAllRendered?: () => void
  onIsLastRendered?: (id: string) => void
  onPressItem?: (id: string) => void
}

export const NativeGridListComponent: React.FC<Props> = ({
  data,
  gridConfig,
  isLastRendered,
  onPressItem,
  onIsAllRendered,
  onIsLastRendered,
  translateService,
}) => {
  useEffect(() => {
    if (onIsAllRendered && isLastRendered) {
      onIsAllRendered()
    }
  }, [onIsAllRendered, isLastRendered])

  const handlePressItem = React.useCallback(
    (id: string) => {
      onPressItem ? onPressItem(id) : null
    },
    [onPressItem]
  )

  return (
    <>
      {data
        ? data.map((item, idx) => (
            <NativeGridItem
              key={idx}
              item={item}
              translateService={translateService}
              componentGridConfig={gridConfig}
              onPressItem={handlePressItem}
              onIsLastRendered={
                data.length === idx + 1 ? onIsLastRendered : undefined
              }
            />
          ))
        : null}
    </>
  )
}

export const NativeGridList = React.memo(NativeGridListComponent)
