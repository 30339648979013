import {DripsyCustomTheme, View} from 'dripsy'
import {isEmpty} from 'lodash'
import React from 'react'
import {Control, FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {PageableResponse} from 'common/types'
import {DateController} from 'form/components/DateController'
import {InputController} from 'form/components/InputController'
import {SelectController} from 'form/components/SelectController'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {EnumPriceList} from 'price_list/price_list_types'
import {FormFields} from 'register/register_constants'
import {
  STANDARD_PL,
  ACTION_PL,
  USER_STATE,
  USER_STATE_OPTIONS,
} from 'user/user_constants'

interface UserDetailFormProps {
  control: Control<any>
  errors: FieldErrors
  formFields: FormFields[]
  actionPriceListOptions?: PageableResponse<EnumPriceList>
  standardPriceListOptions?: PageableResponse<EnumPriceList>
}

export const UserDetailForm: React.FC<UserDetailFormProps> = ({
  actionPriceListOptions,
  control,
  errors,
  formFields,
  standardPriceListOptions,
}) => {
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <View sx={sxStyles.container} accessibilityRole={isWeb ? 'form' : 'none'}>
      {formFields.map((formField, idx) => (
        <View key={idx} sx={sxStyles.formField}>
          {formField.map((formItem) => (
            <View sx={sxStyles.item} key={formItem.id}>
              <View sx={{paddingX: 1}}>
                {formItem.isSelect ? (
                  <SelectController
                    name={formItem.id}
                    displayLabelProperty={'name'}
                    idProperty={'id'}
                    isColumn={true}
                    options={
                      formItem.selectOptions === STANDARD_PL
                        ? standardPriceListOptions
                        : formItem.selectOptions === ACTION_PL
                        ? actionPriceListOptions
                        : formItem.selectOptions === USER_STATE
                        ? USER_STATE_OPTIONS
                        : []
                    }
                    hasNullValue={formItem.hasNullValue}
                    control={control}
                    error={errors[formItem.id]}
                    label={t(formItem.label)}
                    // size={'small'}
                    labelStyle={sxStyles.label}
                    wrapperStyle={sxStyles.wrapperSelect}
                    selectStyle={sxStyles.select}
                    hideErrorInput={false}
                    isDisabled={formItem.isDisabled}
                    translatePrefix={formItem.translatePrefix}
                  />
                ) : formItem.isDate ? (
                  <DateController
                    control={control}
                    error={errors[formItem.id]}
                    id={formItem.id}
                    label={formItem.label}
                    labelStyle={sxStyles.label}
                    hideErrorInput={false}
                    isColumn={true}
                    isDisabled={formItem.isDisabled}
                  />
                ) : (
                  <InputController
                    control={control}
                    error={errors[formItem.id]}
                    hideErrorInput={isEmpty(errors[formItem.id])}
                    id={formItem.id}
                    label={formItem.label}
                    isSecured={formItem.isPassword}
                    disabled={formItem.isDisabled}
                    keyboardType={formItem.keyboardType ?? 'default'}
                    autoCapitalize={
                      formItem.keyboardType ? 'none' : 'sentences'
                    }
                    labelStyle={sxStyles.label}
                    isColumn={true}
                  />
                )}
              </View>
            </View>
          ))}
        </View>
      ))}
    </View>
  )
}

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  container: {
    flexDirection: ['column', null, 'row'],
  },
  formField: {
    flexDirection: 'column',
    width: ['100%', null, '33%'],
  },
  item: {},
  label: {
    paddingBottom: 1,
    color: 'secondaryColor700',
    ...theme.text.body2Bold,
    fontSize: [2, null, 3],
  },
  select: {
    flex: 1,
  },
  wrapperSelect: {},
})
