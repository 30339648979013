import React from 'react'

import {ColumnValue} from 'common/components/ColumnValue'
import {CellRendererParams} from 'grid/grid_types'
import {User} from 'user/user_types'

export const UserCellRenderer: React.FC<CellRendererParams> = ({
  value,
  colDef,
  isSmall,
}) => {
  const property: keyof User = colDef?.cellRendererParams?.property
    ? colDef.cellRendererParams.property
    : null
  let user: User | undefined
  if (value) {
    user = value
  }
  const propertyValue = property ? user?.[property] : user

  return (
    <ColumnValue
      value={typeof propertyValue === 'string' ? propertyValue : ''}
      isSmall={isSmall}
    />
  )
}
