import {formatCurrency} from '@sincersoft/core'
import {View} from 'dripsy'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {CountryFlag} from 'common/components/CountryFlag'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {ProductDetailInfoItem} from 'new_order/components/ProductDetailInfoItem'
import {ProductItem} from 'new_order/new_order_types'

interface ProductDetailInfoProps {
  data: ProductItem
}

export const ProductDetailInfo: React.FC<ProductDetailInfoProps> = ({data}) => {
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)

  const CountryFlagComponent = useMemo(
    () =>
      data.origin ? (
        <CountryFlag countryName={data.origin} height={18} />
      ) : null,
    [data.origin]
  )

  return (
    <View sx={sxStyles.container}>
      <ProductDetailInfoItem label={t('item.packing')} value={data.packing} />
      {data.description ? (
        <ProductDetailInfoItem
          label={t('item.description')}
          value={data.description}
        />
      ) : null}
      {data.class ? (
        <ProductDetailInfoItem label={t('item.class')} value={data.class} />
      ) : null}
      <ProductDetailInfoItem label={t('item.code')} value={data.code} />
      <ProductDetailInfoItem
        label={t('item.country')}
        value={data.origin ? undefined : 'noCountry'}
        translatePrefix={data.origin ? undefined : 'item'}
      >
        {CountryFlagComponent}
      </ProductDetailInfoItem>
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    width: '100%',
    flex: [null, null, 1],
    alignSelf: 'flex-start',
    // paddingLeft: [0, null, 2],
  },
})
