import {Autocomplete, AutocompleteProps} from '@ui-kitten/components'
import {View, Text, SxProp} from 'dripsy'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {LayoutChangeEvent} from 'react-native/Libraries/Types/CoreEventTypes'

import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {isAndroid, isIOS} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

export interface AutoCompleteInputProps extends AutocompleteProps {
  label?: string
  isColumn?: boolean
  labelStyle?: SxProp
}

export const AutoCompleteInput: React.FC<AutoCompleteInputProps> = (props) => {
  const {label, isColumn, labelStyle, children, ...rest} = props
  const isSmall = useIsNarrowScreen(2)
  const isSmallDevice = isAndroid || isIOS || isSmall
  const {t} = useTranslation()

  const sxStyles = applyStyles({isColumn, labelStyle}, isSmallDevice)

  const Label = (
    <View sx={sxStyles.textWrapper}>
      {label && (
        <Text sx={sxStyles.label} variant={'h6'}>
          {t(label)}
        </Text>
      )}
    </View>
  )
  const [autocompleteWidth, setAutocompleteWidth] = useState(300)
  const handleWidth = (event: LayoutChangeEvent) => {
    const {width} = event.nativeEvent.layout
    setAutocompleteWidth(width)
  }

  return (
    <View sx={sxStyles.input}>
      {!isColumn && !isSmallDevice && label && Label}
      <View sx={isColumn ? {} : {flex: 1}} onLayout={handleWidth}>
        <Autocomplete
          placement={isAndroid || isIOS ? 'top end' : 'inner top'}
          {...rest}
          label={label && (isColumn || isSmallDevice) ? Label : undefined}
          style={{
            width: autocompleteWidth,
          }}
        >
          {children}
        </Autocomplete>
      </View>
    </View>
  )
}

const applyStyles = (
  props: Partial<AutoCompleteInputProps>,
  isSmallDevice: boolean
): ObjectsOfSxProps => ({
  input: {
    ...(props.isColumn
      ? {}
      : {
          flexDirection: 'row' as const,
          // TODO: refactor - width: '100%' disrupt layout of input with icon
          width: '100%',
          alignSelf: 'center',
          justifyContent: 'space-between',
        }),
    paddingTop: 1,
  },
  textWrapper: {
    ...(props.isColumn
      ? {}
      : {flex: 1, alignSelf: isSmallDevice ? 'flex-start' : 'center'}),
  },
  label: {
    paddingBottom: props.isColumn ? 1 : 0,
    fontSize: [3, null, 4],
    lineHeight: [3, null, 4],
    color: 'black',
    ...props.labelStyle,
  },
})
