import {yupResolver} from '@hookform/resolvers/yup'
import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {Input} from '@ui-kitten/components'
import {AxiosError} from 'axios'
import {View, Text, useSx} from 'dripsy'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DefaultNameOptions} from 'common/data_helpers'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {formatStringToNumber} from 'common/number_helpers'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {InputController} from 'form/components/InputController'
import {SelectController} from 'form/components/SelectController'
import {
  customItemAmountSchema,
  customItemMixedSchema,
  customItemPriceSchema,
} from 'form/form_schemas'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {UnitOfMeasure} from 'new_order/new_order_types'
import {DEFAULT_CUSTOM_PRICE_NAME} from 'shop_cart/shop_cart_constants'
import {getDataConfigForUpdateCustomItem} from 'shop_cart/shop_cart_helpers'
import {shopCartSlice} from 'shop_cart/shop_cart_slice'
import {ErrorState} from 'shop_cart/shop_cart_types'
import {useAppDispatch} from 'store/redux_hooks'

interface ShopCartCustomItemRowItemProps {
  id: string
  itemId: string
  isNumber?: boolean
  isCurrency?: boolean
  isAmount?: boolean
  unitOfMeasure?: UnitOfMeasure
  maxLength?: number
  label: string
  selectItems?: DefaultNameOptions[]
  value: string | number
  customItemId: number
  onUpdateItem: () => void
  isRequired: boolean
  updateErrorState?: (errorState: ErrorState) => void
  selectTextOnFocus?: boolean
  inputRef?: React.RefObject<Input>
  isNewRowAndFocus?: boolean
  onBlur?: () => void
}

const ShopCartCustomItemRowItemComponent: React.FC<
  ShopCartCustomItemRowItemProps
> = ({
  id,
  itemId,
  label,
  selectItems,
  value,
  customItemId,
  onUpdateItem,
  isRequired,
  updateErrorState,
  isNumber,
  isCurrency,
  isAmount,
  unitOfMeasure,
  isNewRowAndFocus,
  selectTextOnFocus,
  ...restProps
}) => {
  const sxStyles = useThemeSxStyles(applyStyles)
  const {t} = useTranslation()
  const sx = useSx()
  const dispatch = useAppDispatch()

  const {
    control,
    watch,
    reset,
    handleSubmit,
    trigger,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(
      isNumber && isCurrency
        ? customItemPriceSchema(id, false)
        : isAmount
        ? customItemAmountSchema(id, unitOfMeasure, true)
        : customItemMixedSchema(id)
    ),
  })

  const watchValue = watch(id)

  useEffect(() => {
    reset({[id]: value})
  }, [reset, id, value])

  useEffect(() => {
    trigger(id)
  }, [trigger, id])

  const handleSuccessUpdate = async (response: ResponseSuccess) => {
    onUpdateItem()
    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const configForUpdateCustomItem = getDataConfigForUpdateCustomItem(
    customItemId,
    {[itemId]: isNumber ? formatStringToNumber(watchValue) : watchValue}
  )

  const {mutate} = useApiMutation(
    extractKey({
      method: configForUpdateCustomItem.method,
      url: configForUpdateCustomItem.url,
    }),
    {},
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdate,
        onError: handleError,
      },
      axiosConfig: {
        ...configForUpdateCustomItem,
      },
    },
    true
  )

  const handleUpdateItem = useCallback(() => {
    mutate()
  }, [mutate])

  const handleDebounceUpdate = useMemo(
    () => debounce(handleSubmit(handleUpdateItem), 1000),
    [handleSubmit, handleUpdateItem]
  )

  const errorValue = errors[id]

  useEffect(() => {
    const transformedDataValueToString = isNumber
      ? formatStringToNumber(watchValue)
      : watchValue
    if (
      customItemId &&
      !!(watchValue || watchValue === 0 || watchValue === '') &&
      transformedDataValueToString !== value
    ) {
      handleDebounceUpdate()
    }
  }, [customItemId, watchValue, handleDebounceUpdate, value, isNumber])

  useEffect(() => {
    if (isRequired && updateErrorState) {
      // updateErrorState for disable add button
      updateErrorState({
        [id]: !!errorValue,
      })
      if (errorValue) {
        dispatch(
          shopCartSlice.actions.setErrorState({
            id: id,
            name: t('shopCart.validation.customItem'),
          })
        )
      } else {
        dispatch(shopCartSlice.actions.deleteErrorState({id: id}))
      }
    }
  }, [id, isRequired, updateErrorState, errorValue, dispatch])

  // if isNewRowAndFocus and watchValue === DEFAULT_CUSTOM_PRICE_NAME ('Názov')
  const isSelectionActivated =
    isNewRowAndFocus && watchValue === DEFAULT_CUSTOM_PRICE_NAME

  return (
    <View sx={sxStyles.rowItem}>
      <Text sx={sxStyles.label} variant={'caption'}>
        {t(label)}
      </Text>
      <View sx={sxStyles.inputWrapper}>
        {selectItems ? (
          <SelectController
            name={id}
            idProperty={'name'}
            displayLabelProperty={'name'}
            control={control}
            options={selectItems}
            size={'small'}
            hideErrorInput={true}
          />
        ) : (
          <InputController
            control={control}
            id={id}
            size={'small'}
            textStyle={sx(sxStyles.textStyles)}
            isColumn={true}
            hideErrorInput
            showErrorInputBorder={!!errors[id]}
            isNumber={isNumber}
            error={errors[id]}
            isCurrency={isCurrency}
            isSelectionActivated={isSelectionActivated}
            selectTextOnFocus={selectTextOnFocus ?? isSelectionActivated}
            {...restProps}
          />
        )}
      </View>
    </View>
  )
}

export const ShopCartCustomItemRowItem = React.memo(
  ShopCartCustomItemRowItemComponent
)

const applyStyles = (): ObjectsOfSxProps => ({
  rowItem: {
    flex: 1,
    flexDirection: 'row',
  },
  inputWrapper: {
    px: 1,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  label: {
    display: ['flex', null, 'none'],
    flex: 1,
    color: 'grayColor600',
    alignSelf: 'center',
  },
  textStyles: {
    maxWidth: '80%',
  },
})
