import {View} from 'dripsy'
import React from 'react'

import {DocumentPicker} from 'common/components/DocumentPicker'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {IMPORT_IMAGES_MIME_TYPES} from 'price_list/price_list_constants'
import {getDataConfigForUploadBatchImages} from 'product/product_helpers'

interface Props {
  onSuccessUpload?: () => void
}

export const ImportBatchImages: React.FC<Props> = ({onSuccessUpload}) => {
  return (
    <View sx={sxStyles.button}>
      <DocumentPicker
        buttonText={'button.importBatchImages'}
        dataConfig={getDataConfigForUploadBatchImages()}
        onSuccessUpload={onSuccessUpload}
        pickerOptions={{type: IMPORT_IMAGES_MIME_TYPES}}
        activateModalSpinner
      />
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  button: {
    alignSelf: 'center',
    height: 50,
  },
}
