import React from 'react'
import {useWindowDimensions} from 'react-native'

import {
  PARSED_INT_THEME_BREAKPOINT_0,
  PARSED_INT_THEME_BREAKPOINT_1,
  PARSED_INT_THEME_BREAKPOINT_2,
  PARSED_INT_THEME_BREAKPOINT_3,
  PARSED_INT_THEME_BREAKPOINT_4,
} from 'layout/layout_constants'

export type ScreenBreakpointIndex = 0 | 1 | 2 | 3 | 4

export const useIsNarrowScreen = (
  breakpoint: ScreenBreakpointIndex
): boolean => {
  let parsedBreakpoint = 0
  switch (breakpoint) {
    case 0:
      parsedBreakpoint = PARSED_INT_THEME_BREAKPOINT_0
      break
    case 1:
      parsedBreakpoint = PARSED_INT_THEME_BREAKPOINT_1
      break
    case 2:
      parsedBreakpoint = PARSED_INT_THEME_BREAKPOINT_2
      break
    case 3:
      parsedBreakpoint = PARSED_INT_THEME_BREAKPOINT_3
      break
    case 4:
      parsedBreakpoint = PARSED_INT_THEME_BREAKPOINT_4
      break
  }

  const {width} = useWindowDimensions()
  return width <= parsedBreakpoint
}
