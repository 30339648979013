import {AxiosRequestConfig} from 'axios'
import React from 'react'

import {Grid} from 'grid/components/Grid'

interface Props {
  dataServiceConfig?: AxiosRequestConfig
  dataServiceUrl: string
  gridConfig: any
  stateId: string
  hasSearchInput: boolean
  customPagination?: boolean
}

export const OrderDetailCustomItemsGrid: React.FC<Props> = (props) => {
  return (
    <Grid
      dataServiceUrl={props.dataServiceUrl}
      dataServiceConfig={props.dataServiceConfig}
      plainGridConfig={props.gridConfig}
      stateId={props.stateId}
      hasSearchInput={props.hasSearchInput}
      customPagination={props.customPagination}
    />
  )
}
