import React from 'react'

import {CustomPriceColumn} from 'common/components/CustomPriceColumn'
import {CurrencyCellRenderer} from 'grid/components/dynamic/framework_components/CurrencyCellRenderer'
import {CellRendererParams} from 'grid/grid_types'

export const CustomPriceCellRenderer: React.FC<CellRendererParams> = ({
  isSmall,
  data,
  ...restProps
}) => {
  const customPrice =
    data?.customPrice && data.customPrice.length > 0
      ? data.customPrice[0]
      : null

  const {price, productId} = data

  return price && !customPrice ? (
    <CurrencyCellRenderer
      isSmall={isSmall}
      data={data}
      {...restProps}
      value={price}
    />
  ) : (
    <CustomPriceColumn
      customPrice={customPrice?.price}
      customPriceId={customPrice?.id}
      cartItemId={data.id}
      productName={productId.name}
    />
  )
}
