import {BottomTabScreenProps} from '@react-navigation/bottom-tabs'
import {CompositeScreenProps} from '@react-navigation/native'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ReportScreen} from './ReportScreen'
import {
  AuthorizedTabParamList,
  ReportStackParamList,
  RootStackParamList,
} from 'layout/layout_types'

const ReportStack = createNativeStackNavigator<ReportStackParamList>()

type Props = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList, 'NavReportScreen'>,
  NativeStackScreenProps<RootStackParamList>
>

export const NavReportScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <ReportStack.Navigator
      initialRouteName={'ReportScreen'}
      screenOptions={{headerShown: false}}
    >
      <ReportStack.Screen
        name={'ReportScreen'}
        component={ReportScreen}
        options={{title: t('routes.report')}}
      />
    </ReportStack.Navigator>
  )
}
