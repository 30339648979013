import {AxiosRequestConfig} from 'axios'
import {useDripsyTheme, View} from 'dripsy'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import shopCartGrid from '../quick_order_grid_config.json'
import {IconButton} from 'common/components/IconButton'
import {normalizeDataServiceConfig} from 'common/data_helpers'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {Api} from 'config/app_config'
import {Grid} from 'grid/components/Grid'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {QUICK_PRODUCT_INSTANCE_REDUCER_STATE_ID} from 'new_order/new_order_constants'

interface Props {
  resultSearchValue: string
  dataServiceConfig?: AxiosRequestConfig
  onPressAddToCart: () => void
  onPressClearProducts: () => void
  isAddDisabled?: boolean
  isLoading: boolean
}

const NewOrderQuickGridComponent: React.FC<Props> = ({
  resultSearchValue,
  dataServiceConfig,
  onPressAddToCart,
  onPressClearProducts,
  isAddDisabled,
  isLoading,
}) => {
  const {t} = useTranslation()
  const {theme} = useDripsyTheme()
  const isSmallDevice = useIsNarrowScreen(1)

  const memoizedConfigDataServiceConfig = useMemo(
    () =>
      normalizeDataServiceConfig({
        ...dataServiceConfig,
        params: {
          ...(dataServiceConfig ? dataServiceConfig.params : {}),
          isActive: true,
          populate: 'productCategoryId',
          ...(resultSearchValue === '' ? {} : {_fulltext: resultSearchValue}),
        },
      }),
    [dataServiceConfig, resultSearchValue]
  )

  const memoizedPlainGridConfig = useMemo(
    () => ({
      ...shopCartGrid,
      rowStyle: {
        background: theme.colors.white,
      },
    }),
    [theme]
  )

  const Buttons = (
    <View sx={sxStyles.buttonWrapper}>
      <View sx={sxStyles.addToCartButton}>
        <IconButton
          iconName={'add'}
          left
          title={t('button.addSelectedToCart')}
          onPress={onPressAddToCart}
          color={'primaryColor500'}
          appearance={'filled'}
          isLoading={isLoading}
          disabled={isAddDisabled}
          size={'small'}
        />
      </View>
      <View sx={sxStyles.removeFromCartButton}>
        <IconButton
          iconName={'delete'}
          left
          title={t('button.clearSelected')}
          onPress={onPressClearProducts}
          color={'secondaryColor500'}
          status={'danger'}
          appearance={'outline'}
          isLoading={isLoading}
          disabled={isAddDisabled}
          size={'small'}
        />
      </View>
    </View>
  )

  return (
    <View sx={sxStyles.container}>
      <Grid
        dataServiceUrl={Api.product}
        dataServiceConfig={memoizedConfigDataServiceConfig}
        hasModalSpinner
        plainGridConfig={memoizedPlainGridConfig}
        stateId={QUICK_PRODUCT_INSTANCE_REDUCER_STATE_ID}
        title={t('newOrder.quick.titleProducts')}
        headerButton={Buttons}
        showResetSortButton={false}
      />
    </View>
  )
}

export const NewOrderQuickGrid = React.memo(NewOrderQuickGridComponent)

const sxStyles: ObjectsOfSxProps = {
  container: {
    mt: 2,
    mb: 1,
  },
  buttonWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: ['center', null, 'flex-end'],
    justifyContent: ['center', null, 'flex-end'],
    mt: [2, null, 0],
  },
  removeFromCartButton: {
    paddingLeft: [1, 3, 4],
    paddingRight: [0, 0, 2],
  },
  addToCartButton: {
    paddingRight: [0.5, 3, 4],
  },
}
