import {View} from 'dripsy'
import React from 'react'
import {Control} from 'react-hook-form'

import {InputController} from 'form/components/InputController'
import {SelectController} from 'form/components/SelectController'
import {DefaultSelectOptions} from 'form/form_types'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  templates: DefaultSelectOptions[]
  control: Control<any>
  selectedRadio: number
}

const CreateOrderTemplateInputsComponent: React.FC<Props> = ({
  templates,
  control,
  selectedRadio,
}) => {
  return (
    <View sx={sxStyles.container}>
      {selectedRadio === 0 ? (
        <InputController
          key={'templateInput'}
          control={control}
          id={'templateInput'}
          size={'small'}
          isDisabledInput={selectedRadio !== 0}
          hideErrorInput={true}
        />
      ) : (
        <SelectController
          name={'templateSelect'}
          idProperty={'id'}
          displayLabelProperty={'name'}
          control={control}
          options={templates}
          size={'small'}
          disabled={selectedRadio !== 1}
          hideErrorInput={true}
        />
      )}
    </View>
  )
}

export const CreateOrderTemplateInputs = React.memo(
  CreateOrderTemplateInputsComponent
)

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
    alignSelf: 'center',
    minWidth: 210,
    maxWidth: 210,
    mb: 2,
    px: 1,
  },
}
