import {QueryInput} from '@sincersoft/fe-core'
import {isEmpty} from 'lodash'

import {Api} from 'config/app_config'
import {PriceListsIds} from 'order/order_types'
import {ImageUrlWithParamsReturnType} from 'product_category/product_category_types'

export function getDataConfigForUploadProductImage(
  productId: string | number
): QueryInput {
  return {
    url: `${Api.product}/${productId}/${Api.uploadImageAction}`,
    method: 'POST',
  }
}

export function getUrlAndParamsForProductImage(
  productId: string | number,
  imageVersion = 1
): ImageUrlWithParamsReturnType {
  return {
    url: `${Api.product}/${productId}/${Api.getImageAction}`,
    urlParams: {...(imageVersion ? {version: imageVersion} : {})},
  }
}

export function getDataConfigForGetProductImageThumbnail(
  productId: string | number
): QueryInput {
  return {
    url: `${Api.product}/${productId}/${Api.getImageThumbnailAction}`,
    method: 'GET',
  }
}

export function getDataConfigForGetProductChart(
  productId: string | number,
  productCode: string,
  priceListIds?: PriceListsIds
): QueryInput {
  const params = isEmpty(priceListIds)
    ? undefined
    : JSON.stringify(priceListIds)

  return {
    url: `${Api.product}/${productId}/${Api.getProductChartAction}`,
    method: 'GET',
    params: {
      code: productCode,
      priceListIds: params,
    },
  }
}

export function getDataConfigForUploadBatchImages(): QueryInput {
  return {
    url: Api.uploadBatchImages,
    method: 'POST',
  }
}
