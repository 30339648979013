import {View} from 'dripsy'
import React from 'react'

import {HeaderLinksProps} from '../header_types'
import {HeaderLink} from './HeaderLink'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface LayoutProps {
  links: HeaderLinksProps
}

export const HeaderLinks: React.FC<LayoutProps> = ({links}) => {
  const sxStyles = applyStyles()
  return (
    <View sx={sxStyles.headerLinks}>
      {Object.keys(links).map((key) =>
        links[key].forMobile || links[key].hide ? null : (
          <HeaderLink link={links[key]} key={key} />
        )
      )}
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  headerLinks: {
    backgroundColor: 'white',
    display: ['none', null, 'flex'],
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    flexDirection: 'row' as const,
    flex: 1,
  },
})
