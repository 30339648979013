import {gridSlice} from '@sincersoft/fe-grid'
import {Text, useSx, View} from 'dripsy'
import {isEmpty} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Icon} from 'common/components/Icon'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

interface OwnProps {
  instanceReducerId: string
}

type Props = OwnProps

export const Pagination: React.FC<Props> = (props) => {
  const {instanceReducerId} = props

  const dispatch = useAppDispatch()
  const statePagination = useAppSelector(
    (state) => state[instanceReducerId].pagination
  )

  const [pageSize, setPageSize] = useState(statePagination.pageSize)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [rowCount, setRowCount] = useState(1)
  const [previousDisable, setPreviousDisable] = useState(true)
  const [nextDisable, setNextDisable] = useState(false)
  const sx = useSx()

  const sxStyles = applyStyles(previousDisable, nextDisable)

  useEffect(() => {
    const defaultTotalPage = Math.ceil(
      statePagination.totalElements / statePagination.pageSize
    )
    const stateTotalElements = statePagination.totalElements

    if (!isEmpty(statePagination)) {
      setPageSize(statePagination.pageSize)
      setTotalPage(defaultTotalPage)
      setRowCount(stateTotalElements)
      setCurrentPage(statePagination.page)
    }
  }, [statePagination])

  useEffect(() => {
    dispatch(
      gridSlice.actions.setGridPagination({
        pagination: {
          page: currentPage,
        },
        stateId: instanceReducerId,
      })
    )
  }, [currentPage, dispatch, instanceReducerId])

  useEffect(() => {
    setPreviousDisable(currentPage === 1)
    setNextDisable(currentPage === totalPage)
  }, [currentPage, totalPage])

  const handleGoToFirstPage = () => {
    setCurrentPage(1)
  }
  const handleGoToPreviousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }
  const handleGoToNextPage = () => {
    if (currentPage !== totalPage) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }
  const handleGoToLastPage = () => {
    setCurrentPage(totalPage)
  }

  const lowerBound = (currentPage - 1) * pageSize + 1
  const upperBound = Math.min(currentPage * pageSize, rowCount)

  const {t} = useTranslation()
  const currentElementsTranslatedText = t('pagination.currentElements', {
    lowerBound,
    upperBound,
    rowCount,
  })

  const currentPageTranslatedText = t('pagination.currentPage', {
    currentPage,
    totalPage,
  })

  return (
    <View sx={sxStyles.paginationWrapper}>
      <View sx={sxStyles.paginationContainer}>
        <Text sx={sxStyles.text}>{currentElementsTranslatedText}</Text>
        <Icon
          name={'first-page'}
          onPress={handleGoToFirstPage}
          propsStyle={sx(sxStyles.leftPaginationIcons)}
          size={18}
          isDisabled={previousDisable}
        />
        <Icon
          name={'chevron-left'}
          onPress={handleGoToPreviousPage}
          propsStyle={sx(sxStyles.leftPaginationIcons)}
          size={18}
          isDisabled={previousDisable}
        />

        <Text sx={sxStyles.text}>{currentPageTranslatedText}</Text>

        <Icon
          name={'chevron-right'}
          onPress={handleGoToNextPage}
          propsStyle={sx(sxStyles.rightPaginationIcons)}
          size={18}
          isDisabled={nextDisable}
        />
        <Icon
          name={'last-page'}
          onPress={handleGoToLastPage}
          propsStyle={sx(sxStyles.rightPaginationIcons)}
          size={18}
          isDisabled={nextDisable}
        />
      </View>
    </View>
  )
}

const applyStyles = (
  previousDisable: boolean,
  nextDisable: boolean
): ObjectsOfSxProps => ({
  paginationWrapper: {
    marginRight: 4,
    color: 'grayColor600',
  },
  paginationContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 1,
  },
  text: {
    fontSize: 13,
    fontFamily: 'Montserrat',
    color: '#757575',
  },
  leftPaginationIcons: {
    color: previousDisable ? '#75757551' : '#757575',
  },
  rightPaginationIcons: {
    color: nextDisable ? '#75757551' : '#757575',
  },
})
