import {View} from 'dripsy'
import React from 'react'

import {Api} from 'config/app_config'
import {ShopCartGrid} from 'shop_cart/components/ShopCartGrid'
import {SHOP_CART_INSTANCE_REDUCER_STATE_ID} from 'shop_cart/shop_cart_constants'
import shopCartGrid from 'shop_cart/shop_cart_grid_config.json'

export const ShopCartGrids: React.FC = () => (
  <>
    <View>
      <ShopCartGrid
        dataServiceUrl={Api.cartItem}
        gridConfig={shopCartGrid}
        stateId={SHOP_CART_INSTANCE_REDUCER_STATE_ID}
      />
    </View>
  </>
)
