import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {View} from 'dripsy'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {getDataConfigForActiveCategory} from '../new_order_helpers'
import {CategoryItem} from '../new_order_types'
import {NewOrderCategory} from './NewOrderCategory'
import {ProductOrCategorySkeleton} from './ProductOrCategorySkeleton'
import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {AuthorizedLayoutDeleteShopCart} from 'layout/components/AuthorizedLayoutDeleteShopCart'
import {
  AuthorizedScreenProps,
  NewOrderStackParamList,
  ObjectsOfSxProps,
} from 'layout/layout_types'

type Props = CompositeScreenProps<
  NativeStackScreenProps<NewOrderStackParamList, 'NewOrderCategoryScreen'>,
  AuthorizedScreenProps
>

export const NewOrderCategoryScreen: React.FC<Props> = ({navigation}) => {
  const {t} = useTranslation()
  const dataConfigForActiveCategory = getDataConfigForActiveCategory()
  const newOrderCategoryKey = extractKey({
    params: dataConfigForActiveCategory.params,
    url: dataConfigForActiveCategory.url,
  })
  const {data, isLoading, isStale} = useApiQuery<CategoryItem[]>(
    newOrderCategoryKey,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...dataConfigForActiveCategory,
      },
    },
    true
  )

  const handlePressItem = (id: string) => {
    navigation.navigate('NewOrderProductScreen', {idCategory: id})
  }

  useRefetchQuery([{key: newOrderCategoryKey}], isStale)

  const shopCartActionButtons = useCallback(
    () => (
      <AuthorizedLayoutDeleteShopCart
        displayDeleteShopCart
        displayGoToShopCart
      />
    ),
    []
  )

  return (
    <View sx={sxStyles.container}>
      <Header hasBackButton />
      <AuthorizedLayout
        displayActualShop
        subTitle={t('newOrder.category.subTitle')}
        title={t('newOrder.title')}
        shopCartActionButtons={shopCartActionButtons}
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbItem
              label={t('newOrder.order')}
              onPress={() => {
                navigation.navigate('NavNewOrderScreen', {
                  screen: 'NewOrderScreen',
                })
              }}
            />
            <BreadcrumbItem
              label={t('newOrder.category.breadcrumbsSubTitle')}
              isLast
            />
          </Breadcrumbs>
        }
      >
        {isLoading ? (
          <View sx={sxStyles.skeletonWrapper}>
            <ProductOrCategorySkeleton />
          </View>
        ) : null}
        {data && !isLoading ? (
          <NewOrderCategory
            isFiltered={false}
            filteredList={[]}
            list={data || []}
            onPressItem={handlePressItem}
          />
        ) : null}
      </AuthorizedLayout>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
  },
  skeletonWrapper: {
    flex: 1,
    height: 500,
  },
}
