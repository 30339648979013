import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {Button} from '@ui-kitten/components'
import {Text, useSx, View} from 'dripsy'
import {isEmpty} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  LineSegment,
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory-native'

import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {
  enhanceDataWithStartingPoint,
  formatAxisLabel,
  formatDate,
  formatTooltipLabels,
  getRangeForLabelsFromStartingPoint,
  getStartingPointFromDataOrButton,
} from 'new_order/new_order_helpers'
import {
  ButtonOptions,
  DataForChart,
  ProductPriceListChart,
} from 'new_order/new_order_types'
import {PriceListsIds} from 'order/order_types'
import {getDataConfigForGetProductChart} from 'product/product_helpers'

interface ProductDetailChartProps {
  productId: string | number
  productCode: string
  priceListsIdsOfUserFromOrder?: PriceListsIds
}

const ProductDetailChartComponent: React.FC<ProductDetailChartProps> = ({
  productId,
  productCode,
  priceListsIdsOfUserFromOrder,
}) => {
  const {t} = useTranslation()
  const sx = useSx()
  const sxStyles = applyStyles()
  const victoryStyles = applyVictoryStyles()

  const [selectedButton, setSelectedButton] = useState<ButtonOptions | null>(
    null
  )

  const configForProductDetail = getDataConfigForGetProductChart(
    productId,
    productCode,
    priceListsIdsOfUserFromOrder
  )
  const dateNow = new Date().getTime()

  const {data, isLoading} = useApiQuery<ProductPriceListChart>(
    extractKey({
      params: configForProductDetail.params,
      url: configForProductDetail.url,
    }),
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForProductDetail,
      },
    },
    true
  )

  const productChartData = data?.data

  const dataForChart: DataForChart[] | undefined = productChartData?.map(
    (item) => {
      const flooredPrices = item.price ? item.price.toFixed(2) : item.price
      return {
        x: item.date,
        y: flooredPrices ? Number(flooredPrices) : null,
      }
    }
  )
  const mappedPrices =
    productChartData &&
    productChartData.map((i) => (i.price == null ? 0 : i.price))

  const minDateFromData = useMemo(() => {
    const mappedDates =
      productChartData &&
      productChartData.map((i) => (i.price == null ? null : i.date))
    const filteredDates = mappedDates?.filter((date) => date != null)

    return filteredDates && Math.min(...filteredDates)
  }, [productChartData])

  const [currentStartingPoint, setCurrentStartingPoint] = useState<
    number | undefined
  >(minDateFromData)

  const maxYValue =
    mappedPrices && !isEmpty(mappedPrices)
      ? Math.max(...mappedPrices)
      : undefined

  const handleSetDate = (selectedButton: ButtonOptions) => {
    const rangeStartDate = getStartingPointFromDataOrButton(
      selectedButton,
      minDateFromData
    )
    if (rangeStartDate) {
      setCurrentStartingPoint(rangeStartDate)
    }
    setSelectedButton(selectedButton)
  }

  const getTickLabel = useMemo(
    () => (chartData: Date) => {
      const buttonOption =
        getRangeForLabelsFromStartingPoint(currentStartingPoint)
      return formatDate(chartData, buttonOption, 'sk-SK')
    },
    [currentStartingPoint]
  )

  useEffect(() => {
    if (selectedButton == null && minDateFromData) {
      setSelectedButton(ButtonOptions.all)
      setCurrentStartingPoint(minDateFromData)
    }
  }, [minDateFromData, selectedButton])

  const enhancedData = useMemo(
    () => enhanceDataWithStartingPoint(dataForChart, currentStartingPoint),
    [currentStartingPoint, dataForChart]
  )

  const isSmall = useIsNarrowScreen(2)

  return enhancedData &&
    !isEmpty(enhancedData) &&
    !isLoading &&
    maxYValue &&
    maxYValue !== 0 ? (
    <View sx={sxStyles.chartWrapper}>
      <View sx={sxStyles.legendAndButtonsWrapper}>
        <View sx={sxStyles.legendTitleWrapper}>
          <View sx={sxStyles.legendCircle} />
          <Text sx={sxStyles.legendTitle}>{t('chart.legend')}</Text>
        </View>
        <View sx={sxStyles.buttonsWrapper}>
          <Button
            style={sx(sxStyles.zoomButtons)}
            appearance={selectedButton === 'lastMonth' ? 'filled' : 'outline'}
            onPress={() => handleSetDate(ButtonOptions.lastMonth)}
            size='tiny'
          >
            {t('chart.zoomButton.lastMonth')}
          </Button>
          <Button
            style={sx(sxStyles.zoomButtons)}
            onPress={() => handleSetDate(ButtonOptions.lastYear)}
            appearance={selectedButton === 'lastYear' ? 'filled' : 'outline'}
            size='tiny'
          >
            {t('chart.zoomButton.lastYear')}
          </Button>
          <Button
            style={sx(sxStyles.zoomButtons)}
            appearance={
              selectedButton === 'lastThreeYears' ? 'filled' : 'outline'
            }
            onPress={() => handleSetDate(ButtonOptions.lastThreeYears)}
            size='tiny'
          >
            {t('chart.zoomButton.lastThreeYears')}
          </Button>
          <Button
            style={sx(sxStyles.zoomButtons)}
            onPress={() => handleSetDate(ButtonOptions.all)}
            appearance={selectedButton === 'all' ? 'filled' : 'outline'}
            size='tiny'
          >
            {t('chart.zoomButton.all')}
          </Button>
        </View>
      </View>
      <View sx={sxStyles.container}>
        <VictoryChart
          scale={{x: 'time', y: 'linear'}}
          height={220}
          width={isSmall ? 330 : undefined}
          padding={{top: 50, bottom: 100, left: 85, right: 40}}
          domain={{
            y: [0, maxYValue * 1.2],
            x: currentStartingPoint
              ? [currentStartingPoint, dateNow]
              : undefined,
          }}
          containerComponent={
            <VictoryVoronoiContainer
              voronoiBlacklist={['victory_area', 'victory_scatter']}
              labelComponent={
                <VictoryTooltip
                  style={sx(victoryStyles.voronoiStyle)}
                  flyoutStyle={sx(victoryStyles.voronoiFlyout)}
                  pointerLength={20}
                  constrainToVisibleArea
                />
              }
              labels={({datum}) => formatTooltipLabels(datum, t, 'sk-SK')}
            />
          }
        >
          <VictoryArea
            name={'victory_area'}
            data={enhancedData}
            style={victoryStyles.victoryArea}
            // TODO: Broken animation when changing data after rendering
            // animate={{
            //   duration: 500,
            //   onLoad: {
            //     duration: 2500,
            //     before: () => ({_y: 0}),
            //     after: (datum) => ({_y: datum._y}),
            //   },
            // }}
          />
          <VictoryLine
            // TODO: Broken animation when changing data after rendering
            // animate={{
            //   duration: 500,
            //   onLoad: {
            //     duration: 2500,
            //     before: () => ({_y: 0}),
            //     after: (datum) => ({_y: datum._y}),
            //   },
            // }}
            data={enhancedData}
            style={victoryStyles.victoryLine}
          />
          <VictoryScatter
            // TODO: Broken animation when changing data after rendering
            // animate={{
            //   duration: 500,
            //   onLoad: {
            //     duration: 2500,
            //     before: () => ({_y: 0}),
            //     after: (datum) => ({_y: datum._y}),
            //   },
            // }}
            name={'victory_scatter'}
            data={enhancedData}
            style={{
              data: {
                fill: '#68A18C',
                //TODO: Broken animation when changing data after rendering
                //opacity: ({datum}) => datum.opacity,
              },
            }}
            size={2}
          />
          <VictoryAxis
            gridComponent={<LineSegment />}
            style={sx(victoryStyles.independentAxis)}
            scale={{x: 'time'}}
            tickFormat={getTickLabel}
          />
          <VictoryAxis
            dependentAxis
            crossAxis={false}
            tickFormat={(x) => formatAxisLabel(x)}
            style={sx(victoryStyles.dependentAxis)}
            fixLabelOverlap
          />
        </VictoryChart>
      </View>
    </View>
  ) : (
    <Text sx={sxStyles.noDataText}>{t('chart.noData')}</Text>
  )
}

export const ProductDetailChart = React.memo(ProductDetailChartComponent)

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  chartWrapper: {
    flex: 1,
    flexDirection: 'column',
    paddingY: 2,
  },
  legendAndButtonsWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
    flexDirection: 'row',
    paddingX: [null, null, 4],
  },
  legendCircle: {
    width: [12, null, 14],
    height: [12, null, 14],
    backgroundColor: 'primaryColor300',
    borderRadius: 50,
  },
  legendTitleWrapper: {
    alignItems: 'center',
    gap: 1,
    flexDirection: 'row',
  },
  legendTitle: {
    fontFamily: 'Montserrat',
    fontSize: [12, null, 14],
    color: 'grayColor700',
  },
  buttonsWrapper: {
    alignItems: 'center',
    gap: 1,
    flexDirection: 'row',
  },
  noDataText: {
    textAlign: 'center',
  },
  zoomButtons: {},
})

const applyVictoryStyles = () => ({
  dependentAxis: {
    grid: {stroke: '#034E33', strokeWidth: 1.5, opacity: 0.1},
    axis: {stroke: 'transparent'},
    tickLabels: {
      fontSize: [12, null, 14],
      fontFamily: 'Montserrat',
      fill: 'grayColor700',
    },
    axisLabel: {
      fontFamily: 'Montserrat',
      fontSize: [12, null, 14],
      fill: 'grayColor700',
    },
  },
  independentAxis: {
    grid: {stroke: '#034E33', strokeWidth: 1.5, opacity: 0.1},
    tickLabels: {
      fontSize: [12, null, 14],
      fill: 'grayColor700',
      fontFamily: 'Montserrat',
      angle: -45,
      textAnchor: 'end',
    },
    axisLabel: {fontFamily: 'Montserrat'},
  },
  victoryScatter: {
    data: {fill: '#68A18C'},
  },
  victoryLine: {
    data: {stroke: '#68A18C', strokeWidth: 4},
  },
  victoryArea: {
    data: {fill: '#E5E5E5'},
  },
  voronoiFlyout: {
    fontSize: 14,
    fontFamily: 'Montserrat',
    fill: 'primaryColor100',
    stroke: 'primaryColor400',
    strokeWidth: 2,
    padding: 8,
  },
  voronoiStyle: {
    fontSize: [12, null, 14],
    fontFamily: 'Montserrat',
    fill: 'grayColor700',
  },
})
