import {configureStore} from '@reduxjs/toolkit'
import {GridState} from '@sincersoft/fe-grid'

import {reducerResetOnLogoutProxy} from './reducers'

export const store = configureStore({
  reducer: reducerResetOnLogoutProxy,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState> & {
  [p: string]: GridState
}

export type AppDispatch = typeof store.dispatch
