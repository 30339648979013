import {DripsyCustomTheme, ScrollView, Text, useDripsyTheme, View} from 'dripsy'
import React, {useCallback} from 'react'
import {Trans} from 'react-i18next'
import {ScaledSize, useWindowDimensions} from 'react-native'

import {SpinnerModal} from 'common/components/SpinnerModal'
// import {SubTitle} from 'common/components/SubTitle'
// import {Title} from 'common/components/Title'
import {
  getDeviceBreakpointValue,
  isBottomBarVisible,
} from 'common/dimension_helpers'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {HEADER_HEIGHT} from 'header/components/Header'
import {AuthorizedLayoutActualShop} from 'layout/components/AuthorizedLayoutActualShop'
import {AuthorizedLayoutSaveToTemplate} from 'layout/components/AuthorizedLayoutSaveToTemplate'
import {BOTTOM_TAB_BAR_HEIGHT} from 'layout/components/BottomTabBar'
import {ContentLayout} from 'layout/components/ContentLayout'
import {ErrorBoundary} from 'layout/components/ErrorBoundary'
import {ObjectsOfSxProps, TitleValue} from 'layout/layout_types'
import {useAppSelector} from 'store/redux_hooks'

interface RenderProps {
  children?: React.ReactNode
}

function computePageHeight(
  theme: DripsyCustomTheme,
  windowDimensions: ScaledSize,
  bottomBarVisible: boolean
): number {
  let headerHeight = getDeviceBreakpointValue(
    theme,
    windowDimensions.width,
    HEADER_HEIGHT
  )
  headerHeight =
    typeof headerHeight === 'number'
      ? headerHeight
      : parseFloat(headerHeight ?? '0')
  // 100vh - header - bottom bar
  return (
    windowDimensions.height -
    headerHeight -
    (bottomBarVisible ? BOTTOM_TAB_BAR_HEIGHT : 0)
  )
}

interface AuthorizedLayoutProps {
  actionButtons?: React.ElementType
  actionButtonsLeft?: React.ElementType
  shopCartActionButtons?: React.ElementType
  displayActualShop?: boolean
  breadcrumbs?: React.ReactNode
  displaySaveTemplate?: boolean
  orderId?: string | number
  title: string
  titleValue?: TitleValue
  subTitle?: string
  subTitleValue?: TitleValue
  children: React.ReactNode
  searchInput?: React.ReactNode
}

// TODO
// export const AuthorizedLayout: React.FC<AuthorizedLayoutProps> = ({
const AuthorizedLayoutComponent: React.FC<AuthorizedLayoutProps> = ({
  actionButtons,
  actionButtonsLeft,
  shopCartActionButtons,
  displayActualShop,
  breadcrumbs,
  displaySaveTemplate,
  orderId,
  title,
  titleValue,
  subTitle,
  subTitleValue,
  children,
  searchInput,
}) => {
  const windowDimensions = useWindowDimensions()
  const {theme} = useDripsyTheme()
  const bottomBarVisible = isBottomBarVisible(theme, windowDimensions.width)
  const pageHeight = computePageHeight(
    theme,
    windowDimensions,
    bottomBarVisible
  )
  const shopCartItemsCount = useAppSelector(
    (state) => state.login.shopCartItemsCount
  )
  const sxStyles = useThemeSxStyles(
    applyStyles,
    pageHeight,
    !!titleValue,
    !!subTitleValue
  )

  const SubTitleRender: React.FC<RenderProps> = useCallback(
    ({children: subTitleChildren}) => (
      <Text sx={sxStyles.subTitleValue} variant={'body2Bold'}>
        {subTitleChildren}
      </Text>
    ),
    [sxStyles.subTitleValue]
  )

  const TitleRender: React.FC<RenderProps> = useCallback(
    ({children: titleChildren}) => {
      return <Text sx={sxStyles.titleValue}>{titleChildren}</Text>
    },
    [sxStyles.titleValue]
  )

  const ActionButtons = actionButtons ?? (() => null)
  const ActionButtonsLeft = actionButtonsLeft ?? (() => null)
  const ShopCartActionButtons =
    shopCartActionButtons && shopCartItemsCount > 0
      ? shopCartActionButtons
      : () => null
  return (
    <ErrorBoundary>
      <View sx={sxStyles.pageWrapper}>
        <ScrollView
          sx={sxStyles.content}
          contentContainerStyle={styles.contentStyle}
        >
          <ContentLayout>
            {breadcrumbs ?? null}
            <View sx={sxStyles.titlesWrapper}>
              <View sx={{flexDirection: 'row', flex: 1}}>
                <ActionButtonsLeft />
                <View sx={sxStyles.titles}>
                  <Text sx={sxStyles.title}>
                    <Trans
                      i18nKey={title}
                      components={[<TitleRender key={''} />]}
                      values={titleValue}
                      parent={Text}
                    />
                  </Text>
                  {subTitle ? (
                    <Text sx={sxStyles.subTitle} variant={'body2Bold'}>
                      <Trans
                        i18nKey={subTitle}
                        components={[<SubTitleRender key={''} />]}
                        values={subTitleValue}
                        parent={Text}
                      />
                    </Text>
                  ) : null}
                </View>
                <ActionButtons />
                <ShopCartActionButtons />
              </View>
              {searchInput ?? null}
              {displayActualShop ? <AuthorizedLayoutActualShop /> : null}
              {/*TODO: AuthorizedLayoutSaveToTemplate*/}
              {displaySaveTemplate ? (
                <AuthorizedLayoutSaveToTemplate orderId={orderId} />
              ) : null}
            </View>
            {children}
          </ContentLayout>
        </ScrollView>
        <SpinnerModal />
      </View>
    </ErrorBoundary>
  )
}

export const AuthorizedLayout = React.memo(AuthorizedLayoutComponent)

const styles = {
  contentStyle: {
    alignItems: 'center' as const,
  },
}

const applyStyles = (
  theme: DripsyCustomTheme,
  pageHeight: number,
  hasTitleValue: boolean,
  hasSubTitleValue: boolean
): ObjectsOfSxProps => ({
  pageWrapper: {
    width: '100%',
    height: pageHeight,
  },
  snackBarWrapper: {
    height: 100,
    width: 100,
    position: 'absolute',
    bottom: 20,
    backgroundColor: 'red',
    zIndex: 10,
  },
  content: {
    flex: 1,
    backgroundColor: 'white',
  },
  titlesWrapper: {
    flexDirection: ['column', null, 'row'],
  },
  titles: {
    flex: 1,
    flexDirection: 'column',
  },
  title: {
    color: 'secondaryColor600',
    paddingY: [4, null, 6],
    ...(hasTitleValue ? {...theme.text.body1} : {...theme.text.body1Bold}),
    fontSize: [theme.text.body1Bold.fontSize, null, theme.text.h5Bold.fontSize],
    textAlign: 'center',
  },
  titleValue: {
    ...theme.text.body1Bold,
    fontSize: [theme.text.body1Bold.fontSize, null, theme.text.h5Bold.fontSize],
  },
  subTitle: {
    color: 'secondaryColor300',
    paddingX: 1,
    paddingBottom: [3, null, 6],
    ...(hasSubTitleValue ? {...theme.text.body2} : {...theme.text.body2Bold}),
    fontSize: [theme.text.body2Bold.fontSize, null, theme.text.h6Bold.fontSize],
    textAlign: 'center',
  },
  subTitleValue: {
    color: 'secondaryColor300',
    paddingX: 1,
    paddingBottom: [3, null, 6],
    textAlign: 'center',
    fontSize: [theme.text.body2Bold.fontSize, null, theme.text.h6Bold.fontSize],
  },
})
