import {createNativeStackNavigator} from '@react-navigation/native-stack'
import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {StorageDataUtils} from '@sincersoft/native-core'
import {useSx} from 'dripsy'
import {StatusBar} from 'expo-status-bar'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SafeAreaView} from 'react-native-safe-area-context'

import {NavAuthorizedHomeScreen} from './NavAuthorizedHomeScreen'
import {getDataConfigForCheckLogin} from '../layout_helpers'
import {layoutSlice} from '../layout_slice'
import {ObjectsOfSxProps, RootStackParamList} from '../layout_types'
import {NotificationModal} from 'common/components/NotificationModal'
import {getCredentials} from 'common/credential_utils'
import {useLogin, UseLoginData} from 'common/hooks/useLogin'
import {ConfirmRegisterScreen} from 'confirm_register/components/ConfirmRegisterScreen'
import {DeleteAccountRequestScreen} from 'delete_account/components/DeleteAccountRequestScreen'
import {DeleteAccountScreen} from 'delete_account/components/DeleteAccountScreen'
import {ForgottenPasswordScreen} from 'forgotten_password/components/ForgottenPasswordScreen'
import {HeaderLeft} from 'header/components/HeaderLeft'
import {LoginScreen} from 'login/components/LoginScreen'
import {UserRole} from 'login/login_constants'
import {loginAndStoreToken} from 'login/login_thunks'
import {LoginResponse} from 'login/login_types'
import {RegisterScreen} from 'register/components/RegisterScreen'
import {ResetPasswordScreen} from 'reset_password/components/ResetPasswordScreen'
import {SnackbarType} from 'snackbar/snackbar_types'
import {SnackbarContainer} from 'snackbar/SnackbarContainer'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

const MainStack = createNativeStackNavigator<RootStackParamList>()

export const AppNavigator: React.FC = () => {
  const dispatch = useAppDispatch()
  const sx = useSx()
  const isLoggedIn = useAppSelector((state) => state.login.isLoggedIn)
  const loginUser = useAppSelector((state) => state.login.loginUser)
  const isInitialized = useAppSelector((state) => state.layout.isInitialized)

  const {t} = useTranslation()

  const configForCheckLogin = getDataConfigForCheckLogin()

  const {refetch, isSuccess, isError, data, remove} =
    useApiQuery<LoginResponse>(
      extractKey({
        url: configForCheckLogin.url,
      }),
      undefined,
      {
        reactQueryOptions: {enabled: false},
        axiosConfig: {
          ...configForCheckLogin,
        },
      },
      true
    )

  const [values, setValues] = useState<UseLoginData>({
    email: '',
    password: '',
  })

  const {mutate} = useLogin(values)

  useEffect(() => {
    if (values && values.email && values.password) {
      mutate()
    }
  }, [values, mutate])

  const checkCredentials = useCallback(async () => {
    const credentials = await getCredentials()
    if (credentials?.loginEmail && credentials?.loginPassword) {
      setValues({
        email: credentials.loginEmail,
        password: credentials.loginPassword,
      })
    } else {
      dispatch(layoutSlice.actions.setInitialized())
    }
  }, [dispatch])

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(loginAndStoreToken({loginUser: data.user}))
      remove()
      if (!isInitialized) {
        dispatch(layoutSlice.actions.setInitialized())
      }
    } else if (isError) {
      checkCredentials()
    }
  }, [
    data,
    isSuccess,
    isError,
    dispatch,
    remove,
    checkCredentials,
    isInitialized,
  ])

  useEffect(() => {
    const checkIfIsLogged = async () => {
      if (!loginUser && !isInitialized) {
        const accessToken = await StorageDataUtils.get('access_token')
        if (accessToken) {
          await refetch()
        } else {
          await checkCredentials()
        }
      }
    }
    checkIfIsLogged()
  }, [loginUser, isInitialized, dispatch, refetch, checkCredentials])

  const headerLeft = useCallback(
    () => (
      <HeaderLeft
        hasBackButton
        isAdmin={loginUser?.role !== UserRole.CUSTOMER}
      />
    ),
    [loginUser?.role]
  )

  return (
    <SafeAreaView style={sx(sxStyles.safeArea)} edges={['top']}>
      {isInitialized ? (
        <MainStack.Navigator>
          {isLoggedIn && loginUser ? (
            <MainStack.Screen
              name={'NavAuthorizedHomeScreen'}
              component={NavAuthorizedHomeScreen}
              options={{headerShown: false}}
            />
          ) : (
            <MainStack.Group
              screenOptions={{
                headerShown: true,
                headerLeft: headerLeft,
              }}
            >
              <MainStack.Screen
                name={'LoginScreen'}
                component={LoginScreen}
                options={{headerShown: false, title: t('login.title')}}
              />
              <MainStack.Screen
                name={'RegisterScreen'}
                component={RegisterScreen}
                options={{title: t('register.title')}}
              />
              <MainStack.Screen
                name={'ConfirmRegisterScreen'}
                options={{title: t('confirmRegister.title')}}
              >
                {(props) => <ConfirmRegisterScreen {...props} isConfirm />}
              </MainStack.Screen>
              <MainStack.Screen
                name={'RequestRegisterScreen'}
                component={ConfirmRegisterScreen}
                options={{title: t('requestRegister.title')}}
              />
              <MainStack.Screen
                name={'ForgottenPasswordScreen'}
                component={ForgottenPasswordScreen}
                options={{title: t('forgottenPassword.title')}}
              />
              <MainStack.Screen
                name={'ResetPasswordScreen'}
                component={ResetPasswordScreen}
                options={{title: t('resetPassword.title')}}
              />
              <MainStack.Screen
                name={'DeleteAccountRequestScreen'}
                component={DeleteAccountRequestScreen}
                options={{title: t('deleteAccountRequest.title')}}
              />
              <MainStack.Screen
                name={'DeleteAccountScreen'}
                component={DeleteAccountScreen}
                options={{title: t('deleteAccount.title')}}
              />
            </MainStack.Group>
          )}
        </MainStack.Navigator>
      ) : null}
      <NotificationModal />
      <SnackbarContainer type={SnackbarType.UNAUTHORIZED} />
      <SnackbarContainer type={SnackbarType.AUTHORIZED} />
      <StatusBar style='auto' />
    </SafeAreaView>
  )
}

const sxStyles: ObjectsOfSxProps = {
  safeArea: {
    flexDirection: 'column',
    flex: 1,
  },
}
