import {CompositeScreenProps} from '@react-navigation/native'
import {extractKey, QueryInputAsArray, useApiQuery} from '@sincersoft/fe-core'
import {View} from 'dripsy'
import {isEmpty} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import ContentLoader, {Rect} from 'common/react_content_loader'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {ProductDetail} from './ProductDetail'
import {
  getDataConfigForProductDetail,
  transformOrderItemDataForProductDetail,
} from '../new_order_helpers'
import {ProductItem} from '../new_order_types'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {AuthorizedLayoutDeleteShopCart} from 'layout/components/AuthorizedLayoutDeleteShopCart'
import {
  AuthorizedScreenProps,
  ObjectsOfSxProps,
  ProductDetailParentStackScreenProps,
} from 'layout/layout_types'
import {OrderItem, PriceListsIds} from 'order/order_types'

type NavigationProps = CompositeScreenProps<
  ProductDetailParentStackScreenProps,
  AuthorizedScreenProps
>

interface OwnProps {
  breadcrumbs?: React.ReactNode
  isOrderItem?: boolean
  isAdminScreen?: boolean
  keyForInvalidateQuery?: QueryInputAsArray
  orderItemData?: OrderItem
  priceListsIdsOfUserFromOrder?: PriceListsIds
}

type Props = OwnProps & NavigationProps

export const ProductDetailContainer: React.FC<Props> = ({
  route,
  breadcrumbs,
  isOrderItem,
  orderItemData,
  keyForInvalidateQuery,
  priceListsIdsOfUserFromOrder,
  isAdminScreen,
}) => {
  const {t} = useTranslation()

  const productDetailId = route.params.id || route.params.productId

  const configForProductDetail = getDataConfigForProductDetail(productDetailId)

  const newOrderProductDetailKey = extractKey({
    params: configForProductDetail.params,
    url: configForProductDetail.url,
  })

  const {
    data: productData,
    isLoading,
    isStale,
  } = useApiQuery<ProductItem>(
    newOrderProductDetailKey,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForProductDetail,
      },
    },
    true
  )

  useRefetchQuery([{key: newOrderProductDetailKey}], isStale)

  const shopCartActionButtons = useCallback(
    () => (
      <AuthorizedLayoutDeleteShopCart
        displayDeleteShopCart
        displayGoToShopCart
      />
    ),
    []
  )

  const dataToDisplay = useMemo(
    () =>
      isOrderItem && orderItemData
        ? transformOrderItemDataForProductDetail(orderItemData)
        : productData,
    [isOrderItem, orderItemData, productData]
  )

  return (
    <View sx={sxStyles.container}>
      <Header hasBackButton />
      <AuthorizedLayout
        displayActualShop
        title={t('newOrder.title')}
        shopCartActionButtons={shopCartActionButtons}
        breadcrumbs={breadcrumbs}
      >
        {isLoading ? (
          <View sx={sxStyles.skeletonWrapper}>
            <ContentLoader style={styles.skeleton} height={400}>
              <Rect
                x={'0'}
                y={'0'}
                rx={'5'}
                ry={'5'}
                width={'100%'}
                height={'100%'}
              />
            </ContentLoader>
          </View>
        ) : null}
        {isEmpty(dataToDisplay) ? null : (
          <ProductDetail
            isAdminScreen={isAdminScreen}
            data={{
              ...dataToDisplay,
              price: dataToDisplay?.price
                ? dataToDisplay.price
                : dataToDisplay.priceS
                ? dataToDisplay.priceS
                : 0,
            }}
            keyForInvalidateQuery={keyForInvalidateQuery}
            priceListsIdsOfUserFromOrder={priceListsIdsOfUserFromOrder}
          />
        )}
      </AuthorizedLayout>
    </View>
  )
}

const styles = StyleSheet.create({
  skeleton: {
    flex: 1,
  },
})

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
  },
  skeletonWrapper: {
    flex: 1,
    marginX: [3, null, 7],
  },
}
