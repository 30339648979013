import {yupResolver} from '@hookform/resolvers/yup'
import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {DateUtils} from '@sincersoft/core'
import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import {DripsyCustomTheme, Text, View} from 'dripsy'
import Constants from 'expo-constants'
import {isEmpty} from 'lodash'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Platform} from 'react-native'

import {
  DESCRIPTION,
  ADMIN_GROUPED_REPORT_PAGE_OPTIONS,
  USER_GROUPED_REPORT_PAGE_OPTIONS,
  SCREEN,
  SUBJECT,
  REPORT_OPTIONS_TRANSLATE_PREFIX,
} from '../report_constants'
import {getDataConfigForReport} from '../report_helpers'
import {Report} from '../report_types'
import {IconButton} from 'common/components/IconButton'
import {useGetConfig} from 'common/hooks/useGetConfig'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {InputController} from 'form/components/InputController'
import {SelectController} from 'form/components/SelectController'
import {ReportSchema} from 'form/form_schemas'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {ScreenKeyboardAwareScrollView} from 'layout/components/ScreenKeyboardAwareScrollView'
import {
  AuthorizedScreenProps,
  ObjectsOfSxProps,
  ReportStackParamList,
} from 'layout/layout_types'
import {UserRole} from 'login/login_constants'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

type Props = CompositeScreenProps<
  NativeStackScreenProps<ReportStackParamList, 'ReportScreen'>,
  AuthorizedScreenProps
>

interface ReportScreenForm {
  subject: string
  screen: string
  description: string
}

export const ReportScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const sxStyles = useThemeSxStyles(applyStyles)

  const userRole = useAppSelector((state) => state.login.loginUser?.role)
  const {beBuildVersion, beBuildDate} = useGetConfig()

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: {errors},
  } = useForm<ReportScreenForm>({
    resolver: yupResolver(ReportSchema),
  })

  const prepareDataForSave = (): Report => {
    const values = getValues()
    return {
      ...values,
      screen: t(`${REPORT_OPTIONS_TRANSLATE_PREFIX}.${values.screen}`),
    }
  }

  const configForReport = getDataConfigForReport<Report>(prepareDataForSave())

  const handleSuccess = (response: ResponseSuccess) => {
    reset({
      screen: '',
      subject: '',
      description: '',
    })
    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const feVersion = Constants?.expoConfig?.extra?.feVersion
  const mobileVersion = Constants?.expoConfig?.version
  const versionsString = `Frontend Version: ${feVersion}, Backend Version: ${beBuildVersion}`
  const feTimestamp = Constants?.expoConfig?.extra?.feTimestamp
  const feDate = feTimestamp ? new Date(feTimestamp) : undefined
  const formattedFeDate = feDate
    ? DateUtils.format(feDate, 'dd. MM. yyyy, HH:mm')
    : undefined

  const beDate = beBuildDate ? new Date(beBuildDate) : undefined
  const formattedBeDate = beDate
    ? DateUtils.format(beDate, 'dd. MM. yyyy, HH:mm')
    : undefined

  const dataWithVersions = {
    ...configForReport.data,
    description: configForReport.data?.description
      ? configForReport.data.description + ' - ' + versionsString
      : '' + versionsString,
  }

  const {isLoading, mutate} = useApiMutation(
    extractKey({
      url: configForReport.url,
      data: dataWithVersions,
    }),
    configForReport.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccess,
        onError: handleError,
      },
      axiosConfig: {
        url: configForReport.url,
        method: configForReport.method,
        data: dataWithVersions,
      },
    },
    true
  )

  const handlePressReport = () => {
    mutate()
  }

  return (
    <>
      <Header />
      <ScreenKeyboardAwareScrollView>
        <AuthorizedLayout title={t('report.title')}>
          <View sx={sxStyles.content}>
            <InputController
              control={control}
              id={SUBJECT}
              isColumn
              label={t('report.subject')}
              error={errors[SUBJECT]}
              hideErrorInput={isEmpty(errors[SUBJECT])}
              size={'small'}
              labelStyle={sxStyles.label}
            />
            <SelectController
              label={t('report.screen')}
              control={control}
              isColumn
              error={errors[SCREEN]}
              translatePrefix={REPORT_OPTIONS_TRANSLATE_PREFIX}
              name={SCREEN}
              hideErrorInput={false}
              displayLabelProperty={'name'}
              idProperty={'name'}
              options={
                userRole === UserRole.CUSTOMER
                  ? USER_GROUPED_REPORT_PAGE_OPTIONS
                  : ADMIN_GROUPED_REPORT_PAGE_OPTIONS
              }
              labelStyle={sxStyles.label}
              valueStyle={sxStyles.value}
              wrapperStyle={sxStyles.wrapper}
              size={'small'}
            />
            <InputController
              control={control}
              id={DESCRIPTION}
              isColumn
              label={t('report.description')}
              error={errors[DESCRIPTION]}
              hideErrorInput={isEmpty(errors[DESCRIPTION])}
              size={'small'}
              labelStyle={sxStyles.label}
              multiline
              numberOfLines={4}
              maxLength={400}
            />
          </View>
          <View sx={sxStyles.button}>
            <IconButton
              iconName={'report'}
              left
              title={t('button.report')}
              onPress={handleSubmit(handlePressReport)}
              color={'primaryColor500'}
              isLoading={isLoading}
            />
          </View>
          {Platform.OS === 'web' ? (
            <>
              {feVersion !== '' && (
                <Text sx={sxStyles.version}>{`Frontend Version: ${feVersion}${
                  formattedFeDate !== '' && formattedFeDate && feVersion
                    ? ', '
                    : ''
                }${
                  formattedFeDate !== '' && formattedFeDate
                    ? formattedFeDate
                    : ''
                }`}</Text>
              )}
              {beBuildVersion !== '' && (
                <Text
                  sx={sxStyles.version}
                >{`Backend Version: ${beBuildVersion}${
                  formattedBeDate !== '' && formattedBeDate && feVersion
                    ? ', '
                    : ''
                }${
                  formattedBeDate !== '' && formattedBeDate
                    ? formattedBeDate
                    : ''
                }`}</Text>
              )}
            </>
          ) : (
            mobileVersion && (
              <Text
                sx={sxStyles.version}
              >{`Mobile Version: ${mobileVersion}`}</Text>
            )
          )}
        </AuthorizedLayout>
      </ScreenKeyboardAwareScrollView>
    </>
  )
}

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  content: {
    marginX: [1, 3, 4, 0],
  },
  button: {
    alignItems: 'center',
    marginTop: 4,
  },
  wrapper: {
    flex: 1,
    paddingTop: [3, null, 5],
  },
  label: {
    ...theme.text.body2,
    color: 'grayColor700',
    fontSize: [2, null, 3],
    flex: 1,
  },
  value: {
    color: 'grayColor800',
    ...theme.text.body2,
    fontSize: [2, null, 3],
  },
  version: {
    marginTop: 2,
    marginLeft: 1,
  },
})
