import {View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Api} from 'config/app_config'
import {Grid} from 'grid/components/Grid'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {SHOP_CART_INSTANCE_REDUCER_STATE_ID} from 'shop_cart/shop_cart_constants'
import shopCartGrid from 'shop_cart/shop_cart_grid_config.json'

interface Props {
  _shopCartGridConfigLoaded?: boolean
}

export const NewOrderQuickShopCartGridComponent: React.FC<Props> = (_props) => {
  const {t} = useTranslation()
  return (
    <View sx={sxStyles.container}>
      <Grid
        dataServiceUrl={Api.cartItem}
        plainGridConfig={{
          ...shopCartGrid,
          headerHeight: 30,
        }}
        stateId={SHOP_CART_INSTANCE_REDUCER_STATE_ID}
        title={t('newOrder.quick.titleShopCart')}
        showResetSortButton={false}
      />
    </View>
  )
}

export const NewOrderQuickShopCartGrid = React.memo(
  NewOrderQuickShopCartGridComponent
)

const sxStyles: ObjectsOfSxProps = {
  container: {
    px: [0, null, 7],
    py: [1, null, 4],
    mb: 3,
    borderWidth: 2,
    borderColor: 'primaryColor200',
    borderRadius: 7,
  },
}
