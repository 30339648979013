import {
  DocumentPickerResult,
  DocumentPickerSuccessResult,
} from 'expo-document-picker'
import {ImagePickerSuccessResult, ImagePickerResult} from 'expo-image-picker'

import {ResponseSuccess} from 'error/error_helper'

export interface PriceListDetailDateForm {
  dateFrom: Date | null | undefined
  dateTo: Date | null | undefined
}

export interface PriceListDetailDate {
  dateFrom: number | null
  dateTo: number | null
}

export interface EnumPriceList {
  id: string | null
  name: string
}

export interface PriceListItem {
  createdAt: number
  updatedAt: number
  id: number | string
  name: string
  type: EnumPriceListType
  isActive: boolean
  dateFrom: number
  dateTo: number
  importedAt: number
}

export type EnumPriceListType = 'action' | 'standard'

export type PriceListExport = {
  priceListStandard: number
  priceListActionPrimary?: number | null
}

export interface ResponsePriceListExport extends ResponseSuccess {
  documentIdentifierPriceList: string
  documentIdentifierPriceListChanges: string
}

// TODO: move to native-core
export function isDocumentResultSuccess(
  result: DocumentPickerResult | undefined
): result is DocumentPickerSuccessResult {
  return (
    typeof result?.canceled === 'boolean' &&
    !result?.canceled &&
    result.assets?.length > 0
  )
}

// TODO: move to native-core
export function isImagePickerResultSuccess(
  result: ImagePickerResult | undefined
): result is ImagePickerSuccessResult {
  return (
    typeof result?.canceled === 'boolean' &&
    !result?.canceled &&
    result.assets?.length > 0
  )
}
