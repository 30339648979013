import {DripsyCustomTheme, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LabelValue} from 'common/components/LabelValue'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface ProductDetailProps {
  name: string
  code: string
}

export const ProductCategoryDetailInfoComponent: React.FC<
  ProductDetailProps
> = ({name, code}) => {
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)
  return (
    <View sx={sxStyles.content}>
      <View sx={sxStyles.info}>
        <LabelValue
          label={t('item.category')}
          value={name}
          labelStyle={sxStyles.label}
          valueStyle={sxStyles.value}
          wrapperStyle={sxStyles.wrapper}
        />
        <LabelValue
          label={t('item.code')}
          value={code}
          labelStyle={sxStyles.label}
          valueStyle={sxStyles.value}
          wrapperStyle={sxStyles.wrapper}
        />
      </View>
    </View>
  )
}

export const ProductCategoryDetailInfo = React.memo(
  ProductCategoryDetailInfoComponent
)

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  content: {
    alignItems: 'center',
    flexDirection: ['column', null, 'row'],
  },
  button: {
    alignItems: 'center',
    paddingBottom: [4, null, 5],
    paddingTop: [3, null, 4],
  },
  wrapper: {
    paddingTop: [3, null, 5],
  },
  label: {
    ...theme.text.body2Bold,
    color: 'secondaryColor600',
    fontSize: [2, null, 3],
  },
  value: {
    color: 'grayColor800',
    ...theme.text.body2,
    fontSize: [2, null, 3],
  },
  info: {
    width: '100%',
    flex: [null, null, 1],
    paddingLeft: [0, null, 2],
  },
})
