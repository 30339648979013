import {View} from 'dripsy'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {NewOrderList} from './NewOrderList'
import {NewOrderProductListItem} from './NewOrderProductListItem'
import {SearchInput} from 'common/components/SearchInput'
import {usePagination} from 'common/hooks/usePagination'
import {BIG_PAGINATION_SIZE, isAndroid, isIOS} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {NotFoundItem} from 'new_order/components/NotFoundItem'
import {ImageItem, ProductItem} from 'new_order/new_order_types'
import {useAppSelector} from 'store/redux_hooks'
import {RootState} from 'store/redux_store'

interface Props {
  displaySearchInputBasic: boolean
  isButtonsDisabled: boolean
  list: ProductItem[]
  onPressItem: (id: string) => void
  onPressDeleteIcon: () => void
  onChangeSearchValue: (text: string) => void
  searchValue: string
  productCategoryEntityImageId: ImageItem | null
  productCategoryEntityVersion: number
}

const NewOrderProductComponent: React.FC<Props> = ({
  displaySearchInputBasic,
  isButtonsDisabled,
  onPressItem,
  onPressDeleteIcon,
  list,
  searchValue,
  onChangeSearchValue,
  productCategoryEntityImageId,
  productCategoryEntityVersion,
}) => {
  const {t} = useTranslation()
  const isSmallDevice = isAndroid || isIOS
  const {
    isLastRendered,
    onIsAllRendered,
    onIsLastRendered,
    paginationSize,
    renderPaginationButton,
  } = usePagination(isSmallDevice ? undefined : BIG_PAGINATION_SIZE)
  const isShopCartEnabled = useAppSelector(
    (state: RootState) => state.login.isShopCartEnabled
  )
  const sxStyles = applyStyles()

  const handleAllRendered = useCallback(() => {
    onIsAllRendered()
  }, [onIsAllRendered])

  const handleLastRendered = useCallback(
    (name: string) => {
      onIsLastRendered(name)
    },
    [onIsLastRendered]
  )

  return (
    <View sx={sxStyles.container}>
      {displaySearchInputBasic && (
        <SearchInput
          value={searchValue}
          onChangeText={onChangeSearchValue}
          onPressDeleteIcon={onPressDeleteIcon}
          placeholder={t('newOrder.product.placeholder')}
        />
      )}

      {list && list.length > 0 ? (
        <>
          <NewOrderList
            onAllRendered={isSmallDevice ? handleAllRendered : undefined}
            isLastRendered={isSmallDevice && isLastRendered}
          >
            {list.slice(0, paginationSize).map((item, idx) => (
              <NewOrderProductListItem
                key={`${item.id} + ${item.shopCartId}`}
                item={item}
                onPress={onPressItem}
                isShopCartEnabled={isShopCartEnabled}
                onLastRendered={
                  isSmallDevice &&
                  list.slice(0, paginationSize).length === idx + 1
                    ? handleLastRendered
                    : undefined
                }
                productCategoryEntityImageId={productCategoryEntityImageId}
                productCategoryEntityVersion={productCategoryEntityVersion}
                isButtonsDisabled={isButtonsDisabled}
              />
            ))}
          </NewOrderList>
          {isSmallDevice ? renderPaginationButton(list.length) : null}
        </>
      ) : (
        <NotFoundItem title={t('newOrder.notFound')} />
      )}
    </View>
  )
}

export const NewOrderProduct = React.memo(NewOrderProductComponent)

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    paddingX: 1,
  },
})
