import {SelectProps} from '@ui-kitten/components'
import {View, Text, SxProp} from 'dripsy'
import React from 'react'

import {SelectOptionValue} from 'form/components/SelectController'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props extends SelectProps {
  value: SelectOptionValue
  valueStyle?: SxProp
}

export const SelectValue: React.FC<Props> = (props) => {
  const {value, valueStyle, ...restProps} = props
  return (
    <View sx={sxStyles.selectTextWrapper}>
      <Text
        numberOfLines={1}
        variant={'caption'}
        {...restProps}
        sx={{...sxStyles.selectText, ...valueStyle}}
      >
        {value}
      </Text>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  selectTextWrapper: {
    flex: 1,
    mr: 'auto',
    alignItems: 'flex-start',
  },
  selectText: {
    color: 'grayColor600',
    lineHeight: 24,
  },
}
