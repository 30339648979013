import {SxProp} from 'dripsy'
import {PressableStateCallbackType} from 'react-native'

export type PressableStyleMap = Partial<
  Record<keyof PressableStateCallbackType, SxProp>
>

export type PressableStateProps = {
  pressableState: PressableStateCallbackType
  disabled?: boolean
}

export function getPressableStyle(
  pressableState: PressableStateCallbackType,
  styleMap: PressableStyleMap
): SxProp {
  return {
    ...(pressableState.focused ? styleMap.focused : {}),
    ...(pressableState.hovered ? styleMap.hovered : {}),
    ...(pressableState.pressed ? styleMap.pressed : {}),
  }
}
