import {View, Text} from 'dripsy'
import * as Linking from 'expo-linking'
import React from 'react'
import {Control, FieldErrors} from 'react-hook-form'
import {Trans, useTranslation} from 'react-i18next'

import {InputErrorMessage} from 'common/components/InputErrorMessage'
import {useGetConfig} from 'common/hooks/useGetConfig'
import {CheckboxController} from 'form/components/CheckboxController'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {REGISTRATION_FORM_CHECKBOX} from 'register/register_constants'

interface OwnProps {
  control: Control<any>
  errors?: FieldErrors
  hideErrorInput?: boolean
}

export const PolicyCheckbox: React.FC<OwnProps> = ({
  control,
  errors,
  hideErrorInput = false,
  ...restProps
}) => {
  const sxStyles = applyStyles()
  const {t} = useTranslation()

  const {privatePolicyUrl} = useGetConfig()

  const gdprText = t('register.checkbox.accessibilityAgreeWithGdpr')

  return (
    <View sx={sxStyles.wrapper}>
      <View sx={sxStyles.container}>
        <CheckboxController
          accessibilityLabel={gdprText}
          accessibilityHint={gdprText}
          control={control}
          id={REGISTRATION_FORM_CHECKBOX.id}
          {...restProps}
        />
        <View sx={sxStyles.infoTextWrapper}>
          <View sx={sxStyles.linkTextWrapperGdpr}>
            <Text>
              <Trans
                i18nKey='register.checkbox.agreeWithGdpr'
                t={t}
                values={{
                  gtac: t('register.checkbox.gtac'),
                  pdpp: t('register.checkbox.pdpp'),
                }}
                components={{
                  Gtac: (
                    <Text
                      onPress={() =>
                        Linking.openURL(t('register.checkbox.gtacUrl'))
                      }
                      sx={sxStyles.linkTextGdpr}
                    />
                  ),
                  Pdpp: (
                    <Text
                      onPress={() => {
                        if (privatePolicyUrl !== '') {
                          Linking.openURL(privatePolicyUrl)
                        }
                      }}
                      sx={sxStyles.linkTextGdpr}
                    />
                  ),
                }}
              />
            </Text>
          </View>
          <View sx={sxStyles.linkTextWrapperCancelGdpr}>
            <Text>
              <Trans
                i18nKey='register.checkbox.cancelGdprAgreement'
                t={t}
                values={{
                  email: t('register.checkbox.email'),
                }}
                components={{
                  Email: (
                    <Text
                      onPress={() =>
                        Linking.openURL(t('register.checkbox.emailUrl'))
                      }
                      sx={sxStyles.linkTextCancelGdpr}
                    />
                  ),
                }}
              />
            </Text>
          </View>
        </View>
      </View>
      {hideErrorInput ||
      !errors ||
      !errors[REGISTRATION_FORM_CHECKBOX.id] ? null : (
        <InputErrorMessage error={errors[REGISTRATION_FORM_CHECKBOX.id]} />
      )}
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    width: '100%',
    display: 'flex',
    marginTop: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingX: [2, null, 5, 7],
  },
  infoTextWrapper: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 14,
    paddingRight: 2,
    justifyContent: 'flex-start',
  },
  linkTextWrapperGdpr: {
    paddingBottom: 2,
    paddingRight: 2,
  },
  linkTextWrapperCancelGdpr: {
    paddingRight: 2,
    fontSize: 12,
  },
  linkTextGdpr: {
    color: 'primaryColor500',
    fontWeight: 'bold',
  },
  linkTextCancelGdpr: {
    color: 'primaryColor500',
    fontWeight: 'bold',
  },
})
