import {IndexPath} from '@ui-kitten/components'

export function isSingleIndexPath(
  index: IndexPath | IndexPath[]
): index is IndexPath {
  return (index as IndexPath[]).length == null
}

export interface DefaultSelectOptions {
  name: string
  id: string
}
