import {View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {IconButton} from 'common/components/IconButton'
import {ObjectsOfSxProps} from 'layout/layout_types'

type Props = {
  onPressButton: () => void
  isLoading: boolean
  isShopCartEnabled: boolean
  disabled: boolean
}

export const ContinueShopCartOrderButton: React.FC<Props> = ({
  onPressButton,
  isShopCartEnabled,
  disabled,
}) => {
  const {t} = useTranslation()
  return (
    <View sx={sxStyles.button}>
      <IconButton
        title={t('button.continueShopping')}
        onPress={onPressButton}
        appearance={'outline'}
        color={'primaryColor500'}
        disabled={disabled || !isShopCartEnabled}
        iconName={'arrow-back'}
        size={'small'}
        left
      />
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  button: {
    alignItems: 'flex-end',
    paddingBottom: [4, null, 5],
    paddingTop: [5, null, 7],
    marginRight: 10,
  },
  infoText: {
    pt: 2,
    color: 'secondaryColor300',
    textAlign: 'center',
  },
}
