import {Platform} from 'react-native'

import Theme from 'theme/app_theme.json'

export const isAndroid = Platform.OS === 'android'
export const isIOS = Platform.OS === 'ios'
export const isWeb = Platform.OS === 'web'

export const TOKEN_EXPIRED = 'E_TOKEN_EXPIRED'

export const BIG_PAGINATION_SIZE = 99999

export const PARSED_INT_THEME_BREAKPOINT_0 = parseInt(Theme.breakpoints[0])
export const PARSED_INT_THEME_BREAKPOINT_1 = parseInt(Theme.breakpoints[1])
export const PARSED_INT_THEME_BREAKPOINT_2 = parseInt(Theme.breakpoints[2])
export const PARSED_INT_THEME_BREAKPOINT_3 = parseInt(Theme.breakpoints[3])
export const PARSED_INT_THEME_BREAKPOINT_4 = parseInt(Theme.breakpoints[4])

export const MIN_WIDTH_FOR_ACTION_BUTTONS = 165
