import React from 'react'

import {ColumnNumberValue} from 'common/components/ColumnNumberValue'
import {CellRendererParams} from 'grid/grid_types'
import {Shop} from 'user/user_types'

export const AdminUserShopCountCellRenderer: React.FC<CellRendererParams> = ({
  value,
  isSmall,
}) => {
  let propertyValue = 0
  if (value && value.length > 0) {
    const activeShops = value.filter((item: Shop) => item.isActive)
    propertyValue = activeShops.length
  }

  return <ColumnNumberValue value={propertyValue} isSmall={isSmall} />
}
