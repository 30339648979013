import {ReducerUtils} from '@sincersoft/core'
import {GRID_REDUCER_STATE_ID} from '@sincersoft/fe-grid'

export const ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_ID = 'adminProduct'

export const MeasureUnits = {
  PIECES: 'ks',
  POUNDS: 'kg',
} as const

export const MeasureUnitsOptions = [
  {name: MeasureUnits.PIECES},
  {name: MeasureUnits.POUNDS},
]

export const ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_GRID_ID =
  ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_ID
  )

export const PRICE_THRESHOLD_VALUE = 0.5
