import {yupResolver} from '@hookform/resolvers/yup'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import {/*Text*/ useSx /*View*/ /*, ScrollView*/} from 'dripsy'
import {isEmpty} from 'lodash'
import React, {useCallback} from 'react'
import {FieldErrors, useForm} from 'react-hook-form'
// import {useTranslation} from 'react-i18next'
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'

import {RegisterButtons} from './RegisterButtons'
import {RegisterIntro} from './RegisterIntro'
import {useValidateForm} from 'common/hooks/useValidateForm'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {transformFormErrorsToErrorState} from 'common/validation_helper'
import {ApiError} from 'error/error_helper'
import {RegisterSchema} from 'form/form_schemas'
import {ContentLayout} from 'layout/components/ContentLayout'
import {ErrorBoundary} from 'layout/components/ErrorBoundary'
import {ObjectsOfSxProps, RootStackParamList} from 'layout/layout_types'
// import {KeyboardAvoidingView} from 'react-native'
import {PolicyCheckbox} from 'register/components/PolicyCheckbox'
import {RegisterForms} from 'register/components/RegisterForms'
import {getDataConfigForRegister} from 'register/register_helpers'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch} from 'store/redux_hooks'
// import {Notice} from 'common/components/Notice'

type Props = NativeStackScreenProps<RootStackParamList, 'RegisterScreen'>

interface RegisterScreenForm {
  email: string
  password: string
  fullName: string
  phone: string
  userShopName: string
  userShopStreet: string
  userShopCity: string
  userShopPhone: string
  userShopCompany: string
  userShopCompanyRegistrationNumber: number
  userShopCompanyVatNumber: string
  acceptPolicy: boolean
  isSubscribedForPriceList: boolean
}

export const RegisterScreen: React.FC<Props> = ({navigation}) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<RegisterScreenForm>({
    resolver: yupResolver(RegisterSchema),
  })
  const dispatch = useAppDispatch()
  const sx = useSx()
  // const {t} = useTranslation()
  const {validateFormColumnValues} = useValidateForm()

  const handleSuccessRegister = async () => {
    navigation.navigate('RequestRegisterScreen')
    reset()

    dispatch(
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.UNAUTHORIZED,
        isVisible: true,
        data: {
          color: 'success',
          text: 'register.success',
        },
      })
    )
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error, false))
  }

  const preparedValues = (values: any) => {
    return {
      ...values,
      ...(values?.userShopCompanyVatNumber
        ? {
            userShopCompanyVatNumber:
              values.userShopCompanyVatNumber.toUpperCase(),
          }
        : {}),
    }
  }

  const configForRegister = getDataConfigForRegister(
    preparedValues(getValues())
  )

  const {mutate, reset, isLoading} = useApiMutation(
    extractKey({
      url: configForRegister.url,
      data: configForRegister.data,
      method: configForRegister.method,
    }),
    configForRegister.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessRegister,
        onError: handleError,
      },
      axiosConfig: {
        ...configForRegister,
      },
    },
    false
  )
  const sxStyles = applyStyles()

  const handlePressSubmit = useCallback(() => {
    mutate()
  }, [mutate])

  const handlePressSubmitError = useCallback(
    (errorsAfterSubmit: FieldErrors) => {
      if (!isEmpty(errorsAfterSubmit)) {
        const errorState = transformFormErrorsToErrorState(errorsAfterSubmit)
        validateFormColumnValues(errorState, null, 'register.validation')
      }
    },
    [validateFormColumnValues]
  )

  const handlePressCancel = () => {
    navigation.navigate('LoginScreen')
  }

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={sx(sxStyles.contentContainer)}
      extraHeight={150}
      enableOnAndroid
    >
      <ErrorBoundary>
        {/*<KeyboardAvoidingView>*/}
        {/*<ScrollView contentContainerStyle={sxStyles.contentContainer}>*/}
        <ContentLayout>
          <RegisterIntro />
          {/* For disabling registration form uncomment code below and comment code that allows users to go to this screen in LoginScreen.*/}
          {/*<View sx={sxStyles.registrationDisabledWrapper}>*/}
          {/*  <Text variant={'h6'} sx={sxStyles.registrationDisabled}>*/}
          {/*    {t('registrationDisabled')}*/}
          {/*  </Text>*/}
          {/*</View>*/}
          <RegisterForms errors={errors} control={control} />
          {/* Uncomment to enable error showing that registration is disabled.*/}
          {/*{isError && !isLoading && (*/}
          {/*  <Notice text={error?.response?.data?.messageCode} color={'error'} />*/}
          {/*)}*/}
          <PolicyCheckbox errors={errors} control={control} />
          <RegisterButtons
            handlePressCancel={handlePressCancel}
            handlePressSubmit={handleSubmit(
              handlePressSubmit,
              handlePressSubmitError
            )}
            isLoading={isLoading}
          />
        </ContentLayout>
      </ErrorBoundary>
      {/*</ScrollView>*/}
      {/*</KeyboardAvoidingView>*/}
    </KeyboardAwareScrollView>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  contentContainer: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  registrationDisabledWrapper: {
    pt: [6, 5, 4],
    px: 2,
    alignItems: 'center',
  },

  registrationDisabled: {
    color: 'secondaryColor500',
    borderWidth: 1,
    p: 4,
    borderColor: 'secondaryColor300',
    textAlign: 'center',
  },
})
