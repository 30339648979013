import Constants from 'expo-constants'

const {expoConfig} = Constants
let apiPrefix = `${expoConfig?.extra?.apiHostName}`
// add port if it is defined
apiPrefix = expoConfig?.extra?.apiHostPort
  ? `${apiPrefix}:${expoConfig?.extra?.apiHostPort}`
  : apiPrefix

export const Api = {
  addItemToCart: `${apiPrefix}/cart/add-item`,
  checkLogin: `${apiPrefix}/user/me`,
  canOrder: `${apiPrefix}/user/can-order`,
  cart: `${apiPrefix}/cart`,
  cartItem: `${apiPrefix}/cartItem`,
  cartItemsCount: `${apiPrefix}/cart/items-count`,
  customItem: `${apiPrefix}/customItem`,
  customItemCreate: `${apiPrefix}/customItem/create`,
  customPrice: `${apiPrefix}/customPrice`,
  customPriceCreate: `${apiPrefix}/customPrice/create`,
  changePassword: `${apiPrefix}/user/change-password`,
  config: `${apiPrefix}/config`,
  confirmRegister: `${apiPrefix}/user/confirm`,
  createOrder: `${apiPrefix}/order/create`,
  deleteUser: `${apiPrefix}/user`,
  deleteCart: `${apiPrefix}/cart`,
  deleteItemFromCart: `${apiPrefix}/cart/delete-item`,
  forgottenPassword: `${apiPrefix}/user/forgotten-password`,
  login: `${apiPrefix}/user/login`,
  logout: `${apiPrefix}/user/logout`,
  order: `${apiPrefix}/order`,
  orderItem: `${apiPrefix}/orderItem`,
  product: `${apiPrefix}/product`,
  productCategory: `${apiPrefix}/productCategory`,
  productDetail: `${apiPrefix}/product`,
  priceList: `${apiPrefix}/priceList`,
  priceListDownload: `${apiPrefix}/priceList/download`,
  priceListExportRequest: `${apiPrefix}/priceList/export-request`,
  priceListExport: `${apiPrefix}/priceList/export`,
  register: `${apiPrefix}/user/register`,
  resendConfirmation: `${apiPrefix}/user/resend-confirmation`,
  resetPassword: `${apiPrefix}/user/reset-password`,
  deleteAccount: `${apiPrefix}/user/delete-account`,
  deleteAccountRequest: `${apiPrefix}/user/delete-account-request`,
  user: `${apiPrefix}/user`,
  userDevice: `${apiPrefix}/userDevice`,
  userReport: `${apiPrefix}/user/report-error`,
  userShop: `${apiPrefix}/userShop`,
  town: `${apiPrefix}/town`,
  updateCart: `${apiPrefix}/cart`,
  addItemsToCart: `${apiPrefix}/cart/add-items`,
  updateOrderItem: `${apiPrefix}/order/update-item`,
  updateItemInCart: `${apiPrefix}/cart/update-item`,
  uploadPriceList: `${apiPrefix}/priceList/upload`,
  uploadBatchImages: `${apiPrefix}/product/upload-batch-images`,
  ship: 'ship',
  downloadAction: 'download',
  uploadImageAction: `upload-image`,
  getImageAction: `get-image`,
  getProductChartAction: `price-list-chart`,
  getImageThumbnailAction: `get-image-thumbnail`,
  orderTemplate: `${apiPrefix}/orderTemplate`,
  orderTemplateUseAction: `use`,
  orderTemplateOptions: `${apiPrefix}/orderTemplate/for-select`,
  orderTemplateExport: `${apiPrefix}/orderTemplate/exportOrderTemplate`,
  orderTemplateImport: `${apiPrefix}/orderTemplate/importOrderTemplate`,
  orderTemplateImportFromData: `${apiPrefix}/orderTemplate/importOrderTemplateFromData`,
}
