/* eslint-disable @typescript-eslint/no-explicit-any */
import {DripsyCustomTheme, SxProp, useDripsyTheme} from 'dripsy'

export type GetAllButFirstArgumentsOfAnyFunction<T> = T extends (
  first: any,
  ...args: infer Arguments
) => any
  ? Arguments
  : []

export type SxStylesFunction = (
  theme: DripsyCustomTheme,
  ...stylesFnArgs: any[]
) => Record<string, SxProp>

export const asStyleFn = <T extends SxStylesFunction>(func: T) => func

export function useThemeSxStyles<T extends SxStylesFunction>(
  stylesFn: T extends SxStylesFunction ? T : never,
  ...stylesFnArgs: T extends SxStylesFunction
    ? GetAllButFirstArgumentsOfAnyFunction<T>
    : never
): ReturnType<T> {
  const dripsyTheme = useDripsyTheme()

  // call styles function with a dripsy theme as the first argument
  return stylesFn(dripsyTheme.theme, ...stylesFnArgs) as ReturnType<T>
}
