import {View} from 'dripsy'
import React from 'react'

import {DocumentPicker} from 'common/components/DocumentPicker'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {getDataConfigForImportOrderTemplate} from 'order_template/order_template_helpers'

interface Props {
  onSuccessImport?: (response: any) => void
}

export const AuthorizedLayoutImportTemplateButton: React.FC<Props> = ({
  onSuccessImport,
}) => {
  return (
    <View sx={sxStyles.button}>
      <DocumentPicker
        buttonText={'button.importOrderTemplate'}
        dataConfig={getDataConfigForImportOrderTemplate()}
        onSuccessUpload={onSuccessImport}
        pickerOptions={{type: 'application/json'}}
        activateModalSpinner
      />
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  button: {
    paddingTop: [2, null, 28],
    position: 'absolute',
    right: 0,
    flexDirection: ['row', null, 'column'],
  },
  promoButton: {
    pl: [1, null, 0],
    pt: [0, null, 2],
  },
}
