import {ActionCreatorWithPayload} from '@reduxjs/toolkit'
import {AxiosError} from 'axios'

import {ApiError, getErrorMessageCode} from 'error/error_helper'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {IsVisiblePayload, SnackbarType} from 'snackbar/snackbar_types'

export const SnackbarHelpers = {
  getSnackBarError: (
    error: AxiosError<ApiError>,
    isAuthorized = true
  ): ReturnType<ActionCreatorWithPayload<IsVisiblePayload>> => {
    return snackbarSlice.actions.setIsVisible({
      type: isAuthorized ? SnackbarType.AUTHORIZED : SnackbarType.UNAUTHORIZED,
      isVisible: true,
      data: {
        color: 'error',
        text:
          getErrorMessageCode(error) ?? 'response.error.common.unexpectedError',
      },
    })
  },
  getSnackBarSuccess: (
    text: string,
    isAuthorized = true
  ): ReturnType<ActionCreatorWithPayload<IsVisiblePayload>> => {
    return snackbarSlice.actions.setIsVisible({
      type: isAuthorized ? SnackbarType.AUTHORIZED : SnackbarType.UNAUTHORIZED,
      isVisible: true,
      data: {
        color: 'success',
        text: text,
      },
    })
  },
  // display "error" SnackBar with, text and textParams(strings) for translation
  getSnackBarErrorWithText: (
    text: string,
    isAuthorized = true,
    textParams?: string
  ): ReturnType<ActionCreatorWithPayload<IsVisiblePayload>> => {
    return snackbarSlice.actions.setIsVisible({
      type: isAuthorized ? SnackbarType.AUTHORIZED : SnackbarType.UNAUTHORIZED,
      isVisible: true,
      data: {
        color: 'error',
        text: text,
        textParams,
      },
    })
  },
}
