import {View, Text, useDripsyTheme} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ConfirmModal} from 'common/components/ConfirmModal'
import {Icon} from 'common/components/Icon'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  isVisible: boolean
  onSetIsVisible: (isVisible: boolean) => void
}

export const ShopCartDisabledModal: React.FC<Props> = ({
  onSetIsVisible,
  isVisible,
}) => {
  const {t} = useTranslation()
  const {theme} = useDripsyTheme()

  const handlePressConfirmAndCancel = () => {
    onSetIsVisible(false)
  }

  return (
    <ConfirmModal
      modalVisible={isVisible}
      onPressConfirm={handlePressConfirmAndCancel}
      onPressCancel={handlePressConfirmAndCancel}
      buttonConfirmText={t('button.ok')}
    >
      <View sx={sxStyles.content}>
        <Icon
          name={'remove-shopping-cart'}
          size={20}
          color={theme.colors.secondaryColor500}
        />
        <Text sx={sxStyles.text} variant={'captionBold'}>
          {t('shopCart.modalDisabledText')}
        </Text>
      </View>
    </ConfirmModal>
  )
}

const sxStyles: ObjectsOfSxProps = {
  content: {
    flexDirection: 'column',
    padding: 4,
    marginY: 2,
    alignItems: 'center',
  },
  text: {
    color: 'grayColor500',
  },
}
