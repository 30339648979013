import {MaterialIcons} from '@expo/vector-icons'
import {useFocusEffect} from '@react-navigation/native'
import {Modal} from '@ui-kitten/components'
import {SxProp, View, useSx, Image, useDripsyTheme} from 'dripsy'
import React, {useState} from 'react'
import {
  BackHandler,
  ImageLoadEventData,
  ImageResizeMode,
  ImageSourcePropType,
  ImageURISource,
  NativeSyntheticEvent,
  Pressable,
  useWindowDimensions,
} from 'react-native'

import {Icon} from 'common/components/Icon'
import {isWeb, isAndroid} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  source: ImageSourcePropType
  defaultSource: ImageURISource | number
  imageStyle: SxProp
  resizeMode?: ImageResizeMode
  closeIconName?: keyof typeof MaterialIcons.glyphMap
  closeIconColor?: string
  onPress?: () => void
  disableModal?: boolean
}
// TODO: fix this type
type SyntheticEventType =
  | NativeSyntheticEvent<ImageLoadEventData>
  | React.SyntheticEvent<HTMLImageElement, Event>

function isWebLoadEvent(event: ImageLoadEventData | Event): event is Event {
  return isWeb
}

export const ModalableImage: React.FC<Props> = (props) => {
  const dripsyTheme = useDripsyTheme()

  const {
    source,
    defaultSource,
    imageStyle,
    closeIconName = 'close',
    resizeMode = 'contain',
    closeIconColor = dripsyTheme.theme.colors.primaryColor600,
    disableModal,
    onPress,
    ...restProps
  } = props

  const [isImageOpen, setIsImageOpen] = useState(false)
  const {width} = useWindowDimensions()
  const [imageWidth, setImageWidth] = useState(200)
  const [imageHeight, setImageHeight] = useState(200)
  const [isImageLoadError, setIsImageLoadError] = useState(false)
  const sxStyles = applyStyles(width)
  const sx = useSx()

  const imageOnLoadEvent = (event: SyntheticEventType) => {
    const eventPath = event.nativeEvent
    const picture = isWebLoadEvent(eventPath)
      ? (eventPath.target as HTMLImageElement) // explicitly cast of general EventTarget
      : eventPath.source
    const pictureWidth = picture?.width
    const pictureHeight = picture?.height

    if (pictureHeight) {
      setImageHeight(pictureHeight)
    }
    if (pictureHeight) {
      setImageWidth(pictureWidth)
    }
  }

  const handleOpenImage = () => {
    if (!disableModal) {
      setIsImageOpen(true)
    } else if (onPress) {
      onPress()
    }
  }

  const handleCloseImage = () => {
    setIsImageOpen(false)
  }

  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        setIsImageOpen(false)
        return isImageOpen
      }

      if (isAndroid) {
        BackHandler.addEventListener('hardwareBackPress', onBackPress)
      }

      return () =>
        isAndroid
          ? BackHandler.removeEventListener('hardwareBackPress', onBackPress)
          : undefined
    }, [isImageOpen])
  )

  const handleError = () => {
    setIsImageLoadError(true)
  }

  return (
    <>
      <Pressable accessibilityRole='image' onPress={handleOpenImage}>
        <Image
          source={isImageLoadError ? defaultSource : source}
          sx={imageStyle}
          resizeMode={resizeMode}
          onError={handleError}
          {...restProps}
        />
      </Pressable>
      <Modal
        visible={isImageOpen}
        onBackdropPress={handleCloseImage}
        backdropStyle={sx(sxStyles.modalBackDrop)}
        style={sx(sxStyles.modal)}
      >
        <View sx={sxStyles.closeIcon}>
          <Icon
            name={closeIconName}
            onPress={handleCloseImage}
            color={closeIconColor}
            size={30}
          />
        </View>
        <Image
          source={isImageLoadError ? defaultSource : source}
          sx={{...sxStyles.modalImage, aspectRatio: imageWidth / imageHeight}}
          resizeMode={resizeMode}
          onLoad={imageOnLoadEvent}
          {...restProps}
        />
      </Modal>
    </>
  )
}

const applyStyles = (width: number): ObjectsOfSxProps => ({
  modalBackDrop: {
    backgroundColor: '#0000007F',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalImage: {
    minWidth: 300,
    aspectRatio: 1,
    width: width * 0.9,
    height: '100%',
    maxWidth: [null, null, width * 0.7, width * 0.5],
    borderRadius: 10,
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    backgroundColor: '#5E565610',
    borderRadius: 10,
    padding: 0,
    right: 0,
    zIndex: 1,
  },
})
