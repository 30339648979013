import {FilterUtils} from '@sincersoft/core'
import {debounce} from 'lodash'
import {useCallback, useMemo, useState} from 'react'

type UseFilterDataHandleChangeSearchValue<TData> = (
  value: string,
  data: TData[],
  propertyIds?: string[] | undefined
) => void
type UseFilterDataHandlePressDeleteIcon<TData> = (data: TData[]) => void

export type UseFilterDataReturn<TData = any> = {
  filteredData: TData[]
  handleChangeSearchValue: UseFilterDataHandleChangeSearchValue<TData>
  handlePressDeleteIcon: (data: TData[]) => void
  isFiltering: boolean
  searchValue: string
}

export function useFilterData<TData = any>(): UseFilterDataReturn<TData> {
  const [filteredData, setFilteredData] = useState<TData[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [isFiltering, setIsFiltering] = useState(false)

  const filterData = useCallback(
    (data: TData[], filterValue: string, propertyIds: string[]) => {
      let result = data
      if (filterValue !== '') {
        result = FilterUtils.filterData(data, filterValue, propertyIds)
      }
      setFilteredData(result)
      setIsFiltering(false)
    },
    []
  )

  const handleChangeSearchValue: UseFilterDataHandleChangeSearchValue<TData> = (
    value,
    data,
    propertyIds
  ) => {
    setIsFiltering(true)
    setSearchValue(value)
    filterDataDebounce(data, value, propertyIds ?? ['email'])
  }

  const handlePressDeleteIcon: UseFilterDataHandlePressDeleteIcon<TData> = (
    data
  ) => {
    handleChangeSearchValue('', data)
  }

  const filterDataDebounce = useMemo(
    () => debounce(filterData, 600),
    [filterData]
  )

  return {
    filteredData,
    handleChangeSearchValue,
    handlePressDeleteIcon,
    isFiltering,
    searchValue,
  }
}
