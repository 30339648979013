// import {List} from '@ui-kitten/components'
import {ReducerUtils, SortUtils, TypOrderBy} from '@sincersoft/core'
import {
  GRID_REDUCER_STATE_ID,
  gridSlice,
  IGridDynamicComponentsFactory,
  thunks,
} from '@sincersoft/fe-grid'
import {Text, useDripsyTheme, View} from 'dripsy'
import {isEmpty} from 'lodash'
import React, {useState, useEffect, useCallback, useMemo} from 'react'

import {Icon} from 'common/components/Icon'
import {SortModal} from 'common/components/SortModal'
import {usePagination} from 'common/hooks/usePagination'
import {NativeGridList} from 'grid/components/NativeGridList'
import {parseDefaultSortingOptions} from 'grid/grid_helpers'
import {SortObject} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

interface Props {
  data: any
  gridConfig: any
  isLoading: boolean
  isMobile: boolean
  // Intl/i18n
  translateService: any
  onPressItem?: (id: string) => void
  dynamicComponentsFactory?: IGridDynamicComponentsFactory
  disableSorting?: boolean
  stateId: string
  customPagination?: boolean
}

const NativeGridComponent: React.FC<Props> = ({
  data,
  gridConfig,
  isLoading,
  isMobile,
  translateService,
  dynamicComponentsFactory,
  onPressItem,
  disableSorting = false,
  stateId,
  customPagination,
}) => {
  const dripsyTheme = useDripsyTheme()
  const [componentGridConfig, setComponentGridConfig] = useState<any>({})
  const [modalVisible, setModalVisible] = useState(false)
  const [sortObject, setSortObject] = useState<SortObject | null>(null)
  const {
    isLastRendered,
    onIsAllRendered,
    onIsLastRendered,
    paginationSize,
    renderPaginationButton,
    resetPagination,
  } = usePagination(undefined, stateId, customPagination)

  const instanceReducerId = ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    stateId
  )

  const [displayedData, setDisplayedData] = useState<any[]>(data)
  const [displayOverlay, setDisplayOverlay] = useState(false)

  const isLoaded = !isLoading

  useEffect(() => {
    let sortedData = data
    if (data && !customPagination) {
      if (sortObject) {
        if (!displayOverlay) {
          setDisplayOverlay(true)
        }
        sortedData = SortUtils.sortArray(
          data,
          sortObject.columnName,
          sortObject.orderBy
        )
        setDisplayOverlay(false)
      } else if (componentGridConfig?.sortModel) {
        sortedData = SortUtils.sortDataByMultipleColumns(
          data,
          componentGridConfig?.sortModel
        )
      }
    }
    setDisplayedData(sortedData)
  }, [data, sortObject, displayOverlay, componentGridConfig, customPagination])

  useEffect(() => {
    if (gridConfig && gridConfig.isLoaded()) {
      setComponentGridConfig(
        gridConfig.getConfigForGridComponent(
          translateService,
          {},
          isMobile,
          dynamicComponentsFactory
        )
      )
    }
  }, [dynamicComponentsFactory, gridConfig, isMobile, translateService])

  const handlePressItem = useCallback(
    (id: string) => {
      onPressItem ? onPressItem(id) : null
    },
    [onPressItem]
  )

  const dispatch = useAppDispatch()

  const gridPagination = useAppSelector(
    (state) => state[instanceReducerId].pagination
  )

  useEffect(() => {
    if (gridPagination.page === 1) {
      // reset pagination in usePagination
      resetPagination()
    }
  }, [gridPagination])

  const handlePressSort = (columnName: string, typeOrderBy: TypOrderBy) => {
    setModalVisible(false)

    if (customPagination) {
      setSortObject({columnName, orderBy: typeOrderBy})
      if (gridPagination.page !== 1) {
        dispatch(
          gridSlice.actions.resetGridData({
            customPagination: customPagination,
            stateId: instanceReducerId,
          })
        )
      }
      dispatch(
        gridSlice.actions.setGridPagination({
          pagination: {page: 1},
          customPagination: customPagination,
          stateId: instanceReducerId,
        })
      )
      dispatch(
        gridSlice.actions.setGridSort({
          sort: [{[columnName]: typeOrderBy}],
          stateId: instanceReducerId,
        })
      )
    } else {
      setSortObject({columnName, orderBy: typeOrderBy})
      resetPagination()
    }
  }

  const handlePressCancelSort = () => {
    if (!isEmpty(gridConfig?.rawData?.sortingOptions)) {
      const sortArray = parseDefaultSortingOptions(
        gridConfig?.rawData?.sortingOptions
      )
      dispatch(
        gridSlice.actions.setGridSort({
          sort: sortArray,
          stateId: instanceReducerId,
        })
      )
    }
    setSortObject(null)
    resetPagination()
  }

  const handleAllRendered = useCallback(() => {
    onIsAllRendered()
  }, [onIsAllRendered])

  const handleLastRendered = useCallback(
    (name: string) => {
      onIsLastRendered(name)
    },
    [onIsLastRendered]
  )

  const memoizedDisplayedData = useMemo(
    () =>
      customPagination
        ? displayedData
        : displayedData?.slice(0, paginationSize),
    [customPagination, displayedData, paginationSize]
  )

  const areAllItemsDisplayed = useMemo(
    () => gridPagination.totalElements === memoizedDisplayedData.length,
    [gridPagination.totalElements, memoizedDisplayedData.length]
  )

  useEffect(() => {
    if (!isEmpty(gridConfig?.rawData?.sortingOptions)) {
      const sortArray = parseDefaultSortingOptions(
        gridConfig?.rawData?.sortingOptions
      )
      dispatch(
        gridSlice.actions.setGridSort({
          sort: sortArray,
          stateId: instanceReducerId,
        })
      )
    }
  }, [dispatch, gridConfig?.rawData?.sortingOptions, instanceReducerId])

  return isEmpty(componentGridConfig) ? null : (
    <View sx={sxStyles.container}>
      {disableSorting || !isLoaded ? null : (
        <View sx={sxStyles.filterHeader}>
          <Icon
            name={'sort'}
            onPress={() => setModalVisible(true)}
            color={
              sortObject ? dripsyTheme.theme.colors.primaryColor400 : undefined
            }
            backgroundColor={
              sortObject ? dripsyTheme.theme.colors.primaryColor50 : undefined
            }
          />
        </View>
      )}
      {memoizedDisplayedData && memoizedDisplayedData.length > 0 ? (
        <View>
          {displayOverlay && <View sx={sxStyles.overlayA} />}
          {/*<List*/}
          {/*  data={displayedData.slice(0, paginationSize)}*/}
          {/*  renderItem={({item}) => (*/}
          {/*    <NativeGridItem*/}
          {/*      item={item}*/}
          {/*      translateService={translateService}*/}
          {/*      componentGridConfig={componentGridConfig}*/}
          {/*      onPressItem={handlePressItem}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*/>*/}
          <NativeGridList
            data={memoizedDisplayedData}
            gridConfig={componentGridConfig}
            isLastRendered={isLastRendered}
            translateService={translateService}
            // onChangeDisplayedOverlay={handleChangeDisplayedOverlay}
            onPressItem={handlePressItem}
            onIsAllRendered={handleAllRendered}
            onIsLastRendered={handleLastRendered}
          />
          {!areAllItemsDisplayed &&
            renderPaginationButton(displayedData.length)}
        </View>
      ) : (
        // data.map((dataItem) => (
        //   <TouchableOpacity
        //     key={dataItem.id}
        //     onPress={() => handlePressItem(dataItem.id)}
        //   >
        //     <View sx={sxStyles.item}>
        //       {componentGridConfig &&
        //         componentGridConfig.columnDefs &&
        //         componentGridConfig.columnDefs.map((item) => (
        //           <View key={item.headerName} sx={sxStyles.labelValue}>
        //             {item.headerName !== '' ? (
        //               <Text sx={sxStyles.label} variant={'caption'}>{`${localeTextFunc(
        //                 item.headerName,
        //                 ''
        //               )}: `}</Text>
        //             ) : null}
        //             {item.cellRenderer &&
        //             componentGridConfig?.frameworkComponents?.[
        //               item.cellRenderer
        //             ] ? (
        //               CellRendererWrapper(
        //                 componentGridConfig?.frameworkComponents?.[
        //                   item.cellRenderer
        //                 ],
        //                 dataItem,
        //                 dataItem[item.field],
        //                 {cellRendererParams: item?.cellRendererParams}
        //               )
        //             ) : (
        //               <Text numberOfLines={1} sx={sxStyles.value} variant={'caption'}>
        //                 {/* TODO: handle objects with renderers */}
        //                 {!isObject(dataItem[item.field])
        //                   ? get(dataItem, item.field)
        //                   : '-'}
        //               </Text>
        //             )}
        //           </View>
        //         ))}
        //     </View>
        //   </TouchableOpacity>
        // ))
        !isLoading && (
          <Text sx={sxStyles.noData} variant={'caption'}>
            {translateService
              ? translateService.t('grid.noData')
              : 'Žiadne dáta'}
          </Text>
        )
      )}
      <SortModal
        modalVisible={modalVisible}
        onPressSort={handlePressSort}
        onPressCancelSort={handlePressCancelSort}
        onPressHideModal={() => {
          setModalVisible(false)
        }}
        sortingOptions={gridConfig?.rawData?.sortingOptions}
        translatePrefix={gridConfig?.rawData?.sortingTranslatePrefix}
      />
    </View>
  )
}

export const NativeGrid = React.memo(NativeGridComponent)

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
    flexDirection: 'column',
    // padding: 1,
    pb: 2,
  },
  item: {
    flex: 1,
    borderWidth: 1,
    borderColor: 'primaryColor100',
    flexDirection: 'column',
  },
  labelValue: {
    marginX: 1,
    flexDirection: 'row',
  },
  label: {
    color: 'grayColor600',
  },
  value: {
    flex: 1,
    marginLeft: 1,
    textAlign: 'right',
    color: 'grayColor800',
  },
  noData: {
    textAlign: 'center',
  },
  filterHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 1,
  },
  searchInput: {
    flex: 1,
  },
  overlayA: {
    flex: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0.9,
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
}
