import {ReducerUtils} from '@sincersoft/core'
import {GRID_REDUCER_STATE_ID} from '@sincersoft/fe-grid'

import {UnitOfMeasure} from 'new_order/new_order_types'

export const SHOP_CART_INSTANCE_REDUCER_STATE_ID = 'shopCart'
export const SHOP_CART_EMPTY_INSTANCE_REDUCER_STATE_ID = 'shopCartEmpty'

export const SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID =
  ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    SHOP_CART_INSTANCE_REDUCER_STATE_ID
  )

export const CUSTOM_ITEMS_HEADER_NAME = 'shopCart.customItems.header.name'
export const CUSTOM_ITEMS_HEADER_UNIT_OF_MEASURE =
  'shopCart.customItems.header.unitOfMeasureFull'
export const CUSTOM_ITEMS_HEADER_PRICE_FOR_UNIT =
  'shopCart.customItems.header.priceForUnit'
export const CUSTOM_ITEMS_HEADER_COUNT = 'shopCart.customItems.header.count'

export const CUSTOM_ITEMS_HEADER = [
  'shopCart.customItems.header.name',
  'shopCart.customItems.header.count',
  'shopCart.customItems.header.unitOfMeasureFull',
  'shopCart.customItems.header.priceForUnit',
  '',
]

export const UNIT_OF_MEASURE_OPTIONS: Record<'name', UnitOfMeasure>[] = [
  {name: 'kg'},
  {name: 'ks'},
  {name: 'bal'},
  {name: 'g'},
]

export const UNIT_OF_MEASURE_ID = 'unitOfMeasure'
export const NAME_ID = 'name'
export const AMOUNT_ID = 'amount'
export const PRICE_ID = 'price'

export const REQUIRED_IDS = [NAME_ID, AMOUNT_ID, UNIT_OF_MEASURE_ID]

export const MIN_PRICE_VALUE = 0.01
export const MAX_PRICE_VALUE = 999.99
export const MIN_G_VALUE = 50
export const MAX_G_VALUE = 950
export const MIN_KG_VALUE = 0.1
export const MAX_KG_VALUE = 99999.9

export const DEFAULT_CUSTOM_PRICE_NAME = 'Názov'
