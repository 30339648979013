import {yupResolver} from '@hookform/resolvers/yup'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {SxProp, View} from 'dripsy'
import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

import {ConfirmModal} from 'common/components/ConfirmModal'
import {Icon} from 'common/components/Icon'
import {IconButton} from 'common/components/IconButton'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {useOrderTemplateOptions} from 'common/hooks/useOrderTemplateOptions'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {createOrderTemplateSchema} from 'form/form_schemas'
import {layoutSlice} from 'layout/layout_slice'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {CreateOrderTemplate} from 'order_template/components/CreateOrderTemplate'
import {ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_GRID_ID} from 'order_template/order_template_constants'
import {
  getDataConfigForOrderTemplateOptions,
  getDataConfigForSaveOrEditOrderTemplate,
} from 'order_template/order_template_helpers'
import {CreateTemplateForm} from 'order_template/order_template_types'

interface Props {
  orderId?: string | number
  wrapperStyle: SxProp
  displayFullButton?: boolean
}

const SaveToTemplateButtonComponent: React.FC<Props> = ({
  orderId,
  wrapperStyle,
  displayFullButton,
}) => {
  const sxStyles = applyStyles(orderId)
  const {orderTemplateOptions} = useOrderTemplateOptions()
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const queryClient = useQueryClient({context: QueryClientContext})
  const isSmallDevice = useIsNarrowScreen(1)

  const {control, handleSubmit, watch, reset} = useForm<CreateTemplateForm>({
    defaultValues: {
      templateSelect: '',
      templateInput: '',
    },
    resolver: yupResolver(createOrderTemplateSchema),
  })

  const templateInputValue = watch('templateInput')
  const templateSelectValue = watch('templateSelect')

  const getIdFromOptions = () => {
    const findOption = orderTemplateOptions.find(
      (option) => option.id === templateSelectValue
    )
    return findOption ? findOption.id : undefined
  }

  const {t} = useTranslation()
  const dispatch = useDispatch()
  const configForSaveOrEditOrderTemplate =
    getDataConfigForSaveOrEditOrderTemplate(
      selectedIndex === 1,
      {
        ...(orderId ? {orderId} : {}),
        ...(selectedIndex === 0 ? {name: templateInputValue} : {}),
      },
      selectedIndex === 1 ? getIdFromOptions() : undefined
    )

  const handleSuccessSaveOrdEdit = async (response: ResponseSuccess) => {
    setConfirmModalVisible(false)
    const configForOrderTemplateOptions = getDataConfigForOrderTemplateOptions()
    const orderTemplateOptionsKey = extractKey({
      url: configForOrderTemplateOptions.url,
    })
    await queryClient.invalidateQueries(orderTemplateOptionsKey)
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    dispatch(layoutSlice.actions.setModalSpinnerHidden())
    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
    reset()
  }
  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(layoutSlice.actions.setModalSpinnerHidden())
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const {mutate, isLoading} = useApiMutation(
    extractKey({
      data: configForSaveOrEditOrderTemplate.data,
      method: configForSaveOrEditOrderTemplate.method,
      url: configForSaveOrEditOrderTemplate.url,
    }),
    undefined,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessSaveOrdEdit,
        onError: handleError,
      },
      axiosConfig: {
        ...configForSaveOrEditOrderTemplate,
      },
    },
    true
  )

  const handlePressConfirm = () => {
    mutate()
    dispatch(layoutSlice.actions.setModalSpinnerVisible())
  }

  const handlePressCancel = useCallback(() => {
    setConfirmModalVisible(false)
  }, [setConfirmModalVisible])

  const handlePressButton = () => {
    setConfirmModalVisible(true)
  }

  const handleSetSelectedIndex = useCallback(
    (idx: number) => {
      setSelectedIndex(idx)
    },
    [setSelectedIndex]
  )

  const isSaveButtonDisabled = useMemo(() => {
    let result = true
    if (selectedIndex === 0) {
      if (templateInputValue && templateInputValue !== '') {
        result = false
      }
    } else {
      if (templateSelectValue && templateSelectValue !== '') {
        result = false
      }
    }
    return result
  }, [templateSelectValue, templateInputValue, selectedIndex])

  return (
    <View sx={{...sxStyles.button, ...wrapperStyle}}>
      <View sx={sxStyles.buttonWrapper}>
        {isSmallDevice && !displayFullButton ? (
          <View sx={sxStyles.icon}>
            <Icon onPress={handlePressButton} name={'star'} />
          </View>
        ) : (
          <IconButton
            iconName={'star'}
            left
            title={t('button.saveToTemplate')}
            onPress={handlePressButton}
            color={'primaryColor500'}
            appearance={'outline'}
            isLoading={isLoading}
            size={'small'}
          />
        )}
      </View>
      <ConfirmModal
        modalVisible={confirmModalVisible}
        onPressCancel={handlePressCancel}
        onPressConfirm={handleSubmit(handlePressConfirm)}
        isDisabledConfirm={isSaveButtonDisabled}
        isLoading={isLoading}
        withKeyboardAwareScroll
      >
        <CreateOrderTemplate
          templates={orderTemplateOptions}
          control={control}
          selectedRadio={selectedIndex}
          setSelectedRadio={handleSetSelectedIndex}
        />
      </ConfirmModal>
    </View>
  )
}

export const SaveToTemplateButton = React.memo(SaveToTemplateButtonComponent)

const applyStyles = (orderId?: string | number): ObjectsOfSxProps => ({
  button: {
    flexDirection: ['row', null, 'column'],
    mr: 2,
  },
  buttonWrapper: {
    paddingBottom: orderId ? [2, null, 2] : 0,
  },
  icon: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'primaryColor200',
  },
})
