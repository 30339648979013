import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {NotificationResponseData} from 'layout/layout_types'

export interface LayoutState {
  isShopCartEnabled: boolean
  isInitialized: boolean
  isModalSpinnerVisible: boolean
  isNotificationModalVisible: boolean
  notificationData: NotificationResponseData | undefined
  timeoutId: any
  isActiveTimeout: boolean
}

const initialState: LayoutState = {
  isInitialized: false,
  isShopCartEnabled: true,
  isModalSpinnerVisible: false,
  isNotificationModalVisible: false,
  notificationData: undefined,
  timeoutId: null,
  isActiveTimeout: false,
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setIsShopCartEnabled: (state, payload) => {
      state.isShopCartEnabled = payload.payload.isShopCartEnabled
    },
    setInitialized: (state) => {
      state.isInitialized = true
    },
    setModalSpinnerVisible: (state) => {
      state.isModalSpinnerVisible = true
    },
    setModalSpinnerHidden: (state) => {
      state.isModalSpinnerVisible = false
    },
    setNotificationModalVisible: (state) => {
      state.isNotificationModalVisible = true
    },
    setNotificationModalHidden: (state) => {
      state.isNotificationModalVisible = false
      state.notificationData = initialState.notificationData
    },
    setNotificationData: (
      state,
      action: PayloadAction<NotificationResponseData>
    ) => {
      state.notificationData = action.payload
      state.isNotificationModalVisible = true
    },
    setTimeoutId: (state, action: PayloadAction<any>) => {
      state.timeoutId = action.payload
      state.isActiveTimeout = true
    },
    removeTimeoutId: (state) => {
      state.timeoutId = null
    },
    disableTimeout: (state) => {
      state.isActiveTimeout = false
    },
  },
})
