import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {UseQueryResult} from '@tanstack/react-query'

import {PageableResponse} from 'common/types'
import {getDataConfigForEnumPriceList} from 'price_list/price_list_helpers'
import {EnumPriceList} from 'price_list/price_list_types'

interface Result {
  standardPriceListQuery: UseQueryResult<PageableResponse<EnumPriceList>>
  actionPriceListQuery: UseQueryResult<PageableResponse<EnumPriceList>>
}

export const useLoadEnumPriceListOptions = (): Result => {
  const configForStandardPriceList = getDataConfigForEnumPriceList('standard')
  const configForActionPriceList = getDataConfigForEnumPriceList('action')

  const standardPriceListQuery = useApiQuery<PageableResponse<EnumPriceList>>(
    extractKey({
      params: configForStandardPriceList.params,
      url: configForStandardPriceList.url,
    }),
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForStandardPriceList,
      },
    },
    true
  )

  const actionPriceListQuery = useApiQuery<PageableResponse<EnumPriceList>>(
    extractKey({
      params: configForActionPriceList.params,
      url: configForActionPriceList.url,
    }),
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForActionPriceList,
      },
    },
    true
  )

  return {
    standardPriceListQuery,
    actionPriceListQuery,
  }
}
