import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React, {useCallback} from 'react'

import {deleteCredentials} from 'common/credential_utils'
import {useNavigateWithReset} from 'common/hooks/useNavigateWithReset'
import {ApiError, getErrorMessageCode} from 'error/error_helper'
import {HeaderLeft} from 'header/components/HeaderLeft'
import {HeaderLinks} from 'header/components/HeaderLinks'
import {HeaderRight} from 'header/components/HeaderRight'
import {AdminRoutes, ClientRoutes} from 'header/header_constants'
import {getDataConfigForLogout} from 'header/header_helpers'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {UserRole} from 'login/login_constants'
import {refreshApp} from 'login/login_helpers'
import {logoutCleanData} from 'login/login_thunks'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

export const HEADER_HEIGHT = [50, null, 60]

interface HeaderProps {
  hasBackButton?: boolean
}

export const HeaderComponent: React.FC<HeaderProps> = ({
  hasBackButton = false,
}) => {
  const sxStyles = applyStyles()
  const dispatch = useAppDispatch()
  const navigateWithRefresh = useNavigateWithReset()
  const isShopCartEnabled = useAppSelector(
    (state) => state.login.isShopCartEnabled
  )
  const userRole = useAppSelector((state) => state.login.loginUser?.role)
  const loginUserFullName = useAppSelector(
    (state) => state.login.loginUser?.fullName
  )

  const handleSuccessLogout = async () => {
    await dispatch(logoutCleanData())
    await deleteCredentials()
    refreshApp()
  }

  const handlePressProfile = useCallback(() => {
    navigateWithRefresh.navigate('NavProfileScreen', 'ProfileScreen')
  }, [navigateWithRefresh])

  const handlePressReport = useCallback(() => {
    navigateWithRefresh.navigate('NavReportScreen', 'ReportScreen')
  }, [navigateWithRefresh])

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.AUTHORIZED,
        isVisible: true,
        data: {
          color: 'error',
          text:
            getErrorMessageCode(error) ??
            'response.error.common.unexpectedError',
        },
      })
    )
  }

  const configForLogout = getDataConfigForLogout()

  const logoutQueryResponse = useApiMutation(
    extractKey({url: configForLogout.url, method: configForLogout.method}),
    undefined,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessLogout,
        onError: handleError,
      },
      axiosConfig: {
        ...configForLogout,
      },
    },
    true
  )

  const handlePressLogout = useCallback(async () => {
    logoutQueryResponse.mutate()
  }, [logoutQueryResponse])

  return (
    <View sx={sxStyles.header}>
      <View sx={sxStyles.headerItems}>
        <HeaderLeft
          hasBackButton={hasBackButton}
          isAdmin={userRole !== UserRole.CUSTOMER}
        />
        {isWeb && (
          <HeaderLinks
            links={userRole === UserRole.CUSTOMER ? ClientRoutes : AdminRoutes}
          />
        )}
        <HeaderRight
          name={loginUserFullName ?? ''}
          isAdmin={userRole !== UserRole.CUSTOMER}
          isShopCartEnabled={isShopCartEnabled}
          onPressLogout={handlePressLogout}
          onPressProfile={handlePressProfile}
          onPressReport={handlePressReport}
        />
      </View>
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  header: {
    height: HEADER_HEIGHT,
    paddingX: 2,
    backgroundColor: 'white',
    borderBottomColor: 'grayColor200',
    borderBottomWidth: 1,
  },
  headerItems: {
    height: '100%',
    width: ['100%', null, null, 950, 1100, 1300],
    flexDirection: 'row' as const,
    backgroundColor: 'white',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
})

export const Header = React.memo(HeaderComponent)
