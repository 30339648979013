import {
  formatStringToNumber as coreFormatStringToNumber,
  formatNumber,
  formatStringToNumberString,
  NumberFormatOptions,
} from '@sincersoft/core'

// const NUMBER_LOCALE = 'sk-SK'
// const DECIMAL_SEPARATOR = ','
// const DECIMAL_SEPARATOR_ALTERNATIVE = '.'

// export function addThousandsSeparators(
//   value: string,
//   locale: string,
//   options?: Intl.NumberFormatOptions
// ): string {
//   // remove not number characters
//   const normalizedValue = value
//     .replace(/(?!^-)[^0-9.,]/g, '')
//     .replace(DECIMAL_SEPARATOR_ALTERNATIVE, DECIMAL_SEPARATOR)
//   const splittedValue = normalizedValue.split(DECIMAL_SEPARATOR)
//   let result = ''
//   if (splittedValue.length > 0) {
//     // get integer part of the number
//     const [first, ...rest] = splittedValue
//     // add thousands separators to the integer part and join the rest of the number
//     result = [(+`${first}`).toLocaleString(locale, options), ...rest].join(
//       DECIMAL_SEPARATOR
//     )
//   }
//   return result
// }

export function stringToNumberWithDecimalSeparator(value: string): string {
  // const result = addThousandsSeparators(
  //   value
  //     // replace anything other than digit, dot or comma with nothing
  //     .replace(/(?!^-)[^0-9.,]/g, '')
  //     // only 2 decimal
  //     .replace(/^(\d+,?\d{0,2})\d*$/, '$1')
  //     // replace dot with comma
  //     .replace(DECIMAL_SEPARATOR_ALTERNATIVE, DECIMAL_SEPARATOR)
  //     // remove any character different from a digit or comma
  //     .replace(/[^,\d]/g, '')
  //     // only single comma in the value is allowed, remove multiple occurrences
  //     .replace(/^(\d*,?)|(\d*),?/g, '$1$2'),
  //   NUMBER_LOCALE
  // )
  return formatStringToNumberString(value)
}

export function formatStringToNumber(value: string | number): number {
  // format string to number -> '55,55' => 55.55
  return +(typeof value === 'string'
    ? coreFormatStringToNumber(value)
    : // value
      //   // replace anything other than digit, dot or comma with nothing
      //   .replace(/(?!^-)[^0-9.,]/g, '')
      //   // replace dot with comma
      //   .replace(DECIMAL_SEPARATOR, DECIMAL_SEPARATOR_ALTERNATIVE)
      value)
}

export function formatNumberToString(
  value: number,
  options?: NumberFormatOptions
): string {
  // format number to string -> 55.55 => '55,55'
  // return addThousandsSeparators(
  //   `${value}`.replace(DECIMAL_SEPARATOR_ALTERNATIVE, DECIMAL_SEPARATOR),
  //   NUMBER_LOCALE
  // )
  // "0" it`s not formatted to "0.00"
  return value ? formatNumber(value, 'sk-SK', options) : ''
}

export function compareVersions(v1: string, v2: string): number {
  return v1.localeCompare(v2, undefined, {numeric: true, sensitivity: 'base'})
}
