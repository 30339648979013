import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {QueryClientContext} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {View} from 'dripsy'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {PriceListGrid} from './PriceListGrid'
import adminGridConfig from '../admin_price_list_grid_config.json'
import {
  ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_GRID_ID,
  ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_ID,
} from '../price_list_constants'
import {PRICE_LIST} from '../price_list_helpers'
import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {Icon} from 'common/components/Icon'
import {IconButton} from 'common/components/IconButton'
import {useFilterButtons} from 'common/hooks/useFilterButtons'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {Api} from 'config/app_config'
import {ConfigFilterButtons} from 'grid/grid_types'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {AuthorizedLayoutImportButton} from 'layout/components/AuthorizedLayoutImportButton'
import {
  AdminPriceListStackParamList,
  AuthorizedScreenProps,
  ObjectsOfSxProps,
} from 'layout/layout_types'
import {ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_GRID_ID} from 'product/product_constants'
import {ADMIN_PRODUCT_CATEGORY_INSTANCE_REDUCER_STATE_GRID_ID} from 'product_category/product_category_constants'
import {useAppDispatch} from 'store/redux_hooks'

const config: ConfigFilterButtons[] = [
  {
    id: 'standard',
    color: 'primaryColor500',
    activeColor: 'primaryColor500',
    title: 'filterButtons.priceList.standard',
    defaultActive: false,
    condition: [{type: 'standard'}],
  },
  {
    id: 'action',
    color: 'primaryColor500',
    activeColor: 'primaryColor500',
    title: 'filterButtons.priceList.action',
    defaultActive: false,
    condition: [{type: 'action'}],
  },
]

type Props = CompositeScreenProps<
  NativeStackScreenProps<AdminPriceListStackParamList, 'AdminPriceListScreen'>,
  AuthorizedScreenProps
>

export const AdminPriceListScreen: React.FC<Props> = ({navigation}) => {
  const {t} = useTranslation()
  const isSmallDevice = useIsNarrowScreen(1)
  const dispatch = useAppDispatch()
  const {renderFilterButtons, getFilterCondition} = useFilterButtons(
    config,
    true,
    ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_ID
  )

  const handlePressItem = (id: string) => {
    navigation.navigate('AdminPriceListDetailScreen', {id})
  }
  const handlePressDownload = useCallback(() => {
    navigation.navigate('AdminPriceListExportScreen')
  }, [navigation])

  const queryClient = useQueryClient({context: QueryClientContext})

  const handleSuccessUpload = useCallback(() => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_PRODUCT_CATEGORY_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    queryClient.invalidateQueries()
  }, [queryClient, dispatch])

  const filterCondition = getFilterCondition()

  useRefetchQuery([
    {key: ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
  ])

  const actionButtons = useCallback(
    () => (
      <>
        <AuthorizedLayoutImportButton
          onSuccessUpload={handleSuccessUpload}
          showPromo
        />
      </>
    ),
    [handleSuccessUpload]
  )

  const actionButtonsLeft = useCallback(
    () => (
      <>
        <View sx={sxStyles.actionButtonsLeft}>
          <View sx={sxStyles.buttonWrapper}>
            {isSmallDevice ? (
              <View sx={sxStyles.icon}>
                <Icon
                  onPress={handlePressDownload}
                  name={'cloud-download'}
                  color={'primaryColor500'}
                />
              </View>
            ) : (
              <IconButton
                iconName={'cloud-download'}
                left
                title={t('button.exportPriceLists')}
                onPress={handlePressDownload}
                color={'primaryColor500'}
                size={'small'}
              />
            )}
          </View>
        </View>
      </>
    ),
    [handlePressDownload, isSmallDevice, t]
  )

  return (
    <View sx={sxStyles.container}>
      <Header />
      <AuthorizedLayout
        title={t('priceList.title')}
        actionButtonsLeft={actionButtonsLeft}
        actionButtons={actionButtons}
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbItem label={t('priceList.title')} isLast />
          </Breadcrumbs>
        }
      >
        {renderFilterButtons()}
        <PriceListGrid
          dataServiceUrl={Api.priceList}
          stateId={ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_ID}
          dataServiceConfig={{
            params: {
              ...PRICE_LIST.params,
              ...(filterCondition.length > 0
                ? {
                    where: {
                      ...PRICE_LIST.params.where,
                      or: [...filterCondition],
                    },
                  }
                : {}),
            },
          }}
          onPressItem={handlePressItem}
          gridConfig={adminGridConfig}
          hasSearchInput={false}
          customPagination={true}
        />
      </AuthorizedLayout>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
  },
  actionButtonsLeft: {
    // TODO: fix the first padding in array, its supposed to be 7px not 10px
    paddingTop: [2, null, 28],
    // position: 'absolute',
    left: 0,
    flexDirection: ['row', null, 'column'],
  },
  buttonWrapper: {
    flex: 1,
    marginLeft: 2,
  },
  icon: {
    padding: [0, null, 1],
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'primaryColor200',
  },
}
