import React from 'react'

import {ColumnValue} from 'common/components/ColumnValue'
import {CellRendererParams} from 'grid/grid_types'
import {ProductItem} from 'new_order/new_order_types'

export const ProductCellRenderer: React.FC<CellRendererParams> = ({
  value,
  colDef,
  isSmall,
}) => {
  const {property}: {property: keyof ProductItem} = colDef?.cellRendererParams
    ? colDef.cellRendererParams
    : null
  let product: ProductItem | undefined
  if (value) {
    product = value
  }
  const propertyValue = property ? product?.[property] : product

  return (
    <ColumnValue
      value={typeof propertyValue === 'string' ? propertyValue : ''}
      isSmall={isSmall}
    />
  )
}
