import {ReducerUtils} from '@sincersoft/core'
import {GRID_REDUCER_STATE_ID} from '@sincersoft/fe-grid'

export const ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_ID = 'adminPriceList'
export const USER_PRICE_LIST_INSTANCE_REDUCER_STATE_ID = 'userPriceList'

export const MIME_TYPE_ODS = 'application/vnd.oasis.opendocument.spreadsheet'
export const MIME_TYPE_XLS = 'application/vnd.ms-excel'
export const MIME_TYPE_ZIP = 'application/zip'
export const MIME_TYPE_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

// TODO: uncomment after update to Expo 43
// export const IMPORT_MIME_TYPES = [MIME_TYPE_XLS, MIME_TYPE_XLSX, MIME_TYPE_ODS]
export const IMPORT_MIME_TYPES = MIME_TYPE_XLSX
export const IMPORT_IMAGES_MIME_TYPES = MIME_TYPE_ZIP

export const ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_GRID_ID =
  ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_ID
  )

export const USER_PRICE_LIST_INSTANCE_REDUCER_STATE_GRID_ID =
  ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    USER_PRICE_LIST_INSTANCE_REDUCER_STATE_ID
  )
