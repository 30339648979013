import i18n from 'i18next'
// import Backend from 'i18next-http-backend'
import {initReactI18next} from 'react-i18next'

// TODO: after BL
// Guide for i18next https://medium.com/i18n-and-l10n-resources-for-developers/the-ultimate-guide-to-react-localization-with-i18next-518604439845

export async function i18nInit() {
  // wait for initialization, if not there can be a problem in Firefox
  await i18n
    // .use(Backend)
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
      // TODO: set BL
      // backend: {
      //   loadPath:
      // },
      ns: [''],
      defaultNS: '',
      fallbackLng: 'sk',
      react: {
        useSuspense: false, //   <---- problem with React.Suspense
      },
    })

  return i18n
}
