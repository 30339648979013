import {useLinkTo} from '@react-navigation/native'
import {Modal} from '@ui-kitten/components'
import {View, Text} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {Icon} from 'common/components/Icon'
import {IconButton} from 'common/components/IconButton'
import {layoutSlice} from 'layout/layout_slice'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

export const NotificationModal: React.FC = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const linkTo = useLinkTo()

  const notificationData = useAppSelector(
    (state) => state.layout.notificationData
  )

  const isNotificationModalVisible = useAppSelector(
    (state) => state.layout.isNotificationModalVisible
  )

  const handlePressCancel = () => {
    dispatch(layoutSlice.actions.setNotificationModalHidden())
  }

  const handlePressConfirm = () => {
    dispatch(layoutSlice.actions.setNotificationModalHidden())
    if (notificationData && notificationData.link) {
      linkTo(notificationData.link)
    }
  }

  return (
    <Modal
      visible={isNotificationModalVisible}
      backdropStyle={styles.backdrop}
      onBackdropPress={undefined}
    >
      <View sx={sxStyles.container}>
        <View sx={sxStyles.closeIcon}>
          <Icon name={'close'} onPress={handlePressCancel} size={20} />
        </View>
        {notificationData ? (
          <>
            {notificationData?.msg?.title && (
              <Text sx={sxStyles.title} variant={'body2Bold'}>
                {t(notificationData.msg.title)}
              </Text>
            )}
            {notificationData?.msg?.text && (
              <Text sx={sxStyles.text} variant={'caption'}>
                {t(notificationData.msg.text)}
              </Text>
            )}
          </>
        ) : null}
        <View sx={sxStyles.buttons}>
          <IconButton
            title={t('button.cancel')}
            onPress={handlePressCancel}
            color={'primaryColor500'}
            appearance={'outline'}
            size={'small'}
          />
          <IconButton
            title={t('button.show')}
            onPress={handlePressConfirm}
            color={'primaryColor500'}
            size={'small'}
          />
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
})

const sxStyles: ObjectsOfSxProps = {
  container: {
    alignSelf: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 5,
    maxWidth: [300, 320, 500],
    padding: 2,
    marginY: 2,
    width: '100%',
  },
  text: {
    marginTop: 6,
    marginBottom: 5,
    paddingX: 2,
    textAlign: 'center',
    flex: 1,
    color: 'grayColor600',
  },
  title: {
    paddingX: 2,
    textAlign: 'center',
    color: 'primaryColor500',
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
  },
  buttons: {
    marginTop: 2,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}
