import {View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {IconButton} from 'common/components/IconButton'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface ShopCartCustomItemButtonProps {
  onPressButton: () => void
  isDisabled: boolean
}

const ShopCartCustomItemButtonComponent: React.FC<
  ShopCartCustomItemButtonProps
> = ({onPressButton, isDisabled}) => {
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)
  return (
    <View sx={sxStyles.button}>
      <IconButton
        iconName={'shopping-cart'}
        left
        title={t('button.addCustomItem')}
        onPress={onPressButton}
        color={'primaryColor500'}
        size={'small'}
        disabled={isDisabled}
      />
    </View>
  )
}

export const ShopCartCustomItemButton = React.memo(
  ShopCartCustomItemButtonComponent
)

const applyStyles = (): ObjectsOfSxProps => ({
  button: {
    alignItems: 'center',
    paddingBottom: [4, null, 5],
    paddingTop: [3, null, 4],
  },
})
