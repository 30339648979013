import {useSx, View} from 'dripsy'
import React from 'react'
import {ActivityIndicator} from 'react-native'

import {ObjectsOfSxProps} from 'layout/layout_types'
import Theme from 'theme/app_theme.json'

interface OwnProps {}

type Props = OwnProps

export const LoadingIndicator: React.FC<Props> = () => {
  const sx = useSx()
  return (
    <View style={sx(sxStyles.activityWrapper)}>
      <ActivityIndicator color={Theme.colors.primaryColor500} size={'large'} />
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  activityWrapper: {
    position: 'absolute',
    backgroundColor: 'white',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
}
