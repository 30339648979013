import {QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'

export const CHANGE_PASSWORD: QueryInput = {
  url: Api.changePassword,
  method: 'POST',
}

export function getDataConfigForChangePassword<TData>(
  data: TData
): QueryInput<TData> {
  return {
    ...CHANGE_PASSWORD,
    data,
  }
}

export function getDataConfigForDeleteUser<TData>(
  id?: number
): QueryInput<TData> {
  return {
    url: id ? `${Api.user}/${id}` : Api.deleteUser,
    method: 'delete',
  }
}
