import * as SecureStore from 'expo-secure-store'

import {LOGIN_EMAIL, LOGIN_PASSWORD} from 'common/navigation_constants'
import {isWeb} from 'layout/layout_constants'

export async function setCredentials(email: string, password: string) {
  if (!isWeb) {
    await SecureStore.setItemAsync(LOGIN_EMAIL, email)
    await SecureStore.setItemAsync(LOGIN_PASSWORD, password)
  }
}

export async function deleteCredentials() {
  if (!isWeb) {
    await SecureStore.deleteItemAsync(LOGIN_EMAIL)
    await SecureStore.deleteItemAsync(LOGIN_PASSWORD)
  }
}

export async function getCredentials() {
  let result
  if (!isWeb) {
    const loginEmail = await SecureStore.getItemAsync(LOGIN_EMAIL)
    const loginPassword = await SecureStore.getItemAsync(LOGIN_PASSWORD)
    result = {
      loginEmail,
      loginPassword,
    }
  }
  return result
}
