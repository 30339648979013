import {StatePagination} from '@sincersoft/fe-grid'

import {DEFAULT_SORT_TYPE} from 'grid/components/grid_constants'

export const transformPaginationToSailsParams = (
  statePagination: StatePagination
) => {
  return statePagination
    ? {
        limit: statePagination.pageSize,
        skip: statePagination.pageSize * (statePagination.page - 1),
      }
    : undefined
}

export const parseDefaultSortingOptions = (defaultSortingOptions: any[]) => {
  const transformedArray = []

  defaultSortingOptions.forEach((item) => {
    if (item.id) {
      const newObj = {}
      newObj[item.id] = DEFAULT_SORT_TYPE[0].id
      transformedArray.push(newObj)
    }
  })

  return transformedArray
}
