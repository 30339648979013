import {View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {IconButton} from 'common/components/IconButton'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface RegisterButtonsProps {
  handlePressCancel: () => void
  handlePressSubmit: () => void
  isLoading: boolean
}

export const RegisterButtons: React.FC<RegisterButtonsProps> = ({
  handlePressCancel,
  handlePressSubmit,
  isLoading,
}) => {
  const sxStyles = applyStyles()
  const {t} = useTranslation()

  return (
    <View sx={sxStyles.buttons}>
      <IconButton
        iconName={'check'}
        left
        color={'primaryColor500'}
        onPress={handlePressSubmit}
        title={t('button.submit')}
        isLoading={isLoading}
      />
      <View sx={sxStyles.buttonCancel}>
        <IconButton
          iconName={'close'}
          left
          color={'secondaryColor500'}
          onPress={handlePressCancel}
          title={t('button.cancelRegister')}
          appearance={'outline'}
          status={'danger'}
        />
      </View>
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  buttons: {
    display: 'flex',
    flexDirection: ['row-reverse' as const, null, 'column' as const],
    justifyContent: ['space-between', null, 'center'],
    paddingTop: [6, null, 7, null, 8],
    paddingX: 2,
    alignItems: 'center',
  },
  buttonCancel: {
    paddingTop: [null, null, 6],
  },
})
