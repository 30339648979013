import {View, Text} from 'dripsy'
import React from 'react'

import {ModalableImage} from 'common/components/ModalableImage'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface ProductDetailImgAndTitleProps {
  title?: string
  subTitle?: string
  imageUrlWithParams: string
  hasImage: boolean
}

export const ProductDetailImgAndTitleComponent: React.FC<
  ProductDetailImgAndTitleProps
> = ({title, imageUrlWithParams, hasImage, subTitle}) => {
  const sxStyles = applyStyles(hasImage)
  const isSmall = useIsNarrowScreen(0)

  return (
    <View sx={sxStyles.titleAndImg}>
      <View sx={sxStyles.titleAndSubtitle}>
        {title ? (
          <Text
            sx={sxStyles.title}
            variant={isSmall ? 'body1Bold' : 'h6Bold'}
            numberOfLines={2}
          >
            {title}
          </Text>
        ) : null}
        {subTitle ? (
          <Text
            sx={sxStyles.subTitle}
            variant={'captionBold'}
            numberOfLines={1}
          >
            {subTitle}
          </Text>
        ) : null}
      </View>

      <View sx={sxStyles.imgWrapper}>
        <ModalableImage
          source={
            hasImage ? {uri: imageUrlWithParams} : require('assets/default.jpg')
          }
          defaultSource={require('assets/default.jpg')}
          imageStyle={sxStyles.image}
        />
      </View>
    </View>
  )
}

export const ProductDetailImgAndTitle = React.memo(
  ProductDetailImgAndTitleComponent
)

const applyStyles = (hasImage: boolean): ObjectsOfSxProps => ({
  titleAndImg: {
    flex: [null, null, 1],
    flexDirection: ['row-reverse', null, 'column'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleAndSubtitle: {
    flex: 1,
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
    color: 'primaryColor600',
    paddingLeft: [2, null, 0],
  },
  subTitle: {
    textAlign: 'center',
    color: 'primaryColor300',
    paddingLeft: [2, null, 0],
  },
  image: {
    height: [70, null, 230],
    width: [70, null, 230],
  },
  imgWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    height: [70, null, 230],
    width: [70, null, 230],
    paddingY: [3],
    opacity: hasImage ? 1 : 0.6,
  },
})
