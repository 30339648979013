import {QueryInput} from '@sincersoft/fe-core'
import * as Device from 'expo-device'
import * as Notifications from 'expo-notifications'
import {Platform} from 'react-native'

import {Api} from 'config/app_config'

export function getDataConfigForRegisterDevice<TData>(
  data: TData
): QueryInput<TData> {
  return {
    url: Api.userDevice,
    method: 'POST',
    data,
  }
}

export async function registerForPushNotificationsAsync() {
  let token

  if (Platform.OS === 'android') {
    // TODO: setup channel for different environments
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    })
  }

  if (Device.isDevice) {
    const {status: existingStatus} = await Notifications.getPermissionsAsync()
    let finalStatus = existingStatus
    if (existingStatus !== 'granted') {
      const {status} = await Notifications.requestPermissionsAsync()
      finalStatus = status
    }
    if (finalStatus !== 'granted') {
      alert('Failed to get push token for push notification!')
      return
    }
    token = (
      await Notifications.getExpoPushTokenAsync({
        experienceId: '@gpm-voz/gpm-voz',
      })
    ).data
  } else {
    alert('Must use physical device for Push Notifications')
  }

  return token
}
