import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {DateUtils, ReducerUtils} from '@sincersoft/core'
import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {GRID_REDUCER_STATE_ID} from '@sincersoft/fe-grid'
import {View} from 'dripsy'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import userGridConfig from '../user_price_list_grid_config.json'
import {useCheckBox} from 'common/hooks/useCheckBox'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {Api} from 'config/app_config'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {AuthorizedLayoutExportButton} from 'layout/components/AuthorizedLayoutExportButton'
import {
  AuthorizedScreenProps,
  ObjectsOfSxProps,
  PriceListStackParamList,
} from 'layout/layout_types'
import {getDataConfigForActiveCategory} from 'new_order/new_order_helpers'
import {CategoryItem} from 'new_order/new_order_types'
import {PriceListGrid} from 'price_list/components/PriceListGrid'
import {
  USER_PRICE_LIST_INSTANCE_REDUCER_STATE_GRID_ID,
  USER_PRICE_LIST_INSTANCE_REDUCER_STATE_ID,
} from 'price_list/price_list_constants'
import {useAppSelector} from 'store/redux_hooks'
import {RootState} from 'store/redux_store'

type Props = CompositeScreenProps<
  NativeStackScreenProps<PriceListStackParamList, 'PriceListScreen'>,
  AuthorizedScreenProps
>

export const PriceListScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  const [isCheckBoxLoading, setIsCheckBoxLoading] = useState(false)

  const currentDate = DateUtils.format(new Date(), 'yyyy-MM-dd')

  const instanceReducerId = ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    USER_PRICE_LIST_INSTANCE_REDUCER_STATE_ID
  )

  const isLoading = useAppSelector(
    (state) => state[instanceReducerId].isLoading
  )

  const priceListDocumentIdentifier = useAppSelector(
    (state: RootState) => state.login?.loginUser?.priceListDocumentIdentifier
  )

  const dataConfigForActiveCategory = getDataConfigForActiveCategory()
  const {data} = useApiQuery<CategoryItem[]>(
    extractKey({
      params: dataConfigForActiveCategory.params,
      url: dataConfigForActiveCategory.url,
    }),
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...dataConfigForActiveCategory,
      },
    },
    true
  )

  const getConfig = useMemo(() => {
    return data ? data.map((item) => ({id: item.id, title: item.name})) : []
  }, [data])

  const handleChangeLoading = useCallback(
    (value: boolean) => {
      setIsCheckBoxLoading(value)
    },
    [setIsCheckBoxLoading]
  )

  const {renderCheckBoxes, actualActiveIdx} = useCheckBox(
    getConfig,
    // isLoading > 0,
    false,
    handleChangeLoading,
    USER_PRICE_LIST_INSTANCE_REDUCER_STATE_ID,
    true
  )

  const conditionForProductCategory = useMemo(() => {
    return {
      ...(actualActiveIdx && actualActiveIdx.length > 0
        ? {productCategoryId: actualActiveIdx}
        : {}),
    }
  }, [actualActiveIdx])

  const dataServiceConfig = useMemo(() => {
    return {
      params: {
        populate: 'productCategoryId',
        where: {
          price: {'!=': 'null'},
          ...conditionForProductCategory,
          isActive: true,
        },
      },
    }
  }, [conditionForProductCategory])

  const exportParams = useMemo(() => {
    return {
      meta: {
        queryCriteria: {
          where: {
            ...conditionForProductCategory,
          },
        },
      },
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
  }, [conditionForProductCategory])

  useRefetchQuery([
    {key: USER_PRICE_LIST_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
  ])

  const actionButtons = useCallback(
    () => (
      <AuthorizedLayoutExportButton
        apiUrl={`${Api.priceListDownload}/${priceListDocumentIdentifier}`}
        fileName={`cennik_${currentDate}.pdf`}
        isDisabled={isCheckBoxLoading}
        buttonText={'button.downloadPriceList'}
        apiParams={exportParams}
      />
    ),
    [currentDate, exportParams, isCheckBoxLoading, priceListDocumentIdentifier]
  )

  return (
    <View sx={sxStyles.container}>
      <Header />
      <AuthorizedLayout
        title={t('priceList.title')}
        actionButtons={actionButtons}
      >
        {data ? renderCheckBoxes() : null}
        <PriceListGrid
          dataServiceUrl={Api.product}
          stateId={USER_PRICE_LIST_INSTANCE_REDUCER_STATE_ID}
          dataServiceConfig={dataServiceConfig}
          gridConfig={userGridConfig}
          hasSearchInput
          placeholder={t('priceList.placeholder')}
          customPagination
        />
      </AuthorizedLayout>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
  },
  button: {
    paddingTop: [2, null, 4],
    alignSelf: 'center',
  },
}
