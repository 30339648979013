import {View, Text} from 'dripsy'
import React from 'react'

import {Icon} from 'common/components/Icon'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface NotFoundItemProps {
  title: string
}

const NotFoundItemComponent: React.FC<NotFoundItemProps> = ({title}) => {
  const sxStyles = applyStyles()
  return (
    <View sx={sxStyles.container}>
      <Icon name={'search-off'} />
      <Text sx={sxStyles.title} variant={'body1'}>
        {title}
      </Text>
    </View>
  )
}

export const NotFoundItem = React.memo(NotFoundItemComponent)

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    maxWidth: [300, null, 420],
    borderWidth: 1,
    borderColor: 'grayColor300',
    paddingY: [3, null, 5],
    paddingX: [5, null, 7],
    borderRadius: 10,
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 100,
  },
  title: {
    color: 'grayColor500',
    textAlign: 'center',
  },
})
