import {View} from 'dripsy'
import React from 'react'

import {BasicLinkText} from 'common/components/BasicLinkText'
import {
  GRID_ROW_HEADER,
  MOBILE_GRID_LINK_VALUE,
} from 'grid/components/grid_constants'
import {CellRendererParams} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {OrderLink} from 'order/components/OrderLink'

export const OrderLinkCellRenderer: React.FC<CellRendererParams> = ({
  value,
  data,
  isSmall,
}) => {
  const sxStyles = applyStyles(isSmall)
  return (
    <View sx={sxStyles.wrapper}>
      <OrderLink id={data?.id}>
        {(pressableProps) => {
          return (
            <BasicLinkText
              {...pressableProps}
              text={value}
              textStyle={sxStyles.text}
              containerStyle={sxStyles.container}
            />
          )
        }}
      </OrderLink>
    </View>
  )
}

const applyStyles = (isSmall?: boolean): ObjectsOfSxProps => ({
  wrapper: {
    flex: 1,
  },
  container: {
    justifyContent: 'center',
  },
  text: {
    // vertical centering - line-height should be equal to the height of cell
    lineHeight: GRID_ROW_HEADER,
    ...(isSmall ? MOBILE_GRID_LINK_VALUE : {}),
  },
})
