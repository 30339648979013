import {QueryDefaultInput, QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'

export const CHECK_LOGIN: QueryDefaultInput = {
  url: Api.checkLogin,
}

export function getDataConfigForCheckLogin<TData>(): QueryInput<TData> {
  return CHECK_LOGIN
}

export function getDataConfigForConfig<TData>(): QueryInput<TData> {
  return {
    url: Api.config,
  }
}
