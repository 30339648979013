import {Text, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Api} from 'config/app_config'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {UserShopGrid} from 'user/components/UserShopGrid'
import {USER_SHOP_INSTANCE_REDUCER_STATE_ID} from 'user/user_constants'

interface UserDetailShopProps {
  userId: string | number
  haveUserOldShops: boolean
}

export const UserDetailShop: React.FC<UserDetailShopProps> = ({
  userId,
  haveUserOldShops,
}) => {
  const {t} = useTranslation()
  return haveUserOldShops ? (
    <View sx={sxStyles.container}>
      <Text sx={sxStyles.title} variant={'body1Bold'}>
        {t('user.shopList')}
      </Text>
      <Text variant={'caption2Bold'} sx={sxStyles.deprecatedNotice}>
        {t('user.deprecatedShopCarts')}
      </Text>
      <UserShopGrid
        dataServiceUrl={Api.userShop}
        dataServiceConfig={{
          params: {
            userId,
            isActive: true,
            populate: false,
            isPrimary: false,
          },
        }}
        stateId={USER_SHOP_INSTANCE_REDUCER_STATE_ID}
      />
    </View>
  ) : null
}

const sxStyles: ObjectsOfSxProps = {
  title: {
    color: 'secondaryColor600',
    alignSelf: 'center',
    fontSize: [2, null, 4],
    paddingTop: [3, null, 7],
    paddingBottom: [3, null, 6],
  },
  container: {},
  button: {
    paddingTop: 1,
    alignSelf: ['flex-start', null, 'center'],
  },
  deprecatedNotice: {
    color: 'secondaryColor400',
    alignSelf: 'center',
    textAlign: 'center',
    p: 2,
    mx: 2,
    borderWidth: 2,
    borderColor: 'secondaryColor400',
  },
}
