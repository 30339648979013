import {isArray} from 'lodash'
import React from 'react'

import {ColumnValue} from 'common/components/ColumnValue'
import {CellRendererParams} from 'grid/grid_types'
import {Shop} from 'user/user_types'

export const UserShopCellRenderer: React.FC<CellRendererParams> = ({
  value,
  colDef,
  isSmall,
}) => {
  const property: keyof Shop = colDef?.cellRendererParams?.property
    ? colDef.cellRendererParams.property
    : null
  let userShop: Shop | undefined
  if (value && value.length > 0 && isArray(value)) {
    userShop = value.find((item: Shop) => item.isPrimary)
  } else if (value) {
    userShop = value
  }
  const propertyValue = property ? userShop?.[property] : userShop

  return (
    <ColumnValue
      value={typeof propertyValue === 'string' ? propertyValue : ''}
      isSmall={isSmall}
    />
  )
}
