import {TranslateUtils} from '@sincersoft/core'
import {ListItem} from '@ui-kitten/components'
import {ICellRendererParams} from 'ag-grid-community'
import {Pressable, Text, View} from 'dripsy'
import {get, isObject, some} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {NativeGridItemHeader} from 'grid/components/NativeGridItemHeader'
import {ObjectsOfSxProps} from 'layout/layout_types'

const CellRendererWrapper = (
  Component: any,
  data: ICellRendererParams['data'],
  value: ICellRendererParams['value'],
  colDef: ICellRendererParams['colDef']
) => <Component data={data} value={value} colDef={colDef} isSmall />

interface Props {
  item: any
  componentGridConfig: any
  onIsLastRendered?: (id: string) => void
  onPressItem?: (id: string) => void
  translateService: any
}

export const NativeGridItemComponent: React.FC<Props> = ({
  translateService,
  componentGridConfig,
  item,
  onIsLastRendered,
  onPressItem,
}) => {
  const {t} = useTranslation()
  const sxStyles = applyStyles(componentGridConfig.rowStyle?.background)
  const [hideDefault, setHideDefault] = useState<boolean>(true)
  const localeTextFunc = (key: string, defaultValue: string): string => {
    // to avoid key clash with external keys, we add prefix to the start of each key.
    const localizationKey = null

    const translatedValue = TranslateUtils.translate(translateService, key)
    return translatedValue === localizationKey
      ? defaultValue
      : translateService.t(translatedValue)
  }

  useEffect(() => {
    if (onIsLastRendered) {
      onIsLastRendered(item.id)
    }
  }, [onIsLastRendered, item.id])

  const handlePressItem = useCallback(
    (id: string) => {
      onPressItem ? onPressItem(id) : null
    },
    [onPressItem]
  )

  const displayHideForResultButton = some(
    componentGridConfig.columnDefs,
    (item) => item.cellRendererParams?.hideForDefault
  )

  const handlePressDisplayMore = () => {
    setHideDefault(!hideDefault)
  }

  return (
    componentGridConfig &&
    componentGridConfig.columnDefs && (
      <ListItem onPress={() => handlePressItem(item.id)}>
        <View sx={sxStyles.item}>
          {componentGridConfig.columnDefs.map((columnItem: any) =>
            columnItem.hide ||
            (columnItem.cellRendererParams?.hideForDefault &&
              hideDefault) ? null : (
              <View
                key={columnItem.headerName}
                sx={
                  columnItem.cellRendererParams?.isColumn
                    ? sxStyles.labelValueColumn
                    : sxStyles.labelValue
                }
              >
                {columnItem.headerName === '' ||
                columnItem?.cellRendererParams?.headerName ? null : (
                  <NativeGridItemHeader
                    value={`${localeTextFunc(columnItem.headerName, '')}: `}
                  />
                )}

                {columnItem.cellRenderer &&
                componentGridConfig?.components?.[columnItem.cellRenderer] ? (
                  CellRendererWrapper(
                    componentGridConfig?.components?.[columnItem.cellRenderer],
                    item,
                    get(item, columnItem.field),
                    {cellRendererParams: columnItem?.cellRendererParams}
                  )
                ) : (
                  <Text
                    numberOfLines={1}
                    sx={sxStyles.value}
                    variant={'caption'}
                  >
                    {/* TODO: handle objects with renderers */}
                    {isObject(item[columnItem.field])
                      ? '-'
                      : get(item, columnItem.field)}
                  </Text>
                )}
              </View>
            )
          )}
          {displayHideForResultButton ? (
            <View sx={sxStyles.displayButton}>
              <Pressable
                accessibilityRole='button'
                onPress={handlePressDisplayMore}
                sx={sxStyles.pressable}
              >
                <Text variant={'caption2Bold'} sx={sxStyles.hideDefaultText}>
                  {hideDefault
                    ? t('grid.display.more')
                    : t('grid.display.less')}
                </Text>
              </Pressable>
            </View>
          ) : null}
        </View>
      </ListItem>
    )
  )
}

export const NativeGridItem = React.memo(NativeGridItemComponent)

const applyStyles = (backgroundRowColor?: string): ObjectsOfSxProps => ({
  item: {
    flex: 1,
    borderWidth: 1,
    paddingTop: 1,
    borderColor: 'primaryColor100',
    ...(backgroundRowColor
      ? {
          backgroundColor: backgroundRowColor,
        }
      : {}),
    flexDirection: 'column',
  },
  labelValue: {
    marginX: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 1,
  },
  labelValueColumn: {
    marginX: 1,
    flexDirection: 'column',
    marginBottom: 1,
  },
  label: {
    color: 'grayColor600',
    flex: 0.4,
    marginRight: 1,
    alignSelf: 'center',
    lineHeight: 1,
  },
  value: {
    flex: 0.6,
    textAlign: 'right',
    color: 'grayColor800',
  },
  displayButton: {
    alignItems: 'center',
    pb: 1,
  },
  hideDefaultText: {
    color: 'primaryColor500',
  },
  pressable: {
    width: '100%',
    padding: 1,
    alignItems: 'center',
  },
})
