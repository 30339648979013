import {DateUtils} from '@sincersoft/core'
import {Text} from 'dripsy'
import React from 'react'

import {
  GRID_ROW_HEADER,
  MOBILE_GRID_VALUE,
} from 'grid/components/grid_constants'
import {CellRendererParams} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'

export const DateCellRenderer: React.FC<CellRendererParams> = ({
  value,
  isSmall,
  colDef,
}) => {
  const sxStyles = applyStyles(isSmall)

  const fullTime =
    colDef?.cellRendererParams && colDef.cellRendererParams.fullTime
      ? colDef.cellRendererParams?.fullTime
      : null

  return (
    <Text sx={sxStyles.container} variant={'caption'}>
      {value
        ? DateUtils.format(
            +value,
            fullTime ? DateUtils.DATE_FORMAT : DateUtils.DATE_ONLY_FORMAT
          )
        : '-'}
    </Text>
  )
}

const applyStyles = (isSmall?: boolean): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: GRID_ROW_HEADER,
    ...(isSmall
      ? MOBILE_GRID_VALUE
      : {
          textAlignVertical: 'center',
        }),
  },
})
