import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'
// import {useSelector} from 'react-redux'

import {OrderGrid} from './OrderGrid'
import {USER_ORDER_INSTANCE_REDUCER_STATE_ID} from '../order_constants'
import userOrderGridConfig from '../user_order_grid_config.json'
import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {Api} from 'config/app_config'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {AuthorizedScreenProps, OrderStackParamList} from 'layout/layout_types'
// import {RootState} from 'store/redux_store'

type Props = CompositeScreenProps<
  NativeStackScreenProps<OrderStackParamList, 'OrderScreen'>,
  AuthorizedScreenProps
>

export const OrderScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  // const userId = useSelector((state: RootState) => state.login.loginUser?.id)

  return (
    <View sx={{flex: 1}}>
      <Header />
      <AuthorizedLayout
        title={t('routes.order')}
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbItem label={t('routes.order')} isLast />
          </Breadcrumbs>
        }
      >
        <OrderGrid
          dataServiceUrl={Api.order}
          gridConfig={userOrderGridConfig}
          dataServiceConfig={{
            params: {
              populate: 'customItems',
            },
          }}
          stateId={USER_ORDER_INSTANCE_REDUCER_STATE_ID}
          placeholder={t('order.placeholder')}
          customPagination
        />
      </AuthorizedLayout>
    </View>
  )
}
