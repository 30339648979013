import {Text, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Pressable} from 'react-native'

import {LabelValue} from 'common/components/LabelValue'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {NewOrderProductListItemTitle} from 'new_order/components/NewOrderProductListItemTitle'
import {ProductItem} from 'new_order/new_order_types'

interface NewOrderProductListItemInfo {
  name: ProductItem['name']
  description: ProductItem['description']
  itemClass: ProductItem['class']
  packing: ProductItem['packing']
  price: string
  handlePress: () => void
  unitOptions: string[]
}

const NewOrderProductListItemInfoComponent: React.FC<
  NewOrderProductListItemInfo
> = ({
  name,
  description,
  packing,
  itemClass,
  price,
  handlePress,
  unitOptions,
}) => {
  const {t} = useTranslation()
  const sxStyles = applyStyles()

  return (
    <Pressable accessibilityRole='button' onPress={handlePress}>
      <View sx={sxStyles.titleWrapper}>
        <NewOrderProductListItemTitle name={name} />
      </View>
      <View sx={sxStyles.descriptionWithClass}>
        {!description && !itemClass ? (
          <Text>{'-'}</Text>
        ) : (
          <>
            <Text
              sx={sxStyles.description}
              variant={'caption'}
              numberOfLines={1}
            >
              {description}
            </Text>
            <Text sx={sxStyles.class} variant={'captionBold'}>
              {itemClass}
            </Text>
          </>
        )}
      </View>
      <LabelValue
        label={t('item.packing')}
        labelStyle={sxStyles.labelValue}
        value={packing || '-'}
        valueStyle={sxStyles.labelValue}
      />
      <LabelValue
        label={t('item.priceForUnit')}
        labelStyle={sxStyles.labelValue}
        value={price}
        valueStyle={sxStyles.labelValue}
      />
      <LabelValue
        label={t('item.unitOfMeasure')}
        labelStyle={sxStyles.labelValue}
        value={unitOptions.join(', ')}
        valueStyle={sxStyles.labelValue}
      />
    </Pressable>
  )
}

export const NewOrderProductListItemInfo = React.memo(
  NewOrderProductListItemInfoComponent
)

const applyStyles = (): ObjectsOfSxProps => ({
  content: {
    flex: 1,
    borderTopWidth: 1,
    borderColor: 'grayColor300',
    paddingX: [1, null, 3],
    paddingY: [1],
  },
  labelValue: {
    fontSize: [1, null, 2],
  },
  wrapper: {
    marginY: 1,
  },
  titleWrapper: {
    display: ['none', null, 'flex'],
  },
  descriptionWithClass: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  description: {
    color: 'grayColor600',
    fontSize: [1, null, 2],
  },
  class: {
    color: 'grayColor700',
    fontSize: [1, null, 2],
  },
})
