import {
  IGridDynamicFunction,
  IGridFrameworkComponent,
  IGridDynamicComponentsFactory,
} from '@sincersoft/fe-grid'

import * as frameworkComponents from './components/dynamic/framework_components'
import * as functions from './components/dynamic/functions'

// CELL RENDERERS
const ADMIN_ORDER_LINK_CELL_RENDERER = 'AdminOrderLinkCellRenderer'
const ADMIN_USER_SHOP_COUNT_CELL_RENDERER = 'AdminUserShopCountCellRenderer'
const COUNTRY_FLAG_CELL_RENDERER = 'CountryFlagCellRenderer'
const CURRENCY_CELL_RENDERER = 'CurrencyCellRenderer'
const CUSTOM_PRICE_CELL_RENDERER = 'CustomPriceCellRenderer'
const DATE_CELL_RENDERER = 'DateCellRenderer'
const ORDER_LINK_CELL_RENDERER = 'OrderLinkCellRenderer'
const ORDER_AMOUNT_CELL_RENDERER = 'OrderAmountCellRenderer'
const ORDER_PRICE_FOR_UNIT_CELL_RENDERER = 'OrderPriceForUnitCellRenderer'
const ORDER_UNIT_OF_MEASURE_CELL_RENDERER = 'OrderUnitOfMeasureCellRenderer'
const ORDER_STATUS_CELL_RENDERER = 'OrderStatusCellRenderer'
const ORDER_TEMPLATE_ACTION_CELL_RENDERER = 'OrderTemplateActionCellRenderer'
const QUICK_ORDER_AMOUNT_CELL_RENDERER = 'QuickOrderAmountCellRenderer'
const NAME_AND_CATEGORY_CELL_RENDERER = 'NameAndCategoryCellRenderer'
const PRICE_LIST_LINK_CELL_RENDERER = 'PriceListLinkCellRenderer'
const PRICE_WITH_CENTS_CELL_RENDERER = 'PriceWithCentsCellRenderer'
const PRODUCT_CELL_RENDERER = 'ProductCellRenderer'
const PRODUCT_LINK_CELL_RENDERER = 'ProductLinkCellRenderer'
const PRODUCT_CATEGORY_LINK_CELL_RENDERER = 'ProductCategoryLinkCellRenderer'
const SHOP_CART_ACTION_CELL_RENDERER = 'ShopCartActionCellRenderer'
const SHOP_CART_AMOUNT_CELL_RENDERER = 'ShopCartAmountCellRenderer'
const SHOP_CART_UNIT_OF_MEASURE_CELL_RENDERER =
  'ShopCartUnitOfMeasureCellRenderer'
const SHOP_CART_AMOUNT_AND_UNIT_CELL_RENDERER =
  'ShopCartAmountAndUnitCellRenderer'
const TRANSLATE_CELL_RENDERER = 'TranslateCellRenderer'
const USER_LINK_CELL_RENDERER = 'UserLinkCellRenderer'
const USER_CELL_RENDERER = 'UserCellRenderer'
const USER_SHOP_ACTION_CELL_RENDERER = 'UserShopActionCellRenderer'
const USER_SHOP_CELL_RENDERER = 'UserShopCellRenderer'
const PRICE_TOOLTIP_COMPONENT = 'PriceTooltipComponent'
const USER_SHOP_NAME_COMPARATOR = 'userShopNameComparator'
const USER_SHOP_COMPANY_COMPARATOR = 'userShopCompanyComparator'

// VALUE FORMATTERS AND COMPARATORS
const EXAMPLE_VALUE_FORMATTER = 'exampleValueFormatter'

const FUNCTIONS: Record<string, {func: IGridDynamicFunction}> = {
  [EXAMPLE_VALUE_FORMATTER]: {func: functions.exampleValueFormatter},
  [USER_SHOP_NAME_COMPARATOR]: {
    func: functions.userShopNameComparator,
  },
  [USER_SHOP_COMPANY_COMPARATOR]: {
    func: functions.userShopCompanyComparator,
  },
}

const FRAMEWORK_COMPONENTS: Record<string, {comp: IGridFrameworkComponent}> = {
  [ADMIN_ORDER_LINK_CELL_RENDERER]: {
    comp: frameworkComponents.AdminOrderLinkCellRenderer,
  },
  [ADMIN_USER_SHOP_COUNT_CELL_RENDERER]: {
    comp: frameworkComponents.AdminUserShopCountCellRenderer,
  },
  [COUNTRY_FLAG_CELL_RENDERER]: {
    comp: frameworkComponents.CountryFlagCellRenderer,
  },
  [CURRENCY_CELL_RENDERER]: {
    comp: frameworkComponents.CurrencyCellRenderer,
  },
  [CUSTOM_PRICE_CELL_RENDERER]: {
    comp: frameworkComponents.CustomPriceCellRenderer,
  },
  [DATE_CELL_RENDERER]: {
    comp: frameworkComponents.DateCellRenderer,
  },
  [ORDER_AMOUNT_CELL_RENDERER]: {
    comp: frameworkComponents.OrderAmountCellRenderer,
  },
  [ORDER_PRICE_FOR_UNIT_CELL_RENDERER]: {
    comp: frameworkComponents.OrderPriceForUnitCellRenderer,
  },
  [ORDER_UNIT_OF_MEASURE_CELL_RENDERER]: {
    comp: frameworkComponents.OrderUnitOfMeasureCellRenderer,
  },
  [ORDER_STATUS_CELL_RENDERER]: {
    comp: frameworkComponents.OrderStatusCellRenderer,
  },
  [ORDER_LINK_CELL_RENDERER]: {
    comp: frameworkComponents.OrderLinkCellRenderer,
  },
  [ORDER_TEMPLATE_ACTION_CELL_RENDERER]: {
    comp: frameworkComponents.OrderTemplateActionCellRenderer,
  },
  [QUICK_ORDER_AMOUNT_CELL_RENDERER]: {
    comp: frameworkComponents.QuickOrderAmountCellRenderer,
  },
  [NAME_AND_CATEGORY_CELL_RENDERER]: {
    comp: frameworkComponents.NameAndCategoryCellRenderer,
  },
  [PRICE_LIST_LINK_CELL_RENDERER]: {
    comp: frameworkComponents.PriceListLinkCellRenderer,
  },
  [PRODUCT_CELL_RENDERER]: {
    comp: frameworkComponents.ProductCellRenderer,
  },
  [PRODUCT_LINK_CELL_RENDERER]: {
    comp: frameworkComponents.ProductLinkCellRenderer,
  },
  [PRODUCT_CATEGORY_LINK_CELL_RENDERER]: {
    comp: frameworkComponents.ProductCategoryLinkCellRenderer,
  },
  [SHOP_CART_ACTION_CELL_RENDERER]: {
    comp: frameworkComponents.ShopCartActionCellRenderer,
  },
  [SHOP_CART_AMOUNT_CELL_RENDERER]: {
    comp: frameworkComponents.ShopCartAmountCellRenderer,
  },
  [SHOP_CART_UNIT_OF_MEASURE_CELL_RENDERER]: {
    comp: frameworkComponents.ShopCartUnitOfMeasureCellRenderer,
  },
  [SHOP_CART_AMOUNT_AND_UNIT_CELL_RENDERER]: {
    comp: frameworkComponents.ShopCartAmountAndUnitCellRenderer,
  },
  [TRANSLATE_CELL_RENDERER]: {
    comp: frameworkComponents.TranslateCellRenderer,
  },
  [USER_CELL_RENDERER]: {comp: frameworkComponents.UserCellRenderer},
  [USER_LINK_CELL_RENDERER]: {comp: frameworkComponents.UserLinkCellRenderer},
  [USER_SHOP_ACTION_CELL_RENDERER]: {
    comp: frameworkComponents.UserShopActionCellRenderer,
  },
  [USER_SHOP_CELL_RENDERER]: {
    comp: frameworkComponents.UserShopCellRenderer,
  },
  [PRICE_WITH_CENTS_CELL_RENDERER]: {
    comp: frameworkComponents.PriceWithCentsCellRenderer,
  },
  [PRICE_TOOLTIP_COMPONENT]: {
    comp: frameworkComponents.PriceTooltipComponent,
  },
}

export const GridProjectDynamicComponentsFactory: IGridDynamicComponentsFactory =
  {
    getDynamicFuncByName: (name: string): IGridDynamicFunction | null => {
      return FUNCTIONS[name] ? FUNCTIONS[name].func : null
    },

    getFrameworkCompByName: (name: string): IGridFrameworkComponent | null => {
      return FRAMEWORK_COMPONENTS[name] ? FRAMEWORK_COMPONENTS[name].comp : null
    },
  }
