import React from 'react'

import {ColumnValue} from 'common/components/ColumnValue'
import {getFormattedCurrency} from 'common/data_helpers'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {PRICE_THRESHOLD_VALUE} from 'product/product_constants'

interface Props {
  value: number
  isSmall?: boolean
  priceThresholdValue?: number
}

const ColumnPriceValueComponent: React.FC<Props> = ({
  value,
  isSmall,
  priceThresholdValue,
}) => {
  const sxStyles = applyStyles(isSmall)
  const valueWithThreshold = priceThresholdValue ?? PRICE_THRESHOLD_VALUE

  const formatValue = (): string => {
    let result = '-'

    if (value != null) {
      result = getFormattedCurrency(value, valueWithThreshold)
    }
    return result
  }

  return (
    <ColumnValue
      value={formatValue()}
      isSmall={isSmall}
      extraStyle={sxStyles.text}
      smallWithoutFlex
    />
  )
}

export const ColumnPriceValue = React.memo(ColumnPriceValueComponent)

const applyStyles = (isSmall?: boolean): ObjectsOfSxProps => ({
  text: {
    // vertical centering - line-height should be equal to the height of cell
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    ...(isSmall
      ? {}
      : {
          textAlignVertical: 'center',
        }),
  },
})
