import React from 'react'

import {MIN_WIDTH_FOR_ACTION_BUTTONS} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {SaveToTemplateButton} from 'order_template/components/SaveToTemplateButton'

interface Props {
  orderId?: string | number
  isInShopCart?: boolean
}

const AuthorizedLayoutSaveToTemplateComponent: React.FC<Props> = ({
  orderId,
  isInShopCart,
}) => {
  const sxStyles = applyStyles(isInShopCart)
  return (
    <SaveToTemplateButton wrapperStyle={sxStyles.button} orderId={orderId} />
  )
}

export const AuthorizedLayoutSaveToTemplate = React.memo(
  AuthorizedLayoutSaveToTemplateComponent
)

const applyStyles = (isInShopCart?: boolean): ObjectsOfSxProps => ({
  button: {
    marginTop: isInShopCart ? 60 : 2,
    alignSelf: isInShopCart ? 'flex-start' : ['flex-end', null],
    ...(isInShopCart
      ? {minWidth: [0, null, MIN_WIDTH_FOR_ACTION_BUTTONS]}
      : {}),
  },
})
