import {yupResolver} from '@hookform/resolvers/yup'
import {extractKey, useApiMutation, useApiQuery} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import {Text, View} from 'dripsy'
import React, {useCallback, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {Button} from 'common/components/Button'
import {ApiError} from 'error/error_helper'
import {InputController} from 'form/components/InputController'
import {orderTemplateSchema} from 'form/form_schemas'
import {DefaultSelectOptions} from 'form/form_types'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {EditOrderTemplateHeader} from 'order_template/components/EditOrderTemplateHeader'
import {
  getDataConfigForOrderTemplateOptions,
  getDataConfigForUpdateOrderTemplateName,
} from 'order_template/order_template_helpers'
import {
  ResponseUpdateOrderTemplate,
  TemplateForm,
} from 'order_template/order_template_types'

interface Props {
  templateName: string
  id: string
  onSuccessChangeName: (data: ResponseUpdateOrderTemplate) => void
  onErrorChangeName: (error: AxiosError<ApiError>) => void
}

const EditOrderTemplateComponent: React.FC<Props> = ({
  templateName,
  id,
  onErrorChangeName,
  onSuccessChangeName,
}) => {
  const {t} = useTranslation()

  const {control, handleSubmit, watch} = useForm<TemplateForm>({
    defaultValues: {
      template: templateName,
    },
    resolver: yupResolver(orderTemplateSchema),
  })

  const templateValue = watch('template')

  const configForUpdateOrderTemplateName =
    getDataConfigForUpdateOrderTemplateName({name: templateValue}, id)

  const {mutate} = useApiMutation(
    extractKey({
      method: configForUpdateOrderTemplateName.method,
      url: configForUpdateOrderTemplateName.url,
    }),
    undefined,
    {
      reactMutationOptions: {
        onSuccess: onSuccessChangeName,
        onError: onErrorChangeName,
      },
      axiosConfig: {
        ...configForUpdateOrderTemplateName,
      },
    },
    true
  )

  const handlePressEditButton = useCallback(() => {
    mutate()
  }, [mutate])

  const configForOrderTemplates = getDataConfigForOrderTemplateOptions()

  const configForOrderTemplatesKey = extractKey<string[]>({
    url: configForOrderTemplates.url,
  })
  const {data} = useApiQuery<DefaultSelectOptions[]>(
    configForOrderTemplatesKey,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForOrderTemplates,
      },
    },
    true
  )

  const existsTemplateName = useMemo(() => {
    return data?.some((item) => item.name === templateValue)
  }, [data, templateValue])

  return (
    <View sx={sxStyles.container}>
      <EditOrderTemplateHeader />
      <View sx={sxStyles.changeContent}>
        <View sx={sxStyles.changeInput}>
          <InputController
            control={control}
            id={'template'}
            hideErrorInput
            isColumn
            size={'small'}
          />
        </View>
        <View sx={sxStyles.buttonWrapper}>
          <View sx={sxStyles.button}>
            <Button
              color={'primaryColor500'}
              title={t('button.change')}
              onPress={handleSubmit(handlePressEditButton)}
              appearance={'filled'}
              disabled={
                templateValue === '' ||
                templateValue === templateName ||
                existsTemplateName
              }
              size={'small'}
            />
          </View>
        </View>
      </View>

      <Text variant={'caption2'} sx={sxStyles.existsTemplate}>
        {templateValue !== templateName &&
          existsTemplateName &&
          t('orderTemplate.exists')}
      </Text>

      <Text variant={'caption2'} sx={sxStyles.editTemplateText}>
        {t('orderTemplate.editTemplateText')}
      </Text>
    </View>
  )
}

export const EditOrderTemplate = React.memo(EditOrderTemplateComponent)

const sxStyles: ObjectsOfSxProps = {
  container: {
    minWidth: 280,
  },
  changeContent: {
    flexDirection: ['column', null, 'row'],
    px: 2,
    pb: 1,
  },
  changeInput: {
    flex: [null, null, 1],
  },
  existsTemplate: {
    color: 'secondaryColor400',
    minHeight: [35, null, 20],
    pb: 1,
    px: 2,
  },
  editTemplateText: {
    borderTopWidth: 1,
    borderTopColor: 'grayColor500',
    color: 'grayColor500',
    py: 2,
    px: 2,
  },
  buttonWrapper: {
    pt: 1,
    pb: 1,
    pl: [0, null, 7],
    px: [0, null, 5],
    flex: [null, null, 1],
  },
  button: {
    width: ['100%', null, '60%'],
  },
}
