import {QueryDefaultInput, QueryInput} from '@sincersoft/fe-core'

import {
  ProductDetailScreenTypeAndParams,
  OrderProductBreadcrumbLinkType,
  ProductDetailParentLinkType,
} from './order_types'
import {Api} from 'config/app_config'
import {AuthorizedTabParamList} from 'layout/layout_types'

export const ORDER_DETAIL: QueryDefaultInput = {
  url: Api.order,
  params: {
    populate: 'userShopId, userId, customItems',
  },
}

export const ORDER_ITEM: QueryDefaultInput = {
  url: Api.orderItem,
  params: {
    // where: {price: {'!=': 'null'}},
  },
}

export const ORDER_ITEM_EMPTY: QueryDefaultInput = {
  url: Api.orderItem,
  params: {
    where: {price: {'=': 'null'}},
  },
}

export function getDataConfigForOrderDetail<TData>(
  id?: string | number
): QueryInput<TData> {
  return {
    url: `${ORDER_DETAIL.url}/${id}`,
    params: {
      ...ORDER_DETAIL.params,
    },
  }
}

export function getDataConfigForOrder<TData>(): QueryInput<TData> {
  return {
    url: ORDER_DETAIL.url,
    params: {
      ...ORDER_DETAIL.params,
    },
  }
}

export function getDataConfigForOrderItem<TData>(
  id: string | number
): QueryInput<TData> {
  return {
    ...ORDER_ITEM,
    params: {
      ...ORDER_ITEM.params,
      where: {
        ...ORDER_ITEM.params.where,
        orderId: id,
      },
    },
  }
}

export function getDataConfigForSingleOrderItem<TData>(
  id?: string | number
): QueryInput<TData> {
  return {
    url: `${Api.orderItem}/${id}`,
  }
}

export function getDataConfigForOrderItemEmpty<TData>(
  id: string | number
): QueryInput<TData> {
  return {
    ...ORDER_ITEM_EMPTY,
    params: {
      ...ORDER_ITEM_EMPTY.params,
      where: {
        ...ORDER_ITEM_EMPTY.params.where,
        orderId: id,
      },
    },
  }
}

export function getDataConfigForCreateOrder<TData>(
  data: TData
): QueryInput<TData> {
  return {
    url: Api.createOrder,
    method: 'POST',
    data,
  }
}

export function getDataConfigForUpdateAdminNote<TData>(
  id: number | string,
  data: TData
): QueryInput<TData> {
  return {
    url: `${Api.order}/${id}`,
    method: 'PATCH',
    data,
  }
}

export function getDataConfigForUpdateOrderItem<TData>(
  data: TData
): QueryInput<TData> {
  return {
    url: Api.updateOrderItem,
    method: 'POST',
    data,
  }
}

export function getDataConfigForShipOrder<TData>(
  id: number | string
): QueryInput<TData> {
  return {
    url: `${Api.order}/${id}/${Api.ship}`,
    method: 'POST',
  }
}

export function getDataConfigForUpdateQuickOrderItem<TData>(
  data: TData,
  isNew: boolean
): QueryInput<TData> {
  return {
    url: isNew ? Api.addItemToCart : Api.updateItemInCart,
    method: 'POST',
    data,
  }
}

export function getDataConfigForCanOrder<TData>(): QueryInput<TData> {
  return {
    url: Api.canOrder,
  }
}

export function getDataConfigForRemoveOrder<TData>(
  orderId: number | string
): QueryInput<TData> {
  return {
    url: `${Api.order}/${orderId}`,
    method: 'DELETE',
  }
}

export function getNavigateRouteAndOptions(
  data: any,
  linkType?: OrderProductBreadcrumbLinkType
) {
  let route: keyof AuthorizedTabParamList | undefined
  let options: ProductDetailScreenTypeAndParams | undefined

  switch (linkType) {
    case OrderProductBreadcrumbLinkType.AdminOrderScreen:
      route = 'NavAdminOrderScreen'
      options = {
        screen: 'AdminOrderScreen',
      }
      break
    case OrderProductBreadcrumbLinkType.OrderScreen:
      route = 'NavOrderScreen'
      options = {
        screen: 'OrderScreen',
      }
      break
    case OrderProductBreadcrumbLinkType.AdminOrderDetailScreen:
      route = 'NavAdminOrderScreen'
      options = {
        screen: 'AdminOrderDetailScreen',
        params: {
          id: data && data.id ? data.id : '',
        },
      }
      break
    case OrderProductBreadcrumbLinkType.OrderDetailScreen:
      route = 'NavOrderScreen'
      options = {
        screen: 'OrderDetailScreen',
        params: {
          id: data && data.id ? data.id : '',
        },
      }
      break
  }

  return {route, options}
}

export const getNavigateOptionByLinkType = (
  data: any,
  linkType?: ProductDetailParentLinkType
) => {
  let route: keyof AuthorizedTabParamList | undefined
  let options: ProductDetailScreenTypeAndParams | undefined

  switch (linkType) {
    case ProductDetailParentLinkType.ShopCartScreen:
      route = 'NavShopCartScreen'
      options = {
        screen: 'ShopCartDetailProductScreen',
        params: {
          id: data.productId.id,
        },
      }
      break
    case ProductDetailParentLinkType.QuickOrderScreen:
      route = 'NavNewOrderScreen'
      options = {
        screen: 'NewOrderQuickProductDetailScreen',
        params: {
          id: data.id,
        },
      }
      break
    case ProductDetailParentLinkType.OrderDetailScreen:
      route = 'NavOrderScreen'
      options = {
        screen: 'OrderProductDetailScreen',
        params: {
          productId: data.productId.id,
          orderId: data.orderId.id,
          orderItemId: data.id,
        },
      }
      break
    case ProductDetailParentLinkType.AdminOrderDetailScreen:
      route = 'NavAdminOrderScreen'
      options = {
        screen: 'AdminOrderProductDetailScreen',
        params: {
          productId: data.productId.id,
          orderId: data.orderId.id,
          orderItemId: data.id,
        },
      }
      break
    case ProductDetailParentLinkType.AdminProductDetailScreen:
      route = 'NavProductScreen'
      options = {
        screen: 'ProductDetailScreen',
        params: {
          productId: data.id,
        },
      }
      break
  }

  return {route, options}
}
