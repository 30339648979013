import {yupResolver} from '@hookform/resolvers/yup'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'

import {prepareOptionsFromStringArray} from 'common/data_helpers'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {SelectController} from 'form/components/SelectController'
import {shopCartUnitOfMeasureSchema} from 'form/form_schemas'
import {CellRendererParams} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {
  ADMIN_ORDER_CUSTOM_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
  ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID,
  ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
} from 'order/order_constants'
import {
  getDataConfigForOrderDetail,
  getDataConfigForUpdateOrderItem,
} from 'order/order_helpers'
import {UpdateOrderItemInOrders} from 'order/order_types'
import {UNIT_OF_MEASURE_OPTIONS} from 'shop_cart/shop_cart_constants'
import {getDataConfigForUpdateCustomItem} from 'shop_cart/shop_cart_helpers'
import {useAppDispatch} from 'store/redux_hooks'

interface UnitOfMeasureSelect {
  unitOfMeasure: string
}

interface UnitOfMeasureForm {
  unitOfMeasure: string
}

export const OrderUnitOfMeasureCellRenderer: React.FC<CellRendererParams> = ({
  data,
  colDef,
}) => {
  const queryClient = useQueryClient({context: QueryClientContext})
  const dispatch = useAppDispatch()

  const isCustomItem =
    colDef?.cellRendererParams && colDef.cellRendererParams.isCustomItem
      ? colDef.cellRendererParams?.isCustomItem
      : false

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: {errors},
  } = useForm<UnitOfMeasureForm>({
    resolver: yupResolver(shopCartUnitOfMeasureSchema),
  })

  const handleUpdateItem = (itemValue: UnitOfMeasureSelect) => {
    if (data.unitOfMeasure !== itemValue.unitOfMeasure) {
      mutate()
      dispatch(
        gridSlice.actions.setDataProcessing({
          dataProcessing: true,
          stateId: ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
        })
      )
    }
  }

  useEffect(() => {
    reset({
      unitOfMeasure: data.unitOfMeasure,
    })
  }, [data, reset])

  const handleSuccessUpdate = (response: ResponseSuccess) => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_ORDER_CUSTOM_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.setDataProcessing({
        dataProcessing: false,
        stateId: ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    const dataConfigForOrderDetail = getDataConfigForOrderDetail(
      data.orderId.id
    )
    const orderDetailKey = extractKey({
      params: dataConfigForOrderDetail.params,
      url: dataConfigForOrderDetail.url,
    })
    queryClient.invalidateQueries(orderDetailKey)

    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const configForUpdateOrderItem = isCustomItem
    ? getDataConfigForUpdateCustomItem(data.id, {
        unitOfMeasure: getValues().unitOfMeasure,
      })
    : getDataConfigForUpdateOrderItem<UpdateOrderItemInOrders>({
        id: data.id,
        unitOfMeasure: getValues().unitOfMeasure,
      })

  const {mutate} = useApiMutation(
    extractKey({
      data: configForUpdateOrderItem.data,
      method: configForUpdateOrderItem.method,
      url: configForUpdateOrderItem.url,
    }),
    configForUpdateOrderItem.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdate,
        onError: handleError,
      },
      axiosConfig: {
        ...configForUpdateOrderItem,
      },
    },
    true
  )

  return (
    <View sx={sxStyles.container}>
      <SelectController
        onInputChange={handleSubmit(handleUpdateItem)}
        control={control}
        error={errors.unitOfMeasure}
        name={'unitOfMeasure'}
        displayLabelProperty={'name'}
        idProperty={'name'}
        options={
          isCustomItem
            ? UNIT_OF_MEASURE_OPTIONS
            : prepareOptionsFromStringArray(data?.productId?.unitOptions)
        }
        selectInputStyle={sxStyles.selectInputStyle}
        size={'small'}
      />
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: ['flex-end', null, 'center'],
    maxWidth: [105, null, 85],
  },
  selectInputStyle: {
    maxWidth: [105, null, 85],
    minWidth: [105, null, 85],
  },
}
