import {CompositeScreenProps, useNavigation} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {View, Text} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ProductCategoryGrid} from './ProductCategoryGrid'
import {
  ADMIN_PRODUCT_CATEGORY_INSTANCE_REDUCER_STATE_GRID_ID,
  ADMIN_PRODUCT_CATEGORY_INSTANCE_REDUCER_STATE_ID,
} from '../product_category_constants'
import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {Api} from 'config/app_config'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {
  AuthorizedNavigationProp,
  AuthorizedScreenProps,
  ProductCategoryStackParamList,
} from 'layout/layout_types'

type Props = CompositeScreenProps<
  NativeStackScreenProps<
    ProductCategoryStackParamList,
    'ProductCategoryScreen'
  >,
  AuthorizedScreenProps
>

export const ProductCategoryScreen: React.FC<Props> = () => {
  const {t} = useTranslation()

  useRefetchQuery([
    {key: ADMIN_PRODUCT_CATEGORY_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
  ])

  return (
    <View sx={{flex: 1}}>
      <Header />
      <AuthorizedLayout
        title={t('productCategory.title')}
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbItem label={t('productCategory.title')} isLast />
          </Breadcrumbs>
        }
      >
        <ProductCategoryGrid
          dataServiceUrl={Api.productCategory}
          dataServiceConfig={{
            params: {
              isActive: true,
              populate: false,
            },
          }}
          stateId={ADMIN_PRODUCT_CATEGORY_INSTANCE_REDUCER_STATE_ID}
        />
      </AuthorizedLayout>
    </View>
  )
}
