import {DripsyCustomTheme, ResponsiveValue} from 'dripsy'

import {isAndroid, isIOS} from 'layout/layout_constants'

export function isBottomBarVisible(
  theme: DripsyCustomTheme,
  windowWidth: number
): boolean {
  return (
    isIOS ||
    isAndroid ||
    windowWidth <=
      parseFloat(
        theme.breakpoints &&
          Array.isArray(theme.breakpoints) &&
          theme.breakpoints.length >= 2
          ? theme.breakpoints[1]
          : '768'
      )
  )
}

export function getDeviceBreakpointValue(
  theme: DripsyCustomTheme,
  windowWidth: number,
  value: ResponsiveValue<number | string | null | undefined>
): number | string | null | undefined {
  let idx = 0
  let breakpointValue
  if (value == null || !Array.isArray(value)) {
    breakpointValue = value
  } else {
    // value is not null and it is an array
    for (idx; idx < theme.breakpoints.length; idx++) {
      if (windowWidth < parseFloat(theme.breakpoints[idx])) {
        break
      }
    }
    idx = idx > theme.breakpoints.length ? idx - 1 : idx
    if (idx >= value.length) {
      breakpointValue = value[value.length - 1]
    } else {
      breakpointValue = value[idx]
      while (breakpointValue == null && idx >= 1) {
        breakpointValue = value[idx - 1]
      }
    }
  }
  // TODO: Array.isArray does not consider type `readonly any[]` as an array, suppressed for now
  //   https://github.com/microsoft/TypeScript/issues/17002
  // @ts-ignore
  return breakpointValue
}
