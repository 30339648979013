import {NavigationProp, RouteProp} from '@react-navigation/native'

import {AuthorizedTabParamList} from 'layout/layout_types'

export function isLinkSelected(
  linkRoute: string,
  route: RouteProp<AuthorizedTabParamList>,
  navigation: NavigationProp<AuthorizedTabParamList>
): boolean {
  const navigationState = navigation.getState()
  const parentNavigationState = navigation.getParent()?.getState()
  let currentHeaderLinkScreenName: keyof AuthorizedTabParamList | string =
    route.name
  if (
    navigationState.type === 'stack' &&
    parentNavigationState?.type === 'tab'
  ) {
    currentHeaderLinkScreenName =
      parentNavigationState.routeNames[parentNavigationState.index]
  }
  return currentHeaderLinkScreenName === linkRoute
}
