import {View} from 'dripsy'
import React from 'react'

import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  children: React.ReactNode
  containerStyle?: ObjectsOfSxProps
}

export const Breadcrumbs: React.FC<Props> = ({children, containerStyle}) => {
  const isSmall = useIsNarrowScreen(0)
  const sxStyles = applyStyles(isSmall)
  return !isWeb ? null : (
    <View sx={sxStyles.wrapper}>
      <View sx={{...sxStyles.container, ...containerStyle}}>{children}</View>
    </View>
  )
}

const applyStyles = (isSmall: boolean): ObjectsOfSxProps => ({
  wrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'white',
    pl: 2,
  },
  container: {
    backgroundColor: 'white',
    paddingY: 2,
    flexDirection: 'row',
    width: ['100%', null, null, 950, 1100, 1300],
    height: '100%',
    justifyContent: 'flex-start',
    borderBottomColor: 'grayColor200',
    borderBottomWidth: 1,
    flexWrap: 'wrap',
  },
})
