import {useNavigation} from '@react-navigation/native'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {DripsyCustomTheme, Text, View} from 'dripsy'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ConfirmModal} from 'common/components/ConfirmModal'
import {Icon} from 'common/components/Icon'
import {IconButton} from 'common/components/IconButton'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {setNavigationTimeout} from 'common/navigation_thunks'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {ChangeShopInShopCart} from 'layout/components/ChangeShopInShopCart'
import {MIN_WIDTH_FOR_ACTION_BUTTONS} from 'layout/layout_constants'
import {layoutSlice} from 'layout/layout_slice'
import {AuthorizedNavigationProp, ObjectsOfSxProps} from 'layout/layout_types'
import {SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID} from 'shop_cart/shop_cart_constants'
import {
  getDataConfigForCart,
  getDataConfigForDeleteShopCart,
} from 'shop_cart/shop_cart_helpers'
import {shopCartSlice} from 'shop_cart/shop_cart_slice'
import {useAppDispatch} from 'store/redux_hooks'

interface Props {
  activeShopId?: number
  deleteShopCartWithNavigate?: boolean
  displayChangeShopCart?: boolean
  displayDeleteShopCart?: boolean
  displayGoToShopCart?: boolean
  isRow?: boolean
}

const AuthorizedLayoutDeleteShopCartComponent: React.FC<Props> = ({
  activeShopId,
  deleteShopCartWithNavigate,
  displayDeleteShopCart,
  displayChangeShopCart,
  displayGoToShopCart,
  isRow,
}) => {
  const isSmallDevice = useIsNarrowScreen(1)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const queryClient = useQueryClient({context: QueryClientContext})

  const sxStyles = useThemeSxStyles(applyStyles, isRow)

  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigation = useNavigation<AuthorizedNavigationProp>()
  const configForDeleteShopCart = getDataConfigForDeleteShopCart()

  const configForShopCart = getDataConfigForCart()

  const handleSuccessDelete = async (response: ResponseSuccess) => {
    await queryClient.refetchQueries(
      extractKey({
        url: configForShopCart.url,
      })
    )
    dispatch(shopCartSlice.actions.resetErrorState())
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    await queryClient.invalidateQueries()
    dispatch(layoutSlice.actions.setModalSpinnerHidden())
    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
    if (deleteShopCartWithNavigate) {
      dispatch(
        setNavigationTimeout({
          cb: () => {
            navigation.navigate('NavNewOrderScreen', {
              screen: 'NewOrderScreen',
            })
          },
        })
      )
    }
  }
  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(layoutSlice.actions.setModalSpinnerHidden())
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const {mutate, isLoading} = useApiMutation(
    extractKey({
      data: configForDeleteShopCart.data,
      method: configForDeleteShopCart.method,
      url: configForDeleteShopCart.url,
    }),
    undefined,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessDelete,
        onError: handleError,
      },
      axiosConfig: {
        ...configForDeleteShopCart,
      },
    },
    true
  )

  const handlePressConfirm = () => {
    setConfirmModalVisible(false)
    mutate()
    dispatch(layoutSlice.actions.setModalSpinnerVisible())
  }

  const handlePressCancel = () => {
    setConfirmModalVisible(false)
  }

  const handlePressButton = () => {
    setConfirmModalVisible(true)
  }

  const handlePressGotToShopCart = () => {
    navigation.navigate('NavShopCartScreen', {
      screen: 'ShopCartScreen',
    })
  }

  return (
    <View sx={sxStyles.button}>
      {displayChangeShopCart ? (
        <ChangeShopInShopCart
          isSmallDevice={isSmallDevice}
          activeShopId={activeShopId}
          isInRow={isRow}
        />
      ) : null}
      {displayGoToShopCart ? (
        <View sx={sxStyles.buttonWrapper}>
          <IconButton
            iconName={'shopping-cart'}
            left
            title={isSmallDevice ? undefined : t('button.goToShopCart')}
            onPress={handlePressGotToShopCart}
            color={isSmallDevice ? undefined : 'primaryColor500'}
            appearance={isSmallDevice ? 'ghost' : 'outline'}
            isLoading={isLoading}
            size={isSmallDevice ? 'medium' : 'small'}
            style={isSmallDevice ? styles.button : undefined}
          />
        </View>
      ) : null}
      {displayDeleteShopCart ? (
        isSmallDevice ? (
          <View sx={sxStyles.icon}>
            <Icon
              color={'secondaryColor500'}
              onPress={handlePressButton}
              name={'delete'}
            />
          </View>
        ) : (
          <IconButton
            iconName={'delete'}
            left
            title={t('button.deleteShopCart')}
            onPress={handlePressButton}
            color={'secondaryColor500'}
            status={'danger'}
            appearance={'outline'}
            isLoading={isLoading}
            size={'small'}
          />
        )
      ) : null}
      <ConfirmModal
        modalVisible={confirmModalVisible}
        onPressCancel={handlePressCancel}
        onPressConfirm={handlePressConfirm}
        text={t('shopCart.text.confirmDelete')}
        isLoading={isLoading}
      >
        <Text sx={sxStyles.infoText} variant={'caption'}>
          {t('shopCart.text.textInfo')}
        </Text>
      </ConfirmModal>
    </View>
  )
}

export const AuthorizedLayoutDeleteShopCart = React.memo(
  AuthorizedLayoutDeleteShopCartComponent
)

const styles = {
  button: {
    width: 25,
  },
}

const applyStyles = (
  _theme: DripsyCustomTheme,
  isRow?: boolean
): ObjectsOfSxProps => ({
  button: {
    position: 'absolute',
    right: 10,
    flexDirection: isRow ? 'row' : ['row', null, 'column'],
    paddingTop: 2,
  },
  icon: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'secondaryColor500',
  },
  buttonWrapper: {
    paddingBottom: isRow ? 0 : [0, null, 2],
  },
  deleteButtonWrapper: {
    ...(isRow ? {minWidth: [0, null, MIN_WIDTH_FOR_ACTION_BUTTONS]} : {}),
  },
  infoText: {
    color: 'grayColor500',
  },
})
