import {formatNumber} from '@sincersoft/core'
import React from 'react'

import {ColumnValue} from 'common/components/ColumnValue'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {UnitOfMeasure} from 'new_order/new_order_types'

interface Props {
  value: number
  isSmall?: boolean
  withCurrency?: boolean
  unitOfMeasure?: UnitOfMeasure
  selectedUnitOfMeasure?: UnitOfMeasure
  priceThresholdValue?: number
  packingAmount?: number
}

const ColumnNumberValueComponent: React.FC<Props> = ({value, isSmall}) => {
  const sxStyles = applyStyles(isSmall)

  const formatValue = (): string => {
    let result = '-'
    if (value != null) {
      result = formatNumber(value, 'sk-SK')
    }
    return result
  }

  return (
    <ColumnValue
      value={formatValue()}
      isSmall={isSmall}
      extraStyle={sxStyles.text}
    />
  )
}

export const ColumnNumberValue = React.memo(ColumnNumberValueComponent)

const applyStyles = (isSmall?: boolean): ObjectsOfSxProps => ({
  text: {
    // vertical centering - line-height should be equal to the height of cell
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    ...(isSmall
      ? {}
      : {
          textAlignVertical: 'center',
        }),
  },
})
