import {yupResolver} from '@hookform/resolvers/yup'
import {NativeStackNavigationProp} from '@react-navigation/native-stack'
import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import {View, Text} from 'dripsy'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {NativeSyntheticEvent, TextInputKeyPressEventData} from 'react-native'

import {getDataConfigForDeleteAccount} from './delete_account_helpers'
import {setNavigationTimeout} from 'common/navigation_thunks'
import {ConfirmRegisterButton} from 'confirm_register/components/ConfirmRegisterButton'
import {ApiError, getErrorMessageCode} from 'error/error_helper'
import {InputController} from 'form/components/InputController'
import {EmailSchema} from 'form/form_schemas'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps, RootStackParamList} from 'layout/layout_types'
// import {Notice} from 'common/components/Notice'
import {SHORT_SNACKBAR_DURATION} from 'snackbar/snackbar_constants'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch} from 'store/redux_hooks'

interface Props {
  navigation: NativeStackNavigationProp<
    RootStackParamList,
    'DeleteAccountScreen'
  >
  text?: string
  title: string
  token: string
}

interface DeleteAccountForm {
  email: string
}

export const DeleteAccount: React.FC<Props> = ({
  navigation,
  text,
  title,
  token,
}) => {
  const {t} = useTranslation()

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: {errors},
  } = useForm<DeleteAccountForm>({
    resolver: yupResolver(EmailSchema),
  })
  const dispatch = useAppDispatch()

  const handleSuccess = () => {
    dispatch(
      setNavigationTimeout({
        cb: () => {
          navigation.navigate('LoginScreen')
        },
        timeoutInMs: SHORT_SNACKBAR_DURATION,
      })
    )
    reset()
    dispatch(
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.UNAUTHORIZED,
        isVisible: true,
        data: {
          color: 'success',
          text: 'deleteAccount.success',
        },
      })
    )
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.UNAUTHORIZED,
        isVisible: true,
        data: {
          color: 'error',
          text:
            getErrorMessageCode(error) ??
            'response.error.common.unexpectedError',
        },
      })
    )
  }

  const configForDeleteAccount = getDataConfigForDeleteAccount({
    ...getValues(),
    token,
  })

  const {mutate, /*isError, error,*/ reset, isLoading} = useApiMutation(
    extractKey({
      data: configForDeleteAccount.data,
      method: configForDeleteAccount.method,
      url: configForDeleteAccount.url,
    }),
    configForDeleteAccount.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccess,
        onError: handleError,
      },
      axiosConfig: {
        ...configForDeleteAccount,
      },
    },
    false
  )
  const sxStyles = applyStyles()

  const handlePressButton = () => {
    mutate()
  }

  const handleKeyPress = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    if (e.nativeEvent.key === 'Enter') {
      e.preventDefault()
      trigger('email').then((isWithoutError) => {
        if (isWithoutError) {
          mutate()
        }
      })
    }
  }

  return (
    <View sx={sxStyles.container}>
      <Text sx={sxStyles.title} variant={'h5Bold'}>
        {t(title)}
      </Text>
      {text && (
        <Text sx={sxStyles.text} variant={'body1'}>
          {t(text)}
        </Text>
      )}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <View sx={sxStyles.input} accessibilityRole={isWeb ? 'form' : 'none'}>
        <InputController
          control={control}
          id={'email'}
          label={'register.email'}
          error={errors.email}
          hideErrorInput={!errors.email}
          isColumn
          onKeyPress={handleKeyPress}
          autoCapitalize={'none'}
        />
      </View>
      <ConfirmRegisterButton
        handlePressButton={handleSubmit(handlePressButton)}
        title={'button.deleteAccount'}
        isLoading={isLoading}
        color={'secondaryColor600'}
        buttonStatus={'danger'}
      />
      {/*{isError && !isLoading && (*/}
      {/*  <Notice color={'error'} text={error?.response?.data?.messageCode} />*/}
      {/*)}*/}
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    minHeight: '100%',
    flexDirection: 'column' as const,
    alignItems: 'center',
    alignSelf: 'center',
    paddingTop: 100,
    paddingX: 5,
  },
  input: {
    paddingTop: 4,
    width: 300,
  },
  title: {
    fontSize: [5, null, 6],
    lineHeight: [5, null, 6],
    color: 'primaryColor600',
    textAlign: 'center' as const,
  },
  text: {
    paddingTop: [4, null, 5],
    textAlign: 'center' as const,
  },
})
