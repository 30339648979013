import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {View} from 'dripsy'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {useCheckBox} from 'common/hooks/useCheckBox'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {Api} from 'config/app_config'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {AuthorizedLayoutImportButton} from 'layout/components/AuthorizedLayoutImportButton'
import {
  AuthorizedScreenProps,
  ObjectsOfSxProps,
  ProductStackParamList,
} from 'layout/layout_types'
import {UserRole} from 'login/login_constants'
import {getDataConfigForActiveCategory} from 'new_order/new_order_helpers'
import {CategoryItem} from 'new_order/new_order_types'
import {ImportBatchImages} from 'product/components/ImportBatchImages'
import {ProductGrid} from 'product/components/ProductGrid'
import {
  ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_GRID_ID,
  ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_ID,
} from 'product/product_constants'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

type Props = CompositeScreenProps<
  NativeStackScreenProps<ProductStackParamList, 'ProductScreen'>,
  AuthorizedScreenProps
>

export const ProductScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const userRole = useAppSelector((state) => state.login.loginUser?.role)

  const dataConfigForActiveCategory = getDataConfigForActiveCategory()
  const {data} = useApiQuery<CategoryItem[]>(
    extractKey({
      params: dataConfigForActiveCategory.params,
      url: dataConfigForActiveCategory.url,
    }),
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...dataConfigForActiveCategory,
      },
    },
    true
  )

  const getConfig = useMemo(() => {
    return data ? data.map((item) => ({id: item.id, title: item.name})) : []
  }, [data])

  const {renderCheckBoxes, actualActiveIdx} = useCheckBox(
    getConfig,
    false,
    undefined,
    ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_ID,
    true
  )

  const dataServiceConfig = useMemo(() => {
    return {
      params: {
        populate: 'productCategoryId',
        where: {
          ...(actualActiveIdx && actualActiveIdx.length > 0
            ? {productCategoryId: actualActiveIdx}
            : {}),
          isActive: true,
        },
      },
    }
  }, [actualActiveIdx])

  const handleSuccessUpload = useCallback(() => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
  }, [dispatch])
  useRefetchQuery([
    {key: ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
  ])

  const actionButtons = useCallback(
    () => (
      <AuthorizedLayoutImportButton onSuccessUpload={handleSuccessUpload} />
    ),
    [handleSuccessUpload]
  )

  return (
    <View sx={sxStyles.container}>
      <Header />
      <AuthorizedLayout
        title={t('product.title')}
        actionButtons={actionButtons}
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbItem label={t('product.title')} isLast />
          </Breadcrumbs>
        }
      >
        {userRole === UserRole.SUPER_ADMIN && (
          <ImportBatchImages onSuccessUpload={handleSuccessUpload} />
        )}
        {data ? renderCheckBoxes() : null}
        <ProductGrid
          dataServiceUrl={Api.product}
          dataServiceConfig={dataServiceConfig}
          stateId={ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_ID}
          placeholder={t('product.placeholder')}
          customPagination
        />
      </AuthorizedLayout>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
  },
}
