import {ReducerUtils} from '@sincersoft/core'
import {GRID_REDUCER_STATE_ID, gridSlice} from '@sincersoft/fe-grid'
import {View} from 'dripsy'
import {debounce, isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import Collapsible from 'react-native-collapsible'
import {useDispatch} from 'react-redux'

import {CheckBoxButtons} from 'common/components/CheckBoxButtons'
import {CheckBoxes} from 'common/components/CheckBoxes'
import {CheckBoxConfig, CheckBoxData} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'

type UseRenderCheckBox = () => JSX.Element | null

type UsePaginationReturn = {
  actualActiveIdx?: string[]
  renderCheckBoxes: UseRenderCheckBox
}

interface CheckBoxCheckedData {
  [index: string]: {
    isChecked: boolean
  }
}

export const useCheckBox = (
  config: CheckBoxConfig[],
  isDisabled = false,
  onChangeLoading?: (value: boolean) => void,
  stateId?: string,
  customPagination?: boolean
): UsePaginationReturn => {
  const [activeIds, setActiveIds] = useState<CheckBoxData>({})
  const [tempActiveIds, setTempActiveIds] = useState<CheckBoxCheckedData>({})
  const [isFilterActive, setIsFilterActive] = useState(false)

  const gridStateId = stateId
    ? ReducerUtils.getInstanceReducerId(GRID_REDUCER_STATE_ID, stateId)
    : ''
  const dispatch = useDispatch()

  useEffect(() => {
    const result: CheckBoxData = {}
    config.forEach((item) => {
      result[item.id] = {
        id: item.id,
        title: item.title,
        isChecked: false,
      }
    })
    setActiveIds(result)
  }, [config, setActiveIds])

  const actualActiveIdx = useMemo((): string[] | undefined => {
    let result: string[] = []
    Object.keys(activeIds).forEach((name) => {
      if (activeIds[name] && activeIds[name].isChecked) {
        result = [...result, activeIds[name].id]
      }
    })
    // if (result.length > 0) {
    //   if (!isFilterActive) {
    //     setIsFilterActive(true)
    //   }
    // } else {
    //   if (isFilterActive) {
    //     setIsFilterActive(false)
    //   }
    // }
    return result.length > 0 ? result : undefined
  }, [activeIds])

  const [collapsed, setCollapsed] = useState(true)

  const getUpdatedIds = useCallback(
    (oldIds: CheckBoxData, checkedIds: CheckBoxCheckedData): CheckBoxData => {
      let result = {...oldIds}
      Object.keys(checkedIds).forEach((id) => {
        result = {
          ...result,
          ...{
            [id]: {
              ...oldIds[id],
              isChecked: checkedIds[id].isChecked,
            },
          },
        }
      })
      return result
    },
    []
  )

  const handleChangeLoading = useCallback(
    (value: boolean) => {
      if (onChangeLoading) {
        onChangeLoading(value)
      }
    },
    [onChangeLoading]
  )

  const handleChange = useCallback(
    (ids: CheckBoxCheckedData) => {
      setActiveIds((prevActiveIds) => {
        const updatedIds = getUpdatedIds(prevActiveIds, ids)
        return {
          ...updatedIds,
        }
      })
      handleChangeLoading(false)
      setTempActiveIds({})
    },
    [setTempActiveIds, getUpdatedIds, handleChangeLoading]
  )

  const handleDebouncedCheckBox = useMemo(() => {
    return debounce(handleChange, 1500)
  }, [handleChange])

  useEffect(() => {
    if (!isEmpty(tempActiveIds)) {
      handleChangeLoading(true)
      handleDebouncedCheckBox(tempActiveIds)
    }
  }, [tempActiveIds, handleDebouncedCheckBox, handleChangeLoading])

  const handleChangeCheckBox = useCallback(
    (id: string, value: boolean) => {
      setTempActiveIds((prevTempActiveIds) => ({
        ...prevTempActiveIds,
        ...{
          [id]: {
            ...prevTempActiveIds[id],
            isChecked: value,
          },
        },
      }))
      if (customPagination && gridStateId !== '') {
        dispatch(
          gridSlice.actions.resetGridData({
            customPagination: customPagination,
            stateId: gridStateId,
          })
        )
      }
    },
    [customPagination, dispatch, gridStateId]
  )

  const handleChangeCheckBoxes = useCallback(
    (check: boolean) => {
      const result: CheckBoxCheckedData = {}
      Object.keys(activeIds).forEach((idx) => {
        result[idx] = {
          isChecked: check,
        }
      })
      setTempActiveIds(result)
      handleChangeLoading(true)
      handleDebouncedCheckBox(result)
      dispatch(
        gridSlice.actions.resetGridData({
          customPagination: customPagination,
          stateId: gridStateId,
        })
      )
    },
    [
      activeIds,
      handleChangeLoading,
      handleDebouncedCheckBox,
      dispatch,
      customPagination,
      gridStateId,
    ]
  )

  const handleCheckAllCheckBoxes = useCallback(() => {
    handleChangeCheckBoxes(true)
  }, [handleChangeCheckBoxes])

  const handleUnCheckAllCheckBoxes = useCallback(() => {
    handleChangeCheckBoxes(false)
  }, [handleChangeCheckBoxes])

  const handleSetCollapsed = useCallback(() => {
    setCollapsed((prevCollapsed) => !prevCollapsed)
  }, [setCollapsed])

  const checkBoxData = useMemo(
    () => getUpdatedIds(activeIds, tempActiveIds),
    [activeIds, tempActiveIds, getUpdatedIds]
  )

  const debouncedHandleSetCollapsed = useMemo(
    () => debounce(handleSetCollapsed, 100),
    [handleSetCollapsed]
  )

  const renderCheckBoxes: UseRenderCheckBox = () => {
    return (
      <View sx={sxStyles.container}>
        <CheckBoxButtons
          onPressUnCheckAll={handleUnCheckAllCheckBoxes}
          onPressCheckAll={handleCheckAllCheckBoxes}
          collapsed={collapsed}
          isFilterActive={isFilterActive}
          onPressCollapsed={debouncedHandleSetCollapsed}
        />
        <Collapsible collapsed={collapsed}>
          <CheckBoxes
            checkBoxData={checkBoxData}
            onChange={handleChangeCheckBox}
            isDisabled={isDisabled}
          />
        </Collapsible>
      </View>
    )
  }

  return {
    renderCheckBoxes,
    actualActiveIdx,
  }
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    p: 1,
  },
}
