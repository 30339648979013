import 'regenerator-runtime/runtime'
import 'fastestsmallesttextencoderdecoder-encodeinto'
import 'expo-dev-client'
// import './wdyr'
import * as eva from '@eva-design/eva'
import {
  ApiClient,
  IntlProvider,
  QueryClientContext,
  QueryClientInstance,
} from '@sincersoft/fe-core'
import {StorageDataUtils} from '@sincersoft/native-core'
import {QueryClientProvider} from '@tanstack/react-query'
// import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {ApplicationProvider, IconRegistry} from '@ui-kitten/components'
import {DripsyProvider} from 'dripsy'
import * as Notifications from 'expo-notifications'
import {i18n as i18nType} from 'i18next'
import React, {useEffect, useMemo, useState} from 'react'
import {
  ActivityIndicator,
  LogBox,
  StyleSheet,
  useWindowDimensions,
} from 'react-native'
import {Provider as ReactNativePaperProvider} from 'react-native-paper'
import {SafeAreaProvider} from 'react-native-safe-area-context'
import {Provider} from 'react-redux'

import {UpdateProvider} from 'common/components/UpdateProvider'
import {useLoadFonts} from 'common/hooks/useLoadFonts'
import {AppNavigatorContainer} from 'layout/components/AppNavigatorContainer'
import {i18nInit} from 'localization/i18_configuration'
import skTranslation from 'localization/sk.json'
import {logoutCleanData} from 'login/login_thunks'
import {store} from 'store/redux_store'
import Theme from 'theme/app_theme.json'
import {themeFn} from 'theme/dripsyThemeFactory'
import {materialIconsMapping} from 'theme/material_icons_mapping'
import {UiKittenCustomMapping} from 'theme/ui_kitten_custom_mapping'
import {uikTheme} from 'theme/ui_kitten_theme'

const loadingIndicator = (
  <ActivityIndicator color={Theme.colors.primaryColor500} size={'large'} />
)

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
})

// init api client
ApiClient.initApiClient(store, StorageDataUtils, logoutCleanData)
const CLIENT = QueryClientInstance.getClient({
  defaultOptions: {
    queries: {
      cacheTime: 10,
      staleTime: 30000,
    },
  },
})

export default function App() {
  // ignoring setTimeout warning
  LogBox.ignoreLogs(['Setting a timer'])
  const [isI18nLoaded, setIsI18nLoaded] = useState(false)
  const [i18nConfig, setI18nConfig] = useState<i18nType>()

  // loading fonts
  const isFontLoaded = useLoadFonts()

  useEffect(() => {
    const loadI18Async = async () => {
      const initializedI18n = await i18nInit()
      setI18nConfig(initializedI18n)
      setIsI18nLoaded(true)
    }
    loadI18Async()
  }, [])

  // init theme
  const windowDimensions = useWindowDimensions()
  const appTheme = useMemo(() => themeFn(windowDimensions), [windowDimensions])

  return (
    <DripsyProvider theme={appTheme}>
      <ReactNativePaperProvider>
        <Provider store={store}>
          <QueryClientProvider client={CLIENT} context={QueryClientContext}>
            <IconRegistry icons={[materialIconsMapping]} />
            <ApplicationProvider
              {...eva}
              theme={{...eva.light, ...uikTheme}}
              customMapping={UiKittenCustomMapping}
            >
              <SafeAreaProvider style={styles.container}>
                <IntlProvider
                  isReady={isFontLoaded && isI18nLoaded && !!i18nConfig}
                  loadingIndicator={loadingIndicator}
                  translations={{sk: skTranslation}}
                  i18nConfig={i18nConfig}
                >
                  <UpdateProvider>
                    <AppNavigatorContainer />
                  </UpdateProvider>
                </IntlProvider>
              </SafeAreaProvider>
            </ApplicationProvider>
            {/*<ReactQueryDevtools*/}
            {/*  initialIsOpen={false}*/}
            {/*  context={QueryClientContext}*/}
            {/*/>*/}
          </QueryClientProvider>
        </Provider>
      </ReactNativePaperProvider>
    </DripsyProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
