import {yupResolver} from '@hookform/resolvers/yup'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSx, View} from 'dripsy'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'

import {formatStringToNumber} from 'common/number_helpers'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError} from 'error/error_helper'
import {InputController} from 'form/components/InputController'
import {customItemAmountSchema} from 'form/form_schemas'
import {CellRendererParams} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID} from 'shop_cart/shop_cart_constants'
import {
  getDataConfigForCart,
  getDataConfigForUpdateShopCartItem,
} from 'shop_cart/shop_cart_helpers'
import {shopCartSlice} from 'shop_cart/shop_cart_slice'
import {
  ResponseShopCartItem,
  ShopCartItem,
  UpdateShopCartItem,
} from 'shop_cart/shop_cart_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

export const ShopCartAmountCellRenderer: React.FC<CellRendererParams> = ({
  data,
}) => {
  const queryClient = useQueryClient({context: QueryClientContext})
  const dispatch = useAppDispatch()
  const sx = useSx()

  const gridData: ShopCartItem[] = useAppSelector(
    (state) => state.grid_shopCart.data
  )
  const savedItemInRedux = gridData.find((item) => item?.id === data.id)

  const savedUnitOfMeasure = savedItemInRedux?.unitOfMeasure

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: {errors},
    trigger,
  } = useForm({
    resolver: yupResolver(
      customItemAmountSchema('amount', savedItemInRedux?.unitOfMeasure, true)
    ),
  })
  const amountValue = watch('amount')
  const amountError = errors.amount
  const shopCartItemId = data.id

  useEffect(() => {
    reset({
      amount: data.amount,
    })
  }, [data, reset])

  useEffect(() => {
    if (savedUnitOfMeasure) {
      trigger('amount')
    }
  }, [savedUnitOfMeasure, trigger])

  useEffect(() => {
    if (amountError) {
      dispatch(
        shopCartSlice.actions.setErrorState({
          id: shopCartItemId,
          name: data.name,
        })
      )
    } else {
      dispatch(
        shopCartSlice.actions.deleteErrorState({
          id: shopCartItemId,
        })
      )
    }
  }, [amountError, dispatch, shopCartItemId, data.name])

  const handleSuccessUpdate = (response: ResponseShopCartItem) => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.setDataProcessing({
        dataProcessing: false,
        stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    const configForShopCart = getDataConfigForCart()
    const shopCartKey = extractKey({
      url: configForShopCart.url,
    })
    queryClient.invalidateQueries(shopCartKey)

    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const configForUpdateShopCartItem =
    getDataConfigForUpdateShopCartItem<UpdateShopCartItem>({
      id: data.id,
      amount: formatStringToNumber(amountValue),
    })

  const {mutate} = useApiMutation(
    extractKey({
      data: configForUpdateShopCartItem.data,
      method: configForUpdateShopCartItem.method,
      url: configForUpdateShopCartItem.url,
    }),
    configForUpdateShopCartItem.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdate,
        onError: handleError,
      },
      axiosConfig: {
        ...configForUpdateShopCartItem,
      },
    },
    true
  )

  const handleUpdateItem = useCallback(
    (valueObject, value) => {
      if (formatStringToNumber(value) !== data.amount) {
        mutate()
        dispatch(
          gridSlice.actions.setDataProcessing({
            dataProcessing: true,
            stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
          })
        )
      }
    },
    [mutate, dispatch, data]
  )

  const handleDebounceUpdate = useMemo(
    () => debounce(handleSubmit(handleUpdateItem), 1000),
    [handleSubmit, handleUpdateItem]
  )

  useEffect(() => {
    amountValue && handleDebounceUpdate(amountValue)
  }, [amountValue, handleDebounceUpdate])

  return (
    <View sx={sxStyles.wrapper}>
      <View sx={sxStyles.container}>
        <InputController
          control={control}
          id={'amount'}
          size={'small'}
          maxLength={7}
          textStyle={sx(sxStyles.textStyles)}
          isColumn={false}
          hideErrorInput
          isNumber
          showErrorInputBorder={!!errors.amount}
          error={errors.amount}
          selectTextOnFocus
        />
      </View>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  wrapper: {
    flex: 1,
    alignItems: 'flex-end',
  },
  container: {
    flex: 1,
    alignItems: 'flex-end',
    maxWidth: 110,
  },
  textStyles: {
    maxWidth: '80%',
  },
}
