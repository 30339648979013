import {BottomTabScreenProps} from '@react-navigation/bottom-tabs'
import {CompositeScreenProps} from '@react-navigation/native'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {AdminPriceListDetailScreen} from './AdminPriceListDetailScreen'
import {AdminPriceListScreen} from './AdminPriceListScreen'
import {
  AdminPriceListStackParamList,
  AuthorizedTabParamList,
  RootStackParamList,
} from 'layout/layout_types'
import {AdminPriceListExportScreen} from 'price_list/components/AdminPriceListExportScreen'

const AdminPriceListStack =
  createNativeStackNavigator<AdminPriceListStackParamList>()

type Props = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList, 'NavAdminPriceListScreen'>,
  NativeStackScreenProps<RootStackParamList>
>

export const NavAdminPriceListScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <AdminPriceListStack.Navigator
      initialRouteName={'AdminPriceListScreen'}
      screenOptions={{headerShown: false}}
    >
      <AdminPriceListStack.Screen
        name={'AdminPriceListScreen'}
        component={AdminPriceListScreen}
        options={{title: t('routes.priceList')}}
      />
      <AdminPriceListStack.Screen
        name={'AdminPriceListDetailScreen'}
        component={AdminPriceListDetailScreen}
        options={{title: t('routes.priceList')}}
      />
      <AdminPriceListStack.Screen
        name={'AdminPriceListExportScreen'}
        component={AdminPriceListExportScreen}
        options={{title: t('routes.priceList')}}
      />
    </AdminPriceListStack.Navigator>
  )
}
