import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {useGetProductDetail} from 'common/hooks/useGetProductDetail'
import {
  AuthorizedScreenProps,
  NewOrderStackParamList,
} from 'layout/layout_types'
import {ProductDetailContainer} from 'new_order/components/ProductDetailContainer'

type Props = CompositeScreenProps<
  NativeStackScreenProps<NewOrderStackParamList, 'NewOrderProductDetailScreen'>,
  AuthorizedScreenProps
>

export const NewOrderQuickProductDetailScreen: React.FC<Props> = ({
  route,
  navigation,
}) => {
  const {t} = useTranslation()
  const data = useGetProductDetail(route.params.id)

  return (
    <ProductDetailContainer
      navigation={navigation}
      route={route}
      breadcrumbs={
        <Breadcrumbs>
          <BreadcrumbItem
            label={t('newOrder.fastOrder')}
            onPress={() => {
              navigation.navigate('NavNewOrderScreen', {
                screen: 'NewOrderQuickScreen',
              })
            }}
          />
          <BreadcrumbItem
            label={t('newOrder.productDetail.breadcrumbsSubTitle', {
              product: data?.name,
            })}
            isLast
          />
        </Breadcrumbs>
      }
    />
  )
}
