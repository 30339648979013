import {QueryInputAsArray} from '@sincersoft/fe-core'
import {DripsyCustomTheme, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LabelValue} from 'common/components/LabelValue'
import {getFormattedCurrency} from 'common/data_helpers'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {makeUrlWithQueryParams} from 'common/url_helpers'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {ProductDetailChart} from 'new_order/components/ProductDetailChart'
import {ProductDetailImgAndTitle} from 'new_order/components/ProductDetailImgAndTitle'
import {ProductDetailInfo} from 'new_order/components/ProductDetailInfo'
import {ProductItem} from 'new_order/new_order_types'
import {PriceListsIds} from 'order/order_types'
import {UploadImageButton} from 'product/components/UploadImageButton'
import {PRICE_THRESHOLD_VALUE} from 'product/product_constants'
import {
  getDataConfigForUploadProductImage,
  getUrlAndParamsForProductImage,
} from 'product/product_helpers'
import {getUrlAndParamsForProductCategoryImage} from 'product_category/product_category_helpers'

interface NewOrderProductDetailProps {
  data: ProductItem
  keyForInvalidateQuery?: QueryInputAsArray
  priceListsIdsOfUserFromOrder?: PriceListsIds
  isAdminScreen?: boolean
}

export const ProductDetail: React.FC<NewOrderProductDetailProps> = ({
  data,
  keyForInvalidateQuery,
  priceListsIdsOfUserFromOrder,
  isAdminScreen,
}) => {
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)

  const imageUrlAndParams = data.entityImageId
    ? getUrlAndParamsForProductImage(data.id, data.entityImageVersion)
    : getUrlAndParamsForProductCategoryImage(
        data.productCategoryId.id,
        data.productCategoryId.entityImageVersion
      )
  return (
    <View sx={sxStyles.container}>
      <View sx={sxStyles.content}>
        <ProductDetailImgAndTitle
          title={data.name}
          subTitle={data.productCategoryId.name}
          hasImage={
            data.entityImageId != null ||
            data.productCategoryId.entityImageId != null
          }
          imageUrlWithParams={makeUrlWithQueryParams(
            imageUrlAndParams.url,
            imageUrlAndParams.urlParams
          )}
        />
        <ProductDetailInfo data={data} />
      </View>
      <View sx={sxStyles.contentGraph}>
        <View sx={sxStyles.graph}>
          <ProductDetailChart
            productId={data.id}
            productCode={data.code}
            priceListsIdsOfUserFromOrder={priceListsIdsOfUserFromOrder}
          />
        </View>
        <LabelValue
          label={t(
            isAdminScreen ? 'item.priceWithPriceListS' : 'item.priceForUnitFull'
          )}
          value={getFormattedCurrency(
            data.price ? data.price : 0,
            PRICE_THRESHOLD_VALUE
          )}
          labelStyle={sxStyles.priceLabel}
          valueStyle={sxStyles.priceValue}
          wrapperStyle={sxStyles.priceWrapper}
        />
      </View>
      {isAdminScreen && keyForInvalidateQuery && (
        <View sx={sxStyles.button}>
          <UploadImageButton
            dataConfig={getDataConfigForUploadProductImage(data.id)}
            keyForInvalidateQuery={keyForInvalidateQuery}
          />
        </View>
      )}
    </View>
  )
}

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    borderWidth: [1, null, 2],
    borderColor: 'grayColor300',
    borderRadius: 10,
    marginX: [3, null, 7],
    paddingX: [2, null, 4],
    paddingTop: [3, null, 7],
    marginBottom: 2,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    flexDirection: ['column', null, 'row'],
    marginBottom: 2,
  },
  contentGraph: {
    marginTop: [1, null, 2],
    marginBottom: [1, null, 4],
    flexDirection: ['column', null, 'row'],
    justifyContent: 'flex-end',
  },
  graph: {
    marginY: 2,
    flex: 1,
  },
  priceWrapper: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: ['100%', null, '50%'],
    paddingTop: [3, null, 0],
    alignSelf: 'flex-end',
  },
  priceLabel: {
    ...theme.text.body1Bold,
    color: 'grayColor700',
  },
  priceValue: {
    color: 'secondaryColor600',
    ...theme.text.body1Bold,
  },
  button: {
    alignItems: ['center', null, 'flex-end'],
    paddingBottom: [4, null, 5],
    paddingTop: [3, null, 4],
  },
  infoText: {
    color: 'secondaryColor300',
    textAlign: 'center',
  },
})
