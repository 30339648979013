import {Icon, IconProps} from '@ui-kitten/components'
import {EvaSize} from '@ui-kitten/components/devsupport'
import {View, SxProp} from 'dripsy'
import React, {useCallback} from 'react'
import {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  TouchableWithoutFeedback,
} from 'react-native'

import {UiKInput} from './UiKInput'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface SearchInputProps {
  disabled?: boolean
  isColumn?: boolean
  isPassword?: boolean
  label?: string
  onChangeText?: (data: string) => void
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  onPressDeleteIcon: () => void
  size?: EvaSize
  labelStyle?: SxProp
  wrapperStyle?: SxProp
  placeholder?: string
  value: string
}

export const SearchInput: React.FC<SearchInputProps> = ({
  isColumn,
  isPassword,
  label,
  onKeyPress,
  onChangeText,
  onPressDeleteIcon,
  size,
  labelStyle,
  placeholder,
  value,
  wrapperStyle,
  ...rest
}) => {
  const sxStyles = applyStyles()
  const SearchIcon = useCallback(
    (props: IconProps) => (
      <Icon {...props} name={'search'} pack={'material'} size={18} />
    ),
    []
  )

  const DeleteIcon = useCallback(
    (props: IconProps) => (
      <TouchableWithoutFeedback
        onPress={onPressDeleteIcon}
        accessibilityRole={'button'}
      >
        <View>
          <Icon {...props} name={'clear'} />
        </View>
      </TouchableWithoutFeedback>
    ),
    [onPressDeleteIcon]
  )
  return (
    <View sx={{...sxStyles.container, ...wrapperStyle}}>
      <UiKInput
        // onBlur={onBlur}
        label={label}
        onChangeText={onChangeText}
        onKeyPress={onKeyPress}
        value={value}
        isColumn={isColumn}
        isSecured={isPassword}
        size={size ?? 'medium'}
        labelStyle={labelStyle}
        placeholder={placeholder}
        accessoryRight={value === '' ? SearchIcon : DeleteIcon}
        {...rest}
      />
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    paddingX: [1, null, 3],
    maxWidth: 500,
    width: 'auto',
    justifyContent: 'center',
    alignSelf: 'center',
    paddingBottom: 3,
  },
})
