import {yupResolver} from '@hookform/resolvers/yup'
import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {useOrderTemplateOptions} from 'common/hooks/useOrderTemplateOptions'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError} from 'error/error_helper'
import {orderTemplateSchema} from 'form/form_schemas'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {
  AuthorizedScreenProps,
  NewOrderStackParamList,
  ObjectsOfSxProps,
} from 'layout/layout_types'
import {NewOrderItem} from 'new_order/components/NewOrderItem'
import {NewOrderItemTemplate} from 'new_order/components/NewOrderItemTemplate'
import {NewOrderShopPicker} from 'new_order/components/NewOrderShopPicker'
import {getDataConfigForUseOrderTemplate} from 'order_template/order_template_helpers'
import {
  ResponseOrderTemplate,
  TemplateForm,
} from 'order_template/order_template_types'
import {useAppSelector, useAppDispatch} from 'store/redux_hooks'
import {RootState} from 'store/redux_store'

type Props = CompositeScreenProps<
  NativeStackScreenProps<NewOrderStackParamList, 'NewOrderScreen'>,
  AuthorizedScreenProps
>

export const NewOrderScreen: React.FC<Props> = ({navigation}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient({context: QueryClientContext})
  const activeShop = useAppSelector(
    (state: RootState) => state.login.activeShop
  )

  const {orderTemplateOptions, configForOrderTemplatesKey, isStale} =
    useOrderTemplateOptions()

  const {control, handleSubmit, watch} = useForm<TemplateForm>({
    defaultValues: {
      template: '',
    },
    resolver: yupResolver(orderTemplateSchema),
  })

  const templateValue = watch('template')

  useRefetchQuery([{key: configForOrderTemplatesKey}], isStale)

  const handleSuccessUse = async (deletedData: ResponseOrderTemplate) => {
    await queryClient.invalidateQueries()
    navigation.navigate('NavShopCartScreen', {
      screen: 'ShopCartScreen',
    })
    dispatch(SnackbarHelpers.getSnackBarSuccess(deletedData.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const configForUseOrderTemplate =
    getDataConfigForUseOrderTemplate(templateValue)

  const {mutate} = useApiMutation(
    extractKey({
      method: configForUseOrderTemplate.method,
      url: configForUseOrderTemplate.url,
    }),
    undefined,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUse,
        onError: handleError,
      },
      axiosConfig: {
        ...configForUseOrderTemplate,
      },
    },
    true
  )

  const handlePressOrder = useCallback(() => {
    navigation.navigate('NewOrderCategoryScreen')
  }, [navigation])

  const handlePressFastOrder = useCallback(() => {
    navigation.navigate('NewOrderQuickScreen')
  }, [navigation])

  const handlePressCreateOrderFromTemplate = useCallback(() => {
    mutate()
  }, [mutate])

  return (
    <View sx={sxStyles.container}>
      <Header />
      <AuthorizedLayout
        title={t('newOrder.title')}
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbItem label={t('newOrder.order')} isLast />
          </Breadcrumbs>
        }
      >
        <NewOrderShopPicker />
        <View sx={sxStyles.newOrderPicker}>
          <NewOrderItem
            onPress={handlePressOrder}
            title={t('newOrder.order')}
            iconName={'category'}
            isDisabled={!activeShop}
          />
          <NewOrderItem
            onPress={handlePressFastOrder}
            title={t('newOrder.fastOrder')}
            iconName={'speed'}
            isDisabled={!activeShop}
          />
          {orderTemplateOptions && orderTemplateOptions.length > 0 ? (
            <NewOrderItemTemplate
              control={control}
              onPress={handleSubmit(handlePressCreateOrderFromTemplate)}
              options={orderTemplateOptions}
              isDisabled={!activeShop || templateValue === '' || !templateValue}
              isDisabledAll={!activeShop}
            />
          ) : null}
        </View>
      </AuthorizedLayout>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
  },
  newOrderPicker: {
    mx: 3,
    flexDirection: ['column', 'row'],
    justifyContent: 'center',
    paddingTop: [3, null, 7],
    flexWrap: 'wrap',
  },
}
