import {View} from 'dripsy'
import React from 'react'

import {FilterButton} from 'common/components/FilterButton'
import {ActiveFilterIds, ConfigFilterButtons} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  activeFilterIds: ActiveFilterIds
  onActiveFilter: (id: string) => void
  config: ConfigFilterButtons[]
}

export const FilterButtons: React.FC<Props> = (props) => {
  const {onActiveFilter, config, activeFilterIds} = props

  const sxStyles = getSxStyles()

  return (
    <View sx={sxStyles.container}>
      {config.map((item) => (
        <FilterButton
          key={item.id}
          activeColor={item.activeColor}
          color={item.color}
          isActive={
            activeFilterIds && activeFilterIds[item.id]
              ? activeFilterIds[item.id].isActive
              : false
          }
          id={item.id}
          onPress={onActiveFilter}
          title={item.title}
        />
      ))}
    </View>
  )
}

const getSxStyles = (): ObjectsOfSxProps => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    px: 1,
  },
})
