import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {useMemo} from 'react'

import {DefaultSelectOptions} from 'form/form_types'
import {getDataConfigForOrderTemplateOptions} from 'order_template/order_template_helpers'

export interface OrderTemplateOptionsResult {
  orderTemplateOptions: DefaultSelectOptions[]
  isStale: boolean
  configForOrderTemplatesKey: string[]
}

export const useOrderTemplateOptions = (): OrderTemplateOptionsResult => {
  const configForOrderTemplates = getDataConfigForOrderTemplateOptions()

  const configForOrderTemplatesKey = extractKey<string[]>({
    url: configForOrderTemplates.url,
  })
  const {data, isStale} = useApiQuery<DefaultSelectOptions[]>(
    configForOrderTemplatesKey,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForOrderTemplates,
      },
    },
    true
  )

  const resultData = useMemo(() => {
    return data ?? []
  }, [data])

  return {
    orderTemplateOptions: resultData,
    isStale,
    configForOrderTemplatesKey,
  }
}
