import {ReducerUtils} from '@sincersoft/core'
import {GRID_REDUCER_STATE_ID, gridSlice} from '@sincersoft/fe-grid'
import {debounce} from 'lodash'
import {useCallback, useMemo, useState} from 'react'

import {useAppDispatch} from 'store/redux_hooks'

type UseSearchValueHandleChangeSearchValue = (value: string) => void
type UseSearchValueHandlePressDeleteIcon = () => void

export type UseSearchValueReturn = {
  searchValue: string
  resultSearchValue: string
  handleChangeSearchValue: UseSearchValueHandleChangeSearchValue
  handlePressDeleteIcon: UseSearchValueHandlePressDeleteIcon
}

export const useSearchValue = ({
  stateId,
  customPagination,
}: {
  stateId?: string
  customPagination?: boolean
}): UseSearchValueReturn => {
  const [searchValue, setSearchValue] = useState('')
  const [resultSearchValue, setResultSearchValue] = useState('')

  const dispatch = useAppDispatch()
  const gridStateId = stateId
    ? ReducerUtils.getInstanceReducerId(GRID_REDUCER_STATE_ID, stateId)
    : ''
  const handleChangeResultSearchValue = (value: string) => {
    setResultSearchValue(value)
  }

  const resultSearchValueDebounce = useMemo(
    () => debounce(handleChangeResultSearchValue, 600),
    []
  )

  const handleRemoveNativeGridData = useCallback(() => {
    if (customPagination && gridStateId !== '') {
      dispatch(
        gridSlice.actions.resetGridData({
          customPagination: customPagination,
          stateId: gridStateId,
        })
      )
    }
  }, [customPagination, dispatch, gridStateId])

  const removeNativeGridDataDebounce = useMemo(
    () => debounce(handleRemoveNativeGridData, 600),
    [handleRemoveNativeGridData]
  )

  const handleChangeSearchValue = (value: string) => {
    setSearchValue(value)
    resultSearchValueDebounce(value)
    removeNativeGridDataDebounce()
  }
  const handlePressDeleteIcon = () => {
    setSearchValue('')
    setResultSearchValue('')
  }

  return {
    handleChangeSearchValue,
    handlePressDeleteIcon,
    searchValue,
    resultSearchValue,
  }
}
