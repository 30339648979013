import {useNavigation} from '@react-navigation/native'
import React from 'react'

import {Link} from 'common/components/Link'
import {AuthorizedNavigationProp} from 'layout/layout_types'
import {PressableStateProps} from 'theme/style_helper'

interface Props {
  id: string | number
  disabled?: boolean
  children(props: PressableStateProps): JSX.Element
}

export const UserLink: React.FC<Props> = (props) => {
  const {disabled, id, children} = props
  const navigation = useNavigation<AuthorizedNavigationProp>()
  return (
    <Link
      disabled={disabled}
      onPress={() => {
        navigation.navigate('NavUserScreen', {
          screen: 'UserDetailScreen',
          params: {id},
        })
      }}
    >
      {children}
    </Link>
  )
}
