import {createAsyncThunk} from '@reduxjs/toolkit'

import {
  NAVIGATION_TOKEN,
  REMOVE_NAVIGATION_TIMEOUT,
  SET_NAVIGATION_TIMEOUT,
} from 'common/navigation_constants'
import {layoutSlice} from 'layout/layout_slice'
import {SNACKBAR_DURATION} from 'snackbar/snackbar_constants'

export interface SetNavigationTimeoutPayload {
  cb: () => void
  timeoutInMs?: number
}

export interface RemoveNavigationTimeoutPayload {
  timeoutId: number
}

export const setNavigationTimeout = createAsyncThunk(
  NAVIGATION_TOKEN + SET_NAVIGATION_TIMEOUT,
  async (payload: SetNavigationTimeoutPayload, {dispatch}) => {
    const timeoutID = setTimeout(() => {
      payload.cb()
    }, payload.timeoutInMs ?? SNACKBAR_DURATION)
    dispatch(layoutSlice.actions.setTimeoutId(timeoutID))
  }
)

export const removeNavigationTimeout = createAsyncThunk(
  NAVIGATION_TOKEN + REMOVE_NAVIGATION_TIMEOUT,
  async (payload: RemoveNavigationTimeoutPayload, {dispatch}) => {
    clearTimeout(payload.timeoutId)
    dispatch(layoutSlice.actions.removeTimeoutId())
    dispatch(layoutSlice.actions.disableTimeout())
  }
)
