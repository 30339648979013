import {CurrencyFormatOptions, formatCurrency} from '@sincersoft/core'
import {QueryDefaultInput, QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'
import {
  ButtonOptions,
  DataForChart,
  ProductItem,
} from 'new_order/new_order_types'
import {OrderItem} from 'order/order_types'

export const PARAMS_ACTIVE_WITH_CATEGORY = {
  params: {
    isActive: true,
    populate: 'productCategoryId',
  },
}

export const DATA_CONFIG_ACTIVE_PRODUCTS = {
  url: Api.product,
  ...PARAMS_ACTIVE_WITH_CATEGORY,
}

export const DATA_CONFIG_ACTIVE_CATEGORY = {
  url: Api.productCategory,
  params: {
    ...PARAMS_ACTIVE_WITH_CATEGORY.params,
    sort: 'code ASC',
    populate: false,
  },
}

export function getDataConfigForActiveProducts<
  TData
>(): QueryDefaultInput<TData> {
  return DATA_CONFIG_ACTIVE_PRODUCTS
}

export function getDataConfigForActiveCategory<
  TData
>(): QueryDefaultInput<TData> {
  return DATA_CONFIG_ACTIVE_CATEGORY
}

export function getDataConfigForActiveCategoryById<TData>(
  id: string | number
): QueryDefaultInput<TData> {
  return {
    ...DATA_CONFIG_ACTIVE_CATEGORY,
    url: `${DATA_CONFIG_ACTIVE_CATEGORY.url}/${id}`,
  }
}

export function getDataConfigForActiveProductsByCategoryId<TData>(
  productCategoryId: string | number,
  sort: string
): QueryDefaultInput<TData> {
  return {
    ...DATA_CONFIG_ACTIVE_PRODUCTS,
    params: {
      ...DATA_CONFIG_ACTIVE_PRODUCTS.params,
      populate: false,
      productCategoryId,
      sort,
    },
  }
}

export function getDataConfigForProductDetail<TData>(
  productId: string | number
): QueryDefaultInput<TData> {
  return {
    url: `${Api.productDetail}/${productId}`,
  }
}

export function getDataConfigForUserShops<TData>(
  userId?: string | number
): QueryDefaultInput<TData> {
  return {
    url: Api.userShop,
    params: {userId, isActive: true, populate: false},
  }
}

export function getDataConfigForSettingActiveShop<TData>(
  userShopId?: string | number
): QueryInput<TData> {
  return {
    url: Api.updateCart,
    method: 'PATCH',
    params: {userShopId},
  }
}

export function getDataConfigForSaveOrEditUserShop<TData>(
  isEdit: boolean,
  data: TData,
  userId: string | number | undefined,
  shopId: string | number | null
): QueryInput<TData> {
  return {
    url: `${Api.userShop}/${shopId}`,
    method: 'PATCH',
    data: {...data, userId},
  }
}

export function getDataConfigForDeleteUserShop<TData>(
  shopId: number
): QueryInput<TData> {
  return {
    url: `${Api.userShop}/${shopId}`,
    method: 'DELETE',
  }
}

export function getDataConfigForAddingItemToCart<TData>(
  data: TData,
  isUpdate: boolean
): QueryInput<TData> {
  return {
    url: isUpdate ? Api.updateItemInCart : Api.addItemToCart,
    method: 'POST',
    data,
  }
}

export function getDataConfigForAddItemsToCart<TData>(
  data: TData
): QueryInput<TData> {
  return {
    url: Api.addItemsToCart,
    method: 'POST',
    data,
  }
}

export function getTimestamps() {
  const resetHoursToday = new Date().setHours(0, 0, 0, 0)
  const date = new Date(resetHoursToday)
  const currentDate = date.getTime()
  const lastMonth = date.setMonth(date.getMonth() - 1)
  const lastYear = date.setFullYear(date.getFullYear() - 1)
  const lastThreeYears = date.setFullYear(date.getFullYear() - 3)

  return {currentDate, lastMonth, lastYear, lastThreeYears}
}

export const getStartingPointFromDataOrButton = (
  selectedButton: ButtonOptions,
  dataStartDate: number | undefined
) => {
  const {lastMonth, lastYear, lastThreeYears} = getTimestamps()

  const buttonOptionsData = {
    lastMonth: lastMonth,
    lastYear: lastYear,
    lastThreeYears: lastThreeYears,
    all: 0,
  }

  return Math.max(buttonOptionsData[selectedButton], dataStartDate ?? 0)
}

export function getRangeForLabelsFromStartingPoint(
  currentStartingPoint: number | undefined
) {
  const {lastMonth, lastYear, lastThreeYears, currentDate} = getTimestamps()
  let range = ButtonOptions.all

  if (currentStartingPoint) {
    if (
      lastMonth <= currentStartingPoint &&
      currentStartingPoint <= currentDate
    ) {
      range = ButtonOptions.lastMonth
    } else if (
      lastYear <= currentStartingPoint &&
      currentStartingPoint <= lastMonth
    ) {
      range = ButtonOptions.lastYear
    } else if (
      lastThreeYears <= currentStartingPoint &&
      currentStartingPoint <= lastYear
    ) {
      range = ButtonOptions.lastThreeYears
    }
  }
  return range
}

export function enhanceDataWithStartingPoint(
  data: DataForChart[] | undefined,
  startingPoint: number | undefined
): DataForChart[] | undefined {
  if (!data || !startingPoint) {
    return data
  }

  // Filter out points before the starting point
  const filteredData = data.filter((point) => point.x >= startingPoint)
  // Sort the data array based on x values
  filteredData.sort((a, b) => a.x - b.x)

  // If there's no non-null y-value at the starting point, find the nearest point with a non-null y-value
  const closestPoint = filteredData.find((point) => point.y !== null)
  const closestYValue = closestPoint ? closestPoint.y : null

  // Check if the starting point already exists in the array
  const startingPointExists = filteredData.some(
    (point) => point.x === startingPoint
  )

  // Add starting point with the y-value of the nearest point only if it doesn't already exist
  return startingPointExists
    ? filteredData
    : [{x: startingPoint, y: closestYValue}, ...filteredData]
}

export const formatDate = (
  date: Date,
  buttonOption: ButtonOptions,
  locale: Intl.LocalesArgument
) => {
  const FORMAT_DATE_OPTIONS: Record<ButtonOptions, Intl.DateTimeFormatOptions> =
    {
      all: {
        month: 'long',
        year: 'numeric',
      },
      lastMonth: {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      },
      lastYear: {
        month: 'long',
        year: 'numeric',
      },
      lastThreeYears: {
        month: 'long',
        year: 'numeric',
      },
    }
  return date.toLocaleDateString(locale, FORMAT_DATE_OPTIONS[buttonOption])
}

export function formatTooltipLabels(
  datum: {x: number; y: number},
  t: any,
  locale: CurrencyFormatOptions['locale']
) {
  const formattedPrice = formatCurrency(datum.y, 'EUR', {
    locale: locale,
    style: 'currency',
    maximumFractionDigits: 2,
  })

  const formattedDate = new Date(datum.x).toLocaleString(locale, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

  return datum.y == null
    ? ''
    : `${t('chart.labelPrice')} ${formattedPrice} \n${t(
        'chart.labelDate'
      )} ${formattedDate}`
}

export function formatAxisLabel(x: number) {
  return formatCurrency(x, 'EUR', {
    locale: 'sk-SK',
    style: 'currency',
    maximumFractionDigits: 2,
  })
}

export const transformOrderItemDataForProductDetail = (
  data: OrderItem
): ProductItem => ({
  ...data?.productId,
  ...(data?.price ? {price: data.price} : {}),
})
