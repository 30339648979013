import {BottomTabScreenProps} from '@react-navigation/bottom-tabs'
import {CompositeScreenProps} from '@react-navigation/native'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {NewOrderCategoryScreen} from './NewOrderCategoryScreen'
import {NewOrderProductScreen} from './NewOrderProductScreen'
import {NewOrderQuickScreen} from './NewOrderQuickScreen'
import {NewOrderScreen} from './NewOrderScreen'
import {
  AuthorizedTabParamList,
  NewOrderStackParamList,
  RootStackParamList,
} from 'layout/layout_types'
import {NewOrderProductDetailScreen} from 'new_order/components/NewOrderProductDetailScreen'
import {NewOrderQuickProductDetailScreen} from 'new_order/components/NewOrderQuickProductDetailScreen'

const NewOrderStack = createNativeStackNavigator<NewOrderStackParamList>()

type Props = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList, 'NavNewOrderScreen'>,
  NativeStackScreenProps<RootStackParamList>
>

export const NavNewOrderScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <NewOrderStack.Navigator
      initialRouteName={'NewOrderScreen'}
      screenOptions={{headerShown: false}}
    >
      <NewOrderStack.Screen
        name={'NewOrderScreen'}
        component={NewOrderScreen}
        options={{title: t('newOrder.title')}}
      />
      <NewOrderStack.Screen
        name={'NewOrderQuickScreen'}
        component={NewOrderQuickScreen}
        options={{title: t('newOrder.title')}}
      />
      <NewOrderStack.Screen
        name={'NewOrderQuickProductDetailScreen'}
        component={NewOrderQuickProductDetailScreen}
        options={{title: t('newOrder.title')}}
      />
      <NewOrderStack.Screen
        name={'NewOrderCategoryScreen'}
        component={NewOrderCategoryScreen}
        options={{title: t('newOrder.title')}}
      />
      <NewOrderStack.Screen
        name={'NewOrderProductScreen'}
        component={NewOrderProductScreen}
        options={{title: t('newOrder.title')}}
      />
      <NewOrderStack.Screen
        name={'NewOrderProductDetailScreen'}
        component={NewOrderProductDetailScreen}
        options={{title: t('newOrder.title')}}
      />
    </NewOrderStack.Navigator>
  )
}
