import {
  extractKey,
  QueryClientContext,
  useApiMutation,
  useApiQuery,
} from '@sincersoft/fe-core'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React, {useEffect, useMemo, useState} from 'react'

import {NewOrderShopItem} from './NewOrderShopItem'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {loginSlice} from 'login/login_slice'
import {
  getDataConfigForSettingActiveShop,
  getDataConfigForUserShops,
} from 'new_order/new_order_helpers'
import {getDataConfigForCart} from 'shop_cart/shop_cart_helpers'
import {ShopCart} from 'shop_cart/shop_cart_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'
import {Shop} from 'user/user_types'

export const NewOrderShopPicker: React.FC = () => {
  const dispatch = useAppDispatch()
  const userId = useAppSelector((state) => state.login.loginUser?.id)
  const activeShop = useAppSelector((state) => state.login.activeShop)
  const isSetShop = useAppSelector((state) => state.login.isSetShop)
  const [loadingShop, setLoadingShop] = useState<boolean>(false)

  const configForUserShops = getDataConfigForUserShops(userId)
  const configForSettingActiveShop = getDataConfigForSettingActiveShop(
    activeShop?.id
  )
  const queryClient = useQueryClient({context: QueryClientContext})
  const handleSuccessSetShop = (response: ResponseSuccess) => {
    setLoadingShop(false)
    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
    const queryShopCartKey = extractKey({
      url: configForShopCart.url,
    })
    queryClient.invalidateQueries(queryShopCartKey)
  }
  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const {mutate} = useApiMutation(
    extractKey({
      data: configForSettingActiveShop.data,
      url: configForSettingActiveShop.url,
      method: configForSettingActiveShop.method,
      params: configForSettingActiveShop.params,
    }),
    undefined,
    {
      reactMutationOptions: {
        onError: handleError,
        onSuccess: handleSuccessSetShop,
      },
      axiosConfig: {
        ...configForSettingActiveShop,
      },
    },
    true
  )
  const configForShopCart = getDataConfigForCart()
  const {data: dataShopCart} = useApiQuery<ShopCart>(
    extractKey({
      url: configForShopCart.url,
    }),
    undefined,
    {
      axiosConfig: {
        ...configForShopCart,
      },
    },
    true
  )

  const {data, isSuccess} = useApiQuery<Shop[]>(
    extractKey({
      params: configForUserShops.params,
      url: configForUserShops.url,
    }),
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForUserShops,
      },
    },
    true
  )

  useEffect(() => {
    if (activeShop && data && data.length > 1 && isSetShop) {
      mutate()
    }
  }, [activeShop, data, mutate, isSetShop])

  useEffect(() => {
    if (data && dataShopCart) {
      const indexShop = data.findIndex(
        (item) => item.id === dataShopCart.userShopId.id
      )
      if (indexShop >= 0 && data[indexShop]) {
        dispatch(
          loginSlice.actions.setActiveShop({
            shop: data[indexShop],
          })
        )
      } else {
        const indexPrimaryShop = data.findIndex((item) => item.isPrimary)
        dispatch(
          loginSlice.actions.setActiveShop({
            shop: data[indexPrimaryShop],
          })
        )
      }
    }
  }, [data, isSuccess, dataShopCart, dispatch])

  const primaryUserShop = useMemo(
    () => data?.filter((item) => item.isPrimary),
    [data]
  )

  return primaryUserShop && primaryUserShop.length > 0 ? (
    <View sx={sxStyles.container}>
      {primaryUserShop.map((shop, idx) => (
        <NewOrderShopItem
          key={shop.id}
          city={shop.city}
          shop={shop.name}
          street={shop.street}
          id={idx}
          isActive={activeShop?.id === shop.id}
        />
      ))}
    </View>
  ) : null
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}
