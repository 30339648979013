/**
 * Example value formatter, just for demonstrating tha syntax.
 *
 * IMPORTANT!!! value formatter definition file cannot import any other files!
 * If you need some external modules use cellRenderer instead.
 *
 * @author juraj.mosko
 * @since 31.01.2020
 */
import {ValueFormatterParams} from 'ag-grid-community'

export default function exampleValueFormatter(
  params: ValueFormatterParams
): string {
  // just adds some prefix and suffix text for defined value
  return params.value ? `prefix- ${params.value} -suffix` : params.value
}
