import {View} from 'dripsy'
import React from 'react'

import adminOrderDetailGridConfig from '../admin_order_detail_grid_config.json'
import {getDataConfigForOrderItem} from '../order_helpers'
import userOrderDetailGridConfig from '../user_order_detail_grid_config.json'
import {Api} from 'config/app_config'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {OrderDetailGrid} from 'order/components/OrderDetailGrid'
import {
  USER_ORDER_ITEM_INSTANCE_REDUCER_STATE_ID,
  ADMIN_USER_ORDER_ITEM_INSTANCE_REDUCER_STATE_ID,
} from 'order/order_constants'

interface Props {
  orderId: number | string
  isAdmin: boolean
}

const OrderDetailItemsComponent: React.FC<Props> = ({isAdmin, orderId}) => {
  return (
    <>
      <View sx={sxStyles.gridWrapper}>
        <OrderDetailGrid
          dataServiceUrl={Api.orderItem}
          dataServiceConfig={getDataConfigForOrderItem(orderId)}
          gridConfig={
            isAdmin ? adminOrderDetailGridConfig : userOrderDetailGridConfig
          }
          stateId={
            isAdmin
              ? ADMIN_USER_ORDER_ITEM_INSTANCE_REDUCER_STATE_ID
              : USER_ORDER_ITEM_INSTANCE_REDUCER_STATE_ID
          }
          hasSearchInput={false}
          customPagination
        />
      </View>
    </>
  )
}

export const OrderDetailItems = React.memo(OrderDetailItemsComponent)

const sxStyles: ObjectsOfSxProps = {
  gridWrapper: {
    marginY: [5],
  },
}
