import {View} from 'dripsy'
import React from 'react'
import {TouchableOpacity} from 'react-native'

import {ModalableImage} from 'common/components/ModalableImage'
import {makeUrlWithQueryParams} from 'common/url_helpers'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {NewOrderProductListItemTitle} from 'new_order/components/NewOrderProductListItemTitle'
import {ProductItem} from 'new_order/new_order_types'
import {getUrlAndParamsForProductImage} from 'product/product_helpers'
import {getUrlAndParamsForProductCategoryImage} from 'product_category/product_category_helpers'

interface NewOrderProductListItemHeaderProps {
  hasImage: boolean
  hasOwnImage: boolean
  itemId: ProductItem['id']
  itemName: ProductItem['name']
  itemImageVersion: ProductItem['entityImageVersion']
  onPressItem: () => void
}

const NewOrderProductListItemHeaderComponent: React.FC<
  NewOrderProductListItemHeaderProps
> = ({
  hasImage,
  hasOwnImage,
  itemId,
  itemName,
  itemImageVersion,
  onPressItem,
}) => {
  const sxStyles = applyStyles(hasImage)

  const imageUrlAndParams = hasOwnImage
    ? getUrlAndParamsForProductImage(itemId, itemImageVersion)
    : getUrlAndParamsForProductCategoryImage(itemId, itemImageVersion)

  return (
    <View sx={sxStyles.imgWrapper}>
      <ModalableImage
        source={
          hasImage
            ? {
                uri: makeUrlWithQueryParams(
                  imageUrlAndParams.url,
                  imageUrlAndParams.urlParams
                ),
              }
            : require('assets/default.jpg')
        }
        defaultSource={require('assets/default.jpg')}
        imageStyle={sxStyles.image}
        onPress={onPressItem}
        disableModal
      />
      <View sx={sxStyles.titleWithImg}>
        <TouchableOpacity onPress={onPressItem} accessibilityRole={'button'}>
          <NewOrderProductListItemTitle name={itemName} />
        </TouchableOpacity>
      </View>
    </View>
  )
}

export const NewOrderProductListItemHeader = React.memo(
  NewOrderProductListItemHeaderComponent
)

const applyStyles = (hasImage?: boolean): ObjectsOfSxProps => ({
  image: {
    height: [35, null, 150],
    width: [35, null, 150],
  },
  imgWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingY: [1, null, 3],
    flexDirection: 'row',
    opacity: hasImage ? 1 : 0.6,
  },
  titleWithImg: {
    width: ['65%'],
    display: ['flex', null, 'none'],
    alignSelf: 'center',
  },
})
