import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiMutation, useApiQuery} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {OrderTemplateGrid} from './OrderTemplateGrid'
import {
  ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_GRID_ID,
  ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_ID,
} from '../order_template_constants'
import userGridConfig from '../order_template_list_grid.json'
import {ExistsOrderTemplateModal} from 'common/components/ExistsOrderTemplateModal'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {Api} from 'config/app_config'
import {ApiError} from 'error/error_helper'
import {orderTemplateSchema} from 'form/form_schemas'
import {DefaultSelectOptions} from 'form/form_types'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {AuthorizedLayoutImportTemplateButton} from 'layout/components/AuthorizedLayoutImportTemplateButton'
import {
  AuthorizedScreenProps,
  ObjectsOfSxProps,
  OrderTemplateStackParamList,
} from 'layout/layout_types'
import {
  getDataConfigForImportOrderTemplateFromData,
  getDataConfigForOrderTemplateOptions,
} from 'order_template/order_template_helpers'
import {TemplateForm} from 'order_template/order_template_types'
import {useAppDispatch} from 'store/redux_hooks'

type Props = CompositeScreenProps<
  NativeStackScreenProps<
    OrderTemplateStackParamList,
    'OrderTemplateListScreen'
  >,
  AuthorizedScreenProps
>

export const OrderTemplateListScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  useRefetchQuery([
    {key: ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
  ])

  const {
    control,
    reset,
    formState: {errors},
    handleSubmit,
    watch,
  } = useForm<TemplateForm>({
    defaultValues: {
      template: '',
    },
    resolver: yupResolver(orderTemplateSchema),
  })

  const [dataForImport, setDataForImport] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    if (dataForImport) {
      reset({
        template: dataForImport?.name,
      })
    }
  }, [dataForImport, reset])

  const templateValue = watch('template')

  const handleSuccess = () => {
    setIsModalVisible(false)
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const configForImportOrderTemplateFromData =
    getDataConfigForImportOrderTemplateFromData({
      templateName: templateValue,
      templateData: dataForImport,
    })

  const {mutate} = useApiMutation(
    extractKey({
      method: configForImportOrderTemplateFromData.method,
      url: configForImportOrderTemplateFromData.url,
      data: configForImportOrderTemplateFromData.data,
    }),
    configForImportOrderTemplateFromData.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccess,
        onError: handleError,
      },
      axiosConfig: {
        ...configForImportOrderTemplateFromData,
      },
    },
    true
  )

  const handleSuccessImportTemplate = useCallback(
    (response: any) => {
      if (!response.isCreated) {
        setDataForImport(response.loadedData)
        setIsModalVisible(true)
      } else {
        dispatch(
          gridSlice.actions.setRefreshNeeded({
            refreshNeeded: true,
            stateId: ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_GRID_ID,
          })
        )
      }
    },
    [dispatch]
  )

  const shopCartActionButtons = useCallback(
    () => (
      <AuthorizedLayoutImportTemplateButton
        onSuccessImport={handleSuccessImportTemplate}
      />
    ),
    [handleSuccessImportTemplate]
  )

  const handlePressSave = useCallback(() => {
    mutate()
  }, [mutate])

  const handlePressCancel = useCallback(() => {
    setIsModalVisible(false)
  }, [])

  const configForOrderTemplates = getDataConfigForOrderTemplateOptions()

  const configForOrderTemplatesKey = extractKey<string[]>({
    url: configForOrderTemplates.url,
  })
  const {data} = useApiQuery<DefaultSelectOptions[]>(
    configForOrderTemplatesKey,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForOrderTemplates,
      },
    },
    true
  )

  const isCreatedMode = useMemo(() => {
    let isCreate = false
    if (data && templateValue) {
      isCreate = !data.some((item) => item.name === templateValue)
      if (isCreate && dataForImport) {
        isCreate = templateValue !== dataForImport?.name
      }
    } else {
      isCreate = templateValue !== dataForImport?.name
    }
    return isCreate
  }, [data, templateValue, dataForImport])

  return (
    <View sx={sxStyles.container}>
      <Header />
      <AuthorizedLayout
        title={t('orderTemplate.title')}
        actionButtons={shopCartActionButtons}
      >
        <OrderTemplateGrid
          dataServiceUrl={Api.orderTemplate}
          stateId={ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_ID}
          gridConfig={userGridConfig}
          hasSearchInput={false}
        />
        <ExistsOrderTemplateModal
          errors={errors}
          control={control}
          modalVisible={isModalVisible && !!dataForImport}
          onPressCancel={handlePressCancel}
          onPressConfirm={handleSubmit(handlePressSave)}
          isCreate={isCreatedMode}
        />
      </AuthorizedLayout>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
  },
  button: {
    paddingTop: [2, null, 4],
    alignSelf: 'center',
  },
}
