import {LinkingOptions} from '@react-navigation/native'
import {createURL} from 'expo-linking'

import {RootStackParamList} from 'layout/layout_types'

const prefixes = [createURL('/')]

const adminScreens = {
  NavProductCategoryScreen: {
    screens: {
      ProductCategoryScreen: 'kategorie-produktov',
      ProductCategoryDetailScreen: 'kategoria-produktov/:id',
    },
  },
  NavProductScreen: {
    screens: {
      ProductScreen: 'admin-produkty',
      ProductDetailScreen: 'admin-produkt/:productId',
    },
  },
  NavUserScreen: {
    screens: {
      UserScreen: 'admin-zakaznici',
      UserDetailScreen: 'admin-zakaznik/:id',
    },
  },
  NavAdminPriceListScreen: {
    screens: {
      AdminPriceListScreen: 'admin-cenniky',
      AdminPriceListDetailScreen: 'admin-cennik/:id',
      AdminPriceListExportScreen: 'admin-cenniky/exportovať',
    },
  },
  NavAdminOrderScreen: {
    screens: {
      AdminOrderScreen: 'admin-objednavky',
      AdminOrderDetailScreen: 'admin-objednavka/:id',
      AdminOrderProductDetailScreen:
        'admin-objednavka/:orderId/produkt/:orderItemId',
    },
  },
}

const clientScreens = {
  NavNewOrderScreen: {
    screens: {
      NewOrderScreen: 'nova-objednavka',
      NewOrderQuickScreen: 'rychla-objednavka',
      NewOrderQuickProductDetailScreen: 'rychla-objednavka/:id',
      NewOrderCategoryScreen: 'objednavka-kategorie',
      NewOrderProductScreen: 'objednavka-produkty',
      NewOrderProductDetailScreen: 'objednavka-produkt/:id',
    },
  },
  NavPriceListScreen: {
    screens: {
      PriceListScreen: 'cennik',
    },
  },
  NavShopCartScreen: {
    screens: {
      ShopCartScreen: 'kosik',
      ShopCartDetailProductScreen: 'kosik/:id',
    },
  },
  NavOrderScreen: {
    screens: {
      OrderScreen: 'objednavky',
      OrderDetailScreen: 'objednavka/:id',
      OrderProductDetailScreen: 'objednavka/:orderId/produkt/:orderItemId',
    },
  },
  NavOrderTemplateListScreen: {
    screens: {
      OrderTemplateListScreen: 'sablony',
    },
  },
}

export const LinkingConfig: LinkingOptions<RootStackParamList> = {
  prefixes: prefixes,
  config: {
    /* configuration for matching screens with paths */
    screens: {
      NavAuthorizedHomeScreen: {
        screens: {
          NavProfileScreen: {
            screens: {
              ProfileScreen: 'profil',
              ChangePasswordScreen: 'zmena-hesla',
            },
          },
          NavReportScreen: {
            screens: {
              ReportScreen: 'nahlasit-chybu',
            },
          },
          ...adminScreens,
          ...clientScreens,
        },
      },
      LoginScreen: 'prihlasenie',
      RegisterScreen: 'registracia',
      RequestRegisterScreen: 'odoslana-registracia',
      ConfirmRegisterScreen: 'user/confirm',
      ForgottenPasswordScreen: 'zabudnute-heslo',
      DeleteAccountScreen: 'user/delete-account',
      DeleteAccountRequestScreen: 'user/delete-account-request',
      ResetPasswordScreen: 'user/reset-password',
    },
  },
}
