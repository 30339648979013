import {getReducers} from '@sincersoft/fe-grid'
import {AnyAction, combineReducers} from '@reduxjs/toolkit'

import {layoutSlice} from 'layout/layout_slice'
import {loginSlice} from 'login/login_slice'
import {QUICK_PRODUCT_INSTANCE_REDUCER_STATE_ID} from 'new_order/new_order_constants'
import {newOrderSlice} from 'new_order/new_order_slice'
import {
  ADMIN_ORDER_INSTANCE_REDUCER_STATE_ID,
  ADMIN_USER_ORDER_ITEM_EMPTY_INSTANCE_REDUCER_STATE_ID,
  ADMIN_USER_ORDER_ITEM_INSTANCE_REDUCER_STATE_ID,
  USER_ORDER_INSTANCE_REDUCER_STATE_ID,
  USER_ORDER_ITEM_EMPTY_INSTANCE_REDUCER_STATE_ID,
  USER_ORDER_ITEM_INSTANCE_REDUCER_STATE_ID,
  ADMIN_USER_ORDER_CUSTOM_ITEMS_INSTANCE_REDUCER_STATE_ID,
  USER_ORDER_CUSTOM_ITEMS_INSTANCE_REDUCER_STATE_ID,
} from 'order/order_constants'
import {ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_ID} from 'order_template/order_template_constants'
import {
  ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_ID,
  USER_PRICE_LIST_INSTANCE_REDUCER_STATE_ID,
} from 'price_list/price_list_constants'
import {ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_ID} from 'product/product_constants'
import {ADMIN_PRODUCT_CATEGORY_INSTANCE_REDUCER_STATE_ID} from 'product_category/product_category_constants'
import {
  SHOP_CART_INSTANCE_REDUCER_STATE_ID,
  SHOP_CART_EMPTY_INSTANCE_REDUCER_STATE_ID,
} from 'shop_cart/shop_cart_constants'
import {shopCartSlice} from 'shop_cart/shop_cart_slice'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {
  ADMIN_USER_INSTANCE_REDUCER_STATE_ID,
  USER_SHOP_INSTANCE_REDUCER_STATE_ID,
} from 'user/user_constants'
import {userSlice} from 'user/user_slice'
import {versionSlice} from 'web_version/version_slice'

export const rootReducer = {
  layout: layoutSlice.reducer,
  login: loginSlice.reducer,
  newOrder: newOrderSlice.reducer,
  user: userSlice.reducer,
  snackbar: snackbarSlice.reducer,
  shopCart: shopCartSlice.reducer,
  version: versionSlice.reducer,
  ...getReducers(ADMIN_USER_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(USER_SHOP_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(USER_PRICE_LIST_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(ADMIN_PRODUCT_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(ADMIN_PRODUCT_CATEGORY_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(ADMIN_ORDER_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(ADMIN_USER_ORDER_ITEM_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(ADMIN_USER_ORDER_CUSTOM_ITEMS_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(USER_ORDER_CUSTOM_ITEMS_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(ADMIN_USER_ORDER_ITEM_EMPTY_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(USER_ORDER_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(USER_ORDER_ITEM_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(USER_ORDER_ITEM_EMPTY_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(SHOP_CART_EMPTY_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(SHOP_CART_INSTANCE_REDUCER_STATE_ID),
  ...getReducers(QUICK_PRODUCT_INSTANCE_REDUCER_STATE_ID),
} as const

const combinedReducer = combineReducers(rootReducer)

const initialState = combinedReducer(undefined, {} as never)

// Proxy reducer, that wraps all application reducers and reset them when on logout
export const reducerResetOnLogoutProxy = (state: any, action: AnyAction) => {
  // check if action is logout action
  if (action.type === loginSlice.actions.setLoggedOut.type) {
    // reset state
    return combinedReducer(
      {
        ...initialState,
        // do not reset some part of state
        // (resetting layout.isInitialized will trigger autologin and that is unwanted here)
        layout: {
          ...initialState.layout,
          isInitialized: state.layout.isInitialized,
        },
      },
      action
    )
  }
  // propagate action to unchanged state
  return combinedReducer(state, action)
}
