import {DripsyCustomTheme, Text, useSx, View, Image} from 'dripsy'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TouchableOpacity} from 'react-native'

import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {CategoryItem} from 'new_order/new_order_types'
// import {useLoadImageSource} from 'common/hooks/useLoadImageSource'

// import {isWeb} from 'layout/layout_constants'

interface NewOrderCategoryListItem {
  imageUrlWithParams: string
  hasImage: boolean
  name: CategoryItem['name']
  onPress: () => void
}

export const NewOrderCategoryListItem: React.FC<NewOrderCategoryListItem> = ({
  imageUrlWithParams,
  hasImage,
  name,
  onPress,
}) => {
  const {t} = useTranslation()
  const [isImageLoadError, setIsImageLoadError] = useState(false)
  const sxStyles = useThemeSxStyles(applyStyles, hasImage)
  const sx = useSx()

  const handleError = () => {
    setIsImageLoadError(true)
  }

  return (
    <View sx={sxStyles.container}>
      <TouchableOpacity
        style={sx(sxStyles.wrapper)}
        onPress={onPress}
        accessibilityLabel={t('accessibility.categoryItem.label')}
        accessibilityHint={t('accessibility.categoryItem.hint')}
      >
        <View sx={sxStyles.imgWrapper}>
          <Image
            source={
              !isImageLoadError && hasImage
                ? {uri: imageUrlWithParams}
                : require('assets/default.jpg')
            }
            onError={handleError}
            sx={sxStyles.image}
            resizeMode={'contain'}
          />
          {/*{isWeb ? (
            <Image
              source={require('assets/default.jpg')}
              sx={sxStyles.image}
              resizeMode={'contain'}
            />
          ) : (
            <Image
              source={image ?? require('assets/default.jpg')}
              defaultSource={require('assets/default.jpg')}
              sx={sxStyles.image}
              resizeMode={'contain'}
            />
          )}*/}
        </View>
        <View sx={sxStyles.textWrapper}>
          <Text sx={sxStyles.title}>{name}</Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}

const applyStyles = (
  theme: DripsyCustomTheme,
  hasImage: boolean
): ObjectsOfSxProps => ({
  container: {
    flexDirection: 'column',
    marginX: [1, null, 2],
    marginY: [1, null, 2],
    borderWidth: 2,
    borderColor: 'grayColor300',
    borderRadius: 7,
    maxWidth: ['46%', '31%', 204],
    minWidth: ['46%', '31%', 204],
  },
  wrapper: {
    flex: 1,
  },
  image: {
    //flex: 1, TODO: check on native if still works
    height: [100, null, 150],
    width: [100, null, 150],
  },
  imgWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingY: [3],
    opacity: hasImage ? 1 : 0.6,
  },
  textWrapper: {
    flex: 1,
    borderTopWidth: 1,
    borderColor: 'grayColor300',
    minHeight: [60],
    justifyContent: 'center',
  },
  title: {
    paddingX: [1, null, null, 2],
    paddingY: [1],
    color: 'primaryColor400',
    textAlign: 'center',
    fontFamily: theme.text.body1Bold.fontFamily,
    fontSize: [
      theme.text.captionBold.fontSize,
      null,
      theme.text.body1Bold.fontSize,
    ],
    letterSpacing: [
      theme.text.captionBold.letterSpacing,
      theme.text.body1Bold.letterSpacing,
    ],
  },
})
