import {Modal} from '@ui-kitten/components'
import {View, Text, DripsyCustomTheme} from 'dripsy'
import React from 'react'
import {Control, FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {IconButton} from 'common/components/IconButton'
import {ModalContentWrapper} from 'common/components/ModalContentWrapper'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {InputController} from 'form/components/InputController'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {EditOrderTemplateHeader} from 'order_template/components/EditOrderTemplateHeader'

interface Props {
  isCreate: boolean
  modalVisible: boolean
  onPressCancel: () => void
  onPressConfirm: () => void
  control: Control<any>
  errors: FieldErrors
}

export const ExistsOrderTemplateModal: React.FC<Props> = ({
  isCreate,
  modalVisible,
  onPressCancel,
  onPressConfirm,
  control,
  errors,
}) => {
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)

  return (
    <Modal
      visible={modalVisible}
      backdropStyle={styles.backdrop}
      onBackdropPress={onPressCancel}
      style={styles.container}
    >
      <ModalContentWrapper
        withKeyboardAwareScroll
        withoutWrapperScreenKeyboardAwareAScroll
      >
        <View sx={sxStyles.container}>
          <EditOrderTemplateHeader />
          <View sx={sxStyles.changeInput}>
            <InputController
              control={control}
              id={'template'}
              isColumn
              size={'small'}
              error={errors.template}
              hideErrorInput={!errors.template}
            />
          </View>

          <View sx={sxStyles.existsTemplateWrapper}>
            <Text sx={sxStyles.existsTemplate}>
              {isCreate ? '' : t('orderTemplate.updateOld')}
            </Text>
          </View>
          <View sx={sxStyles.buttons}>
            <IconButton
              iconName={'arrow-back'}
              left
              title={t('button.cancel')}
              onPress={onPressCancel}
              color={'primaryColor500'}
              appearance={'outline'}
              size={'small'}
            />
            <IconButton
              iconName={'check'}
              left
              title={
                isCreate
                  ? t('button.createTemplate')
                  : t('button.updateTemplate')
              }
              onPress={onPressConfirm}
              color={'primaryColor500'}
              size={'small'}
            />
          </View>
        </View>
      </ModalContentWrapper>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 300,
    maxWidth: 300,
    minHeight: 192,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
})

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  container: {
    alignSelf: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 15,
    maxWidth: [300, 320, 500],
    padding: 2,
    marginY: 2,
    width: '100%',
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    mt: 3,
  },
  label: {
    ...theme.text.captionBold,
  },
  changeInput: {},
  existsTemplateWrapper: {
    my: 2,
    mx: 3,
  },
  existsTemplate: {
    color: 'secondaryColor300',
    textAlign: 'center',
  },
})
