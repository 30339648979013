import {AxiosRequestConfig} from 'axios'
import React from 'react'

import {Grid} from 'grid/components/Grid'

interface Props {
  dataServiceConfig?: AxiosRequestConfig
  dataServiceUrl: string
  gridConfig: any
  stateId: string
  onPressItem?: (id: string) => void
}

export const ShopCartGrid: React.FC<Props> = (props) => {
  return (
    <Grid
      dataServiceUrl={props.dataServiceUrl}
      dataServiceConfig={props.dataServiceConfig}
      plainGridConfig={props.gridConfig}
      stateId={props.stateId}
      onPressItem={props.onPressItem}
      showResetSortButton={false}
    />
  )
}
