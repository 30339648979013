import {TFunction} from 'i18next'
import {isEmpty} from 'lodash'
import {FieldErrors} from 'react-hook-form'

import {ErrorStateString} from 'shop_cart/shop_cart_types'

export interface ColumnWithRowNames {
  [key: string]: string[]
}

const VALIDATION_SEPARATOR = '-'

export function formatColumnNameFromId(id: string): string {
  const itemNameSplit = id.split(VALIDATION_SEPARATOR)
  return itemNameSplit[itemNameSplit.length - 1]
}

export function formatTranslatedColumns(
  t: TFunction,
  columnNames: string[],
  prefixTranslation: string,
  columnsWithRowNames?: ColumnWithRowNames
): string {
  const translatedColumns = columnNames.map((item) => {
    const formattedColumnsWithRowNames =
      !isEmpty(columnsWithRowNames) && columnsWithRowNames[item]
        ? columnsWithRowNames[item].join(', ')
        : null
    return (
      t(`${prefixTranslation}.${item}`) +
      `${
        formattedColumnsWithRowNames ? ` (${formattedColumnsWithRowNames})` : ''
      }`
    )
  })
  return translatedColumns.join(', ')
}

export function transformFormErrorsToErrorState(
  formErrors: FieldErrors
): ErrorStateString {
  let result: ErrorStateString = {}
  Object.keys(formErrors).forEach((item) => {
    if (!isEmpty(formErrors[item]?.ref)) {
      result = {...result, [item]: ''}
    }
  })
  return result
}

// format to ID for forms
export function formatToFormItemId(prefix: string | number, sufix: string) {
  return `${prefix}` + VALIDATION_SEPARATOR + sufix
}

export function checkIfExists(data: string[], name: string) {
  return data.findIndex((cName) => cName === name)
}
