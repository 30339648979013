import {View} from 'dripsy'
import React, {useEffect} from 'react'

import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  children: React.ReactNode
  onAllRendered?: () => void
  isLastRendered?: boolean
}

export const NewOrderList: React.FC<Props> = ({
  children,
  onAllRendered,
  isLastRendered,
}) => {
  const sxStyles = applyStyles()
  useEffect(() => {
    if (onAllRendered && isLastRendered) {
      onAllRendered()
    }
  }, [onAllRendered, isLastRendered])
  return <View sx={sxStyles.container}>{children}</View>
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    // justifyContent: 'center' as const,
  },
})
