import {registerRootComponent} from 'expo'
import {createElement} from 'react'
import {createRoot} from 'react-dom/client'
import {Platform} from 'react-native'

import App from './App'

// fixed for Warning: ReactDOM.render is no longer supported in React 18.
// Use createRoot instead. Until you switch to the new API, your app will behave as if it's running React 17
if ('web' === Platform.OS) {
  const rootTag = createRoot(
    document.getElementById('root') ?? document.getElementById('main')
  )
  rootTag.render(createElement(App))
} else {
  // registerRootComponent calls AppRegistry.registerComponent('main', () => App);
  // It also ensures that whether you load the app in Expo Go or in a native build,
  // the environment is set up appropriately
  registerRootComponent(App)
}
