import {Shop} from 'user/user_types'

export default function userShopCompanyComparator(
  valueA: Shop[],
  valueB: Shop[]
) {
  const userShopA = valueA.find((item: Shop) => item.isPrimary)?.company
  const userShopB = valueB.find((item: Shop) => item.isPrimary)?.company

  return userShopA === userShopB ? 0 : userShopA > userShopB ? 1 : -1
}
