import {QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'

export const CONFIRM_REGISTER: QueryInput = {
  url: Api.confirmRegister,
}

export function getDataConfigForConfirmRegisterEmail<TData>(
  url: string,
  data: TData
): QueryInput<TData> {
  return {
    method: 'POST',
    url,
    data,
  }
}

export function getDataConfigForConfirmRegister<TData>(
  data: TData
): QueryInput<TData> {
  return {
    ...CONFIRM_REGISTER,
    method: 'POST',
    data,
  }
}
