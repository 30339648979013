import {QueryInput, QueryInputAsArray} from '@sincersoft/fe-core'
import {View} from 'dripsy'
import React from 'react'

import {ImagePicker} from 'common/components/ImagePicker'
import {ObjectsOfSxProps} from 'layout/layout_types'

type Props = {
  dataConfig: QueryInput
  keyForInvalidateQuery?: QueryInputAsArray
}

export const UploadImageButton: React.FC<Props> = (props) => {
  return (
    <View sx={sxStyles.button}>
      <ImagePicker
        buttonText={'button.loadImg'}
        dataConfig={props.dataConfig}
        keyForInvalidateQuery={props.keyForInvalidateQuery}
      />
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  button: {
    alignSelf: 'center',
  },
}
