import {CompositeScreenProps, useNavigation} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {DetailSkeleton} from 'common/components/DetailSkeleton'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {ContentLayout} from 'layout/components/ContentLayout'
import {
  AuthorizedNavigationProp,
  AuthorizedScreenProps,
  ProductCategoryStackParamList,
} from 'layout/layout_types'
import {CategoryItem} from 'new_order/new_order_types'
import {ProductCategoryDetail} from 'product_category/components/ProductCategoryDetail'
import {getDataConfigForActiveCategoryById} from 'product_category/product_category_helpers'

type Props = CompositeScreenProps<
  NativeStackScreenProps<
    ProductCategoryStackParamList,
    'ProductCategoryDetailScreen'
  >,
  AuthorizedScreenProps
>

export const ProductCategoryDetailScreen: React.FC<Props> = ({route}) => {
  const dataConfigForActiveCategory = getDataConfigForActiveCategoryById(
    route.params.id
  )

  const {t} = useTranslation()
  const navigation = useNavigation<AuthorizedNavigationProp>()

  const keyForQuery = extractKey({
    params: dataConfigForActiveCategory.params,
    url: dataConfigForActiveCategory.url,
  })

  const {data, isLoading, isStale} = useApiQuery<CategoryItem>(
    keyForQuery,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...dataConfigForActiveCategory,
      },
    },
    true
  )

  useRefetchQuery([{key: keyForQuery}], isStale)

  return (
    <View sx={{flex: 1}}>
      <Header hasBackButton />
      <AuthorizedLayout
        title={'productCategory.detail.title'}
        titleValue={{category: data && data.name}}
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbItem
              label={t('productCategory.title')}
              onPress={() => {
                navigation.navigate('NavProductCategoryScreen', {
                  screen: 'ProductCategoryScreen',
                })
              }}
            />
            <BreadcrumbItem
              label={t('productCategory.detail.breadcrumbsTitle', {
                category: data?.name,
              })}
              isLast
            />
          </Breadcrumbs>
        }
      >
        <ContentLayout>
          {data ? (
            <ProductCategoryDetail
              data={data}
              keyForInvalidateQuery={keyForQuery}
            />
          ) : null}
          {!data && isLoading ? <DetailSkeleton /> : null}
        </ContentLayout>
      </AuthorizedLayout>
    </View>
  )
}
