import React from 'react'

import {ColumnValue} from 'common/components/ColumnValue'
import {CellRendererParams} from 'grid/grid_types'

export const TranslateCellRenderer: React.FC<CellRendererParams> = ({
  value,
  colDef,
  isSmall,
}) => {
  const {prefix}: {prefix: string} = colDef?.cellRendererParams
    ? colDef.cellRendererParams
    : null

  return (
    <ColumnValue value={`${prefix}.${value}`} isSmall={isSmall} isTranslate />
  )
}
