import {IconProps, Icon} from '@ui-kitten/components'
import {EvaSize} from '@ui-kitten/components/devsupport'
import {useDripsyTheme, View} from 'dripsy'
import React from 'react'
import {
  Controller,
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form'
import {NativeSyntheticEvent, TextInputKeyPressEventData} from 'react-native'

import {DisabledLabelValue} from 'common/components/DisabledLabelValue'
import {InputErrorMessage} from 'common/components/InputErrorMessage'
import {UiKInput, UiKInputProps} from 'common/components/UiKInput'
import {
  formatNumberToString,
  stringToNumberWithDecimalSeparator,
} from 'common/number_helpers'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface InputControllerProps extends UiKInputProps {
  control: Control<any>
  error?: FieldError | Merge<FieldError, FieldErrorsImpl>
  id: string
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  hideErrorInput?: boolean
  showErrorInputBorder?: boolean
  size?: EvaSize
  isCurrency?: boolean
  isNumber?: boolean
  isDisabledInput?: boolean
  isSelectionActivated?: boolean
}

const InputControllerComponent: React.FC<InputControllerProps> = ({
  control,
  error,
  id,
  onKeyPress,
  hideErrorInput = false,
  size,
  isNumber,
  isCurrency,
  isDisabledInput,
  selectTextOnFocus,
  isSelectionActivated,
  ...restProps
}) => {
  const dripsyTheme = useDripsyTheme()
  const sxStyles = applyStyles()

  const renderCurrency = (props: IconProps) => (
    <Icon
      {...props}
      style={[
        props.style,
        {
          width: dripsyTheme.theme.text.body2.fontSize,
          height: dripsyTheme.theme.text.body2.fontSize,
        },
      ]}
      name={'euro'}
      pack={'material'}
    />
  )

  //TODO: focus on input
  return (
    <View sx={sxStyles.container}>
      <Controller
        control={control}
        render={({field: {onChange, /*onBlur,*/ value}}) => {
          const formatValue =
            isNumber && typeof value !== 'string'
              ? formatNumberToString(value, {
                  // set max maximumFractionDigits to 2 for "isNumber" input
                  currency: 'EUR',
                  maximumFractionDigits: 2,
                  ...(isCurrency ? {minimumFractionDigits: 2} : {}),
                })
              : value
          const onChangeText = (changeValue: string) => {
            const newValue = isNumber
              ? stringToNumberWithDecimalSeparator(changeValue)
              : changeValue
            onChange(newValue)
          }
          return restProps.disabled ? (
            <DisabledLabelValue
              {...restProps}
              label={restProps.label ?? ''}
              value={value}
            />
          ) : (
            <UiKInput
              // onBlur={onBlur}
              onChangeText={onChangeText}
              onKeyPress={onKeyPress}
              value={formatValue?.toString()}
              selection={
                // fixed problem on native devices after focus
                isSelectionActivated
                  ? {start: 0, end: formatValue?.toString().length}
                  : undefined
              }
              selectTextOnFocus={selectTextOnFocus}
              size={size ?? 'medium'}
              isNumber={isNumber}
              disabled={isDisabledInput}
              {...(isCurrency ? {accessoryRight: renderCurrency} : {})}
              {...restProps}
            />
          )
        }}
        name={id}
        defaultValue={''}
      />
      {hideErrorInput ? null : <InputErrorMessage error={error} />}
    </View>
  )
}

export const InputController = React.memo(InputControllerComponent)

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    width: '100%',
    justifyContent: 'center',
  },
})
