import {Modal} from '@ui-kitten/components'
import {useDripsyTheme, View} from 'dripsy'
import React from 'react'
import {StyleSheet, ActivityIndicator, useWindowDimensions} from 'react-native'

import {ObjectsOfSxProps} from 'layout/layout_types'
import {useAppSelector} from 'store/redux_hooks'

export const SpinnerModal: React.FC = () => {
  const {theme} = useDripsyTheme()
  const {width, height} = useWindowDimensions()
  const sxStyles = applyStyles(width, height)
  const isModalSpinnerVisible = useAppSelector(
    (state) => state.layout.isModalSpinnerVisible
  )

  // TODO: error with Hermes on IOS
  return (
    <Modal visible={isModalSpinnerVisible} backdropStyle={styles.backdrop}>
      <View sx={sxStyles.spinner}>
        <ActivityIndicator size={150} color={theme.colors.primaryColor400} />
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: 'rgba(255, 255, 255, 0.55)',
  },
})

const applyStyles = (width: number, height: number): ObjectsOfSxProps => ({
  spinner: {
    height: height,
    width: width,
    justifyContent: 'center',
  },
})
