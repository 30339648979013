import {View, DripsyCustomTheme} from 'dripsy'
import React from 'react'

import {BreadcrumbItemContent} from './BreadcrumbItemContent'
import {Link} from 'common/components/Link'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  itemStyle?: ObjectsOfSxProps
  label: string
  isLast?: boolean
  onPress?: () => void
}

export const BreadcrumbItem: React.FC<Props> = ({
  itemStyle,
  label,
  isLast,
  onPress,
}) => {
  const sxStyles = useThemeSxStyles(applyStyles)

  return (
    <View sx={{...sxStyles.itemContainer, ...itemStyle}}>
      {onPress ? (
        <Link disabled={isLast} onPress={onPress}>
          {(pressableProps) => {
            return (
              <BreadcrumbItemContent
                pressableProps={pressableProps}
                label={label}
              />
            )
          }}
        </Link>
      ) : (
        <BreadcrumbItemContent label={label} isLast={isLast} />
      )}
    </View>
  )
}

const applyStyles = (_theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
