import {QueryDefaultInput, QueryInput} from '@sincersoft/fe-core'
import {Platform} from 'react-native'

import {Api} from 'config/app_config'

export const LOGIN: QueryDefaultInput = {
  url: Api.login,
}

export function getDataConfigForLogin<TData>(data: TData): QueryInput<TData> {
  return {
    ...LOGIN,
    method: 'POST',
    data,
  }
}

export function refreshApp() {
  if (Platform.OS === 'web') {
    window.location.reload()
  }
}
