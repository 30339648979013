import {yupResolver} from '@hookform/resolvers/yup'
import {
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'

import {prepareOptionsFromStringArray} from 'common/data_helpers'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError} from 'error/error_helper'
import {SelectController} from 'form/components/SelectController'
import {shopCartUnitOfMeasureSchema} from 'form/form_schemas'
import {CellRendererParams} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID} from 'shop_cart/shop_cart_constants'
import {
  getDataConfigForCart,
  getDataConfigForUpdateShopCartItem,
} from 'shop_cart/shop_cart_helpers'
import {
  ResponseShopCartItem,
  UpdateShopCartItem,
} from 'shop_cart/shop_cart_types'
import {useAppDispatch} from 'store/redux_hooks'

interface ShopCartUnitOfMeasureForm {
  unitOfMeasure: string
}

export const ShopCartUnitOfMeasureCellRenderer: React.FC<
  CellRendererParams
> = ({data}) => {
  const queryClient = useQueryClient({context: QueryClientContext})
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: {errors},
  } = useForm<ShopCartUnitOfMeasureForm>({
    resolver: yupResolver(shopCartUnitOfMeasureSchema),
  })

  useEffect(() => {
    reset({
      unitOfMeasure: data.unitOfMeasure,
    })
  }, [data, reset])

  const handleSuccessUpdate = (response: ResponseShopCartItem) => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.setDataProcessing({
        dataProcessing: false,
        stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    const configForShopCart = getDataConfigForCart()
    const shopCartKey = extractKey({
      url: configForShopCart.url,
    })
    queryClient.invalidateQueries(shopCartKey)

    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const configForUpdateShopCartItem =
    getDataConfigForUpdateShopCartItem<UpdateShopCartItem>({
      id: data.id,
      unitOfMeasure: getValues().unitOfMeasure,
    })

  const {mutate} = useApiMutation(
    extractKey({
      data: configForUpdateShopCartItem.data,
      method: configForUpdateShopCartItem.method,
      url: configForUpdateShopCartItem.url,
    }),
    configForUpdateShopCartItem.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUpdate,
        onError: handleError,
      },
      axiosConfig: {
        ...configForUpdateShopCartItem,
      },
    },
    true
  )

  const handleUpdateItem = useCallback(
    (valObject, value) => {
      if (value !== data.unitOfMeasure) {
        mutate()
        dispatch(
          gridSlice.actions.setDataProcessing({
            dataProcessing: true,
            stateId: SHOP_CART_INSTANCE_REDUCER_STATE_GRID_ID,
          })
        )
      }
    },
    [data, dispatch, mutate]
  )

  const options = useMemo(
    () => prepareOptionsFromStringArray(data?.productId?.unitOptions),
    [data?.productId?.unitOptions]
  )

  return (
    <View sx={sxStyles.container}>
      {data && data.productId && data.productId.unitOptions ? (
        <SelectController
          onInputChange={handleSubmit(handleUpdateItem)}
          control={control}
          error={errors.unitOfMeasure}
          name={'unitOfMeasure'}
          displayLabelProperty={'name'}
          idProperty={'name'}
          options={options}
          selectInputStyle={sxStyles.selectInputStyle}
          size={'small'}
        />
      ) : null}
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: ['flex-end', null, 'center'],
    maxWidth: 110,
  },
  selectInputStyle: {
    maxWidth: 110,
    minWidth: 110,
  },
}
