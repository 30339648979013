import {QueryDefaultInput, QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'

export const SHOP_CART: QueryDefaultInput = {
  url: Api.cart,
  params: {
    populate: 'userShopId',
  },
}

export const CART_ITEM: QueryDefaultInput = {
  url: Api.cartItem,
  // params: {
  //   where: {price: {'!=': 'null'}},
  // },
}

export const CART_ITEM_EMPTY: QueryDefaultInput = {
  url: Api.cartItem,
  params: {
    where: {price: {'=': 'null'}},
  },
}

export function getDataConfigForCart<TData>(): QueryInput<TData> {
  return {
    ...SHOP_CART,
  }
}

export function getDataConfigForCartItem<TData>(): QueryInput<TData> {
  return {
    ...CART_ITEM,
  }
}

export function getDataConfigForUpdateShopCart<TData>(
  data: TData
): QueryInput<TData> {
  return {
    url: Api.updateCart,
    method: 'PATCH',
    data,
  }
}

export function getDataConfigForDeleteShopCart<TData>(): QueryInput<TData> {
  return {
    url: Api.deleteCart,
    method: 'DELETE',
  }
}

export function getDataConfigForDeleteShopCartItem<TData>(
  data: TData
): QueryInput<TData> {
  return {
    url: Api.deleteItemFromCart,
    method: 'POST',
    data,
  }
}

export function getDataConfigForUpdateShopCartItem<TData>(
  data: TData
): QueryInput<TData> {
  return {
    url: Api.updateItemInCart,
    method: 'POST',
    data,
  }
}

export function getDataConfigForShopCartItemsCount<TData>(): QueryInput<TData> {
  return {
    url: Api.cartItemsCount,
    method: 'GET',
  }
}

export function getDataConfigForUpdateCustomItem<TData>(
  customItemId: number,
  data: TData
): QueryInput<TData> {
  return {
    url: `${Api.customItem}/${customItemId}`,
    method: 'PATCH',
    data,
  }
}

export function getDataConfigForDeleteCustomItem<TData>(
  customItemId: number
): QueryInput<TData> {
  return {
    url: `${Api.customItem}/${customItemId}`,
    method: 'DELETE',
  }
}

export function getDataConfigForCreateCustomItem<TData>(
  data: TData
): QueryInput<TData> {
  return {
    url: Api.customItemCreate,
    method: 'POST',
    data,
  }
}

export function getDataConfigForCreateCustomPrice<TData>(
  data: TData
): QueryInput<TData> {
  return {
    url: Api.customPriceCreate,
    method: 'POST',
    data,
  }
}

export function getDataConfigForDeleteCustomPrice<TData>(
  customPriceId?: number
): QueryInput<TData> {
  return {
    url: `${Api.customPrice}/${customPriceId}`,
    method: 'DELETE',
  }
}

export function getDataConfigForUpdateCustomPrice<TData>(
  customPriceId: number,
  data: TData
): QueryInput<TData> {
  return {
    url: `${Api.customPrice}/${customPriceId}`,
    method: 'PATCH',
    data,
  }
}
