import {QueryDefaultInput, QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'
import {ImportOrderTemplateData} from 'order_template/order_template_types'

export function getDataConfigForOrderTemplates<
  TData
>(): QueryDefaultInput<TData> {
  return {
    url: Api.orderTemplate,
  }
}

export function getDataConfigForOrderTemplateOptions<
  TData
>(): QueryDefaultInput<TData> {
  return {
    url: Api.orderTemplateOptions,
  }
}

export function getDataConfigForUpdateOrderTemplateName<TData>(
  data: TData,
  orderTemplateId: string
): QueryInput<TData> {
  return {
    url: `${Api.orderTemplate}/${orderTemplateId}`,
    method: 'PATCH',
    data,
  }
}

export function getDataConfigForSaveOrEditOrderTemplate<TData>(
  isEdit: boolean,
  data: TData,
  orderTemplateId?: string
): QueryInput<TData> {
  return {
    url: `${Api.orderTemplate}${isEdit ? `/${orderTemplateId}` : ''}`,
    method: isEdit ? 'PATCH' : 'POST',
    data,
  }
}

export function getDataConfigForDeleteOrderTemplate<TData>(
  orderTemplateId: number
): QueryInput<TData> {
  return {
    url: `${Api.orderTemplate}/${orderTemplateId}`,
    method: 'DELETE',
  }
}

export function getDataConfigForUseOrderTemplate<TData>(
  orderTemplateId: string
): QueryInput<TData> {
  return {
    url: `${Api.orderTemplate}/${orderTemplateId}/${Api.orderTemplateUseAction}`,
    method: 'POST',
  }
}

export function getDataConfigForImportOrderTemplate(): QueryInput {
  return {
    url: Api.orderTemplateImport,
    method: 'POST',
  }
}

export function getDataConfigForImportOrderTemplateFromData(
  data: ImportOrderTemplateData
): QueryInput {
  return {
    url: Api.orderTemplateImportFromData,
    method: 'POST',
    data,
  }
}
