import {QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'

export const DELETE_ACCOUNT: QueryInput = {
  url: Api.deleteAccount,
  method: 'POST',
}

export function getDataConfigForDeleteAccount<TData>(
  data: TData
): QueryInput<TData> {
  return {
    ...DELETE_ACCOUNT,
    data,
  }
}
