import {ReducerUtils} from '@sincersoft/core'
import {useApiQuery, extractGridKey} from '@sincersoft/fe-core'
import {
  GRID_REDUCER_STATE_ID,
  gridSlice,
  thunks,
  commonGridConfig as defaultGridConfig,
  IGridDynamicComponentsFactory,
} from '@sincersoft/fe-grid'
import {AxiosRequestConfig} from 'axios'
import {isEqual} from 'lodash'
import {stringify} from 'qs'
import React, {useEffect, useMemo, useState} from 'react'

import {NativeGrid} from './NativeGrid'
import {NativeGridSkeleton} from './NativeGridSkeleton'
import {layoutSlice} from 'layout/layout_slice'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'
import {RootState} from 'store/redux_store'

interface Props {
  dataServiceUrl: string
  dataServiceConfig?: AxiosRequestConfig
  plainGridConfig: any
  commonGridConfig?: any
  stateId: string
  translateService: any
  dynamicComponentsFactory?: IGridDynamicComponentsFactory
  onPressItem?: (id: string) => void
  hasModalSpinner?: boolean
  disableSorting?: boolean
  customPagination?: boolean
  currentPage?: number
}

const NativeGridContainerComponent: React.FC<Props> = ({
  dataServiceUrl,
  dataServiceConfig,
  hasModalSpinner,
  plainGridConfig,
  commonGridConfig,
  stateId,
  translateService,
  dynamicComponentsFactory,
  onPressItem,
  disableSorting = false,
  customPagination,
}) => {
  const dispatch = useAppDispatch()

  const gridKey = useMemo(
    () => extractGridKey(dataServiceUrl, dataServiceConfig),
    [dataServiceUrl, dataServiceConfig]
  )
  const {
    refetch,
    remove,
    data: queryData,
    isSuccess,
    isLoading,
    isRefetching,
  } = useApiQuery(
    gridKey,
    undefined,
    {
      reactQueryOptions: {enabled: false},
      axiosConfig: {
        url: dataServiceUrl,
        ...dataServiceConfig,
        paramsSerializer: (params) => stringify(params),
      },
    },
    true
  )

  const instanceReducerId = ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    stateId
  )

  useEffect(() => {
    if (isSuccess && queryData && !isLoading) {
      if (hasModalSpinner) {
        dispatch(layoutSlice.actions.setModalSpinnerHidden())
      }
      dispatch(
        thunks.loadData({
          data: queryData.rowsData ?? queryData,
          customPagination: customPagination,
          stateId: instanceReducerId,
        })
      )
      dispatch(
        gridSlice.actions.setGridPagination({
          pagination: {
            totalElements: queryData.totalElements,
          },
          stateId: instanceReducerId,
        })
      )
    }
  }, [
    queryData,
    isSuccess,
    dispatch,
    instanceReducerId,
    hasModalSpinner,
    isLoading,
    isRefetching,
    customPagination,
  ])

  const refreshNeeded = useAppSelector(
    (state) => state[instanceReducerId].refreshNeeded
  )

  useEffect(() => {
    if (refreshNeeded) {
      refetch()
      dispatch(
        gridSlice.actions.setRefreshNeeded({
          refreshNeeded: false,
          stateId: instanceReducerId,
        })
      )
    }
  }, [refreshNeeded, dispatch, refetch, instanceReducerId])

  const isMobile = false
  const data = useAppSelector(
    (state: RootState) => state[instanceReducerId].data
  )
  const gridConfig = useAppSelector(
    (state) => state[instanceReducerId].gridConfig
  )

  const [dataState, setDataState] = useState({})

  useEffect(() => {
    dispatch(
      thunks.loadConfig({
        stateId: instanceReducerId,
        plainGridConfig,
        commonGridConfig: commonGridConfig ?? defaultGridConfig,
      })
    )
  }, [commonGridConfig, dispatch, instanceReducerId, plainGridConfig])

  useEffect(() => {
    // get current state of data dependencies
    const getDataState = () => ({dataServiceUrl, dataServiceConfig, stateId})

    const newDataState = getDataState()
    if (!isEqual(dataState, newDataState)) {
      setDataState(newDataState)
      // dispatch new loadData request only if some of dependency has changed
      // dispatch(thunks.loadData(instanceReducerId, dataServiceUrl, dataServiceConfig))
      refetch()
    }
  }, [
    instanceReducerId,
    dataServiceUrl,
    dataServiceConfig,
    dispatch,
    stateId,
    dataState,
    refetch,
  ])

  useEffect(() => {
    refetch()
    return () => {
      remove()
      // reset grid on unmount
      dispatch(gridSlice.actions.resetGrid({stateId: instanceReducerId}))
    }
  }, [refetch, remove, dispatch, instanceReducerId])

  useEffect(() => {
    if (hasModalSpinner && dataServiceConfig && !data) {
      dispatch(layoutSlice.actions.setModalSpinnerVisible())
    }
  }, [hasModalSpinner, dispatch, dataServiceConfig, data])

  return (
    <>
      {isLoading && <NativeGridSkeleton />}
      <NativeGrid
        data={data}
        gridConfig={gridConfig}
        isLoading={isLoading}
        isMobile={isMobile}
        translateService={translateService}
        dynamicComponentsFactory={dynamicComponentsFactory}
        onPressItem={onPressItem}
        disableSorting={disableSorting}
        stateId={stateId}
        customPagination={customPagination}
      />
    </>
  )
}

export const NativeGridContainer = React.memo(NativeGridContainerComponent)
