import {TypOrderBy} from '@sincersoft/core'
import * as yup from 'yup'

import {formatStringToNumber} from 'common/number_helpers'
import {UserStatus} from 'login/login_constants'
import {UnitOfMeasure} from 'new_order/new_order_types'
import {
  MAX_G_VALUE,
  MAX_KG_VALUE,
  MAX_PRICE_VALUE,
  MIN_G_VALUE,
  MIN_KG_VALUE,
  MIN_PRICE_VALUE,
} from 'shop_cart/shop_cart_constants'

const EmailObject = yup
  .string()
  .email('errors.form.email')
  .required('errors.form.required')

const PasswordObject = yup
  .string()
  .required('errors.form.required')
  .min(6, 'errors.form.min')

const RegistrationNumberObject = yup
  .string()
  .trim()
  .matches(/^[0-9]*$/, 'errors.form.number')
  .required('errors.form.required')
  .test('len', 'errors.form.max', (val) =>
    val ? val.toString().length <= 8 : true
  )
  .nullable()

const CompanyVatNumberObject = yup
  .string()
  .matches(/^([a-zA-Z]{2})?[0-9]*$/, 'errors.form.companyVatNumber')
  .required('errors.form.required')
  .test('len', 'errors.form.companyVatNumberLength', (val) => {
    const value = val ? val.toString() : ''
    return value.match(/^[a-zA-Z]{2}[0-9]*$/)
      ? value.length === 12
      : value.length === 10
  })

export const LoginSchema = yup
  .object({
    email: EmailObject,
    password: PasswordObject,
  })
  .required()

export const RegisterSchema = yup
  .object({
    fullName: yup.string().required('errors.form.required'),
    email: EmailObject,
    phone: yup.string().required('errors.form.required'),
    password: PasswordObject,
    userShopName: yup.string().required('errors.form.required'),
    userShopStreet: yup.string(),
    userShopStreetNumber: yup.string().required('errors.form.required'),
    userShopCity: yup.string().required('errors.form.required'),
    userShopPhone: yup.string(),
    isSubscribedForPriceList: yup.boolean(),
    userShopCompany: yup.string().required('errors.form.required'),
    userShopCompanyRegistrationNumber: RegistrationNumberObject,
    userShopCompanyVatNumber: CompanyVatNumberObject,
    acceptPolicy: yup.boolean().oneOf([true], 'errors.form.acceptPolicy'),
  })
  .required()

export const EmailSchema = yup
  .object({
    email: EmailObject,
  })
  .required()

export const PasswordSchema = yup
  .object({
    password: PasswordObject,
  })
  .required()

export const NewOrderSchema = yup
  .object({
    amount: yup.mixed().test('', '', (val) => {
      let testValue = val
      if (typeof testValue === 'string') {
        testValue = formatStringToNumber(testValue)
      }
      return testValue ? testValue > 0 : true
    }),
    unitOfMeasure: yup.string().required('errors.form.required'),
  })
  .required()

export const UserSchema = yup
  .object({
    fullName: yup.string().required('errors.form.required'),
    userStatus: yup
      .mixed()
      .oneOf([UserStatus.UNCONFIRMED, UserStatus.CONFIRMED])
      .required('errors.form.required'),
    phone: yup.string().required('errors.form.required'),
    isSubscribedForPriceList: yup.boolean(),
    priceListActionPrimary: yup.number().nullable(),
    priceListActionSecondary: yup.number().nullable(),
    priceListStandard: yup.number().nullable().required('errors.form.required'),
  })
  .required()

export const ProfileSchema = yup
  .object({
    fullName: yup.string().required('errors.form.required'),
    phone: yup.string().required('errors.form.required'),
    isSubscribedForPriceList: yup.boolean(),
  })
  .required()

export const ChangePasswordSchema = yup
  .object({
    oldPassword: yup.string().required('errors.form.required'),
    newPassword: yup.string().required('errors.form.required'),
    againPassword: yup
      .string()
      .required('errors.form.required')
      .oneOf([yup.ref('newPassword'), null], 'errors.form.mustMatch'),
  })
  .required()

export const shopSchema = yup
  .object({
    name: yup.string().required('errors.form.required'),
    street: yup.string(),
    streetNumber: yup.string().required('errors.form.required'),
    city: yup.string().required('errors.form.required'),
    phone: yup.string(),
    company: yup.string().required('errors.form.required'),
    companyRegistrationNumber: RegistrationNumberObject,
    companyVatNumber: CompanyVatNumberObject,
  })
  .required()

export const priceListSchema = yup.object({
  dateFrom: yup.date().nullable(),
  dateTo: yup
    .date()
    .nullable()
    .when('dateFrom', (started, schema) =>
      started ? schema.min(started, 'errors.form.endDateToStartDate') : yup
    ),
})

export const sortSchema = yup
  .object({
    columnName: yup.string().nullable().required('errors.form.required'),
    type: yup.mixed<TypOrderBy>().required('errors.form.required'),
  })
  .required()

export const orderTemplateSchema = yup
  .object({
    template: yup.string().nullable().required('errors.form.required'),
  })
  .required()

export const createOrderTemplateSchema = yup
  .object({
    templateInput: yup.string().nullable(),
    templateSelect: yup.string().nullable(),
  })
  .required()

export const shopCartAmountSchema = yup
  .object({
    amount: yup.mixed().test('', '', (value) => {
      const formatValue = formatStringToNumber(value ?? 0)
      return formatValue ? formatValue > 0 : false
    }),
  })
  .required()

export const shopCartUnitOfMeasureSchema = yup
  .object({
    unitOfMeasure: yup.string().required('errors.form.required'),
  })
  .required()

export const shopCartCustomerNoteSchema = yup
  .object({
    customerNote: yup.string(),
  })
  .required()

export const administratorNoteSchema = yup
  .object({
    administratorNote: yup.string(),
  })
  .required()

export const quickShopSchema = yup
  .object({
    amount: yup.mixed().test('', '', (value) => {
      const formatValue = formatStringToNumber(value ?? 0)
      return formatValue ? formatValue > 0 : false
    }),
    unitOfMeasure: yup.string().required('errors.form.required'),
  })
  .required()

export const shopCartAmountAndUnitSchema = yup
  .object({
    amount: yup.mixed().test('', '', (value) => {
      const formatValue = formatStringToNumber(value ?? 0)
      return formatValue ? formatValue > 0 : false
    }),
    unitOfMeasure: yup.string().required('errors.form.required'),
  })
  .required()

export const orderPriceSchema = yup
  .object({
    price: yup
      .string()
      .required('errors.form.required')
      .test('', '', (value) => {
        const formatValue = formatStringToNumber(value ?? 0)
        return formatValue ? formatValue > 0 : false
      }),
  })
  .required()

export const orderStatusSchema = yup
  .object({
    status: yup.string().required('errors.form.required'),
  })
  .required()

export const ReportSchema = yup
  .object({
    subject: yup.string().required('errors.form.required'),
    screen: yup.string().required('errors.form.required'),
    description: yup.string().required('errors.form.required'),
  })
  .required()

export const valueSchema = yup
  .object({
    value: yup.mixed(),
  })
  .required()

export const customPriceSchema = yup
  .object({
    price: yup
      .string()
      .required('errors.form.required')
      .test('', '', (value) => {
        const formatValue = formatStringToNumber(value ?? 0)
        return formatValue ? formatValue > 0 : false
      }),
  })
  .required()

export const PriceListExportSchema = yup
  .object({
    priceListActionPrimary: yup.number().nullable(),
    priceListStandard: yup.number().nullable().required('errors.form.required'),
  })
  .required()

export const customItemPriceSchema = (id: string, isRequired: boolean) => {
  return yup.object({
    [id]: yup.mixed().test('price', 'Price is out of range', (val) => {
      let testValue = val
      if (typeof testValue === 'string' && testValue) {
        testValue = formatStringToNumber(testValue)
      }

      if (testValue > MAX_PRICE_VALUE) {
        return new yup.ValidationError(
          'customPrice.maxPriceExceeded',
          testValue,
          id
        )
      } else if (testValue < MIN_PRICE_VALUE) {
        return new yup.ValidationError(
          'customPrice.lowPriceExceeded',
          testValue,
          id
        )
      } else if (!isRequired) {
        return true
      }

      return true
    }),
  })
}

export const customItemAmountSchema = (
  id: string,
  unitOfMeasure?: UnitOfMeasure,
  isRequired = false
) => {
  return yup
    .object({
      [id]: yup
        .mixed()
        .test('amount', 'Wrong amount', (val) => {
          let testValue = val
          if (typeof testValue === 'string') {
            testValue = formatStringToNumber(testValue)
          }
          return testValue || testValue === 0
            ? unitOfMeasure === 'kg'
              ? testValue >= MIN_KG_VALUE && testValue <= MAX_KG_VALUE
              : unitOfMeasure === 'g'
              ? testValue >= MIN_G_VALUE && testValue <= MAX_G_VALUE
              : testValue > 0
            : !isRequired
        })
        .required(),
    })
    .required()
}

export const customItemMixedSchema = (id: string) => {
  return yup
    .object({
      [id]: yup.mixed().test('name', 'Wrong name', (val: string) => {
        return val !== ''
      }),
    })
    .required()
}
