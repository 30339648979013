import {yupResolver} from '@hookform/resolvers/yup'
import {CompositeScreenProps, useNavigation} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiMutation, useApiQuery} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {DetailSkeleton} from 'common/components/DetailSkeleton'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError} from 'error/error_helper'
import {priceListSchema} from 'form/form_schemas'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {ContentLayout} from 'layout/components/ContentLayout'
import {
  AdminPriceListStackParamList,
  AuthorizedNavigationProp,
  AuthorizedScreenProps,
} from 'layout/layout_types'
import {PriceListDetail} from 'price_list/components/PriceListDetail'
import {ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_GRID_ID} from 'price_list/price_list_constants'
import {
  getDataConfigForPriceList,
  getDataConfigForSavePriceList,
} from 'price_list/price_list_helpers'
import {
  PriceListDetailDate,
  PriceListDetailDateForm,
  PriceListItem,
} from 'price_list/price_list_types'
import {useAppDispatch} from 'store/redux_hooks'

type Props = CompositeScreenProps<
  NativeStackScreenProps<
    AdminPriceListStackParamList,
    'AdminPriceListDetailScreen'
  >,
  AuthorizedScreenProps
>

export const AdminPriceListDetailScreen: React.FC<Props> = ({route}) => {
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: {errors},
  } = useForm<PriceListDetailDateForm>({
    defaultValues: {},
    resolver: yupResolver(priceListSchema),
  })

  const dataConfigForPriceListDetail = getDataConfigForPriceList(
    route.params.id
  )

  const priceListyKey = extractKey({
    params: dataConfigForPriceListDetail.params,
    url: dataConfigForPriceListDetail.url,
  })

  const {data, isSuccess, isLoading, isStale} = useApiQuery<PriceListItem>(
    priceListyKey,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...dataConfigForPriceListDetail,
      },
    },
    true
  )

  useEffect(() => {
    if (isSuccess && data) {
      reset({
        // ...(data.dateFrom ? {dateFrom: new Date(data.dateFrom)} : {}),
        // ...(data.dateTo ? {dateTo: new Date(data.dateTo)} : {}),
        dateFrom: data.dateFrom ? new Date(data.dateFrom) : null,
        dateTo: data.dateTo ? new Date(data.dateTo) : null,
      })
    }
  }, [data, isSuccess, reset])

  const prepareDataToSave = (
    dataToSave: PriceListDetailDateForm
  ): PriceListDetailDate => {
    return {
      dateFrom: dataToSave?.dateFrom?.getTime() ?? null,
      dateTo: dataToSave?.dateTo?.getTime() ?? null,
    }
  }

  const configForSavePriceList = getDataConfigForSavePriceList(
    prepareDataToSave(getValues()),
    route.params.id
  )

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const handleSuccessSave = () => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_PRICE_LIST_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    dispatch(SnackbarHelpers.getSnackBarSuccess('priceList.update.success'))
  }

  const saveQuery = useApiMutation(
    extractKey({
      data: configForSavePriceList.data,
      method: configForSavePriceList.method,
      params: configForSavePriceList.params,
      url: configForSavePriceList.url,
    }),
    configForSavePriceList.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessSave,
        onError: handleError,
      },
      axiosConfig: {
        ...configForSavePriceList,
      },
    },
    true
  )

  const handleSavePriceList = () => {
    saveQuery.mutate()
  }

  const handlePressDeleteDate = (id: string) => {
    reset({...getValues(), [id]: null})
  }

  const {t} = useTranslation()
  const navigation = useNavigation<AuthorizedNavigationProp>()

  useRefetchQuery([{key: priceListyKey}], isStale)
  return (
    <View sx={{flex: 1}}>
      <Header hasBackButton />
      <AuthorizedLayout
        title={'priceList.detail.title'}
        titleValue={{priceList: data && data.name}}
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbItem
              label={t('priceList.admin.title')}
              onPress={() => {
                navigation.navigate('NavAdminPriceListScreen', {
                  screen: 'AdminPriceListScreen',
                })
              }}
            />
            <BreadcrumbItem
              label={t('priceList.detail.breadcrumbsTitle', {
                priceList: data?.name,
              })}
              isLast
            />
          </Breadcrumbs>
        }
      >
        <ContentLayout>
          {data ? (
            <PriceListDetail
              data={data}
              onPressButton={handleSubmit(handleSavePriceList)}
              onPressDeleteDate={handlePressDeleteDate}
              control={control}
              errors={errors}
            />
          ) : null}
          {!data && isLoading ? <DetailSkeleton /> : null}
        </ContentLayout>
      </AuthorizedLayout>
    </View>
  )
}
