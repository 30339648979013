import {QueryInput} from '@sincersoft/fe-core'

import {Api} from 'config/app_config'

export const RESET_PASSWORD: QueryInput = {
  url: Api.resetPassword,
  method: 'POST',
}

export function getDataConfigForResetPassword<TData>(
  data: TData
): QueryInput<TData> {
  return {
    ...RESET_PASSWORD,
    data,
  }
}
