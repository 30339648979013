import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {ConfirmRegisterButton} from './ConfirmRegisterButton'
import {ConfirmRegisterEmail} from './ConfirmRegisterEmail'
import {ConfirmRegisterTitleWithText} from './ConfirmRegisterTitleWithText'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {Api} from 'config/app_config'
import {getDataConfigForConfirmRegister} from 'confirm_register/confirm_register_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {ContentLayout} from 'layout/components/ContentLayout'
import {ErrorBoundary} from 'layout/components/ErrorBoundary'
import {TOKEN_EXPIRED} from 'layout/layout_constants'
import {ObjectsOfSxProps, RootStackParamList} from 'layout/layout_types'
import {useAppDispatch} from 'store/redux_hooks'
// import {Notice} from 'common/components/Notice'

interface ConfirmRegisterScreenOwnProps {
  isConfirm?: boolean
}

type Props = ConfirmRegisterScreenOwnProps &
  NativeStackScreenProps<
    RootStackParamList,
    'ConfirmRegisterScreen' | 'RequestRegisterScreen'
  >

export const ConfirmRegisterScreen: React.FC<Props> = ({
  navigation,
  route,
  isConfirm,
}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error, false))
  }

  const handleSuccess = (response: ResponseSuccess) => {
    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode, false))
  }

  const configConfirmRegister = getDataConfigForConfirmRegister({
    token: route.params?.token,
  })

  const {mutate, reset, error, isError, isSuccess, status} = useApiMutation(
    extractKey({
      data: configConfirmRegister.data,
      method: configConfirmRegister.method,
      url: configConfirmRegister.url,
    }),
    configConfirmRegister.data,
    {
      reactMutationOptions: {
        onError: handleError,
        onSuccess: handleSuccess,
      },
      axiosConfig: {
        ...configConfirmRegister,
      },
    },
    false
  )

  useEffect(() => {
    if (isConfirm) {
      mutate()
    }
  }, [mutate, isConfirm])

  const sxStyles = applyStyles()

  const handlePressButton = useCallback(() => {
    if (isConfirm && isSuccess) {
      reset()
      navigation.navigate('LoginScreen')
    }
  }, [isSuccess, reset, navigation, isConfirm])

  const isExpired = error?.response?.data?.errorCode === TOKEN_EXPIRED

  const displayButton = (isConfirm && !isError) || (isError && isExpired)

  return (
    <View sx={sxStyles.containerWrapper}>
      <ErrorBoundary>
        <ContentLayout>
          {status === 'success' || !isExpired ? (
            <View sx={sxStyles.container}>
              <ConfirmRegisterTitleWithText
                title={t(
                  `${isConfirm ? 'confirmRegister' : 'requestRegister'}.title`
                )}
                text={t(
                  `${
                    isConfirm ? 'confirmRegister.text' : 'requestRegister.text'
                  }`
                )}
              />
              {displayButton && isSuccess && (
                <ConfirmRegisterButton
                  handlePressButton={handlePressButton}
                  title={`button.${isSuccess ? 'login' : 'resendEmail'}`}
                />
              )}
              {/*{isError && (*/}
              {/*  <Notice*/}
              {/*    color={'error'}*/}
              {/*    text={error?.response?.data?.messageCode}*/}
              {/*  />*/}
              {/*)}*/}
            </View>
          ) : (
            <ConfirmRegisterEmail
              keyUrl={Api.confirmRegister}
              navigation={navigation}
              title={'confirmRegister.email.title'}
              text={'confirmRegister.email.text'}
            />
          )}
        </ContentLayout>
      </ErrorBoundary>
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  containerWrapper: {
    flex: 1,
    backgroundColor: 'primaryColor100',
  },
  container: {
    flex: 1,
    minHeight: '100%',
    flexDirection: 'column' as const,
    alignSelf: 'center',
    paddingTop: 100,
    paddingX: 5,
  },
})
