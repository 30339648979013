import {MaterialIcons} from '@expo/vector-icons'
import {useDripsyTheme, useSx} from 'dripsy'
import {DripsyThemeContext} from 'dripsy/build/core/provider/context'
import React from 'react'
import {StyleSheet, TextStyle} from 'react-native'

import {Colors} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'

const DEFAULT_ICON_SIZE = 24

interface IconProps {
  backgroundColor?: string
  color?: Colors
  onPress?: () => void
  isDisabled?: boolean
  name: keyof typeof MaterialIcons.glyphMap
  size?: number
  propsStyle?: TextStyle
}

export const Icon: React.FC<IconProps> = ({
  backgroundColor,
  color,
  onPress,
  isDisabled = false,
  name,
  size = DEFAULT_ICON_SIZE,
  propsStyle,
}) => {
  const dripsyTheme = useDripsyTheme()
  const sx = useSx()
  const sxStyles = applyStyles(dripsyTheme, color, isDisabled)
  MaterialIcons.defaultProps
  const onPresIcon = () => {
    if (onPress && !isDisabled) {
      onPress()
    }
  }

  return (
    <MaterialIcons.Button
      name={name}
      onPress={onPresIcon}
      backgroundColor={backgroundColor ?? 'transparent'}
      size={size}
      activeOpacity={1}
      underlayColor={'transparent'}
      iconStyle={{
        ...sx(sxStyles.iconColor),
        ...styles.iconStyle,
        ...propsStyle,
      }}
      disabled={!onPress}
    />
  )
}

const styles = StyleSheet.create({
  iconStyle: {marginRight: 0},
})

const applyStyles = (
  dripsyTheme: DripsyThemeContext,
  color?: Colors,
  isDisabled?: boolean
): ObjectsOfSxProps => ({
  iconColor: {
    color: isDisabled
      ? dripsyTheme.theme.colors.grayColor300
      : color ?? dripsyTheme.theme.colors.grayColor700,
  },
})
