import {Text, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {CUSTOM_ITEMS_HEADER} from 'shop_cart/shop_cart_constants'

const ShopCartCustomItemsHeaderComponent: React.FC = () => {
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)
  return (
    <View sx={sxStyles.container}>
      {CUSTOM_ITEMS_HEADER.map((item) => (
        <Text sx={sxStyles.headerItem} key={item} variant={'body2Bold'}>
          {t(item)}
        </Text>
      ))}
    </View>
  )
}

export const ShopCartCustomItemsHeader = React.memo(
  ShopCartCustomItemsHeaderComponent
)

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    display: ['none', null, 'flex'],
    flexDirection: 'row',
  },
  headerItem: {
    flex: 1,
    color: 'grayColor400',
    textAlign: 'center',
  },
})
