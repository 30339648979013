import {BottomTabBarProps} from '@react-navigation/bottom-tabs'
import {View} from 'dripsy'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSafeAreaInsets} from 'react-native-safe-area-context'

import {ClientRoutes} from 'header/header_constants'
import {HeaderLinksProps, LinkProps} from 'header/header_types'
import {BottomTabBarItem} from 'layout/components/BottomTabBarItem'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {ShopCartDisabledModal} from 'shop_cart/components/ShopCartDisabledModal'
import {useAppSelector} from 'store/redux_hooks'

export const BOTTOM_TAB_BAR_HEIGHT = 65

interface BottomTabBarOwnProps {
  routes: HeaderLinksProps
  isShopCartEnabled: boolean
}

type BottomTabProps = BottomTabBarProps & BottomTabBarOwnProps

export const BottomTabBar: React.FC<BottomTabProps> = ({
  state,
  navigation,
  routes,
  isShopCartEnabled,
}) => {
  const {t} = useTranslation()
  const safeAreaInsets = useSafeAreaInsets()
  const sxStyles = applyStyles(safeAreaInsets.bottom)
  const shopCartItemsCount = useAppSelector(
    (reduxState) => reduxState.login.shopCartItemsCount
  )

  const [modalVisible, setModalVisible] = useState(false)

  const handleSetIsVisible = (value: boolean) => {
    setModalVisible(value)
  }

  const onPressBottomTabBarItem = useCallback(
    (routeName: string, routeKey: string, isFocused: boolean) => {
      if (isShopCartEnabled || routeName !== ClientRoutes.shopCart.route) {
        const event = navigation.emit({
          type: 'tabPress',
          target: routeKey,
          canPreventDefault: true,
        })

        if (isFocused) {
          navigation.reset({
            index: 0,
            routes: [{name: routeName}],
          })
        } else if (!isFocused && !event.defaultPrevented) {
          // The `merge: true` option makes sure that the params inside the tab screen are preserved
          navigation.navigate({name: routeName, merge: true, params: {}})
        }
      } else if (
        !isShopCartEnabled &&
        routeName === ClientRoutes.shopCart.route
      ) {
        handleSetIsVisible(true)
      }
    },
    [navigation, isShopCartEnabled]
  )

  return (
    <View sx={sxStyles.bottomTabBar}>
      {state.routes.map((route, index) => {
        let actualRoute: LinkProps | undefined
        Object.keys(routes).forEach((key) => {
          if (routes[key].route === route.name) {
            actualRoute = routes[key]
          }
        })
        const label = actualRoute ? t(actualRoute.name) : route.name
        const isFocused = state.index === index

        return actualRoute && actualRoute.hide ? null : (
          <BottomTabBarItem
            iconName={
              route.name === 'NavShopCartScreen'
                ? isShopCartEnabled
                  ? actualRoute?.icon
                  : 'remove-shopping-cart'
                : actualRoute?.icon
            }
            isFocused={isFocused}
            isShopCartEnabled={isShopCartEnabled}
            key={route.name}
            routeKey={route.key}
            label={label}
            onPressTabBarItem={onPressBottomTabBarItem}
            routeName={route.name}
            shopCartCountValue={
              route.name === 'NavShopCartScreen'
                ? shopCartItemsCount
                : undefined
            }
          />
        )
      })}
      <ShopCartDisabledModal
        isVisible={modalVisible}
        onSetIsVisible={handleSetIsVisible}
      />
    </View>
  )
}

const applyStyles = (insetsBottom: number): ObjectsOfSxProps => ({
  bottomTabBar: {
    flexDirection: 'row' as const,
    backgroundColor: 'primaryColor50',
    height: BOTTOM_TAB_BAR_HEIGHT + insetsBottom,
    paddingBottom: insetsBottom,
    borderTopColor: 'grayColor400',
    borderTopWidth: 1,
  },
})
