import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {useGetProductDetail} from 'common/hooks/useGetProductDetail'
import {
  AuthorizedScreenProps,
  NewOrderStackParamList,
} from 'layout/layout_types'
import {ProductDetailContainer} from 'new_order/components/ProductDetailContainer'

type Props = CompositeScreenProps<
  NativeStackScreenProps<NewOrderStackParamList, 'NewOrderProductDetailScreen'>,
  AuthorizedScreenProps
>

export const NewOrderProductDetailScreen: React.FC<Props> = ({
  route,
  navigation,
}) => {
  const {t} = useTranslation()

  const productId = route.params.id || route.params.productId
  const data = useGetProductDetail(productId)

  return data ? (
    <ProductDetailContainer
      navigation={navigation}
      route={route}
      breadcrumbs={
        <Breadcrumbs>
          <BreadcrumbItem
            label={t('newOrder.order')}
            onPress={() => {
              navigation.navigate('NavNewOrderScreen', {
                screen: 'NewOrderScreen',
              })
            }}
          />
          <BreadcrumbItem
            label={t('newOrder.category.breadcrumbsSubTitle')}
            onPress={() => {
              navigation.navigate('NavNewOrderScreen', {
                screen: 'NewOrderCategoryScreen',
              })
            }}
          />
          <BreadcrumbItem
            label={t('newOrder.product.breadcrumbsSubTitle', {
              category: data?.productCategoryId?.name,
            })}
            onPress={() => {
              data &&
                navigation.navigate('NavNewOrderScreen', {
                  screen: 'NewOrderProductScreen',
                  params: {
                    idCategory: data.productCategoryId.id,
                  },
                })
            }}
          />
          <BreadcrumbItem
            label={t('newOrder.productDetail.breadcrumbsSubTitle', {
              product: data?.name,
            })}
            isLast
          />
        </Breadcrumbs>
      }
    />
  ) : null
}
