import {Pressable, Text, useDripsyTheme, View} from 'dripsy'
import React, {useState} from 'react'

import {Icon} from 'common/components/Icon'
import {useNavigateWithReset} from 'common/hooks/useNavigateWithReset'
import {HeaderMenu} from 'header/components/HeaderMenu'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {ShopCartDisabledModal} from 'shop_cart/components/ShopCartDisabledModal'
import {useAppSelector} from 'store/redux_hooks'

interface HeaderRightProps {
  name: string
  isAdmin: boolean
  isShopCartEnabled: boolean
  onPressLogout: () => void
  onPressProfile: () => void
  onPressReport: () => void
}

export const HeaderRight: React.FC<HeaderRightProps> = ({
  name,
  isAdmin,
  isShopCartEnabled,
  onPressLogout,
  onPressProfile,
  onPressReport,
}) => {
  const dripsyTheme = useDripsyTheme()
  const sxStyles = applyStyles()
  const navigateWithRefresh = useNavigateWithReset()
  const [modalVisible, setModalVisible] = useState(false)

  const shopCartItemsCount = useAppSelector(
    (state) => state.login.shopCartItemsCount
  )

  const onShopCartPress = () => {
    navigateWithRefresh.navigate('NavShopCartScreen', 'ShopCartScreen')
  }

  const handleSetModalVisible = (value: boolean) => {
    setModalVisible(value)
  }

  const handleOpenModal = () => {
    setModalVisible(true)
  }

  return (
    <View sx={sxStyles.headerRight}>
      <HeaderMenu
        text={name}
        onPressLogout={onPressLogout}
        onPressProfile={onPressProfile}
        onPressReport={onPressReport}
      />
      {isAdmin || !isWeb ? null : (
        <Pressable
          onPress={isShopCartEnabled ? onShopCartPress : handleOpenModal}
          accessibilityRole={'button'}
        >
          <View sx={sxStyles.shopCart}>
            <Icon
              size={30}
              name={
                isShopCartEnabled ? 'shopping-cart' : 'remove-shopping-cart'
              }
              color={
                isShopCartEnabled
                  ? dripsyTheme.theme.colors.grayColor400
                  : dripsyTheme.theme.colors.secondaryColor500
              }
              onPress={isShopCartEnabled ? onShopCartPress : handleOpenModal}
            />
            {shopCartItemsCount ? (
              <View sx={sxStyles.shopCartItemsCount}>
                <Text sx={sxStyles.shopCartItemsCountText}>
                  {shopCartItemsCount}
                </Text>
              </View>
            ) : null}
          </View>
        </Pressable>
      )}
      <ShopCartDisabledModal
        isVisible={modalVisible}
        onSetIsVisible={handleSetModalVisible}
      />
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  headerRight: {
    alignSelf: 'center',
    flexDirection: 'row' as const,
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: 200,
  },
  shopCart: {
    paddingLeft: 3,
    display: ['none', null, 'flex'],
  },
  shopCartItemsCount: {
    backgroundColor: 'primaryColor100',
    position: 'absolute',
    borderWidth: 2,
    borderColor: 'primaryColor400',
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    aspectRatio: '1',
  },
  shopCartItemsCountText: {
    color: 'primaryColor600',
  },
})
