import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {AutocompleteItem, TextProps} from '@ui-kitten/components'
import {RenderProp} from '@ui-kitten/components/devsupport'
import {View} from 'dripsy'
import {debounce} from 'lodash'
import React, {ReactText, useEffect, useMemo, useState} from 'react'
import {
  Controller,
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form'

import {
  AutoCompleteInput,
  AutoCompleteInputProps,
} from 'common/components/AutoCompleteInput'
import {InputErrorMessage} from 'common/components/InputErrorMessage'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {getDataConfigForSearch} from 'user/user_helpers'

interface InputControllerProps extends AutoCompleteInputProps {
  control: Control<any>
  error?: FieldError | Merge<FieldError, FieldErrorsImpl>
  hideErrorInput?: boolean
  id: string
  label?: string
  url: string
}

export const AutoCompleteInputController: React.FC<InputControllerProps> = ({
  control,
  error,
  hideErrorInput = false,
  id,
  label,
  url,
  ...restProps
}) => {
  const sxStyles = applyStyles()
  const [findValue, setFindValue] = useState('')

  const configForSearch = getDataConfigForSearch(url, findValue)

  const {data, refetch} = useApiQuery<Array<string>>(
    extractKey({
      params: configForSearch.params,
      url: configForSearch.url,
    }),
    undefined,
    {
      reactQueryOptions: {
        enabled: false,
      },
      axiosConfig: {
        ...configForSearch,
      },
    },
    false
  )

  const renderOption = (
    item: ReactText | RenderProp<TextProps> | undefined,
    index: number
  ) => <AutocompleteItem key={index} title={item} />

  const loadOptions = useMemo(() => debounce(() => refetch(), 400), [refetch])

  useEffect(() => {
    if (findValue !== '') {
      loadOptions()
    }
  }, [findValue, loadOptions])

  return (
    <View sx={sxStyles.container}>
      <Controller
        control={control}
        render={({field: {onChange, value}}) => {
          const onChangeText = (changeValue: string) => {
            setFindValue(changeValue)
            onChange(changeValue)
          }

          const onSelect = (index: number) => {
            if (data && data.length > index && data[index]) {
              onChange(data[index])
              setFindValue(data[index])
            }
          }

          return (
            <AutoCompleteInput
              label={label}
              value={(findValue === '' ? value : findValue) ?? ''}
              onSelect={onSelect}
              onChangeText={onChangeText}
              {...restProps}
            >
              {data != null && data.length > 0 && findValue !== '' ? (
                data.map(renderOption)
              ) : (
                <AutocompleteItem title={findValue} />
              )}
            </AutoCompleteInput>
          )
        }}
        name={id}
        defaultValue={''}
      />
      {hideErrorInput ? null : <InputErrorMessage error={error} />}
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    width: '100%',
    justifyContent: 'center',
  },
})
