import {DripsyCustomTheme, View} from 'dripsy'
import {isEmpty} from 'lodash'
import React from 'react'
import {Control, FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {IconButton} from 'common/components/IconButton'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {InputController} from 'form/components/InputController'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {FormFields} from 'register/register_constants'

interface UserDetailFormProps {
  control: Control<any>
  errors: FieldErrors
  formFields: FormFields
  isLoading: boolean
  onPressChange: () => void
  onPressCancel: () => void
}

export const ProfileChangePasswordForm: React.FC<UserDetailFormProps> = ({
  control,
  errors,
  formFields,
  isLoading,
  onPressChange,
  onPressCancel,
}) => {
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)
  return (
    <View sx={sxStyles.container}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <View sx={sxStyles.content} accessibilityRole={isWeb ? 'form' : 'none'}>
        {formFields.map((formItem) => (
          <InputController
            key={formItem.id}
            control={control}
            error={errors[formItem.id]}
            hideErrorInput={isEmpty(errors[formItem.id])}
            id={formItem.id}
            label={formItem.label}
            isSecured={formItem.isPassword}
            keyboardType={formItem.keyboardType ?? 'default'}
            autoCapitalize={formItem.keyboardType ? 'none' : 'sentences'}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            autoComplete={formItem.isPassword ? 'password-new' : 'off'}
            labelStyle={sxStyles.label}
          />
        ))}
        <View sx={sxStyles.buttons}>
          <View sx={sxStyles.button}>
            <IconButton
              iconName={'close'}
              left
              title={t('button.cancel')}
              onPress={onPressCancel}
              color={'secondaryColor500'}
              appearance={'outline'}
              status={'danger'}
              size={'small'}
            />
          </View>
          <IconButton
            iconName={'lock-outline'}
            left
            title={t('button.changePassword')}
            onPress={onPressChange}
            color={'primaryColor500'}
            isLoading={isLoading}
            size={'small'}
          />
        </View>
      </View>
    </View>
  )
}

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    padding: [1, 2, 5],
    margin: [1, 2, 5],
    borderWidth: 1,
    borderColor: 'grayColor200',
    alignItems: 'center',
  },
  content: {
    minWidth: ['100%', null, 700],
    maxWidth: 1000,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  label: {
    color: 'secondaryColor700',
    ...theme.text.body1Bold,
    fontSize: [2, null, 3],
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: ['space-between', null, 'flex-end'],
    paddingTop: [6, null, 7],
  },
  button: {
    paddingRight: [0, null, 6],
  },
})
