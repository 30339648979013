import {useNavigation} from '@react-navigation/native'
import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import {Text, useDripsyTheme, View} from 'dripsy'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ConfirmModal} from 'common/components/ConfirmModal'
import {Icon} from 'common/components/Icon'
import {IconButton} from 'common/components/IconButton'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {ApiError} from 'error/error_helper'
import {AuthorizedNavigationProp, ObjectsOfSxProps} from 'layout/layout_types'
import {logoutCleanData} from 'login/login_thunks'
import {getDataConfigForDeleteUser} from 'profile/profile_helpers'
import {useAppDispatch} from 'store/redux_hooks'

interface Props {
  email?: string
  id?: number
  isDisabled: boolean
}

export const ProfileDeleteAccount: React.FC<Props> = ({
  email,
  id,
  isDisabled,
}) => {
  const {t} = useTranslation()
  const navigation = useNavigation<AuthorizedNavigationProp>()
  const dripsyTheme = useDripsyTheme()
  const dispatch = useAppDispatch()
  const [modalVisible, setModalVisible] = useState(false)

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const handleSuccess = useCallback(() => {
    if (id) {
      dispatch(
        SnackbarHelpers.getSnackBarSuccess('profile.delete.detailSuccess')
      )
      setModalVisible(false)
      navigation.navigate('NavUserScreen', {screen: 'UserScreen'})
    } else {
      dispatch(SnackbarHelpers.getSnackBarSuccess('profile.delete.success'))
      dispatch(logoutCleanData())
    }
  }, [dispatch, id, navigation])

  const configForDeleteAccount = getDataConfigForDeleteUser(id)

  const deleteAccountQuery = useApiMutation(
    extractKey({
      method: configForDeleteAccount.method,
      url: configForDeleteAccount.url,
    }),
    undefined,
    {
      reactMutationOptions: {
        onError: handleError,
        onSuccess: handleSuccess,
      },
      axiosConfig: {
        ...configForDeleteAccount,
      },
    },
    true
  )

  const handlePressDeleteConfirm = () => {
    deleteAccountQuery.mutate()
  }

  const handlePressCancel = () => {
    setModalVisible(false)
  }

  const handlePressDelete = () => {
    setModalVisible(true)
  }

  return (
    <View sx={sxStyles.buttonWrapper}>
      {isDisabled && (
        <Text variant={'caption2Bold'} sx={sxStyles.disabledDelete}>
          {t('profile.delete.disabled')}
        </Text>
      )}
      <IconButton
        iconName={'close'}
        left
        title={t('button.deleteAccount')}
        onPress={handlePressDelete}
        color={'secondaryColor500'}
        appearance={'outline'}
        status={'danger'}
        size={'small'}
        disabled={isDisabled}
      />
      <ConfirmModal
        modalVisible={modalVisible}
        onPressCancel={handlePressCancel}
        onPressConfirm={handlePressDeleteConfirm}
      >
        <View sx={sxStyles.modalContent}>
          <Text sx={sxStyles.title} variant={'body1Bold'}>
            {t(`profile.delete.${id ? 'detailText' : 'text'}`)}
          </Text>
          <Icon
            name={'warning'}
            color={dripsyTheme.theme.colors.secondaryColor500}
            size={60}
          />
          <Text sx={sxStyles.infoText} variant={'caption'}>
            {t(`profile.delete.${id ? 'detailTextInfo' : 'textInfo'}`, {email})}
          </Text>
        </View>
      </ConfirmModal>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  buttonWrapper: {
    alignSelf: 'center',
    paddingY: [2, null, 4],
  },
  modalContent: {
    flexDirection: 'column',
    alignItems: 'center',
    marginX: 2,
    marginY: 2,
  },
  title: {
    color: 'secondaryColor500',
    marginBottom: 2,
  },
  infoText: {
    color: 'grayColor500',
  },
  disabledDelete: {
    color: 'secondaryColor500',
    borderWidth: 2,
    borderColor: 'secondaryColor200',
    p: 1,
    mt: 1,
    mb: 2,
  },
}
