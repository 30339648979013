import {View} from 'dripsy'
import React, {useCallback} from 'react'

import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {ShopCartCustomItemRow} from 'shop_cart/components/ShopCartCustomItemRow'
import {ShopCartCustomItemsHeader} from 'shop_cart/components/ShopCartCustomItemsHeader'
import {DEFAULT_CUSTOM_PRICE_NAME} from 'shop_cart/shop_cart_constants'
import {CustomItem} from 'shop_cart/shop_cart_types'

interface ShopCartCustomItemsProps {
  customItems: CustomItem[]
  onPressDeleteRow: (index: number) => void
  onUpdateItem: () => void
  isNewRow: boolean
  onChangeIsNewRow: () => void
  updateErrorState: (errorState: any) => void
}

const ShopCartCustomItemsComponent: React.FC<ShopCartCustomItemsProps> = ({
  onPressDeleteRow,
  onUpdateItem,
  customItems,
  isNewRow,
  onChangeIsNewRow,
  updateErrorState,
}) => {
  const sxStyles = useThemeSxStyles(applyStyles)

  // check if it is new Row (clicked newRow button, price and amount is empty) and
  // check if value of name is DEFAULT_CUSTOM_PRICE_NAME => not changed data
  const isNewRowAndFocus = useCallback(
    (item: CustomItem) =>
      isNewRow &&
      !item.price &&
      !item.amount &&
      item.name === DEFAULT_CUSTOM_PRICE_NAME,
    [isNewRow]
  )

  return (
    <View sx={sxStyles.container}>
      <ShopCartCustomItemsHeader />
      {customItems.map((item, index) => (
        <ShopCartCustomItemRow
          key={`customItems.${item.id ?? index}`}
          onPressDelete={onPressDeleteRow}
          onUpdateItem={onUpdateItem}
          index={item.id ?? index}
          customItemId={item.id}
          customItem={item}
          updateErrorState={updateErrorState}
          onChangeIsNewRow={onChangeIsNewRow}
          isNewRowAndFocus={
            // check only for last row
            index === customItems.length - 1 && isNewRowAndFocus(item)
          }
        />
      ))}
    </View>
  )
}

export const ShopCartCustomItems = React.memo(ShopCartCustomItemsComponent)

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    paddingBottom: [4, null, 5],
    paddingTop: [3, null, 4],
  },
})
