import {View, Text} from 'dripsy'
import {isEmpty} from 'lodash'
import React from 'react'
import {Control, FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {Api} from 'config/app_config'
import {AutoCompleteInputController} from 'form/components/AutoCompleteInputController'
import {InputController} from 'form/components/InputController'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {FormFields, USER_SHOP_CITY} from 'register/register_constants'

interface RegisterFormProps {
  control: Control<any>
  errors: FieldErrors
  isColumn?: boolean
  title?: string
  formFields: FormFields
  children?: React.ReactNode
}

export const RegisterForm: React.FC<RegisterFormProps> = ({
  control,
  errors,
  isColumn,
  title,
  formFields,
  children,
}) => {
  const {t} = useTranslation()
  const sxStyles = applyStyles()
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <View sx={sxStyles.container} accessibilityRole={isWeb ? 'form' : 'none'}>
      {title && (
        <Text variant={'h6Bold'} sx={sxStyles.title}>
          {t(title)}
        </Text>
      )}
      {formFields.map((formItem) =>
        formItem.id === USER_SHOP_CITY ? (
          <AutoCompleteInputController
            control={control}
            id={formItem.id}
            key={formItem.id}
            isColumn={isColumn}
            error={errors[formItem.id]}
            label={formItem.label}
            url={Api.town}
            hideErrorInput={isEmpty(errors[formItem.id])}
          />
        ) : (
          <InputController
            key={formItem.id}
            control={control}
            error={errors[formItem.id]}
            hideErrorInput={isEmpty(errors[formItem.id])}
            id={formItem.id}
            label={formItem.label}
            isColumn={isColumn}
            isSecured={formItem.isPassword}
            keyboardType={formItem.keyboardType ?? 'default'}
            autoCapitalize={formItem.keyboardType ? 'none' : 'sentences'}
            textStyle={{
              ...(formItem.isCapitalized ? {textTransform: 'uppercase'} : {}),
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            autoComplete={formItem.isPassword ? 'password-new' : 'off'}
          />
        )
      )}
      {children}
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    flexDirection: 'column' as const,
    gap: 1,
  },
  title: {
    fontSize: [3, null, 5],
    lineHeight: [3, null, 5],
    color: 'black',
    alignSelf: 'center',
    paddingBottom: [4, null, 5],
  },
})
