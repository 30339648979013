import {Text, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import adminOrderDetailCustomItemsGridConfig from '../admin_order_detail_custom_items_config.json'
import userOrderDetailGridCustomItemsConfig from '../order_detail_custom_items_config.json'
import {Api} from 'config/app_config'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {OrderDetailCustomItemsGrid} from 'order/components/OrderDetailCustomItemsGrid'
import {
  USER_ORDER_CUSTOM_ITEMS_INSTANCE_REDUCER_STATE_ID,
  ADMIN_USER_ORDER_CUSTOM_ITEMS_INSTANCE_REDUCER_STATE_ID,
} from 'order/order_constants'

interface Props {
  orderId: number | string
  isAdmin: boolean
}

const OrderDetailCustomItemsComponent: React.FC<Props> = ({
  isAdmin,
  orderId,
}) => {
  const {t} = useTranslation()
  return (
    <>
      <View sx={sxStyles.gridWrapper}>
        <Text variant={'body1Bold'} sx={sxStyles.title}>
          {t('order.detail.customItems.title')}
        </Text>
        <OrderDetailCustomItemsGrid
          dataServiceUrl={Api.customItem}
          dataServiceConfig={{
            params: {
              where: {
                orderId,
              },
            },
          }}
          gridConfig={
            isAdmin
              ? adminOrderDetailCustomItemsGridConfig
              : userOrderDetailGridCustomItemsConfig
          }
          stateId={
            isAdmin
              ? ADMIN_USER_ORDER_CUSTOM_ITEMS_INSTANCE_REDUCER_STATE_ID
              : USER_ORDER_CUSTOM_ITEMS_INSTANCE_REDUCER_STATE_ID
          }
          hasSearchInput={false}
          customPagination
        />
      </View>
    </>
  )
}

export const OrderDetailCustomItems = React.memo(
  OrderDetailCustomItemsComponent
)

const sxStyles: ObjectsOfSxProps = {
  gridWrapper: {
    marginY: [5],
  },
  title: {
    pt: 2,
    textAlign: 'center',
    color: 'grayColor500',
  },
}
