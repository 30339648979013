import {Text} from 'dripsy'
import React from 'react'

import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  value: string
}

export const NativeGridItemHeaderComponent: React.FC<Props> = ({value}) => {
  const sxStyles = applyStyles()
  return (
    <Text sx={sxStyles.label} variant={'caption'}>
      {value}
    </Text>
  )
}

export const NativeGridItemHeader = React.memo(NativeGridItemHeaderComponent)

const applyStyles = (): ObjectsOfSxProps => ({
  label: {
    color: 'grayColor600',
    flex: 0.4,
    marginRight: 1,
    alignSelf: 'center',
    lineHeight: 1,
  },
})
