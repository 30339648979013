import {BottomTabScreenProps} from '@react-navigation/bottom-tabs'
import {CompositeScreenProps} from '@react-navigation/native'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {UserDetailScreen} from './UserDetailScreen'
import {UserScreen} from './UserScreen'
import {
  AuthorizedTabParamList,
  RootStackParamList,
  UserStackParamList,
} from 'layout/layout_types'

const UsersStack = createNativeStackNavigator<UserStackParamList>()

type Props = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList, 'NavUserScreen'>,
  NativeStackScreenProps<RootStackParamList>
>

export const NavUserScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <UsersStack.Navigator
      initialRouteName={'UserScreen'}
      screenOptions={{headerShown: false}}
    >
      <UsersStack.Screen
        name={'UserScreen'}
        component={UserScreen}
        options={{title: t('routes.user')}}
      />
      <UsersStack.Screen
        name={'UserDetailScreen'}
        component={UserDetailScreen}
        options={{title: t('routes.user')}}
      />
    </UsersStack.Navigator>
  )
}
