import {SxProp} from 'dripsy'
import React from 'react'

import {LabelValue} from 'common/components/LabelValue'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  isColumn?: boolean
  isDate?: boolean
  label: string
  labelStyle?: SxProp
  numberOfLinesForValue?: number
  translatePrefix?: string
  value?: string | number | null
  valueStyle?: SxProp
  wrapperStyle?: SxProp
}

export const DisabledLabelValue: React.FC<Props> = ({
  isColumn,
  isDate,
  label,
  labelStyle,
  numberOfLinesForValue,
  value,
  translatePrefix,
  valueStyle,
  wrapperStyle,
}) => {
  const sxStyles = applyStyles()

  return (
    <LabelValue
      isColumn={isColumn}
      isDate={isDate}
      label={label}
      labelStyle={{...sxStyles.labelStyle, ...labelStyle}}
      numberOfLinesForValue={numberOfLinesForValue}
      translatePrefix={translatePrefix}
      value={value == null ? undefined : value}
      valueStyle={{...sxStyles.valueStyle, ...valueStyle}}
      wrapperStyle={{...sxStyles.wrapperStyle, ...wrapperStyle}}
    />
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  labelStyle: {},
  valueStyle: {
    fontSize: 4,
    height: 40,
    paddingTop: 2,
    paddingLeft: 1,
  },
  wrapperStyle: {
    paddingTop: 1,
  },
})
