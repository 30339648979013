import {KeyboardTypeOptions} from 'react-native'

export interface FormField {
  id: string
  label: string
  isCapitalized?: boolean
  isPassword?: boolean
  isSelect?: boolean
  isDisabled?: boolean
  isNumber?: boolean
  hasNullValue?: boolean
  isDate?: boolean
  selectOptions?: string
  translatePrefix?: string
  keyboardType?: KeyboardTypeOptions
}

export const USER_SHOP_CITY = 'userShopCity'

export type FormFields = FormField[]

export const FORM_FIELDS: FormFields = [
  {id: 'email', label: 'register.email', keyboardType: 'email-address'},
  {id: 'password', label: 'register.password', isPassword: true},
  {id: 'fullName', label: 'register.fullName'},
  {id: 'phone', label: 'register.phone', keyboardType: 'phone-pad'},
]

export const USER_SHOP_FORM_FIELDS: FormFields = [
  {id: 'userShopName', label: 'shop.name'},
  {id: 'userShopStreet', label: 'shop.street'},
  {id: 'userShopStreetNumber', label: 'shop.streetNumber'},
  {id: USER_SHOP_CITY, label: 'shop.city'},
  {id: 'userShopPhone', label: 'shop.phone', keyboardType: 'phone-pad'},
  {id: 'userShopCompany', label: 'shop.company'},
  {
    id: 'userShopCompanyRegistrationNumber',
    label: 'shop.companyRegistrationNumber',
    keyboardType: 'number-pad',
    isNumber: true,
  },
  {
    id: 'userShopCompanyVatNumber',
    label: 'shop.companyVatNumber',
    isCapitalized: true,
  },
]

export const REGISTRATION_FORM_CHECKBOX = {
  id: 'acceptPolicy',
}

export const SUBSCRIBE_TO_PRICE_LIST_FORM_CHECKBOX = {
  id: 'isSubscribedForPriceList',
  label: 'user.priceListSubscribe',
}
