import {ReducerUtils} from '@sincersoft/core'
import {GRID_REDUCER_STATE_ID} from '@sincersoft/fe-grid'

import {FormFields} from 'register/register_constants'

export const ADMIN_USER_INSTANCE_REDUCER_STATE_ID = 'adminUser'
export const USER_SHOP_INSTANCE_REDUCER_STATE_ID = 'userShop'
export const STANDARD_PL = 'priceListStandard'
export const ACTION_PL = 'priceListAction'
export const USER_STATE = 'userState'

export const ADMIN_USER_INSTANCE_REDUCER_STATE_GRID_ID =
  ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    ADMIN_USER_INSTANCE_REDUCER_STATE_ID
  )

export const USER_SHOP_INSTANCE_REDUCER_STATE_GRID_ID =
  ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    USER_SHOP_INSTANCE_REDUCER_STATE_ID
  )

export type UserPriceListType =
  | 'priceListStandard'
  | 'priceListActionPrimary'
  | 'priceListActionSecondary'

export interface ModalVisiblePayload {
  visible: boolean
  isEdit: boolean
  shopId: null | string | number
}

export interface UserState {
  modalVisible: boolean
  isModalModeEdit: boolean
  shopIdForModal: null | string | number
}

export const USER_STATE_OPTIONS = [
  {id: 'confirmed', name: 'confirmed'},
  {id: 'unconfirmed', name: 'unconfirmed'},
]

export const USER_FORM_FIELDS: FormFields[] = [
  [
    {id: 'fullName', label: 'user.name'},
    {id: 'phone', label: 'user.phone', keyboardType: 'phone-pad'},
    {
      id: 'email',
      label: 'user.email',
      keyboardType: 'email-address',
      isDisabled: true,
    },
  ],
  [
    {
      id: 'priceListStandard',
      label: 'user.priceListStandard',
      isSelect: true,
      selectOptions: STANDARD_PL,
    },
    {
      id: 'priceListActionPrimary',
      label: 'user.priceList1',
      isSelect: true,
      selectOptions: ACTION_PL,
      hasNullValue: true,
    },
    {
      id: 'priceListActionSecondary',
      label: 'user.priceList2',
      isSelect: true,
      selectOptions: ACTION_PL,
      hasNullValue: true,
    },
  ],
  [
    {id: 'createdAt', label: 'user.date', isDisabled: true, isDate: true},
    {
      id: 'userStatus',
      label: 'user.state',
      isSelect: true,
      selectOptions: 'userState',
      translatePrefix: 'user',
    },
  ],
]

export const SHOP_FORM_FIELDS: FormFields = [
  {id: 'name', label: 'shop.name'},
  {id: 'street', label: 'shop.street'},
  {id: 'streetNumber', label: 'shop.streetNumber'},
  {id: 'city', label: 'shop.city'},
  {id: 'phone', label: 'shop.phone', keyboardType: 'phone-pad'},
  {id: 'company', label: 'shop.company'},
  {
    id: 'companyRegistrationNumber',
    label: 'shop.companyRegistrationNumber',
    keyboardType: 'number-pad',
  },
  {id: 'companyVatNumber', label: 'shop.companyVatNumber', isCapitalized: true},
]
