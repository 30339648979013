import {MaterialIcons} from '@expo/vector-icons'
import {View, Text, useDripsyTheme} from 'dripsy'
import React, {useCallback} from 'react'
import {TouchableOpacity} from 'react-native'

import {Icon} from 'common/components/Icon'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface NewOrderItemProps {
  onPress: () => void
  title: string
  iconName: keyof typeof MaterialIcons.glyphMap
  isDisabled: boolean
}

const NewOrderItemComponent: React.FC<NewOrderItemProps> = ({
  onPress,
  iconName,
  title,
  isDisabled,
}) => {
  const sxStyles = applyStyles(isDisabled)
  const dripsyTheme = useDripsyTheme()

  const handlePress = useCallback(() => {
    if (!isDisabled) {
      onPress()
    }
  }, [onPress, isDisabled])

  return (
    <View sx={sxStyles.container}>
      <TouchableOpacity accessibilityRole='button' onPress={handlePress}>
        <View sx={sxStyles.content}>
          <Icon
            name={iconName}
            onPress={handlePress}
            color={
              isDisabled
                ? dripsyTheme.theme.colors.grayColor300
                : dripsyTheme.theme.colors.grayColor500
            }
          />
          <Text sx={sxStyles.title}>{title}</Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}

export const NewOrderItem = React.memo(NewOrderItemComponent)

const applyStyles = (isDisabled: boolean): ObjectsOfSxProps => ({
  container: {
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: isDisabled ? 'grayColor300' : 'grayColor400',
    borderRadius: 15,
    my: 3,
    mx: [0, 3],
    width: ['100%', 'auto'],
    justifyContent: 'center',
  },
  content: {
    alignItems: 'center',
    padding: [6],
    flex: 1,
    flexDirection: 'column',
  },
  title: {
    color: isDisabled ? 'grayColor300' : 'grayColor500',
    pb: 1,
  },
})
