import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {OrderGrid} from './OrderGrid'
import adminOrderGridConfig from '../admin_order_grid_config.json'
import {
  ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID,
  ADMIN_ORDER_INSTANCE_REDUCER_STATE_ID,
} from '../order_constants'
import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {Api} from 'config/app_config'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {
  AdminOrderStackParamList,
  AuthorizedScreenProps,
  ObjectsOfSxProps,
} from 'layout/layout_types'

type Props = CompositeScreenProps<
  NativeStackScreenProps<AdminOrderStackParamList, 'AdminOrderScreen'>,
  AuthorizedScreenProps
>

export const AdminOrderScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  useRefetchQuery([
    {key: ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
  ])

  return (
    <View sx={sxStyles.container}>
      <Header />
      <AuthorizedLayout
        title={t('routes.order')}
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbItem label={t('routes.order')} isLast />
          </Breadcrumbs>
        }
      >
        <OrderGrid
          dataServiceUrl={Api.order}
          gridConfig={adminOrderGridConfig}
          dataServiceConfig={{
            params: {
              populate: 'userId, userShopId, customItems',
            },
          }}
          stateId={ADMIN_ORDER_INSTANCE_REDUCER_STATE_ID}
          placeholder={t('order.placeholder')}
          customPagination
        />
      </AuthorizedLayout>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
  },
}
