import {BottomTabScreenProps} from '@react-navigation/bottom-tabs'
import {CompositeScreenProps} from '@react-navigation/native'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {AdminOrderDetailScreen} from './AdminOrderDetailScreen'
import {AdminOrderScreen} from './AdminOrderScreen'
import {
  AdminOrderStackParamList,
  AuthorizedTabParamList,
  RootStackParamList,
} from 'layout/layout_types'
import {OrderProductDetailScreen} from 'order/components/OrderProductDetailScreen'

const AdminOrderStack = createNativeStackNavigator<AdminOrderStackParamList>()

type Props = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList, 'NavAdminOrderScreen'>,
  NativeStackScreenProps<RootStackParamList>
>

export const NavAdminOrderScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <AdminOrderStack.Navigator
      initialRouteName={'AdminOrderScreen'}
      screenOptions={{headerShown: false}}
    >
      <AdminOrderStack.Screen
        name={'AdminOrderScreen'}
        component={AdminOrderScreen}
        options={{title: t('routes.order')}}
      />
      <AdminOrderStack.Screen
        name={'AdminOrderDetailScreen'}
        component={AdminOrderDetailScreen}
        initialParams={{id: '1'}}
        options={{title: t('routes.order')}}
      />
      <AdminOrderStack.Screen
        name={'AdminOrderProductDetailScreen'}
        component={OrderProductDetailScreen}
        initialParams={{orderId: '1', orderItemId: '1', productId: '1'}}
        options={{title: t('routes.order')}}
      />
    </AdminOrderStack.Navigator>
  )
}
