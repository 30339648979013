import {HeaderLinksProps} from './header_types'

export const AdminRoutes: HeaderLinksProps = {
  user: {
    route: 'NavUserScreen',
    name: 'routes.user',
    icon: 'groups',
  },
  order: {
    route: 'NavAdminOrderScreen',
    name: 'routes.order',
    icon: 'list-alt',
  },
  product: {
    route: 'NavProductScreen',
    name: 'routes.product',
    icon: 'widgets',
  },
  category: {
    route: 'NavProductCategoryScreen',
    name: 'routes.category',
    icon: 'category',
  },
  priceList: {
    route: 'NavAdminPriceListScreen',
    name: 'routes.priceList',
    icon: 'request-page',
  },
  profile: {
    route: 'NavProfileScreen',
    name: 'routes.profile',
    icon: 'request-page',
    hide: true,
  },
  report: {
    route: 'NavReportScreen',
    name: 'routes.report',
    icon: 'report',
    hide: true,
  },
}

export const ClientRoutes: HeaderLinksProps = {
  newOrder: {
    route: 'NavNewOrderScreen',
    name: 'routes.newOrder',
    icon: 'add',
  },
  order: {
    route: 'NavOrderScreen',
    // webRoute: 'Orders',
    name: 'routes.order',
    icon: 'list-alt',
  },
  orderTemplateList: {
    route: 'NavOrderTemplateListScreen',
    name: 'routes.orderTemplateList',
    icon: 'star',
  },
  priceList: {
    route: 'NavPriceListScreen',
    name: 'routes.priceList',
    icon: 'request-page',
  },
  shopCart: {
    route: 'NavShopCartScreen',
    name: 'routes.shopCart',
    icon: 'shopping-cart',
    forMobile: true,
  },
  profile: {
    route: 'NavProfileScreen',
    name: 'routes.profile',
    icon: 'request-page',
    hide: true,
  },
  report: {
    route: 'NavReportScreen',
    name: 'routes.report',
    icon: 'report',
    hide: true,
  },
}
