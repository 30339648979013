import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {View} from 'dripsy'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {NewOrderProduct} from './NewOrderProduct'
import {ProductOrCategorySkeleton} from './ProductOrCategorySkeleton'
import {
  getDataConfigForActiveCategoryById,
  getDataConfigForActiveProductsByCategoryId,
} from '../new_order_helpers'
import {CategoryItem, ProductItem} from '../new_order_types'
import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {SearchInput} from 'common/components/SearchInput'
import {useEnhancedData} from 'common/hooks/useEnhancedData'
import {useFilterData} from 'common/hooks/useFilterData'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {AuthorizedLayoutDeleteShopCart} from 'layout/components/AuthorizedLayoutDeleteShopCart'
import {ScreenKeyboardAwareScrollView} from 'layout/components/ScreenKeyboardAwareScrollView'
import {
  AuthorizedScreenProps,
  NewOrderStackParamList,
  ObjectsOfSxProps,
} from 'layout/layout_types'

type Props = CompositeScreenProps<
  NativeStackScreenProps<NewOrderStackParamList, 'NewOrderProductScreen'>,
  AuthorizedScreenProps
>

export const NewOrderProductScreen: React.FC<Props> = ({navigation, route}) => {
  const {t} = useTranslation()
  const {
    filteredData,
    handlePressDeleteIcon,
    handleChangeSearchValue,
    searchValue,
  } = useFilterData()

  const displaySearchInputBasic = useIsNarrowScreen(2)
  const sxStyles = applyStyles()

  const {getEnhancedData, isRefetching} = useEnhancedData()

  const configForActiveProductsWithCategoryId =
    getDataConfigForActiveProductsByCategoryId(
      route.params.idCategory,
      'name ASC'
    )
  const configForActiveCategoryById = getDataConfigForActiveCategoryById(
    route.params.idCategory
  )

  const productCategoryKey = extractKey({
    params: configForActiveCategoryById.params,
    url: configForActiveCategoryById.url,
  })

  const productCategoryQuery = useApiQuery<CategoryItem>(
    productCategoryKey,
    undefined,
    {
      axiosConfig: {
        ...configForActiveCategoryById,
      },
    },
    true
  )
  const newOrderProductKey = extractKey({
    params: configForActiveProductsWithCategoryId.params,
    url: configForActiveProductsWithCategoryId.url,
  })
  const {
    data: queryData,
    isLoading,
    remove,
    isStale,
  } = useApiQuery<ProductItem[]>(
    newOrderProductKey,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForActiveProductsWithCategoryId,
      },
    },
    true
  )

  const data = useMemo(() => queryData?.rowsData ?? queryData, [queryData])

  // remove data
  useEffect(() => {
    return () => {
      remove()
    }
  }, [remove])

  const handlePressItem = useCallback(
    (id: string) => {
      navigation.navigate('NewOrderProductDetailScreen', {id})
    },
    [navigation]
  )

  const handleChangeSearch = useCallback(
    (value: string) => {
      if (data) {
        handleChangeSearchValue(value, data, ['name'])
      }
    },
    [data, handleChangeSearchValue]
  )

  const handlePressDelete = useCallback(() => {
    if (data) {
      handlePressDeleteIcon(data)
    }
  }, [data, handlePressDeleteIcon])

  useRefetchQuery(
    [{key: newOrderProductKey}, {key: productCategoryKey}],
    isStale
  )

  const shopCartActionButtons = useCallback(
    () => (
      <AuthorizedLayoutDeleteShopCart
        displayDeleteShopCart
        displayGoToShopCart
      />
    ),
    []
  )

  return (
    <>
      <Header hasBackButton />
      <ScreenKeyboardAwareScrollView>
        <AuthorizedLayout
          displayActualShop
          subTitle={'newOrder.product.subTitle'}
          subTitleValue={{
            category:
              productCategoryQuery.data && productCategoryQuery.data?.name,
          }}
          title={t('newOrder.title')}
          shopCartActionButtons={shopCartActionButtons}
          breadcrumbs={
            <Breadcrumbs>
              <BreadcrumbItem
                label={t('newOrder.order')}
                onPress={() => {
                  navigation.navigate('NavNewOrderScreen', {
                    screen: 'NewOrderScreen',
                  })
                }}
              />
              <BreadcrumbItem
                label={t('newOrder.category.breadcrumbsSubTitle')}
                onPress={() => {
                  navigation.navigate('NavNewOrderScreen', {
                    screen: 'NewOrderCategoryScreen',
                  })
                }}
              />
              <BreadcrumbItem
                label={t('newOrder.product.breadcrumbsSubTitle', {
                  category: productCategoryQuery.data?.name,
                })}
                isLast
              />
            </Breadcrumbs>
          }
          searchInput={
            !displaySearchInputBasic && (
              <View sx={sxStyles.searchInput}>
                <SearchInput
                  value={searchValue}
                  onChangeText={handleChangeSearch}
                  onPressDeleteIcon={handlePressDelete}
                  placeholder={t('newOrder.product.placeholder')}
                />
              </View>
            )
          }
        >
          {isLoading ? (
            <View sx={{flex: 1}}>
              <ProductOrCategorySkeleton />
            </View>
          ) : null}
          {data && !isLoading && (
            <NewOrderProduct
              list={getEnhancedData(searchValue === '' ? data : filteredData)}
              onPressItem={handlePressItem}
              onPressDeleteIcon={handlePressDelete}
              searchValue={searchValue}
              onChangeSearchValue={handleChangeSearch}
              productCategoryEntityImageId={
                productCategoryQuery?.data?.entityImageId ?? null
              }
              productCategoryEntityVersion={
                productCategoryQuery?.data?.entityImageVersion ?? 0
              }
              displaySearchInputBasic={displaySearchInputBasic}
              isButtonsDisabled={isRefetching}
            />
          )}
        </AuthorizedLayout>
      </ScreenKeyboardAwareScrollView>
    </>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  searchInput: {
    width: 250,
    position: 'absolute',
    pt: [3, null, 4],
  },
})
