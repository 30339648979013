import {Modal} from '@ui-kitten/components'
import {View, Text} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {IconButton} from 'common/components/IconButton'
import {ModalContentWrapper} from 'common/components/ModalContentWrapper'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  buttonConfirmText?: string
  isDisabledBackdropPress?: boolean
  isDisabledConfirm?: boolean
  isLoading?: boolean
  modalVisible: boolean
  onPressCancel: () => void
  onPressConfirm: () => void
  text?: string
  children?: React.ReactNode
  withKeyboardAwareScroll?: boolean
}

export const ConfirmModal: React.FC<Props> = ({
  buttonConfirmText,
  isDisabledBackdropPress,
  isDisabledConfirm,
  isLoading,
  modalVisible,
  onPressCancel,
  onPressConfirm,
  text,
  children,
  withKeyboardAwareScroll,
}) => {
  const {t} = useTranslation()

  return (
    <Modal
      visible={modalVisible}
      backdropStyle={styles.backdrop}
      onBackdropPress={
        isDisabledBackdropPress ? undefined : () => onPressCancel()
      }
      style={{...(withKeyboardAwareScroll ? styles.container : {})}}
    >
      <ModalContentWrapper withKeyboardAwareScroll={withKeyboardAwareScroll}>
        <View sx={sxStyles.container}>
          {text ? (
            <Text sx={sxStyles.text} variant={'body1Bold'}>
              {text}
            </Text>
          ) : null}
          {children ? <View>{children}</View> : null}
          <View sx={sxStyles.buttons}>
            <IconButton
              iconName={'arrow-back'}
              left
              title={t('button.back')}
              onPress={onPressCancel}
              color={'primaryColor500'}
              appearance={'outline'}
              size={'small'}
              disabled={isLoading}
            />
            <IconButton
              iconName={'check'}
              left
              title={buttonConfirmText ?? t('button.confirm')}
              onPress={onPressConfirm}
              color={'primaryColor500'}
              size={'small'}
              disabled={isDisabledConfirm}
              isLoading={isLoading}
            />
          </View>
        </View>
      </ModalContentWrapper>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 300,
    minHeight: 250,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
})

const sxStyles: ObjectsOfSxProps = {
  container: {
    alignSelf: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 15,
    maxWidth: [300, 320, 500],
    padding: 2,
    marginY: 2,
    width: '100%',
  },
  text: {
    marginTop: 2,
    marginBottom: 5,
    paddingX: 2,
    textAlign: 'center',
    color: 'primaryColor500',
    flex: 1,
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
  },
  buttons: {
    marginTop: [6, null, 3],
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}
