import {yupResolver} from '@hookform/resolvers/yup'
import {NativeStackNavigationProp} from '@react-navigation/native-stack'
import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import {View, Text} from 'dripsy'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {NativeSyntheticEvent, TextInputKeyPressEventData} from 'react-native'

import {setNavigationTimeout} from 'common/navigation_thunks'
import {ConfirmRegisterButton} from 'confirm_register/components/ConfirmRegisterButton'
import {ApiError, getErrorMessageCode} from 'error/error_helper'
import {InputController} from 'form/components/InputController'
import {PasswordSchema} from 'form/form_schemas'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps, RootStackParamList} from 'layout/layout_types'
// import {Notice} from 'common/components/Notice'
import {getDataConfigForResetPassword} from 'reset_password/components/reset_password_helpers'
import {SHORT_SNACKBAR_DURATION} from 'snackbar/snackbar_constants'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch} from 'store/redux_hooks'

interface ResetPasswordProps {
  navigation: NativeStackNavigationProp<
    RootStackParamList,
    'ResetPasswordScreen'
  >
  title: string
  token: string
}

interface ResetPasswordForm {
  password: string
}

export const ResetPassword: React.FC<ResetPasswordProps> = ({
  navigation,
  title,
  token,
}) => {
  const {t} = useTranslation()

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: {errors},
  } = useForm<ResetPasswordForm>({
    resolver: yupResolver(PasswordSchema),
  })
  const dispatch = useAppDispatch()

  const handleSuccessResetPassword = () => {
    // TODO: after reset to login?
    dispatch(
      setNavigationTimeout({
        cb: () => {
          navigation.navigate('LoginScreen')
        },
        timeoutInMs: SHORT_SNACKBAR_DURATION,
      })
    )
    reset()
    dispatch(
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.UNAUTHORIZED,
        isVisible: true,
        data: {
          color: 'success',
          text: 'resetPassword.success',
        },
      })
    )
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.UNAUTHORIZED,
        isVisible: true,
        data: {
          color: 'error',
          text:
            getErrorMessageCode(error) ??
            'response.error.common.unexpectedError',
        },
      })
    )
  }

  const configForResetPassword = getDataConfigForResetPassword({
    ...getValues(),
    token,
  })

  const {mutate, /*isError, error,*/ reset, isLoading} = useApiMutation(
    extractKey({
      data: configForResetPassword.data,
      method: configForResetPassword.method,
      url: configForResetPassword.url,
    }),
    configForResetPassword.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessResetPassword,
        onError: handleError,
      },
      axiosConfig: {
        ...configForResetPassword,
      },
    },
    false
  )
  const sxStyles = applyStyles()

  const handlePressButton = () => {
    mutate()
  }

  const handleKeyPress = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    if (e.nativeEvent.key === 'Enter') {
      e.preventDefault()
      trigger('password').then((isWithoutError) => {
        if (isWithoutError) {
          mutate()
        }
      })
    }
  }

  return (
    <View sx={sxStyles.container}>
      <Text sx={sxStyles.title} variant={'h5Bold'}>
        {t(title)}
      </Text>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <View sx={sxStyles.input} accessibilityRole={isWeb ? 'form' : 'none'}>
        <InputController
          control={control}
          id={'password'}
          label={'register.password'}
          error={errors.password}
          hideErrorInput={!errors.password}
          isColumn
          isSecured
          onKeyPress={handleKeyPress}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          autoComplete={'password-new'}
          autoCapitalize={'none'}
        />
      </View>
      <ConfirmRegisterButton
        handlePressButton={handleSubmit(handlePressButton)}
        title={'button.createPassword'}
        isLoading={isLoading}
      />
      {/*{isError && !isLoading && (*/}
      {/*  <Notice color={'error'} text={error?.response?.data?.messageCode} />*/}
      {/*)}*/}
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    minHeight: '100%',
    flexDirection: 'column' as const,
    alignSelf: 'center',
    paddingTop: 100,
    paddingX: 5,
  },
  input: {
    paddingTop: 4,
    width: 300,
  },
  title: {
    fontSize: [5, null, 6],
    lineHeight: [5, null, 6],
    color: 'primaryColor600',
    textAlign: 'center' as const,
  },
})
