import {Text} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ObjectsOfSxProps} from 'layout/layout_types'

const EditOrderTemplateHeaderComponent: React.FC = () => {
  const {t} = useTranslation()

  return (
    <>
      <Text variant={'body1Bold'} sx={sxStyles.title}>
        {t('orderTemplate.changeTemplate')}
      </Text>
      <Text variant={'captionBold'} sx={sxStyles.changeName}>
        {t('orderTemplate.changeTemplateName')}
      </Text>
    </>
  )
}

export const EditOrderTemplateHeader = React.memo(
  EditOrderTemplateHeaderComponent
)

const sxStyles: ObjectsOfSxProps = {
  title: {
    textAlign: 'center',
    mt: 2,
    mb: 4,
    color: 'primaryColor500',
  },
  changeName: {
    color: 'grayColor500',
    px: 2,
    pb: 1,
  },
}
