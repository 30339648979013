import {
  extractKey,
  QueryClientContext,
  QueryInput,
  QueryInputAsArray,
  useApiMutation,
} from '@sincersoft/fe-core'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import * as FileSystem from 'expo-file-system'
import {ImagePickerOptions, ImagePickerResult} from 'expo-image-picker'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {IconButton} from 'common/components/IconButton'
import {useImagePicker} from 'common/hooks/useImagePicker'
// import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {
  requestMediaLibraryPermission,
  requestStoragePermission,
} from 'common/permission_helpers'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {
  normalizeUri,
  prepareFormDataForUploadImage,
} from 'common/upload_helpers'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {isWeb, isAndroid} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {isImagePickerResultSuccess} from 'price_list/price_list_types'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch} from 'store/redux_hooks'

type Props = {
  buttonText?: string
  dataConfig: QueryInput
  keyForInvalidateQuery?: QueryInputAsArray
  pickerOptions?: ImagePickerOptions
}

export const ImagePicker: React.FC<Props> = ({
  buttonText,
  dataConfig,
  keyForInvalidateQuery,
  pickerOptions,
}) => {
  // const isSmallDevice = useIsNarrowScreen(1)
  const {t} = useTranslation()
  const handlePicker = useImagePicker(pickerOptions)
  const [imagePickerResult, setImagePickerResult] =
    useState<ImagePickerResult>()
  const [uploadingFailed, setUploadingFailed] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient({context: QueryClientContext})

  const handleSuccess = (response: ResponseSuccess) => {
    reset()
    if (keyForInvalidateQuery) {
      queryClient.invalidateQueries(keyForInvalidateQuery)
    }

    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const dataConfigWithData = {
    ...dataConfig,
    data: prepareFormDataForUploadImage(imagePickerResult),
  }
  const {isLoading, mutate, reset} = useApiMutation(
    extractKey({
      url: dataConfigWithData.url,
      data: dataConfigWithData.data,
      method: dataConfigWithData.method,
    }),
    dataConfigWithData.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccess,
        onError: handleError,
      },
      axiosConfig: {
        // axios automatically transforms FormData to json, see this:
        // https://github.com/bmuenzenmeyer/axios-1.0.0-migration-guide?tab=readme-ov-file#multipart-form-data-is-no-longer-automatically-set
        ...(isAndroid
          ? {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          : {}),
        ...dataConfigWithData,
      },
    },
    true
  )

  useEffect(() => {
    async function imagePickerResultFunction() {
      if (isImagePickerResultSuccess(imagePickerResult) && imagePickerResult) {
        // normalize file uri for all platforms
        const fileUri = normalizeUri(imagePickerResult?.assets?.[0].uri || '')
        const fileInfo = isWeb ? null : await FileSystem.getInfoAsync(fileUri)
        if (isWeb || fileInfo?.exists) {
          // if file exists, call upload request
          mutate()
        } else {
          setUploadingFailed(true)
        }
      }
    }

    imagePickerResultFunction()
  }, [imagePickerResult, mutate])

  useEffect(() => {
    if (uploadingFailed) {
      // show error in snackbar if anything has failed
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.AUTHORIZED,
        isVisible: true,
        data: {
          color: 'error',
          text: 'product.error.upload',
        },
      })
      setUploadingFailed(false)
    }
  }, [uploadingFailed])

  const handleOnPressPicker = async () => {
    try {
      // request permissions first
      const hasMediaLibraryPermissions = await requestMediaLibraryPermission()
      const hasStoragePermissions = await requestStoragePermission()
      if (hasMediaLibraryPermissions && hasStoragePermissions) {
        // open picker dialog
        const result = await handlePicker()
        // propagate picker dialog result to the component
        setImagePickerResult(result)
      } else {
        setUploadingFailed(true)
      }
    } catch (error) {
      console.error('Uploading image failed:', error)
      setUploadingFailed(true)
    }
  }
  return (
    <View sx={sxStyles.wrapper}>
      {/*{isSmallDevice ? (*/}
      {/*  <View sx={sxStyles.icon}>*/}
      {/*    <Icon onPress={handleOnPressPicker} name={'upload-file'} />*/}
      {/*  </View>*/}
      {/*) : (*/}
      <IconButton
        title={t(buttonText ?? 'button.uploadFile')}
        onPress={handleOnPressPicker}
        color={'secondaryColor500'}
        status={'danger'}
        iconName={'file-upload'}
        left
        isLoading={isLoading}
        size={'small'}
      />
      {/*)}*/}
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  wrapper: {
    flex: 1,
    marginRight: 2,
  },
  icon: {
    padding: [0, null, 1],
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'primaryColor200',
  },
}
