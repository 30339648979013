import {CompositeScreenProps, useNavigation} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {View} from 'dripsy'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {getDataConfigForOrderDetail} from '../order_helpers'
import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {Api} from 'config/app_config'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {AuthorizedLayoutExportButton} from 'layout/components/AuthorizedLayoutExportButton'
import {AuthorizedLayoutSaveToTemplate} from 'layout/components/AuthorizedLayoutSaveToTemplate'
import {
  AuthorizedNavigationProp,
  AuthorizedScreenProps,
  ObjectsOfSxProps,
  OrderStackParamList,
} from 'layout/layout_types'
import {OrderDetailCustomItems} from 'order/components/OrderDetailCustomItems'
import {OrderDetailInfo} from 'order/components/OrderDetailInfo'
import {OrderDetailItems} from 'order/components/OrderDetailItems'
import {OrderDetail} from 'order/order_types'

type Props = CompositeScreenProps<
  NativeStackScreenProps<OrderStackParamList, 'OrderDetailScreen'>,
  AuthorizedScreenProps
>

export const OrderDetailScreen: React.FC<Props> = ({route}) => {
  const dataConfigForOrderDetail = getDataConfigForOrderDetail(route.params.id)
  const {data} = useApiQuery<OrderDetail>(
    extractKey({
      params: dataConfigForOrderDetail.params,
      url: dataConfigForOrderDetail.url,
    }),
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...dataConfigForOrderDetail,
      },
    },
    true
  )

  const apiParams = useMemo(
    () => ({
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
    []
  )

  const actionButtons = useCallback(
    () => (
      <View sx={sxStyles.actionButtons}>
        <View sx={sxStyles.exportButtonWrapper}>
          <AuthorizedLayoutExportButton
            apiUrl={`${Api.order}/${Api.downloadAction}/${data?.documentIdentifier}`}
            fileName={`objednavka_${data?.ident}.pdf`}
            buttonText={'button.downloadOrder'}
            apiParams={apiParams}
          />
        </View>

        <AuthorizedLayoutSaveToTemplate orderId={data?.id} />
      </View>
    ),
    [apiParams, data?.documentIdentifier, data?.id, data?.ident]
  )

  const navigation = useNavigation<AuthorizedNavigationProp>()
  const {t} = useTranslation()

  return (
    <View sx={sxStyles.container}>
      <Header hasBackButton />
      <AuthorizedLayout
        title={'order.detail.title'}
        titleValue={{order: data && data.ident}}
        actionButtons={actionButtons}
        orderId={data?.id}
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbItem
              label={t('routes.order')}
              onPress={() => {
                navigation.navigate('NavOrderScreen', {
                  screen: 'OrderScreen',
                })
              }}
            />
            <BreadcrumbItem
              label={t('order.detail.breadcrumbsTitle', {
                order: data && data.ident,
              })}
              isLast
            />
          </Breadcrumbs>
        }
      >
        {data ? (
          <>
            <OrderDetailInfo data={data} isAdmin={false} />
            <OrderDetailItems orderId={data.id} isAdmin={false} />
            <OrderDetailCustomItems orderId={data.id} isAdmin={false} />
          </>
        ) : null}
      </AuthorizedLayout>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
  },
  gridWrapper: {
    marginY: [5],
  },
  exportButtonWrapper: {
    alignItems: 'flex-end',
    display: 'flex',
    flex: 1,
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
  },
}
