import {yupResolver} from '@hookform/resolvers/yup'
import {CompositeScreenProps, useNavigation} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {DateUtils} from '@sincersoft/core'
import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import {DripsyCustomTheme, Text, View} from 'dripsy'
import {isEqual, pick} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {IconButton} from 'common/components/IconButton'
import {useLoadEnumPriceListOptions} from 'common/hooks/useLoadEnumPriceLists'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {Api} from 'config/app_config'
import {ApiError, getErrorMessageCode} from 'error/error_helper'
import {SelectController} from 'form/components/SelectController'
import {PriceListExportSchema} from 'form/form_schemas'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {ContentLayout} from 'layout/components/ContentLayout'
import {isWeb} from 'layout/layout_constants'
import {
  AdminPriceListStackParamList,
  AuthorizedNavigationProp,
  AuthorizedScreenProps,
  ObjectsOfSxProps,
} from 'layout/layout_types'
import {DownloadPdfButton} from 'price_list//components/DownloadPdfButton'
import {
  getDataConfigForPriceListExportRequest,
  getPriceListCombinationLabel,
} from 'price_list/price_list_helpers'
import {
  PriceListExport,
  ResponsePriceListExport,
} from 'price_list/price_list_types'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch} from 'store/redux_hooks'

interface PriceListExportScreenForm {
  priceListActionPrimary: number
  priceListStandard: number
}

type Props = CompositeScreenProps<
  NativeStackScreenProps<
    AdminPriceListStackParamList,
    'AdminPriceListExportScreen'
  >,
  AuthorizedScreenProps
>

export const AdminPriceListExportScreen: React.FC<Props> = ({}) => {
  const [documentIdentifiers, setDocumentIdentifiers] =
    useState<
      Pick<
        ResponsePriceListExport,
        'documentIdentifierPriceList' | 'documentIdentifierPriceListChanges'
      >
    >()
  const [requestedPriceLists, setRequestedPriceLists] =
    useState<PriceListExport>()
  const dispatch = useAppDispatch()
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)
  const navigation = useNavigation<AuthorizedNavigationProp>()
  const enumPriceListOptions = useLoadEnumPriceListOptions()
  const {
    control,
    trigger,
    getValues,
    reset,
    formState: {errors},
    watch,
  } = useForm<PriceListExportScreenForm>({
    resolver: yupResolver(PriceListExportSchema),
  })

  const selectedValues = watch()

  const currentDate = DateUtils.format(new Date(), 'yyyy-MM-dd')

  const isEqualSelectedAndRequestedPriceList = useMemo(
    () => isEqual(requestedPriceLists, selectedValues),
    [requestedPriceLists, selectedValues]
  )

  const exportedCombination = useMemo(
    () =>
      requestedPriceLists
        ? getPriceListCombinationLabel(
            requestedPriceLists,
            enumPriceListOptions.standardPriceListQuery?.data,
            enumPriceListOptions.actionPriceListQuery?.data
          )
        : '',
    [enumPriceListOptions, requestedPriceLists]
  )

  const apiParams = useMemo(
    () => ({
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
    []
  )

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.AUTHORIZED,
        isVisible: true,
        data: {
          color: 'error',
          text:
            getErrorMessageCode(error) ??
            'response.error.common.unexpectedError',
        },
      })
    )
  }

  const handleSuccessExportRequest = async (data: ResponsePriceListExport) => {
    setDocumentIdentifiers(
      pick(data, [
        'documentIdentifierPriceList',
        'documentIdentifierPriceListChanges',
      ])
    )
    setRequestedPriceLists(getDataToSave(getValues()))
  }

  const getDataToSave = (actualValue: PriceListExport): PriceListExport => {
    return {
      priceListActionPrimary: actualValue.priceListActionPrimary,
      priceListStandard: actualValue.priceListStandard,
    }
  }

  const configForPriceListExport = getDataConfigForPriceListExportRequest(
    getDataToSave(getValues())
  )

  const exportPriceListQuery = useApiMutation(
    extractKey({
      url: configForPriceListExport.url,
      method: configForPriceListExport.method,
      data: configForPriceListExport.data,
    }),
    getDataToSave(getValues()),
    {
      reactMutationOptions: {
        onError: handleError,
        onSuccess: handleSuccessExportRequest,
      },
      axiosConfig: {
        ...configForPriceListExport,
      },
    },
    true
  )

  useEffect(() => {
    reset()
  }, [reset])

  const handlePressSave = useCallback(() => {
    exportPriceListQuery.mutate()
  }, [exportPriceListQuery])

  const handlePressExport = useCallback(async () => {
    // check if profile part is valid
    const isValid = await trigger()
    if (isValid) {
      handlePressSave()
    }
  }, [handlePressSave, trigger])

  return (
    <View sx={{flex: 1}}>
      <Header hasBackButton />
      <AuthorizedLayout
        title={'priceList.admin.exportTitle'}
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbItem
              label={t('priceList.admin.title')}
              onPress={() => {
                navigation.navigate('NavAdminPriceListScreen', {
                  screen: 'AdminPriceListScreen',
                })
              }}
            />
            <BreadcrumbItem label={t('priceList.admin.exportTitle')} isLast />
          </Breadcrumbs>
        }
      >
        <ContentLayout>
          <View
            sx={sxStyles.container}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            accessibilityRole={isWeb ? 'form' : 'none'}
          >
            <View sx={sxStyles.formFieldWrapper}>
              <View sx={sxStyles.item}>
                <SelectController
                  name={'priceListStandard'}
                  displayLabelProperty={'name'}
                  idProperty={'id'}
                  isColumn
                  options={enumPriceListOptions.standardPriceListQuery.data}
                  control={control}
                  error={errors.priceListStandard}
                  label={t('user.priceListStandard')}
                  labelStyle={sxStyles.label}
                  wrapperStyle={sxStyles.wrapperSelect}
                  selectStyle={sxStyles.select}
                  hideErrorInput={false}
                />
              </View>
              <View sx={sxStyles.item}>
                <SelectController
                  name={'priceListActionPrimary'}
                  displayLabelProperty={'name'}
                  idProperty={'id'}
                  isColumn
                  options={enumPriceListOptions.actionPriceListQuery.data}
                  hasNullValue
                  control={control}
                  error={errors.priceListActionPrimary}
                  label={t('user.priceList1')}
                  labelStyle={sxStyles.label}
                  wrapperStyle={sxStyles.wrapperSelect}
                  selectStyle={sxStyles.select}
                  hideErrorInput={false}
                />
              </View>
            </View>
            <View sx={sxStyles.button}>
              <IconButton
                iconName={'picture-as-pdf'}
                left
                title={t('button.exportPriceLists')}
                onPress={handlePressExport}
                color={'primaryColor500'}
                isLoading={exportPriceListQuery.isLoading}
                size={'small'}
              />
            </View>
            {isEqualSelectedAndRequestedPriceList && documentIdentifiers && (
              <View>
                <Text variant={'body2Bold'}>
                  {t('priceList.admin.exportText', {
                    combination: exportedCombination,
                  })}
                </Text>
                <DownloadPdfButton
                  apiUrl={`${Api.priceListExport}/${documentIdentifiers.documentIdentifierPriceList}`}
                  fileName={`cennik_${currentDate}_${exportedCombination}.pdf`}
                  isDisabled={exportPriceListQuery.isLoading}
                  buttonText={'button.downloadPriceList'}
                  buttonTextSuffix={exportedCombination}
                  apiParams={apiParams}
                />
                <DownloadPdfButton
                  apiUrl={`${Api.priceListExport}/${documentIdentifiers.documentIdentifierPriceListChanges}`}
                  fileName={`zmeny_cien_${currentDate}_${exportedCombination}.pdf`}
                  isDisabled={exportPriceListQuery.isLoading}
                  buttonText={'button.downloadPriceListChanges'}
                  buttonTextSuffix={exportedCombination}
                  apiParams={apiParams}
                />
              </View>
            )}
          </View>
        </ContentLayout>
      </AuthorizedLayout>
    </View>
  )
}

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  container: {
    flexDirection: ['column'],
    px: [3, null],
  },
  formFieldWrapper: {
    flexDirection: 'column',
    width: ['100%', null, '33%'],
  },
  item: {},
  label: {
    paddingBottom: 1,
    color: 'secondaryColor700',
    ...theme.text.body2Bold,
    fontSize: [2, null, 3],
  },
  select: {
    flex: 1,
  },
  wrapperSelect: {},
  button: {
    alignSelf: 'flex-start',
    paddingRight: [2, null, 8],
    paddingY: [3, null, 5],
  },
})
