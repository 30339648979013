import {ReducerUtils} from '@sincersoft/core'
import {GRID_REDUCER_STATE_ID} from '@sincersoft/fe-grid'

export const ADMIN_PRODUCT_CATEGORY_INSTANCE_REDUCER_STATE_ID =
  'adminProductCategory'

export const ADMIN_PRODUCT_CATEGORY_INSTANCE_REDUCER_STATE_GRID_ID =
  ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    ADMIN_PRODUCT_CATEGORY_INSTANCE_REDUCER_STATE_ID
  )
