import {EvaStatus} from '@ui-kitten/components/devsupport'
import {View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {IconButton} from 'common/components/IconButton'
import {Colors} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface ConfirmRegisterButtonProps {
  handlePressButton: () => void
  buttonStatus?: EvaStatus
  isLoading?: boolean
  color?: Colors
  title: string
}

export const ConfirmRegisterButton: React.FC<ConfirmRegisterButtonProps> = ({
  handlePressButton,
  buttonStatus,
  isLoading,
  title,
  color,
}) => {
  const {t} = useTranslation()
  const sxStyles = applyStyles()

  return (
    <View sx={sxStyles.button}>
      <IconButton
        iconName={'send'}
        left
        color={color ?? 'primaryColor500'}
        onPress={handlePressButton}
        title={t(title)}
        isLoading={isLoading}
        size={'small'}
        status={buttonStatus ?? 'primary'}
      />
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  button: {
    paddingTop: 3,
    alignSelf: 'center',
  },
})
