import {useNavigation} from '@react-navigation/native'
import {ReducerUtils} from '@sincersoft/core'
import {
  extractGridKey,
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {useQueryClient} from '@tanstack/react-query'
import {Button, Icon, IconProps} from '@ui-kitten/components'
import {AxiosError} from 'axios'
import {Text, View} from 'dripsy'
import React, {useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'

import {ConfirmModal} from 'common/components/ConfirmModal'
import {useExportToPdf} from 'common/hooks/useExportToPdf'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {Api} from 'config/app_config'
import {ApiError} from 'error/error_helper'
import {CellRendererParams} from 'grid/grid_types'
import {AuthorizedNavigationProp, ObjectsOfSxProps} from 'layout/layout_types'
import {EditOrderTemplate} from 'order_template/components/EditOrderTemplate'
import {ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_GRID_ID} from 'order_template/order_template_constants'
import {
  getDataConfigForDeleteOrderTemplate,
  getDataConfigForOrderTemplateOptions,
  getDataConfigForUseOrderTemplate,
} from 'order_template/order_template_helpers'
import {
  OrderTemplateItem,
  ResponseOrderTemplate,
  ResponseUpdateOrderTemplate,
} from 'order_template/order_template_types'

const EditIcon = (props: IconProps) => (
  <Icon {...props} name='edit' pack={'material'} />
)
const DeleteIcon = (props: IconProps) => (
  <Icon {...props} name='delete' pack={'material'} />
)

const ExportIcon = (props: IconProps) => (
  <Icon {...props} name='file-download' pack={'material'} />
)

export const OrderTemplateActionCellRenderer: React.FC<CellRendererParams> = ({
  data,
  isSmall,
}) => {
  const queryClient = useQueryClient({context: QueryClientContext})
  const dispatch = useDispatch()
  const navigation = useNavigation<AuthorizedNavigationProp>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const invalidateOrderTemplateOptions = () => {
    const orderTemplateGridKey = extractGridKey(Api.orderTemplate)
    const configForOrderTemplateOptions = getDataConfigForOrderTemplateOptions()
    const orderTemplateOptionsKey = extractKey({
      url: configForOrderTemplateOptions.url,
    })
    queryClient.invalidateQueries([
      orderTemplateOptionsKey,
      orderTemplateGridKey,
    ])
  }

  const handleSuccessDelete = (deletedData: ResponseOrderTemplate) => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    const orderTemplateGridKey = extractGridKey(Api.orderTemplate)
    const allData =
      queryClient.getQueryData<OrderTemplateItem[]>(orderTemplateGridKey) ?? []
    const updatedData = ReducerUtils.deleteObjectInArray(
      allData,
      data.id,
      'id',
      ''
    )
    queryClient.setQueryData(orderTemplateGridKey, updatedData)
    invalidateOrderTemplateOptions()

    dispatch(SnackbarHelpers.getSnackBarSuccess(deletedData.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const configForDeleteOrderTemplate = getDataConfigForDeleteOrderTemplate(
    data.id
  )

  const {mutate: deleteTemplateMutate} = useApiMutation(
    extractKey({
      method: configForDeleteOrderTemplate.method,
      url: configForDeleteOrderTemplate.url,
    }),
    undefined,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessDelete,
        onError: handleError,
      },
      axiosConfig: {
        ...configForDeleteOrderTemplate,
      },
    },
    true
  )

  const handleSuccessUse = async (deletedData: ResponseOrderTemplate) => {
    setIsModalVisible(false)
    await queryClient.invalidateQueries()
    navigation.navigate('NavShopCartScreen', {
      screen: 'ShopCartScreen',
    })
    dispatch(SnackbarHelpers.getSnackBarSuccess(deletedData.messageCode))
  }

  const configForUseOrderTemplate = getDataConfigForUseOrderTemplate(data.id)

  const {mutate} = useApiMutation(
    extractKey({
      method: configForUseOrderTemplate.method,
      url: configForUseOrderTemplate.url,
    }),
    undefined,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessUse,
        onError: handleError,
      },
      axiosConfig: {
        ...configForUseOrderTemplate,
      },
    },
    true
  )

  const handleDeleteOrderTemplate = () => {
    deleteTemplateMutate()
  }

  const handleEditOrderTemplate = () => {
    setIsModalVisible(true)
  }

  const handlePressModalCancel = useCallback(() => {
    setIsModalVisible(false)
  }, [setIsModalVisible])

  const handlePressModalConfirm = useCallback(() => {
    mutate()
  }, [mutate])

  const handleSuccessChangeName = (changeData: ResponseUpdateOrderTemplate) => {
    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ORDER_TEMPLATE_LIST_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    dispatch(SnackbarHelpers.getSnackBarSuccess(changeData.messageCode))
    invalidateOrderTemplateOptions()
  }
  const {handleOnPressExport} = useExportToPdf(
    `${Api.orderTemplateExport}/${data.id}`,
    `${data.name}.json`
  )

  const handlePressExportTemplate = () => {
    handleOnPressExport(true)
  }

  const renderButtons = () => (
    <>
      <Button
        appearance='ghost'
        size='small'
        accessoryLeft={EditIcon}
        onPress={handleEditOrderTemplate}
      />
      <Button
        appearance='ghost'
        size='small'
        status='danger'
        accessoryLeft={DeleteIcon}
        onPress={handleDeleteOrderTemplate}
      />
      <Button
        appearance='ghost'
        size='small'
        status='basic'
        accessoryLeft={ExportIcon}
        onPress={handlePressExportTemplate}
      />
      <ConfirmModal
        modalVisible={isModalVisible}
        onPressCancel={handlePressModalCancel}
        onPressConfirm={handlePressModalConfirm}
        withKeyboardAwareScroll
      >
        <EditOrderTemplate
          templateName={data.name ?? 'Name'}
          id={data.id ?? ''}
          onErrorChangeName={handleError}
          onSuccessChangeName={handleSuccessChangeName}
        />
      </ConfirmModal>
    </>
  )

  return isSmall ? (
    <View sx={sxStyles.container}>{renderButtons()}</View>
  ) : (
    <Text sx={sxStyles.container}>{renderButtons()}</Text>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}
