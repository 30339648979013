import {yupResolver} from '@hookform/resolvers/yup'
import {NativeStackNavigationProp} from '@react-navigation/native-stack'
import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import React from 'react'
import {useForm} from 'react-hook-form'
import {NativeSyntheticEvent, TextInputKeyPressEventData} from 'react-native'

import {ConfirmRegisterButton} from './ConfirmRegisterButton'
import {ConfirmRegisterTitleWithText} from './ConfirmRegisterTitleWithText'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {setNavigationTimeout} from 'common/navigation_thunks'
import {getDataConfigForConfirmRegisterEmail} from 'confirm_register/confirm_register_helpers'
import {ApiError, getErrorMessageCode} from 'error/error_helper'
import {InputController} from 'form/components/InputController'
import {EmailSchema} from 'form/form_schemas'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps, RootStackParamList} from 'layout/layout_types'
// import {Notice} from 'common/components/Notice'
import {SHORT_SNACKBAR_DURATION} from 'snackbar/snackbar_constants'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch} from 'store/redux_hooks'

interface ConfirmRegisterEmailProps {
  buttonTitle?: string
  errorCode?: string
  keyUrl: string
  successMessage?: string
  navigation: NativeStackNavigationProp<
    RootStackParamList,
    | 'ConfirmRegisterScreen'
    | 'RequestRegisterScreen'
    | 'ForgottenPasswordScreen'
    | 'DeleteAccountRequestScreen'
  >
  title: string
  text?: string
}

export const ConfirmRegisterEmail: React.FC<ConfirmRegisterEmailProps> = ({
  buttonTitle,
  errorCode,
  keyUrl,
  successMessage,
  navigation,
  title,
  text,
}) => {
  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(EmailSchema),
  })

  const dispatch = useAppDispatch()

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.UNAUTHORIZED,
        isVisible: true,
        data: {
          color: 'error',
          text:
            getErrorMessageCode(error, errorCode) ??
            'response.error.common.unexpectedError',
        },
      })
    )
  }

  const handleSuccessConfirm = () => {
    dispatch(
      setNavigationTimeout({
        cb: () => {
          navigation.navigate('LoginScreen')
        },
        timeoutInMs: SHORT_SNACKBAR_DURATION,
      })
    )
    reset()
    if (successMessage) {
      dispatch(
        snackbarSlice.actions.setIsVisible({
          type: SnackbarType.UNAUTHORIZED,
          isVisible: true,
          data: {
            color: 'success',
            text: successMessage,
          },
        })
      )
    }
  }

  const configForConfirmRegister = getDataConfigForConfirmRegisterEmail(
    keyUrl,
    getValues()
  )

  const {mutate, reset, isSuccess, isLoading} = useApiMutation(
    extractKey({
      data: configForConfirmRegister.data,
      method: configForConfirmRegister.method,
      url: configForConfirmRegister.url,
    }),
    configForConfirmRegister.data,
    {
      reactMutationOptions: {
        onError: handleError,
        onSuccess: handleSuccessConfirm,
      },
      axiosConfig: {
        ...configForConfirmRegister,
      },
    },
    false
  )

  const sxStyles = applyStyles()

  const handlePressButton = () => {
    mutate()
  }

  const handleKeyPress = async (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    if (e.nativeEvent.key === 'Enter') {
      e.preventDefault()
      await trigger('email').then((isWithoutError) => {
        if (isWithoutError) {
          mutate()
        }
      })
    }
  }

  return (
    <View sx={sxStyles.container}>
      <ConfirmRegisterTitleWithText title={title} text={text} />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <View sx={sxStyles.input} accessibilityRole={isWeb ? 'form' : 'none'}>
        <InputController
          control={control}
          id={'email'}
          label={'register.email'}
          error={errors.email}
          hideErrorInput={!errors.email}
          // isColumn
          onKeyPress={handleKeyPress}
          keyboardType={'email-address'}
          autoCapitalize={'none'}
          isColumn={useIsNarrowScreen(1)}
        />
      </View>
      <ConfirmRegisterButton
        handlePressButton={handleSubmit(handlePressButton)}
        title={buttonTitle ?? `button.${isSuccess ? 'login' : 'resendEmail'}`}
        isLoading={isLoading}
      />
      {/*{isError && !isLoading && (*/}
      {/*  <Notice*/}
      {/*    color={'error'}*/}
      {/*    text={errorCode ?? error?.response?.data?.messageCode}*/}
      {/*  />*/}
      {/*)}*/}
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    flex: 1,
    minHeight: '100%',
    flexDirection: 'column',
    alignSelf: 'center',
    paddingTop: 100,
    paddingX: 5,
  },
  input: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingTop: 4,
    alignSelf: 'center',
    minWidth: 250,
  },
})
