import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {Text, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {IconButton} from 'common/components/IconButton'
import {
  AuthorizedScreenProps,
  ObjectsOfSxProps,
  ShopCartStackParamList,
} from 'layout/layout_types'
import {useAppSelector} from 'store/redux_hooks'

type Props = CompositeScreenProps<
  NativeStackScreenProps<ShopCartStackParamList, 'ShopCartScreen'>,
  AuthorizedScreenProps
>

export const ShopCartEmptyComponent: React.FC<Props> = ({navigation}) => {
  const {t} = useTranslation()
  const isShopCartEnabled = useAppSelector(
    (state) => state.login.isShopCartEnabled
  )

  const handlePressNewOrder = () => {
    navigation.navigate('NavNewOrderScreen', {
      screen: 'NewOrderScreen',
    })
  }

  return (
    <View sx={sxStyles.container}>
      <Text sx={sxStyles.title} variant={'body1Bold'}>
        {t(isShopCartEnabled ? 'shopCart.empty' : 'shopCart.modalDisabledText')}
      </Text>
      <IconButton
        iconName={'category'}
        left
        title={t('button.newOrder')}
        color={'primaryColor500'}
        appearance={'outline'}
        onPress={handlePressNewOrder}
      />
    </View>
  )
}
export const ShopCartEmpty = React.memo(ShopCartEmptyComponent)

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
    alignItems: 'center',
  },
  title: {
    color: 'grayColor500',
    paddingY: 3,
  },
}
