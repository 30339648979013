import {useNavigationState} from '@react-navigation/native'
import {useApiQuery} from '@sincersoft/fe-core'
import {Modal} from '@ui-kitten/components'
import {Text, View} from 'dripsy'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import {IconButton} from 'common/components/IconButton'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'
import {ActivityTimer} from 'web_version/activity_timer'
import {VERSION_REDUCER_STATE_ID} from 'web_version/version_constants'
import {loadNewVersion, setNewVersion} from 'web_version/version_thunk'
import {VersionHashFile} from 'web_version/version_types'

export const FetchNewAppVersion: React.FC = () => {
  const [versioningTimer] = useState(ActivityTimer())
  const [isDialogOpened, setIsDialogOpened] = useState(false)
  const navigationState = useNavigationState((state) => state)
  const dispatch = useAppDispatch()
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)
  const newVersionAvailable = useAppSelector(
    (state) => state[VERSION_REDUCER_STATE_ID].newVersionAvailable
  )

  const appVersionHashUrl = '/latestVersion.json'

  const isProduction =
    (process.env.NODE_ENV === 'production' &&
      process.env.APP_ENV !== 'development') ||
    process.env.APP_ENV === 'production'

  const {data, refetch} = useApiQuery<VersionHashFile>(
    [appVersionHashUrl],
    null,
    {
      axiosConfig: {url: appVersionHashUrl},
      reactQueryOptions: {
        enabled: false,
      },
    }
  )

  useEffect(() => {
    if (isProduction) {
      refetch()
    }
    const timerId = versioningTimer.setInterval({
      callback: () => refetch(),
      interval: versioningTimer.BASIC_INTERVAL,
      forcedInterval: versioningTimer.DAY_INTERVAL,
    })

    return () => {
      if (versioningTimer) {
        // clear interval on unmount
        versioningTimer.clearInterval(timerId)
      }
    }
  }, [dispatch, versioningTimer, refetch, isProduction])

  useEffect(() => {
    if (versioningTimer) {
      versioningTimer.runTimersCheck()
    }
  }, [versioningTimer, navigationState])

  useEffect(() => {
    setIsDialogOpened(newVersionAvailable)
  }, [newVersionAvailable])

  useEffect(() => {
    if (data && data.versionHash) {
      dispatch(setNewVersion(data.versionHash))
    }
  }, [data, dispatch])

  const handleDialogCancel = () => {}

  const handleDialogConfirm = () => {
    setIsDialogOpened(false)
    dispatch(loadNewVersion())
  }

  return (
    <Modal
      visible={isDialogOpened}
      backdropStyle={styles.backdrop}
      onBackdropPress={handleDialogCancel}
      style={styles.container}
    >
      <View sx={sxStyles.container}>
        <Text sx={sxStyles.text} variant={'body1Bold'}>
          {t('version.newVersion')}
        </Text>
        <View sx={sxStyles.newVersionInfoWrapper}>
          <Text variant={'captionBold'}>{t('version.newVersionInfo')}</Text>
        </View>
        <View sx={sxStyles.buttons}>
          <IconButton
            iconName={'check'}
            left
            title={t('button.refresh')}
            onPress={handleDialogConfirm}
            color={'primaryColor500'}
            size={'small'}
          />
        </View>
      </View>
    </Modal>
  )
}

FetchNewAppVersion.displayName = 'FetchNewAppVersion'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 300,
    minHeight: 192,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
})

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    alignSelf: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 15,
    maxWidth: [300, 320, 500],
    padding: 2,
    marginY: 2,
    width: '100%',
  },
  text: {
    marginTop: 2,
    marginBottom: 5,
    paddingX: 4,
    textAlign: 'center',
    color: 'primaryColor500',
  },
  newVersionInfoWrapper: {
    flexDirection: 'row',
    paddingX: 4,
    justifyContent: 'space-between',
    py: 1,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    mt: 3,
  },
})
