import {BottomTabScreenProps} from '@react-navigation/bottom-tabs'
import {CompositeScreenProps} from '@react-navigation/native'
import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {OrderTemplateListScreen} from './OrderTemplateListScreen'
import {
  AuthorizedTabParamList,
  OrderTemplateStackParamList,
  RootStackParamList,
} from 'layout/layout_types'

const OrderTemplateListStack =
  createNativeStackNavigator<OrderTemplateStackParamList>()

type Props = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList, 'NavOrderTemplateListScreen'>,
  NativeStackScreenProps<RootStackParamList>
>

export const NavOrderTemplateListScreen: React.FC<Props> = () => {
  const {t} = useTranslation()
  return (
    <OrderTemplateListStack.Navigator
      initialRouteName={'OrderTemplateListScreen'}
      screenOptions={{headerShown: false}}
    >
      <OrderTemplateListStack.Screen
        name={'OrderTemplateListScreen'}
        component={OrderTemplateListScreen}
        options={{title: t('routes.orderTemplateList')}}
      />
    </OrderTemplateListStack.Navigator>
  )
}
