import {Text, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useWindowDimensions} from 'react-native'

import {IconButton} from 'common/components/IconButton'
import {PARSED_INT_THEME_BREAKPOINT_0} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

type Props = {
  onPressButton: () => void
  isLoading: boolean
  isShopCartEnabled: boolean
}

export const ShopCartOrderButton: React.FC<Props> = ({
  onPressButton,
  isLoading,
  isShopCartEnabled,
}) => {
  // isLoading = true
  const {t} = useTranslation()
  const {width} = useWindowDimensions()
  const isSmall = width <= PARSED_INT_THEME_BREAKPOINT_0
  return (
    <View sx={sxStyles.button}>
      <IconButton
        iconName={'send'}
        left
        title={t('button.order')}
        onPress={onPressButton}
        color={'primaryColor500'}
        isLoading={isLoading}
        disabled={isLoading || !isShopCartEnabled}
        size={'small'}
      />
      {isShopCartEnabled ? null : (
        <Text variant={'body2Bold'} sx={sxStyles.infoText}>
          {t('shopCart.modalDisabledText')}
        </Text>
      )}
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  button: {
    alignItems: 'flex-end',
    paddingBottom: [4, null, 5],
    paddingTop: [5, null, 7],
  },
  infoText: {
    pt: 2,
    color: 'secondaryColor300',
    textAlign: 'center',
  },
}
