import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {
  IsVisiblePayload,
  SnackbarState,
  SnackbarType,
} from 'snackbar/snackbar_types'

const initialState: SnackbarState = {
  isVisible: {
    [SnackbarType.AUTHORIZED]: false,
    [SnackbarType.UNAUTHORIZED]: false,
  },
  data: {
    [SnackbarType.AUTHORIZED]: undefined,
    [SnackbarType.UNAUTHORIZED]: undefined,
  },
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setIsVisible: (state, action: PayloadAction<IsVisiblePayload>) => {
      state.isVisible[action.payload.type] = action.payload.isVisible
      state.data[action.payload.type] = action.payload.isVisible
        ? action.payload.data
        : undefined
    },
  },
})
