import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {Text, useDripsyTheme, View} from 'dripsy'
import React, {useEffect} from 'react'
import {useWindowDimensions} from 'react-native'

import {ObjectsOfSxProps} from '../layout_types'
import {Icon} from 'common/components/Icon'
import {PARSED_INT_THEME_BREAKPOINT_1} from 'layout/layout_constants'
import {UserRole} from 'login/login_constants'
import {loginSlice} from 'login/login_slice'
import {NewOrderShopItem} from 'new_order/components/NewOrderShopItem'
import {getDataConfigForCart} from 'shop_cart/shop_cart_helpers'
import {ShopCart} from 'shop_cart/shop_cart_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

const AuthorizedLayoutActualShopComponent: React.FC = () => {
  const dripsyTheme = useDripsyTheme()
  const sxStyles = applyStyles()
  const dispatch = useAppDispatch()
  const activeShop = useAppSelector((state) => state.login.activeShop)
  const userRole = useAppSelector((state) => state.login.loginUser?.role)
  const isAdmin =
    userRole === UserRole.SUPER_ADMIN || userRole === UserRole.ADMIN

  const configForShopCart = getDataConfigForCart()
  const {data, refetch} = useApiQuery<ShopCart>(
    extractKey({
      url: configForShopCart.url,
    }),
    undefined,
    {
      reactQueryOptions: {
        enabled: false,
      },
      axiosConfig: {
        ...configForShopCart,
      },
    },
    true
  )

  useEffect(() => {
    if (data && !isAdmin && !activeShop) {
      dispatch(
        loginSlice.actions.setActiveShop({
          shop: data.userShopId,
        })
      )
    }
  }, [dispatch, data, activeShop])

  useEffect(() => {
    if (!activeShop && !isAdmin) {
      refetch()
    }
  }, [activeShop, refetch])

  const window = useWindowDimensions()
  return activeShop ? (
    <View sx={sxStyles.content}>
      {window.width < PARSED_INT_THEME_BREAKPOINT_1 ? (
        <View sx={sxStyles.shopForMobile}>
          <Icon
            name={'storefront'}
            color={dripsyTheme.theme.colors.primaryColor500}
          />
          <Text
            sx={sxStyles.shopName}
            variant={'captionBold'}
            numberOfLines={1}
          >
            {activeShop.name}
          </Text>
        </View>
      ) : (
        <View>
          <NewOrderShopItem
            city={activeShop.city}
            shop={activeShop.name}
            street={activeShop.street}
            isActive
          />
        </View>
      )}
    </View>
  ) : null
}

export const AuthorizedLayoutActualShop = React.memo(
  AuthorizedLayoutActualShopComponent
)

const applyStyles = (): ObjectsOfSxProps => ({
  content: {
    paddingTop: 1,
    backgroundColor: 'white',
  },
  shopForMobile: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 1,
  },
  shopName: {
    color: 'primaryColor500',
    maxWidth: ['100%', null, 250],
    pr: 2,
  },
})
