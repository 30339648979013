import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {SetLastAppVersionPayload, SetNewVersionAvailablePayload} from './types'
import {VERSION_REDUCER_STATE_ID} from './version_constants'

export interface VersionState {
  lastAppVersion: string
  newVersionAvailable: boolean
}

const initialState: VersionState = {
  lastAppVersion: '',
  newVersionAvailable: false,
}

export const versionSlice = createSlice({
  name: VERSION_REDUCER_STATE_ID,
  initialState,
  reducers: {
    setLastAppVersion: (
      state,
      action: PayloadAction<SetLastAppVersionPayload>
    ) => {
      state.lastAppVersion = action.payload.lastAppVersion
    },
    setNewVersionAvailable: (
      state,
      action: PayloadAction<SetNewVersionAvailablePayload>
    ) => {
      state.newVersionAvailable = action.payload.newVersionAvailable
    },
  },
})
