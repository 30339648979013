import {DripsyCustomTheme, Text, View} from 'dripsy'
import {get} from 'lodash'
import React from 'react'

import {BasicLinkText} from 'common/components/BasicLinkText'
import {Link} from 'common/components/Link'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {MOBILE_GRID_VALUE} from 'grid/components/grid_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface OwnProps {
  data: any
  value: any
  onPress: () => void
  isSmall?: boolean
  hasLink?: boolean
  productCategoryNameProperty: string
}

type Props = OwnProps

export const NameAndCategoryLink: React.FC<Props> = (props) => {
  const {onPress, value, productCategoryNameProperty, data, isSmall, hasLink} =
    props
  const sxStyles = useThemeSxStyles(applyStyles, isSmall)

  const categoryText = get(data, productCategoryNameProperty) ?? ''

  const content = (
    <View sx={sxStyles.wrapper}>
      <Text sx={sxStyles.text} numberOfLines={1} variant={'captionBold'}>
        {value}
      </Text>
      <Text sx={sxStyles.category} numberOfLines={1}>
        {categoryText}
      </Text>
    </View>
  )

  const link = (
    <Link onPress={onPress}>
      {(pressableProps) => {
        return (
          <>
            <BasicLinkText
              text={value}
              textStyle={sxStyles.text}
              containerStyle={sxStyles.container}
              {...pressableProps}
            />
            <Text sx={sxStyles.category} numberOfLines={1} {...pressableProps}>
              {categoryText}
            </Text>
          </>
        )
      }}
    </Link>
  )

  const pressableContent = isSmall ? (
    link
  ) : (
    <View sx={sxStyles.wrapper}>{link}</View>
  )

  return hasLink ? pressableContent : content
}

const applyStyles = (
  theme: DripsyCustomTheme,
  isSmall?: boolean
): ObjectsOfSxProps => ({
  wrapper: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    // special height for row
    height: [30, null, 46],
  },
  text: {
    ...(isSmall ? MOBILE_GRID_VALUE : {}),
    ...theme.text.captionBold,
  },
  category: {
    color: 'grayColor600',
    ...(isSmall ? MOBILE_GRID_VALUE : {}),
    fontSize: [1],
  },
})
