import {Text} from 'dripsy'
import React from 'react'
import {FieldError, FieldErrorsImpl, Merge} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ObjectsOfSxProps} from 'layout/layout_types'

interface InputErrorMessageProps {
  error?: FieldError | Merge<FieldError, FieldErrorsImpl>
}

export const InputErrorMessage: React.FC<InputErrorMessageProps> = ({
  error,
}) => {
  const sxStyles = applyStyles()
  const {t} = useTranslation()
  return (
    <Text sx={sxStyles.errorMessage} numberOfLines={1} variant={'caption'}>
      {error && error.message ? t(error.message) : ' '}
    </Text>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  errorMessage: {
    color: 'secondaryColor500',
    paddingTop: 1,
    paddingX: 2,
  },
})
