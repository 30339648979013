import {SxProp, Text} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  GRID_ROW_HEADER,
  MOBILE_GRID_VALUE,
} from 'grid/components/grid_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  value: string
  isSmall?: boolean
  smallWithoutFlex?: boolean
  isTranslate?: boolean
  extraStyle?: SxProp
}

const ColumnValueComponent: React.FC<Props> = ({
  value,
  isSmall,
  isTranslate,
  extraStyle,
  smallWithoutFlex,
}) => {
  const {t} = useTranslation()
  const sxStyles = applyStyles(isSmall, smallWithoutFlex)

  return (
    <Text
      sx={{...sxStyles.text, ...extraStyle}}
      numberOfLines={1}
      variant={'caption'}
    >
      {isTranslate ? t(value) : value}
    </Text>
  )
}

export const ColumnValue = React.memo(ColumnValueComponent)

const applyStyles = (
  isSmall?: boolean,
  smallWithoutFlex?: boolean
): ObjectsOfSxProps => ({
  text: {
    // vertical centering - line-height should be equal to the height of cell
    lineHeight: GRID_ROW_HEADER,
    ...(isSmall ? MOBILE_GRID_VALUE : {}),
    ...(isSmall
      ? {
          ...(smallWithoutFlex ? {} : {flex: 1}),
          alignItems: 'flex-end',
        }
      : {}),
  },
})
