import {View, Text} from 'dripsy'
import {forOwn} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {ShopCartCustomItemButton} from 'shop_cart/components/ShopCartCustomItemButton'
import {ShopCartCustomItems} from 'shop_cart/components/ShopCartCustomItems'
import {CustomItem, ErrorState} from 'shop_cart/shop_cart_types'

interface ShopCartCustomItemsContentProps {
  onPressAddNewRow: () => void
  onPressDeleteRow: (index: number) => void
  onUpdateItem: () => void
  customItems: CustomItem[]
}

const ShopCartCustomItemsContentComponent: React.FC<
  ShopCartCustomItemsContentProps
> = ({onPressAddNewRow, onPressDeleteRow, onUpdateItem, customItems}) => {
  const [errorState, setErrorState] = useState<ErrorState>({})
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)

  const updateErrorState = useCallback(
    (errorValue: ErrorState) => {
      setErrorState((prevState) => ({...prevState, ...errorValue}))
    },
    [setErrorState]
  )

  const isDisabledAddButton = useMemo(() => {
    let result = false
    forOwn(errorState, (value, key) => {
      // key = 'id-name', where id is number
      const parseKeyToId = parseInt(key)
      const findIndex = parseKeyToId
        ? customItems.findIndex((item) => item.id === parseKeyToId)
        : -1
      if (!result && value && findIndex >= 0) {
        result = value
      }
    })
    return result
  }, [errorState, customItems])

  const [isNewRow, setIsNewRow] = useState(false)

  const handlePressAddNewRow = useCallback(() => {
    onPressAddNewRow()
    setIsNewRow(true)
  }, [onPressAddNewRow])

  const handleChangeIsNewRow = useCallback(() => {
    setIsNewRow(false)
  }, [])

  return (
    <View sx={sxStyles.container}>
      <Text variant={'body1Bold'} sx={sxStyles.title}>
        {t('shopCart.customItems.title')}
      </Text>
      <ShopCartCustomItems
        onPressDeleteRow={onPressDeleteRow}
        onUpdateItem={onUpdateItem}
        customItems={customItems}
        updateErrorState={updateErrorState}
        isNewRow={isNewRow}
        onChangeIsNewRow={handleChangeIsNewRow}
      />
      <ShopCartCustomItemButton
        onPressButton={handlePressAddNewRow}
        isDisabled={isDisabledAddButton}
      />
    </View>
  )
}

export const ShopCartCustomItemsContent = React.memo(
  ShopCartCustomItemsContentComponent
)

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    paddingBottom: [4, null, 5],
    paddingTop: [3, null, 4],
  },
  title: {
    pt: 2,
    textAlign: 'center',
    color: 'grayColor500',
  },
})
