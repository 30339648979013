import {View} from 'dripsy'
import React from 'react'
import {useSafeAreaInsets} from 'react-native-safe-area-context'

import {BOTTOM_TAB_BAR_HEIGHT} from 'layout/components/BottomTabBar'
import {isIOS} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface ContentLayoutProps {
  children: React.ReactNode
}

export const ContentLayout: React.FC<ContentLayoutProps> = ({children}) => {
  const safeAreaInsets = useSafeAreaInsets()
  const sxStyles = applyStyles(safeAreaInsets.bottom)
  return <View sx={sxStyles.content}>{children}</View>
}

const applyStyles = (insetsBottom: number): ObjectsOfSxProps => ({
  content: {
    flex: 1,
    paddingBottom: insetsBottom
      ? BOTTOM_TAB_BAR_HEIGHT + insetsBottom
      : isIOS
      ? 25
      : 15,
    flexDirection: 'column' as const,
    width: '100%',
    maxWidth: ['100%', null, null, 950, 1100, 1300],
    alignSelf: 'center',
    backgroundColor: 'white',
  },
})
