import {ReducerUtils} from '@sincersoft/core'
import {GRID_REDUCER_STATE_ID, gridSlice} from '@sincersoft/fe-grid'
import {View} from 'dripsy'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {IconButton} from 'common/components/IconButton'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {useAppDispatch} from 'store/redux_hooks'

type UsePaginationRenderPaginationButton = (
  displayedDataLength: number
) => JSX.Element | null
type UsePaginationResetPagination = () => void

export type UsePaginationReturn = {
  isLastRendered: boolean
  onIsAllRendered: () => void
  onIsLastRendered: (name: string) => void
  paginationSize: number
  renderPaginationButton: UsePaginationRenderPaginationButton
  resetPagination: UsePaginationResetPagination
}

const DEFAULT_PAGINATION_SIZE = 10

export const usePagination = (
  customPaginationSize?: number,
  stateId?: string,
  isCustomPagination?: boolean
): UsePaginationReturn => {
  const {t} = useTranslation()
  const [paginationSize, setPaginationSize] = useState(
    customPaginationSize || DEFAULT_PAGINATION_SIZE
  )

  const [currentPage, setCurrentPage] = useState(1)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLastRendered, setIsLastRendered] = useState<boolean>(false)
  const [isLastRenderedName, setIsLastRenderedName] = useState<string>('')
  const [isAllRendered, setIsAllRendered] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const gridStateId = stateId
    ? ReducerUtils.getInstanceReducerId(GRID_REDUCER_STATE_ID, stateId)
    : undefined

  useEffect(() => {
    if (isLastRendered && isAllRendered) {
      setIsLoading(false)
    }
  }, [isLastRendered, isAllRendered])

  const onIsAllRendered = () => {
    if (!isAllRendered && isLoading) {
      setIsAllRendered(true)
    }
  }

  const onIsLastRendered = useCallback(
    (name: string) => {
      if (isLastRenderedName !== name) {
        setIsLastRenderedName(name)
        if (!isLastRendered && isLoading) {
          setIsLastRendered(true)
        }
      }
    },
    [
      isLastRendered,
      isLastRenderedName,
      isLoading,
      setIsLastRendered,
      setIsLastRenderedName,
    ]
  )

  const updatePaginationSize = useCallback(() => {
    setPaginationSize(
      (prevState) =>
        prevState + (customPaginationSize || DEFAULT_PAGINATION_SIZE)
    )
  }, [setPaginationSize, customPaginationSize])

  const handleDebounceUpdate = useMemo(
    () => debounce(updatePaginationSize, 600),
    [updatePaginationSize]
  )

  const updateCurrentPage = useCallback(() => {
    setCurrentPage((prevState) => prevState + 1)
  }, [])

  const handleDebounceUpdateCurrentPage = useMemo(
    () => debounce(updateCurrentPage, 600),
    [updateCurrentPage]
  )

  const handlePressNextData = useCallback(async () => {
    setIsLoading(true)
    setIsLastRendered(false)
    setIsAllRendered(false)
    handleDebounceUpdate()
    handleDebounceUpdateCurrentPage()
  }, [handleDebounceUpdate, handleDebounceUpdateCurrentPage])

  useEffect(() => {
    if (gridStateId && isCustomPagination) {
      dispatch(
        gridSlice.actions.setGridPagination({
          pagination: {
            pageSize: customPaginationSize || DEFAULT_PAGINATION_SIZE,
            page: currentPage,
          },
          stateId: gridStateId,
        })
      )
    }
  }, [
    currentPage,
    customPaginationSize,
    dispatch,
    gridStateId,
    isCustomPagination,
  ])

  const renderButton = useCallback(() => {
    return (
      <View sx={sxStyles.button}>
        <IconButton
          iconName={'expand-more'}
          left
          title={t('button.nextItems')}
          onPress={handlePressNextData}
          color={'primaryColor500'}
          isLoading={isLoading}
        />
      </View>
    )
  }, [isLoading, handlePressNextData, t])

  const renderPaginationButton: UsePaginationRenderPaginationButton = (
    displayedDataLength
  ) => {
    return paginationSize < displayedDataLength || isCustomPagination
      ? renderButton()
      : null
  }

  const resetPagination: UsePaginationResetPagination = () => {
    setPaginationSize(DEFAULT_PAGINATION_SIZE)
    // reset to initial
    setCurrentPage(1)
    setIsLastRenderedName('')
    setIsLastRendered(false)
    setIsAllRendered(false)
  }

  return {
    paginationSize,
    renderPaginationButton,
    resetPagination,
    onIsAllRendered,
    onIsLastRendered,
    isLastRendered,
  }
}

const sxStyles: ObjectsOfSxProps = {
  button: {
    marginY: 2,
    alignItems: 'center',
  },
}
