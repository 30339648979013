import {SelectGroup, SelectItem, Select, IndexPath} from '@ui-kitten/components'
import {SelectProps} from '@ui-kitten/components/ui/select/select.component'
import {View, Text, SxProp, useSx} from 'dripsy'
import {isArray} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DisabledLabelValue} from 'common/components/DisabledLabelValue'
import {InputErrorMessage} from 'common/components/InputErrorMessage'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {PageableResponse} from 'common/types'
import {SelectValue} from 'form/components/SelectValue'
import {isSingleIndexPath} from 'form/form_types'
import {isAndroid, isIOS} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

export type SelectOption = any
export type SelectOptionValue = any

interface Props extends SelectProps {
  isSecured?: boolean
  name: string
  idProperty: string
  displayLabelProperty: string
  hasNullValue?: boolean
  hideErrorInput?: boolean
  isError?: boolean
  isColumn?: boolean
  isDisabled?: boolean
  isRow?: boolean
  error?: FieldError | Merge<FieldError, FieldErrorsImpl>
  control: Control<any>
  label?: string
  onInputChange?: (value: SelectOptionValue) => void
  onInputBlur?: () => void
  options?: SelectOption[] | PageableResponse<any>
  wrapperStyle?: SxProp
  labelStyle?: SxProp
  valueStyle?: SxProp
  selectStyle?: SxProp
  selectInputStyle?: SxProp
  translatePrefix?: string
}

const SelectControllerComponent: React.FC<Props> = (props) => {
  const {
    name,
    isError,
    error,
    control,
    hasNullValue,
    hideErrorInput = true,
    label,
    onInputChange,
    options,
    idProperty,
    isColumn,
    isDisabled,
    isRow,
    displayLabelProperty,
    wrapperStyle,
    labelStyle,
    selectStyle,
    selectInputStyle,
    valueStyle,
    translatePrefix,
    ...rest
  } = props

  const {t} = useTranslation()
  const sx = useSx()
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>()

  useEffect(() => {
    let result = []
    const dataItems = isArray(options) ? options : options?.rowsData
    if (dataItems) {
      if (hasNullValue) {
        result = [{id: null, name: ''}, ...dataItems]
      } else {
        result = dataItems
      }
    }
    setSelectOptions(result)
  }, [options, hasNullValue])

  const isSmall = useIsNarrowScreen(2)
  const isSmallDevice = isAndroid || isIOS || isSmall

  const sxStyles = applyStyles(isColumn || isSmallDevice, isRow || false)

  const getStringValue = (
    value: SelectOptionValue,
    optionItems?: SelectOption[]
  ) => {
    let result = ''
    if (value && optionItems) {
      optionItems.forEach((item) => {
        if (item.items) {
          const stringValue = getStringValue(value, item.items)
          if (stringValue !== '') {
            result = stringValue
          }
        } else {
          const ownValue =
            value != null && typeof value === 'object'
              ? value[idProperty]
              : value
          if (ownValue === item[idProperty]) {
            result = item[displayLabelProperty]
          }
        }
      })
    }
    return result
  }

  const getStringValueForSelect = (
    value: SelectOptionValue,
    optionItems?: SelectOption[]
  ) => {
    const result = getStringValue(value, optionItems)
    return translatePrefix ? t(`${translatePrefix}.${result}`) : t(result)
  }

  const getSelectedIndex = (
    value: SelectOptionValue,
    optionItems: SelectOption[],
    parentIdx?: number
  ) => {
    let result
    if (value) {
      optionItems?.forEach((oItem, idx) => {
        if (oItem.items) {
          getSelectedIndex(value, oItem.items, idx)
        } else {
          const ownValue =
            value != null && typeof value === 'object'
              ? value[idProperty]
              : value
          if (oItem[idProperty] === ownValue) {
            if (parentIdx) {
              result = new IndexPath(idx, parentIdx)
            } else {
              result = new IndexPath(idx)
            }
          }
        }
      })
    }
    return result
  }

  const renderOptions = (optionItems?: SelectOption[]) => {
    return optionItems?.map((selectOption, idx) =>
      selectOption.name ? (
        <SelectItem
          title={
            translatePrefix
              ? t(`${translatePrefix}.${selectOption[displayLabelProperty]}`)
              : selectOption[displayLabelProperty]
          }
          key={selectOption[idProperty]}
          disabled={selectOption.disabled}
        />
      ) : (
        <SelectGroup
          title={
            translatePrefix
              ? t(`${translatePrefix}.${selectOption.title}`)
              : selectOption.title
          }
          key={`${selectOption.title}${idx}`}
        >
          {renderOptions(selectOption.items)}
        </SelectGroup>
      )
    )
  }
  const getValueFromOptions = (rowIdx: number, sectionIdx?: number): string => {
    let result
    if (selectOptions) {
      if (sectionIdx || sectionIdx === 0) {
        result = selectOptions[sectionIdx].items[rowIdx][idProperty]
      } else {
        result = selectOptions[rowIdx][idProperty]
      }
    }
    return result
  }

  const placeholder = useCallback(
    () => <Text>{selectOptions ? t('select.placeholder') : ''}</Text>,
    [t, selectOptions]
  )

  return (
    <View sx={{...sxStyles.container, ...wrapperStyle}}>
      <Controller
        control={control}
        name={name}
        render={({field: {onChange: onControllerChange, value}}) => {
          const stringValue = getStringValueForSelect(
            value,
            selectOptions ?? []
          )
          return isDisabled ? (
            <DisabledLabelValue
              {...rest}
              label={label ?? ''}
              labelStyle={labelStyle}
              value={value}
              isColumn={isColumn}
              translatePrefix={translatePrefix}
            />
          ) : (
            <View sx={sxStyles.item}>
              {label ? (
                <Text
                  sx={{...sxStyles.label, ...labelStyle}}
                  variant={'caption'}
                >
                  {label}
                </Text>
              ) : null}
              <View sx={{...sxStyles.selectWrapper, ...selectStyle}}>
                <Select
                  placeholder={placeholder}
                  style={sx({...sxStyles.selectInput, ...selectInputStyle})}
                  selectedIndex={getSelectedIndex(value, selectOptions ?? [])}
                  status={isError ? 'danger' : 'basic'}
                  onSelect={(index) => {
                    const changeValue =
                      selectOptions && isSingleIndexPath(index)
                        ? getValueFromOptions(index.row, index.section)
                        : null
                    onControllerChange(changeValue)
                    if (onInputChange) {
                      onInputChange(changeValue)
                    }
                  }}
                  {...(!value || isArray(value)
                    ? {}
                    : {
                        value: (
                          <SelectValue
                            value={stringValue}
                            valueStyle={valueStyle}
                            {...props}
                          />
                        ),
                      })}
                  {...rest}
                >
                  {renderOptions(selectOptions)}
                </Select>
              </View>
            </View>
          )
        }}
        defaultValue={null}
      />
      {hideErrorInput ? null : <InputErrorMessage error={error} />}
    </View>
  )
}

export const SelectController = React.memo(SelectControllerComponent)

const applyStyles = (
  isSmallDevice: boolean,
  isRow: boolean
): ObjectsOfSxProps => ({
  container: {
    paddingTop: 1,
  },
  item: {
    flexDirection: isSmallDevice && !isRow ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: isSmallDevice && !isRow ? 'flex-start' : 'center',
    flexWrap: isSmallDevice && !isRow ? 'nowrap' : 'wrap',
  },
  label: {
    color: 'grayColor600',
  },
  renderIcon: {
    ml: 1,
  },
  selectWrapper: {
    ...(isSmallDevice && !isRow ? {flex: 1, width: '100%'} : {}),
  },
  selectInput: {
    ...(isSmallDevice && !isRow ? {flex: 1, width: '100%'} : {}),
    minWidth: isSmallDevice && !isRow ? '100%' : 100,
  },
})
