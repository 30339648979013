import {
  Button as UKButton,
  ButtonProps as BTNProps,
  Spinner,
} from '@ui-kitten/components'
import {useSx, View} from 'dripsy'
import React from 'react'

import {Colors} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface ButtonProps {
  title: string
  onPress: () => void
  color: Colors
  isLoading?: boolean
  isSmallSpinner?: boolean
}

type CustomButtonProps = ButtonProps & BTNProps

export const Button: React.FC<CustomButtonProps> = ({
  title,
  onPress,
  color,
  appearance = 'filled',
  status = 'primary',
  isLoading,
  isSmallSpinner,
  ...rest
}) => {
  const sxStyles = applyStyles(color, appearance, isSmallSpinner)
  const sx = useSx()

  const handlePressButton = () => {
    onPress()
  }

  return isLoading ? (
    <View sx={sxStyles.spinner}>
      <Spinner status={status} size={isSmallSpinner ? 'tiny' : undefined} />
    </View>
  ) : (
    <UKButton
      onPress={handlePressButton}
      appearance={appearance}
      style={sx(sxStyles.button)}
      status={rest.disabled ? 'basic' : status}
      {...rest}
    >
      {title}
    </UKButton>
  )
}

const applyStyles = (
  color: Colors,
  appearance: CustomButtonProps['appearance'],
  isSmallSpinner?: boolean
): ObjectsOfSxProps => ({
  button: {
    borderColor: appearance === 'outline' ? color : 'transparent',
  },
  spinner: {
    borderWidth: 1,
    borderColor: color ?? 'primaryColor500',
    borderRadius: 5,
    px: 5,
    ...(isSmallSpinner
      ? {
          // Special height and padding for reload spinner
          justifyContent: 'center',
          height: 32,
          mt: 1,
          width: 70,
        }
      : {
          alignItems: 'center',
          paddingY: 2,
        }),
  },
})
