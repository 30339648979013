export const INTEGER_MEASUREMENT_UNITS = ['bal', 'ks']
export const DOT_AND_COMMA_REG_EXP = /[.,]/

export function checkForDot(text: string | number, unit: string) {
  if (text != null) {
    if (INTEGER_MEASUREMENT_UNITS.includes(unit)) {
      if (typeof text !== 'string') {
        text = text.toString()
      }
      const newText = text.split('.')[0]
      return text.includes('.') ? parseInt(newText, 10) : parseInt(text, 10)
    }
    return text
  }
}
