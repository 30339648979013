// import {useState} from 'react'
import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {sortBy} from 'lodash'

import {ProductItem} from 'new_order/new_order_types'
import {getDataConfigForCartItem} from 'shop_cart/shop_cart_helpers'
import {ShopCartItem} from 'shop_cart/shop_cart_types'

type UseEnhancedDataGetEnhancedData = (oldData: ProductItem[]) => ProductItem[]

export type UseEnhancedDataReturn = {
  getEnhancedData: UseEnhancedDataGetEnhancedData
  isRefetching: boolean
}

export const useEnhancedData = (): UseEnhancedDataReturn => {
  // const [enhancedData, setEnhanceData] = useState<any[]>([])

  const configForShopCart = getDataConfigForCartItem()
  const {data, isRefetching, isFetching} = useApiQuery<ShopCartItem[]>(
    extractKey({
      url: configForShopCart.url,
    }),
    undefined,
    {
      axiosConfig: {
        ...configForShopCart,
      },
    },
    true
  )

  const enhanceData = (item: ProductItem, items: ShopCartItem[]) => {
    const result = items.map((cartItem) => {
      return {
        ...item,
        amount: cartItem.amount,
        shopCartUnitOfMeasure: cartItem.unitOfMeasure,
        shopCartId: cartItem.id,
        createdAt: cartItem.createdAt,
      }
    })
    return sortBy(result, (resultItem) => resultItem.createdAt)
  }

  const getEnhancedData: UseEnhancedDataGetEnhancedData = (oldData) => {
    let result: ProductItem[] = []

    if (isRefetching || isFetching) {
      result = oldData
    } else {
      if (data && data.length > 0) {
        result = oldData.map((item) => {
          const itemInShop = data.find(
            (cartItem) => cartItem.productId.id === item.id
          )
          let enhancedItem = item
          if (itemInShop) {
            enhancedItem = {...enhancedItem, shopCartId: itemInShop.id}
          }
          return enhancedItem
        })
      } else {
        result = oldData
      }
    }
    return result
  }

  return {
    // enhancedData,
    getEnhancedData,
    isRefetching: isRefetching || isFetching,
  }
}
