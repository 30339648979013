import {yupResolver} from '@hookform/resolvers/yup'
import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiMutation, useApiQuery} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'
import {View} from 'dripsy'
import {isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo} from 'react'
import {FieldErrors, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {DetailSkeleton} from 'common/components/DetailSkeleton'
import {IconButton} from 'common/components/IconButton'
import {useFormForUserShop} from 'common/hooks/useFormForUserShop'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {useValidateForm} from 'common/hooks/useValidateForm'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {transformFormErrorsToErrorState} from 'common/validation_helper'
import {
  ApiError,
  getErrorMessageCode,
  ResponseSuccess,
} from 'error/error_helper'
import {ProfileSchema} from 'form/form_schemas'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {ScreenKeyboardAwareScrollView} from 'layout/components/ScreenKeyboardAwareScrollView'
import {
  AuthorizedScreenProps,
  ObjectsOfSxProps,
  ProfileStackParamList,
} from 'layout/layout_types'
import {UserRole} from 'login/login_constants'
import {loginSlice} from 'login/login_slice'
import {ProfileDeleteAccount} from 'profile/components/ProfileDeleteAccount'
import {PROFILE_FORM_FIELDS} from 'profile/profile_constants'
import {ProfileSave} from 'profile/profile_types'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'
import {UserDetail} from 'user/components/UserDetail'
import {USER_SHOP_INSTANCE_REDUCER_STATE_GRID_ID} from 'user/user_constants'
import {
  getDataConfigForSaveUser,
  getDataConfigForUserById,
} from 'user/user_helpers'
import {User} from 'user/user_types'

type Props = CompositeScreenProps<
  NativeStackScreenProps<ProfileStackParamList, 'ProfileScreen'>,
  AuthorizedScreenProps
>

interface SaveProfileResponse extends ResponseSuccess {
  updatedUser: User
}

interface ProfileScreenForm {
  fullName: string
  phone: string
  isSubscribedForPriceList: boolean
}

export const ProfileScreen: React.FC<Props> = ({navigation}) => {
  const {t} = useTranslation()

  const userId = useAppSelector((state) => state.login.loginUser?.id)

  const configForUserById = getDataConfigForUserById(userId)

  const profileKey = extractKey({
    params: configForUserById.params,
    url: configForUserById.url,
  })

  const {data, isSuccess, isLoading, remove, isStale} = useApiQuery<User>(
    profileKey,
    undefined,
    {
      reactQueryOptions: {
        // refetchOnWindowFocus: true,
      },
      axiosConfig: {
        ...configForUserById,
      },
    },
    true
  )
  const dispatch = useAppDispatch()
  const {validateFormColumnValues} = useValidateForm()
  const {
    control,
    getValues,
    reset,
    trigger,
    formState: {errors: profileErrors},
  } = useForm<ProfileScreenForm>({
    resolver: yupResolver(ProfileSchema),
  })

  const getDataToSave = (actualValue: ProfileSave): ProfileSave => {
    return {
      fullName: actualValue.fullName,
      phone: actualValue.phone,
      isSubscribedForPriceList: actualValue.isSubscribedForPriceList,
    }
  }

  const updateUser = (updatedData: SaveProfileResponse) => {
    dispatch(
      loginSlice.actions.updateLoginUser({user: updatedData.updatedUser})
    )
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.AUTHORIZED,
        isVisible: true,
        data: {
          color: 'error',
          text:
            getErrorMessageCode(error) ??
            'response.error.common.unexpectedError',
        },
      })
    )
  }

  const handleSuccess = async (resultData: SaveProfileResponse) => {
    updateUser(resultData)
    dispatch(
      SnackbarHelpers.getSnackBarSuccess(
        data?.role === UserRole.CUSTOMER
          ? 'response.success.user.userAndShop'
          : 'response.success.user.updated'
      )
    )
  }

  const configForSaveUser = getDataConfigForSaveUser<ProfileSave>(
    getDataToSave(getValues()),
    userId
  )

  const saveDetailQuery = useApiMutation(
    extractKey({
      data: configForSaveUser.data,
      method: configForSaveUser.method,
      params: configForSaveUser.params,
      url: configForSaveUser.url,
    }),
    configForSaveUser.data,
    {
      reactMutationOptions: {
        onError: handleError,
        onSuccess: handleSuccess,
      },
      axiosConfig: {
        ...configForSaveUser,
      },
    },
    true
  )

  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      reset(data)
    }
  }, [data, isSuccess, isLoading, reset])

  useEffect(() => {
    return () => {
      remove()
    }
  }, [remove])

  const handlePressSave = useCallback(() => {
    if (data) {
      saveDetailQuery.mutate()
    }
  }, [data, saveDetailQuery])

  const handlePressChangePassword = () => {
    navigation.navigate('ChangePasswordScreen')
  }

  useRefetchQuery(
    [
      {key: profileKey},
      {key: USER_SHOP_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
    ],
    isStale
  )

  const primaryShopId = useMemo(() => {
    let shopId = null
    if (data && data.userShops.length > 0) {
      shopId = data.userShops.find((item) => item.isPrimary)?.id ?? null
    }
    return shopId
  }, [data])

  const {
    userShopTrigger,
    handlePressSaveUserShop,
    userShopErrors,
    ...userShopProps
  } = useFormForUserShop(userId, primaryShopId)

  const profileScreenFormErrors = useMemo(() => {
    return {...userShopErrors, ...profileErrors}
  }, [userShopErrors, profileErrors])

  const handleDisplayError = useCallback(
    (errorsAfterSubmit: FieldErrors) => {
      if (!isEmpty(errorsAfterSubmit)) {
        const errorState = transformFormErrorsToErrorState(errorsAfterSubmit)
        validateFormColumnValues(errorState, null, 'profile.validation')
      }
    },
    [validateFormColumnValues]
  )

  const handlePressSaveProfileAndShop = useCallback(async () => {
    // check if profile part is valid
    const isProfileValid = await trigger()
    let isValidUserShop = false
    if (userShopTrigger) {
      // check if userShop part is valid
      isValidUserShop = await userShopTrigger()
    }
    if (isProfileValid && isValidUserShop) {
      handlePressSaveUserShop()
      handlePressSave()
    } else if (data?.role !== UserRole.CUSTOMER && isProfileValid) {
      handlePressSave()
    }
  }, [handlePressSave, handlePressSaveUserShop, trigger, userShopTrigger, data])

  useEffect(() => {
    handleDisplayError(profileScreenFormErrors)
  }, [handleDisplayError, profileScreenFormErrors])

  return (
    <>
      <Header />
      <ScreenKeyboardAwareScrollView>
        <AuthorizedLayout
          title={t('profile.title')}
          breadcrumbs={
            <Breadcrumbs>
              <BreadcrumbItem label={t('profile.title')} isLast />
            </Breadcrumbs>
          }
        >
          {data ? (
            <>
              <UserDetail
                userShopErrors={userShopErrors}
                data={data}
                onPressSave={handlePressSaveProfileAndShop}
                control={control}
                errors={profileErrors}
                formFields={PROFILE_FORM_FIELDS}
                isLoading={saveDetailQuery.isLoading}
                primaryShopId={primaryShopId}
                {...userShopProps}
              >
                <View sx={sxStyles.buttonWrapper}>
                  <IconButton
                    iconName={'lock-outline'}
                    left
                    title={t('button.changePassword')}
                    onPress={handlePressChangePassword}
                    color={'primaryColor500'}
                    size={'small'}
                  />
                </View>
                {data.role === UserRole.CUSTOMER && (
                  <ProfileDeleteAccount isDisabled={!data.removable} />
                )}
              </UserDetail>
            </>
          ) : null}
          {!data && isLoading ? <DetailSkeleton /> : null}
        </AuthorizedLayout>
      </ScreenKeyboardAwareScrollView>
    </>
  )
}

const sxStyles: ObjectsOfSxProps = {
  buttonWrapper: {
    alignSelf: 'center',
    paddingY: [2, null, 4],
  },
}
