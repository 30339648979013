import {SxProp} from 'dripsy'

import {Sort} from 'grid/grid_types'
import Theme from 'theme/app_theme.json'

export const GRID_ROW_HEADER = [20, null, 46] // 26 for theme balham, 46 for theme material
export const MOBILE_GRID_VALUE: SxProp = {
  color: 'grayColor800',
  ...Theme.text.caption,
  textAlign: 'right',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
}

export const MOBILE_GRID_LINK_VALUE: SxProp = {
  ...Theme.text.captionBold,
  textAlign: 'right',
}

export const DEFAULT_SORT_TYPE: Sort[] = [
  {id: 'asc', name: 'asc'},
  {id: 'desc', name: 'desc'},
]
