import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {extractKey, useApiQuery} from '@sincersoft/fe-core'
import {isEmpty} from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {useGetProductDetail} from 'common/hooks/useGetProductDetail'
import {AuthorizedScreenProps, OrderStackParamList} from 'layout/layout_types'
import {UserRole} from 'login/login_constants'
import {ProductDetailContainer} from 'new_order/components/ProductDetailContainer'
import {
  getDataConfigForOrderDetail,
  getDataConfigForSingleOrderItem,
  getNavigateRouteAndOptions,
} from 'order/order_helpers'
import {
  OrderDetail,
  OrderItem,
  OrderProductBreadcrumbLinkType,
  PriceListsIds,
} from 'order/order_types'
import {useAppSelector} from 'store/redux_hooks'

type Props = CompositeScreenProps<
  NativeStackScreenProps<OrderStackParamList, 'OrderProductDetailScreen'>,
  AuthorizedScreenProps
>

export const OrderProductDetailScreen: React.FC<Props> = ({
  route,
  navigation,
}) => {
  const {t} = useTranslation()

  const dataConfigForOrderDetail = getDataConfigForOrderDetail(
    route.params.orderId
  )

  const {data: orderDetailData, isLoading: orderDetailIsLoading} =
    useApiQuery<OrderDetail>(
      extractKey({
        params: dataConfigForOrderDetail.params,
        url: dataConfigForOrderDetail.url,
      }),
      undefined,
      {
        reactQueryOptions: {},
        axiosConfig: {
          ...dataConfigForOrderDetail,
        },
      },
      true
    )

  const configForOrderItem = getDataConfigForSingleOrderItem(
    route.params.orderItemId
  )

  const orderItemKey = extractKey({
    params: configForOrderItem.params,
    url: configForOrderItem.url,
  })

  const {data: orderItemData, isLoading: orderItemDataIsLoading} =
    useApiQuery<OrderItem>(
      orderItemKey,
      undefined,
      {
        reactQueryOptions: {},
        axiosConfig: {
          ...configForOrderItem,
        },
      },
      true
    )

  const productId = route.params.id || route.params.productId

  const data = useGetProductDetail(productId)

  const userRole = useAppSelector((state) => state.login.loginUser?.role)
  const isAdmin =
    userRole === UserRole.SUPER_ADMIN || userRole === UserRole.ADMIN

  const priceListsIdsOfUserFromOrder: PriceListsIds = useMemo(
    () => ({
      priceListActionPrimary: orderDetailData?.priceListActionPrimary,
      priceListActionSecondary: orderDetailData?.priceListActionSecondary,
      priceListStandard: orderDetailData?.priceListStandard,
    }),
    [orderDetailData]
  )

  const onPressNavigateTo = (type: OrderProductBreadcrumbLinkType) => {
    const {route: navigateRoute, options: navigateOptions} =
      getNavigateRouteAndOptions(orderDetailData, type)
    if (navigateRoute && !isEmpty(navigateOptions)) {
      navigation.navigate(navigateRoute, navigateOptions)
    }
  }

  return !orderItemDataIsLoading && !orderDetailIsLoading ? (
    <ProductDetailContainer
      isOrderItem={true}
      navigation={navigation}
      route={route}
      orderItemData={orderItemData}
      priceListsIdsOfUserFromOrder={priceListsIdsOfUserFromOrder}
      breadcrumbs={
        <Breadcrumbs>
          <BreadcrumbItem
            label={t('routes.order')}
            onPress={() =>
              onPressNavigateTo(
                isAdmin
                  ? OrderProductBreadcrumbLinkType.AdminOrderScreen
                  : OrderProductBreadcrumbLinkType.OrderScreen
              )
            }
          />
          <BreadcrumbItem
            label={t('order.detail.breadcrumbsTitle', {
              order: orderDetailData && orderDetailData.ident,
            })}
            onPress={() => () =>
              onPressNavigateTo(
                isAdmin
                  ? OrderProductBreadcrumbLinkType.AdminOrderDetailScreen
                  : OrderProductBreadcrumbLinkType.OrderDetailScreen
              )}
          />
          <BreadcrumbItem
            label={t('newOrder.productDetail.breadcrumbsSubTitle', {
              product: data?.name,
            })}
            isLast
          />
        </Breadcrumbs>
      }
    />
  ) : null
}
