import {
  Button as UKButton,
  ButtonProps as BTNProps,
  Icon as UKIcon,
  IconProps,
} from '@ui-kitten/components'
import {useSx} from 'dripsy'
import React, {CSSProperties} from 'react'
import {ActivityIndicator} from 'react-native'

import {Colors} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'

interface ButtonProps {
  title?: string
  onPress?: () => void
  iconName?: string
  appearance?: string
  iconStyle?: CSSProperties
  accessory?: string
  left?: boolean
  right?: boolean
  isSmall?: boolean
  color?: Colors
  isLoading?: boolean
  isSmallSpinner?: boolean
  isMediumButton?: boolean
}

type CustomButtonProps = ButtonProps & BTNProps

export const IconButton: React.FC<CustomButtonProps> = ({
  title,
  onPress,
  appearance = 'filled',
  status = 'primary',
  color,
  isLoading,
  isSmall,
  iconName,
  left,
  right,
  disabled,
  size = 'large',
  ...rest
}) => {
  const sxStyles = applyStyles(
    appearance,
    color,
    isSmall,
    isLoading,
    disabled,
    size
  )
  const sx = useSx()

  const Icon = (props: IconProps) => (
    <UKIcon
      {...props}
      name={iconName}
      pack={'material'}
      style={{...props.style, ...sxStyles.iconStyle}}
    />
  )

  const Loading = (props: IconProps) => (
    <ActivityIndicator
      {...props}
      size={'small'}
      color={appearance === 'filled' ? '#ffffff' : '#046240FF'}
      style={{...props.style, ...sxStyles.indicatorDefault}}
    />
  )

  const handlePressButton = () => {
    onPress && onPress()
  }

  // isLoading = true
  return (
    <UKButton
      accessoryLeft={
        left && !isSmall && !isLoading
          ? Icon
          : left && !isSmall && isLoading
          ? Loading
          : left && isSmall && isLoading
          ? Loading
          : undefined
      }
      accessoryRight={
        right && !isSmall && !isLoading
          ? Icon
          : right && !isSmall && isLoading
          ? Loading
          : right && isSmall && isLoading
          ? Loading
          : undefined
      }
      onPress={handlePressButton}
      appearance={appearance}
      style={sx(sxStyles.button)}
      status={disabled ? 'basic' : status}
      disabled={disabled}
      size={size}
      {...rest}
    >
      {title}
    </UKButton>
  )
}

const applyStyles = (
  appearance: CustomButtonProps['appearance'],
  color?: Colors,
  isSmall?: boolean,
  isLoading?: boolean,
  disabled?: boolean | null,
  size?: string
): ObjectsOfSxProps => ({
  iconStyle: {
    marginRight: 0,
  },
  button: {
    borderColor:
      !disabled && appearance === 'outline' && color ? color : 'transparent',
    ...(size === 'small'
      ? {}
      : {
          height: 48,
        }),
  },
  indicatorDefault: {
    marginRight: 0,
    ...(isSmall && isLoading
      ? {
          marginLeft: 0,
        }
      : {}),
  },
  indicatorIsSmall: {
    // alignItems: 'center',
    // paddingRight: 10,
  },
})
