import {MaterialIcons} from '@expo/vector-icons'
import {View, Text, useSx, Pressable} from 'dripsy'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Platform} from 'react-native'
import {Portal, Snackbar} from 'react-native-paper'
import {useSafeAreaInsets} from 'react-native-safe-area-context'

import {BOTTOM_TAB_BAR_HEIGHT} from 'layout/components/BottomTabBar'
import {isWeb} from 'layout/layout_constants'
import {SNACKBAR_DURATION} from 'snackbar/snackbar_constants'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarData, SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch, useAppSelector} from 'store/redux_hooks'

interface Props {
  type: SnackbarType
}

export const SnackbarContainer: React.FC<Props> = ({type}) => {
  const isVisible = useAppSelector((state) => state.snackbar.isVisible[type])
  const data = useAppSelector((state) => state.snackbar.data[type])
  const [wrapperWidth, setWrapperWidth] = useState<number | undefined>(
    undefined
  )

  const dispatch = useAppDispatch()
  const {t} = useTranslation()
  const sx = useSx()
  const safeAreaInsets = useSafeAreaInsets()

  const sxStyles = applyStyles(
    isVisible,
    safeAreaInsets.bottom,
    wrapperWidth,
    data
  )
  const handleDismiss = () => {
    dispatch(snackbarSlice.actions.setIsVisible({type, isVisible: false}))
  }
  return data && data?.text ? (
    <Portal>
      <Snackbar
        visible={isVisible}
        onDismiss={handleDismiss}
        duration={SNACKBAR_DURATION}
        style={sx(sxStyles.snackbar)}
        wrapperStyle={sx(sxStyles.wrapper)}
        onLayout={(event) => {
          const {width} = event.nativeEvent.layout
          setWrapperWidth(width)
        }}
      >
        <View sx={sxStyles.contentWrapper}>
          <View sx={sxStyles.info}>
            <MaterialIcons name={'error'} size={24} color={'white'} />
          </View>

          {wrapperWidth ? (
            <Text sx={sxStyles.text}>
              {t(data.text, {items: data.textParams})}
            </Text>
          ) : null}
          <Pressable
            sx={sxStyles.close}
            onPress={handleDismiss}
            accessibilityRole={'button'}
          >
            <MaterialIcons
              name={'close'}
              size={24}
              color={'white'}
              backgroundColor={'transparent'}
            />
          </Pressable>
        </View>
      </Snackbar>
    </Portal>
  ) : null
}

const applyStyles = (
  isVisible: boolean,
  insetsBottom: number,
  wrapperWidth?: number,
  data?: SnackbarData
) => ({
  wrapper: {
    alignSelf: 'center',
    flexDirection: isWeb ? ('row' as const) : ('column' as const),
    width: '100%',
    bottom: [BOTTOM_TAB_BAR_HEIGHT + insetsBottom, null, isWeb ? 15 : null, 15],
    alignItems: 'center',
    justifyContent: 'center',
  },
  snackbar: {
    backgroundColor: isVisible
      ? data?.color === 'success'
        ? 'primaryColor400'
        : 'secondaryColor400'
      : 'transparent',
    width: [300, 320, 500],
    borderRadius: 5,
  },
  contentWrapper: {
    flex: 1,
    width: '100%',
    flexDirection: 'row' as const,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  text: {
    maxWidth: (wrapperWidth ?? 1) * (Platform.OS === 'android' ? 0.75 : 1),
    color: 'white',
    flex: 1,
    paddingX: 1,
    alignSelf: 'center',
  },
  close: {
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
  },
  info: {
    left: 0,
  },
})
