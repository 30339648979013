import {useSx, View} from 'dripsy'
import React from 'react'
import ContentLoader, {Rect} from 'common/react_content_loader'

import {ObjectsOfSxProps} from 'layout/layout_types'

export const DetailSkeleton: React.FC = () => {
  const sx = useSx()
  return (
    <View sx={sxStyles.wrapper}>
      <ContentLoader style={sx(sxStyles.content)} width={'100%'}>
        <Rect
          x={'10'}
          y={'10'}
          rx={'5'}
          ry={'5'}
          width={'100%'}
          height={'100%'}
        />
      </ContentLoader>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  wrapper: {
    minWidth: ['99%', '100%'],
    maxWidth: ['99%', '100%'],
    height: 500,
  },
  content: {
    flex: 1,
  },
}
