import {DripsyCustomTheme, View} from 'dripsy'
import {isEmpty} from 'lodash'
import React from 'react'
import {Control, FieldErrors} from 'react-hook-form'

import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {Api} from 'config/app_config'
import {AutoCompleteInputController} from 'form/components/AutoCompleteInputController'
import {InputController} from 'form/components/InputController'
import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {FormFields} from 'register/register_constants'

interface UserDetailFormProps {
  control: Control<any>
  errors: FieldErrors
  formFields: FormFields
}

export const NewShopForm: React.FC<UserDetailFormProps> = ({
  control,
  errors,
  formFields,
}) => {
  const sxStyles = useThemeSxStyles(applyStyles)
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <View sx={sxStyles.container} accessibilityRole={isWeb ? 'form' : 'none'}>
      {formFields.map((formItem) =>
        formItem.id === 'city' ? (
          <AutoCompleteInputController
            key={formItem.id}
            control={control}
            error={errors[formItem.id]}
            id={formItem.id}
            label={formItem.label}
            url={Api.town}
            labelStyle={sxStyles.label}
            size={'small'}
          />
        ) : (
          <InputController
            key={formItem.id}
            control={control}
            error={errors[formItem.id]}
            hideErrorInput={isEmpty(errors[formItem.id])}
            id={formItem.id}
            label={formItem.label}
            isSecured={formItem.isPassword}
            keyboardType={formItem.keyboardType ?? 'default'}
            autoCapitalize={formItem.keyboardType ? 'none' : 'sentences'}
            labelStyle={sxStyles.label}
            textStyle={{
              ...(formItem.isCapitalized ? {textTransform: 'uppercase'} : {}),
            }}
            size={'small'}
          />
        )
      )}
    </View>
  )
}

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  container: {
    flexDirection: 'column',
    padding: [1, 2, 5],
  },
  label: {
    color: 'secondaryColor700',
    ...theme.text.body1Bold,
    fontSize: [2, null, 3],
  },
})
