import {
  BottomTabNavigationProp,
  BottomTabScreenProps,
} from '@react-navigation/bottom-tabs'
import {
  CompositeNavigationProp,
  CompositeScreenProps,
  NavigatorScreenParams,
} from '@react-navigation/native'
import {
  NativeStackNavigationProp,
  NativeStackScreenProps,
} from '@react-navigation/native-stack'
import {SxProp} from 'dripsy'

export type ObjectsOfSxProps = Record<string, SxProp>

interface ErrorData {
  errorCode: string
  messageCode: string
  error: string
}

interface ErrorResponse {
  data: ErrorData
}

export interface Error {
  response?: ErrorResponse
}

export type RootStackParamList = {
  ConfirmRegisterScreen: {
    token: string
  }
  DeleteAccountScreen: {
    token: string
  }
  DeleteAccountRequestScreen: undefined
  ForgottenPasswordScreen: undefined
  NavAuthorizedHomeScreen: NavigatorScreenParams<AuthorizedTabParamList>
  LoginScreen: undefined
  RegisterScreen: undefined
  ResetPasswordScreen: {
    token: string
  }
  RequestRegisterScreen: undefined
}

export type AuthorizedTabParamList = {
  NavAdminOrderScreen: NavigatorScreenParams<AdminOrderStackParamList>
  NavAdminPriceListScreen: NavigatorScreenParams<AdminPriceListStackParamList>
  NavUserScreen: NavigatorScreenParams<UserStackParamList>
  NavProductScreen: NavigatorScreenParams<ProductStackParamList>
  NavOrderScreen: NavigatorScreenParams<OrderStackParamList>
  NavOrderTemplateListScreen: NavigatorScreenParams<OrderTemplateStackParamList>
  NavProductCategoryScreen: NavigatorScreenParams<ProductCategoryStackParamList>
  NavPriceListScreen: NavigatorScreenParams<PriceListStackParamList>
  NavNewOrderScreen: NavigatorScreenParams<NewOrderStackParamList>
  NavShopCartScreen: NavigatorScreenParams<ShopCartStackParamList>
  NavProfileScreen: NavigatorScreenParams<ProfileStackParamList>
  NavReportScreen: NavigatorScreenParams<ReportStackParamList>
}

export type ProductDetailParentStackScreenProps =
  | NativeStackScreenProps<
      NewOrderStackParamList,
      'NewOrderProductDetailScreen'
    >
  | NativeStackScreenProps<OrderStackParamList, 'OrderProductDetailScreen'>
  | NativeStackScreenProps<ProductStackParamList, 'ProductDetailScreen'>
  | NativeStackScreenProps<
      ShopCartStackParamList,
      'ShopCartDetailProductScreen'
    >
  | NativeStackScreenProps<
      NewOrderStackParamList,
      'NewOrderQuickProductDetailScreen'
    >
  | NativeStackScreenProps<AdminOrderStackParamList, 'AdminOrderScreen'>
  | NativeStackScreenProps<OrderStackParamList, 'OrderScreen'>
  | NativeStackScreenProps<OrderStackParamList, 'OrderDetailScreen'>
  | NativeStackScreenProps<AdminOrderStackParamList, 'AdminOrderDetailScreen'>
  | NativeStackScreenProps<
      AdminOrderStackParamList,
      'AdminOrderProductDetailScreen'
    >

export type OrderStackParamList = {
  OrderScreen: undefined
  OrderDetailScreen: {
    id: string | number
  }
  OrderProductDetailScreen: {
    productId: string | number
    orderId: string | number
    orderItemId: string | number
  }
}

export type OrderTemplateStackParamList = {
  OrderTemplateListScreen: undefined
}

export type AdminOrderStackParamList = {
  AdminOrderScreen: undefined
  AdminOrderDetailScreen: {
    id: string | number
  }
  AdminOrderProductDetailScreen: {
    productId: string | number
    orderId: string | number
    orderItemId: string | number
  }
}

export type UserStackParamList = {
  UserScreen: undefined
  UserDetailScreen: {
    id: string | number
  }
}

export type NewOrderStackParamList = {
  NewOrderScreen: undefined
  NewOrderQuickScreen: undefined
  NewOrderQuickProductDetailScreen: {
    id: string | number
  }
  NewOrderCategoryScreen: undefined
  NewOrderProductScreen: {
    idCategory: string | number
  }
  NewOrderProductDetailScreen: {
    id?: string | number
    productId?: string | number
    orderId?: string | number
    orderItemId?: string | number
  }
}

export type PriceListStackParamList = {
  PriceListScreen: undefined
}

export type AdminPriceListStackParamList = {
  AdminPriceListScreen: undefined
  AdminPriceListDetailScreen: {
    id: string | number
  }
  AdminPriceListExportScreen: undefined
}

export type ProductCategoryStackParamList = {
  ProductCategoryScreen: undefined
  ProductCategoryDetailScreen: {
    id: string | number
  }
}

export type ProductStackParamList = {
  ProductScreen: undefined
  ProductDetailScreen: {
    id?: string | number
    productId?: string | number
  }
}

export type ReportStackParamList = {
  ReportScreen: undefined
}

export type ShopCartStackParamList = {
  ShopCartScreen: undefined
  ShopCartDetailProductScreen: {
    id: string | number
  }
}

export type NewOrderQuickStackParamList = {
  NewOrderQuickScreen: undefined
  NewOrderQuickDetailProductScreen: {
    name: string | number
  }
}

export type ProfileStackParamList = {
  ProfileScreen: undefined
  ChangePasswordScreen: undefined
}

export type AuthorizedScreenProps = CompositeScreenProps<
  BottomTabScreenProps<AuthorizedTabParamList>,
  NativeStackScreenProps<RootStackParamList>
>

export type AuthorizedNavigationProp = CompositeNavigationProp<
  BottomTabNavigationProp<AuthorizedTabParamList>,
  NativeStackNavigationProp<RootStackParamList>
>

export interface NotificationResponseMessage {
  text: string
  title: string
}

export interface NotificationResponseData {
  msg: NotificationResponseMessage
  link: string
  type: 'orderChanged' | 'shoppingEnabled'
}

export function isNotificationResponseData(
  data:
    | NotificationResponseData
    | {
        [key: string]: any
      }
): data is NotificationResponseData {
  return data.link != null
}

export interface TitleValue {
  [index: string]: string | undefined
}

export interface Config {
  privatePolicyUrl: string
  beBuildVersion: string
  beBuildDate: string
}
