import {View, Text} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ObjectsOfSxProps} from 'layout/layout_types'

const CreateOrderTemplateHeaderComponent: React.FC = () => {
  const {t} = useTranslation()
  return (
    <View sx={sxStyles.titleWrapper}>
      <Text sx={sxStyles.title} variant={'body1Bold'}>
        {t('orderTemplate.confirm')}
      </Text>
      <Text variant={'caption'} sx={sxStyles.titleText}>
        {t('orderTemplate.confirmText')}
      </Text>
    </View>
  )
}

export const CreateOrderTemplateHeader = React.memo(
  CreateOrderTemplateHeaderComponent
)

const sxStyles: ObjectsOfSxProps = {
  titleWrapper: {
    mt: 2,
    mb: 4,
    px: 2,
  },
  title: {
    mb: 2,
    textAlign: 'center',
    color: 'primaryColor500',
  },
  titleText: {
    textAlign: 'center',
    color: 'grayColor500',
  },
}
