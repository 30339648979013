import {View} from 'dripsy'
import React from 'react'

import {CountryFlag} from 'common/components/CountryFlag'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {ProductItem} from 'new_order/new_order_types'

interface Props {
  origin: ProductItem['origin']
  isInShop: boolean
}

const NewOrderProductListItemCountryComponent: React.FC<Props> = ({
  origin,
  isInShop,
}) => {
  const sxStyles = applyStyles(isInShop)
  return (
    <View sx={sxStyles.originWrapper}>
      <CountryFlag
        countryName={origin}
        height={15}
        sxFallbackText={sxStyles.originFallbackText}
      />
    </View>
  )
}

export const NewOrderProductListItemCountry = React.memo(
  NewOrderProductListItemCountryComponent
)

const applyStyles = (isInShop?: boolean): ObjectsOfSxProps => ({
  originWrapper: {
    position: 'absolute',
    top: -9,
    left: '41%',
    borderTopRightRadius: 5,
    backgroundColor: isInShop ? 'primaryColor50' : 'white',
    paddingX: 2,
  },
  originFallbackText: {
    fontSize: [1, null, 2],
  },
})
