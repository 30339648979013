import {extractKey, useApiMutation} from '@sincersoft/fe-core'
import {AxiosError} from 'axios'

import {setCredentials} from 'common/credential_utils'
import {ApiError, getErrorMessageCode} from 'error/error_helper'
import {layoutSlice} from 'layout/layout_slice'
import {getDataConfigForLogin} from 'login/login_helpers'
import {loginAndStoreToken} from 'login/login_thunks'
import {LoginResponse} from 'login/login_types'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch} from 'store/redux_hooks'

export interface UseLoginData {
  email: string
  password: string
}
export const useLogin = (values: UseLoginData) => {
  const dispatch = useAppDispatch()
  const handleSuccessLogin = async (data: LoginResponse) => {
    if (data) {
      await setCredentials(values.email, values.password)
      await dispatch(
        loginAndStoreToken({loginUser: data.user, token: data.token})
      )
      dispatch(layoutSlice.actions.setInitialized())
      reset()
    }
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.UNAUTHORIZED,
        isVisible: true,
        data: {
          color: 'error',
          text:
            getErrorMessageCode(error) ||
            'response.error.user.invalidCredentials',
        },
      })
    )
  }

  const configForLogin = getDataConfigForLogin(values)

  const {reset, mutate, error, isLoading, isError} = useApiMutation<
    LoginResponse,
    AxiosError<ApiError>
  >(
    extractKey({
      url: configForLogin.url,
      method: configForLogin.method,
      data: configForLogin.data,
    }),
    configForLogin.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessLogin,
        onError: handleError,
      },
      axiosConfig: {
        ...configForLogin,
      },
    },
    false
  )

  return {
    reset,
    mutate,
    error,
    isLoading,
    isError,
  }
}
