import {
  CommonActions,
  Link,
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import {DripsyCustomTheme, useSx, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LinkProps} from '../header_types'
import {useIsNarrowScreen} from 'common/hooks/useIsNarrowScreen'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {isLinkSelected} from 'header/header_helper'
import {AuthorizedTabParamList, ObjectsOfSxProps} from 'layout/layout_types'

interface LayoutProps {
  link: LinkProps
}

export const HeaderLink: React.FC<LayoutProps> = ({link}) => {
  const sx = useSx()
  const {t} = useTranslation()
  const isSmallDevice = useIsNarrowScreen(2)
  const route = useRoute<RouteProp<AuthorizedTabParamList>>()
  const navigation = useNavigation<NavigationProp<AuthorizedTabParamList>>()
  const isSelected = isLinkSelected(link.route, route, navigation)
  const sxStyles = useThemeSxStyles(applyStyles, isSelected, isSmallDevice)
  const optionalActionProp = isSelected
    ? {
        action: CommonActions.reset({
          index: 0,
          routes: [{name: link.route}],
        }),
      }
    : {}
  return (
    <View sx={sxStyles.headerLink}>
      <Link
        to={{screen: link.route, params: {}}}
        {...optionalActionProp}
        style={sx(sxStyles.link)}
      >
        {t(link.name)}
      </Link>
    </View>
  )
}

const applyStyles = (
  theme: DripsyCustomTheme,
  isSelected: boolean,
  isSmallDevice: boolean
): ObjectsOfSxProps => ({
  headerLink: {
    paddingX: [1, null, null, 4],
  },
  link: {
    color: isSelected ? 'primaryColor500' : 'grayColor500',
    ...theme.text.body2Bold,
    ...(isSmallDevice ? {fontSize: theme.fontSizes[2]} : {}),
  },
})
