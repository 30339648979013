import {yupResolver} from '@hookform/resolvers/yup'
import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {
  // extractGridKey,
  extractKey,
  useApiMutation,
  useApiQuery,
} from '@sincersoft/fe-core'
import {gridSlice} from '@sincersoft/fe-grid'
import {AxiosError} from 'axios'
import {isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo} from 'react'
import {FieldErrors, useForm} from 'react-hook-form'
// import {ReducerUtils} from '@sincersoft/core'
import {useTranslation} from 'react-i18next'

import {User, UserSave} from '../user_types'
import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {DetailSkeleton} from 'common/components/DetailSkeleton'
import {getReferenceDataId} from 'common/data_helpers'
import {useFormForUserShop} from 'common/hooks/useFormForUserShop'
import {useLoadEnumPriceListOptions} from 'common/hooks/useLoadEnumPriceLists'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {useValidateForm} from 'common/hooks/useValidateForm'
import {transformFormErrorsToErrorState} from 'common/validation_helper'
import {ApiError, getErrorMessageCode} from 'error/error_helper'
import {UserSchema} from 'form/form_schemas'
import {Header} from 'header/components/Header'
// import {Api} from 'config/app_config'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {ScreenKeyboardAwareScrollView} from 'layout/components/ScreenKeyboardAwareScrollView'
import {AuthorizedScreenProps, UserStackParamList} from 'layout/layout_types'
import {UserStatus} from 'login/login_constants'
import {ProfileDeleteAccount} from 'profile/components/ProfileDeleteAccount'
import {snackbarSlice} from 'snackbar/snackbar_slice'
import {SnackbarType} from 'snackbar/snackbar_types'
import {useAppDispatch} from 'store/redux_hooks'
import {UserDetail} from 'user/components/UserDetail'
import {
  ADMIN_USER_INSTANCE_REDUCER_STATE_GRID_ID,
  USER_FORM_FIELDS,
  USER_SHOP_INSTANCE_REDUCER_STATE_GRID_ID,
} from 'user/user_constants'
import {
  getDataConfigForSaveUser,
  getDataConfigForUserById,
} from 'user/user_helpers'

interface UserDetailScreenForm {
  fullName: string
  userStatus: UserStatus
  phone: string
  isSubscribedForPriceList: boolean
  priceListActionPrimary: number
  priceListActionSecondary: number
  priceListStandard: number
}

function normalizeUserData(data?: User): User | undefined {
  let normalizedData
  if (data) {
    normalizedData = {
      ...data,
      priceListStandard: getReferenceDataId(data.priceListStandard),
      priceListActionPrimary: getReferenceDataId(data.priceListActionPrimary),
      priceListActionSecondary: getReferenceDataId(
        data.priceListActionSecondary
      ),
    }
  }
  return normalizedData
}

type Props = CompositeScreenProps<
  NativeStackScreenProps<UserStackParamList, 'UserDetailScreen'>,
  AuthorizedScreenProps
>

export const UserDetailScreen: React.FC<Props> = ({route, navigation}) => {
  const dispatch = useAppDispatch()
  const {validateFormColumnValues} = useValidateForm()

  const {
    control,
    trigger,
    getValues,
    reset,
    formState: {errors: userProfileErrors},
  } = useForm<UserDetailScreenForm>({
    resolver: yupResolver(UserSchema),
  })

  const {t} = useTranslation()

  const configForUserById = getDataConfigForUserById(route.params.id)

  const enumPriceListOptions = useLoadEnumPriceListOptions()

  const userDetailKey = extractKey({
    params: configForUserById.params,
    url: configForUserById.url,
  })

  const {data, isSuccess, remove, isLoading, isStale} = useApiQuery<User>(
    userDetailKey,
    undefined,
    {
      reactQueryOptions: {},
      axiosConfig: {
        ...configForUserById,
        params: {
          ...configForUserById.params,
        },
      },
    },
    true
  )

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.AUTHORIZED,
        isVisible: true,
        data: {
          color: 'error',
          text:
            getErrorMessageCode(error) ??
            'response.error.common.unexpectedError',
        },
      })
    )
  }

  // TODO: rework snackbar text response
  const handleSuccessSave = async () => {
    dispatch(
      snackbarSlice.actions.setIsVisible({
        type: SnackbarType.AUTHORIZED,
        isVisible: true,
        data: {
          color: 'success',
          text: 'response.success.user.userAndShop',
        },
      })
    )

    dispatch(
      gridSlice.actions.setGridPagination({
        pagination: {
          page: 1,
        },
        stateId: ADMIN_USER_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.resetGridData({
        customPagination: true,
        stateId: ADMIN_USER_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )

    dispatch(
      gridSlice.actions.setRefreshNeeded({
        refreshNeeded: true,
        stateId: ADMIN_USER_INSTANCE_REDUCER_STATE_GRID_ID,
      })
    )
    navigation.navigate('NavUserScreen', {
      screen: 'UserScreen',
    })
  }

  const getDataToSave = (actualValue: UserSave): UserSave => {
    return {
      fullName: actualValue.fullName,
      phone: actualValue.phone,
      isSubscribedForPriceList: actualValue.isSubscribedForPriceList,
      priceListActionPrimary: actualValue.priceListActionPrimary,
      priceListActionSecondary: actualValue.priceListActionSecondary,
      priceListStandard: actualValue.priceListStandard,
      userStatus: actualValue.userStatus,
    }
  }

  const configForSaveUser = getDataConfigForSaveUser(
    getDataToSave(getValues()),
    data ? data.id : undefined
  )

  const saveDetailQuery = useApiMutation(
    extractKey({
      url: configForSaveUser.url,
      method: configForSaveUser.method,
      data: configForSaveUser.data,
    }),
    getDataToSave(getValues()),
    {
      reactMutationOptions: {
        onError: handleError,
        onSuccess: handleSuccessSave,
      },
      axiosConfig: {
        ...configForSaveUser,
      },
    },
    true
  )

  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      // TODO: Type error -> change price list number (id) to string (name)
      reset(normalizeUserData(data))
    }
  }, [data, isSuccess, isLoading, reset])

  useEffect(() => {
    return () => {
      remove()
    }
  }, [remove])

  const handlePressSave = useCallback(() => {
    if (data) {
      saveDetailQuery.mutate()
    }
  }, [data, saveDetailQuery])

  useRefetchQuery(
    [
      {key: userDetailKey},
      {key: USER_SHOP_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
    ],
    isStale
  )

  const userPrimaryShopId = useMemo(() => {
    let shopId = null
    if (data && data.userShops.length > 0) {
      shopId = data.userShops.find((item) => item.isPrimary)?.id ?? null
    }
    return shopId
  }, [data])

  const {
    userShopTrigger,
    handlePressSaveUserShop,
    userShopErrors,
    ...userShopProps
  } = useFormForUserShop(data?.id, userPrimaryShopId)

  const userDetailScreenFormErrors = useMemo(() => {
    return {...userShopErrors, ...userProfileErrors}
  }, [userShopErrors, userProfileErrors])

  const handleDisplayError = useCallback(
    (errorsAfterSubmit: FieldErrors) => {
      if (!isEmpty(errorsAfterSubmit)) {
        const errorState = transformFormErrorsToErrorState(errorsAfterSubmit)
        validateFormColumnValues(errorState, null, 'user.validation')
      }
    },
    [validateFormColumnValues]
  )

  const handlePressSaveProfileAndShop = useCallback(async () => {
    // check if profile part is valid
    const isProfileValid = await trigger()
    let isValidUserShop = false
    if (userShopTrigger) {
      // check if userShop part is valid
      isValidUserShop = await userShopTrigger()
    }
    if (isProfileValid && isValidUserShop) {
      handlePressSaveUserShop()
      handlePressSave()
    }
  }, [handlePressSave, handlePressSaveUserShop, trigger, userShopTrigger])

  useEffect(() => {
    handleDisplayError(userDetailScreenFormErrors)
  }, [handleDisplayError, userDetailScreenFormErrors])

  const normalizedData = normalizeUserData(data)
  return (
    <>
      <Header hasBackButton />
      <ScreenKeyboardAwareScrollView>
        <AuthorizedLayout
          title={'user.title'}
          titleValue={{user: normalizedData && normalizedData.fullName}}
          breadcrumbs={
            <Breadcrumbs>
              <BreadcrumbItem
                label={t('routes.user')}
                onPress={() => {
                  navigation.navigate('NavUserScreen', {
                    screen: 'UserScreen',
                  })
                }}
              />
              <BreadcrumbItem
                label={t('user.breadcrumbsTitle', {
                  user: normalizedData && normalizedData.fullName,
                })}
                isLast
              />
            </Breadcrumbs>
          }
        >
          {normalizedData ? (
            <>
              <UserDetail
                data={normalizedData}
                onPressSave={handlePressSaveProfileAndShop}
                control={control}
                userShopErrors={userShopErrors}
                errors={userProfileErrors}
                formFields={USER_FORM_FIELDS}
                isLoading={saveDetailQuery.isLoading}
                standardPriceListOptions={
                  enumPriceListOptions.standardPriceListQuery.data
                }
                actionPriceListOptions={
                  enumPriceListOptions.actionPriceListQuery.data
                }
                primaryShopId={userPrimaryShopId}
                {...userShopProps}
              >
                <ProfileDeleteAccount
                  id={normalizedData.id}
                  isDisabled={!normalizedData.removable}
                  email={normalizedData.email}
                />
              </UserDetail>
            </>
          ) : null}
          {!normalizedData && isLoading ? <DetailSkeleton /> : null}
        </AuthorizedLayout>
      </ScreenKeyboardAwareScrollView>
    </>
  )
}
