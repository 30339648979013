import {DripsyCustomTheme, View} from 'dripsy'
import React from 'react'
import {Control, FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {LabelValue} from 'common/components/LabelValue'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {PriceListItem} from 'price_list/price_list_types'
// import {Button} from 'common/components/Button'

// import {DateController} from 'form/components/DateController'

interface PriceListProps {
  data: PriceListItem
  onPressButton: () => void
  onPressDeleteDate: (id: string) => void
  control: Control<any>
  errors: FieldErrors
  isLoading?: boolean
}

export const PriceListDetail: React.FC<PriceListProps> = ({
  // control,
  data,
  // errors,
  // onPressButton,
  // onPressDeleteDate,
}) => {
  const {t} = useTranslation()
  // const isActionType = data.type === 'action'
  const sxStyles = useThemeSxStyles(applyStyles)
  return (
    <View sx={sxStyles.container}>
      <View sx={sxStyles.content}>
        <View sx={sxStyles.info}>
          <LabelValue
            label={t('priceList.detail.type')}
            value={data?.type}
            labelStyle={sxStyles.label}
            valueStyle={sxStyles.value}
            wrapperStyle={sxStyles.wrapper}
            translatePrefix={'priceList.detail'}
          />
          <LabelValue
            label={t('priceList.detail.dateImported')}
            value={data?.importedAt}
            isDate
            labelStyle={sxStyles.label}
            valueStyle={sxStyles.value}
            wrapperStyle={sxStyles.wrapper}
          />
          {/* THIS IS NOT USED CURRENTLY BUT WE KEEP IT HERE FOR POSSIBLE FUTURE IMPLEMENTATION */}
          {/*{isActionType ? (*/}
          {/*  <>*/}
          {/*    <LabelValue*/}
          {/*      label={t('priceList.detail.dateRange')}*/}
          {/*      labelStyle={sxStyles.label}*/}
          {/*      wrapperStyle={sxStyles.wrapper}*/}
          {/*    />*/}
          {/*    <DateController*/}
          {/*      control={control}*/}
          {/*      error={errors['dateFrom']}*/}
          {/*      id={'dateFrom'}*/}
          {/*      label={'priceList.detail.dateFrom'}*/}
          {/*      labelStyle={sxStyles.label}*/}
          {/*      onPressDeleteIcon={onPressDeleteDate}*/}
          {/*    />*/}
          {/*    <DateController*/}
          {/*      control={control}*/}
          {/*      error={errors['dateTo']}*/}
          {/*      id={'dateTo'}*/}
          {/*      label={'priceList.detail.dateTo'}*/}
          {/*      labelStyle={sxStyles.label}*/}
          {/*      onPressDeleteIcon={onPressDeleteDate}*/}
          {/*    />*/}
          {/*  </>*/}
          {/*) : null}*/}
        </View>
      </View>
      {/* THIS IS NOT USED CURRENTLY BUT WE KEEP IT HERE FOR POSSIBLE FUTURE IMPLEMENTATION */}
      {/*{isActionType ? (*/}
      {/*  <View sx={sxStyles.button}>*/}
      {/*    <Button*/}
      {/*      title={t('button.save')}*/}
      {/*      onPress={onPressButton}*/}
      {/*      color={'primaryColor500'}*/}
      {/*    />*/}
      {/*  </View>*/}
      {/*) : null}*/}
    </View>
  )
}

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  container: {
    borderWidth: 1,
    borderColor: 'primaryColor300',
    borderRadius: 10,
    marginX: [3, null, 7],
    paddingX: [2, null, 3],
    paddingTop: [3, null, 7],
    paddingBottom: [3, null, 7],
  },
  content: {
    alignItems: 'center',
    flexDirection: ['column', null, 'row'],
  },
  button: {
    alignItems: 'center',
    paddingTop: [3, null, 4],
  },
  wrapper: {
    paddingTop: [3, null, 5],
  },
  label: {
    ...theme.text.body2Bold,
    color: 'secondaryColor600',
    fontSize: [2, null, 3],
  },
  value: {
    color: 'grayColor800',
    ...theme.text.body2,
    fontSize: [2, null, 3],
  },
  info: {
    width: '100%',
    flex: [null, null, 1],
    paddingLeft: [0, null, 2],
  },
})
