import React from 'react'
import {useTranslation} from 'react-i18next'

import {ColumnValue} from 'common/components/ColumnValue'
import {getFormattedCurrency} from 'common/data_helpers'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {UnitOfMeasure} from 'new_order/new_order_types'
import {PRICE_THRESHOLD_VALUE} from 'product/product_constants'

interface Props {
  value: number | null
  isSmall?: boolean
  withCurrency?: boolean
  unitOfMeasure?: UnitOfMeasure
  selectedUnitOfMeasure?: UnitOfMeasure
  priceThresholdValue?: number
  packingAmount?: number
}

const ColumnCurrencyValueComponent: React.FC<Props> = ({
  value,
  isSmall,
  priceThresholdValue,
  unitOfMeasure,
  selectedUnitOfMeasure,
  packingAmount,
}) => {
  const sxStyles = applyStyles(isSmall)
  const {t} = useTranslation()
  const valueWithThreshold = priceThresholdValue ?? PRICE_THRESHOLD_VALUE

  const formatValue = (): string => {
    let result = '-'
    if (value != null) {
      // TODO: handle issue on backend for "bylinky", price come as 0
      // TODO: handle issue here for !unitOfMeasure || !selectedUnitOfMeasure - e.g. in quick order grid
      //
      if (
        !unitOfMeasure ||
        !selectedUnitOfMeasure ||
        unitOfMeasure === selectedUnitOfMeasure ||
        (unitOfMeasure === 'kg' && selectedUnitOfMeasure === 'g') ||
        (packingAmount !== undefined &&
          packingAmount >= 0 &&
          selectedUnitOfMeasure === 'bal')
      ) {
        if (value === 0) {
          result = t('product.notDefined')
        } else {
          result = getFormattedCurrency(value, valueWithThreshold)
        }
      } else {
        result = t('product.differentUnitOfMeasure')
      }
    }
    return result
  }

  return (
    <ColumnValue
      value={formatValue()}
      isSmall={isSmall}
      extraStyle={sxStyles.text}
    />
  )
}

export const ColumnWithDiffUnitValue = React.memo(ColumnCurrencyValueComponent)

const applyStyles = (isSmall?: boolean): ObjectsOfSxProps => ({
  text: {
    // vertical centering - line-height should be equal to the height of cell
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    ...(isSmall
      ? {}
      : {
          textAlignVertical: 'center',
        }),
  },
})
