import React from 'react'

import {ColumnPriceValue} from 'common/components/ColumnPriceValue'
import {ColumnWithDiffUnitValue} from 'common/components/ColumnWithDiffUnitValue'
import {CellRendererParams} from 'grid/grid_types'

export const PriceWithCentsCellRenderer: React.FC<CellRendererParams> = ({
  value,
  isSmall,
}) => {
  return <ColumnPriceValue value={value} isSmall={isSmall} />
}
