import {View} from 'dripsy'
import React from 'react'

import {DocumentPicker} from 'common/components/DocumentPicker'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {IMPORT_MIME_TYPES} from 'price_list/price_list_constants'
import {getDataConfigForUploadPriceList} from 'price_list/price_list_helpers'

interface Props {
  onSuccessUpload?: () => void
  showPromo?: boolean
}

export const AuthorizedLayoutImportButton: React.FC<Props> = ({
  onSuccessUpload,
  showPromo,
}) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <View sx={sxStyles.button}>
      <DocumentPicker
        buttonText={'button.importProductsAndPriceList'}
        dataConfig={getDataConfigForUploadPriceList(timeZone)}
        onSuccessUpload={onSuccessUpload}
        pickerOptions={{type: IMPORT_MIME_TYPES}}
        activateModalSpinner
      />
      {showPromo && (
        <View sx={sxStyles.promoButton}>
          <DocumentPicker
            buttonText={'button.importPromoPriceList'}
            dataConfig={getDataConfigForUploadPriceList(timeZone, showPromo)}
            onSuccessUpload={onSuccessUpload}
            pickerOptions={{type: IMPORT_MIME_TYPES}}
            activateModalSpinner
            specialIcon={'backup'}
            status={'danger'}
            appearance={'outline'}
            color={'secondaryColor500'}
          />
        </View>
      )}
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  button: {
    // TODO: fix the first padding in array, its supposed to be 7px not 10px
    paddingTop: [2, null, 28],
    // position: 'absolute',
    right: 0,
    flexDirection: ['row', null, 'column'],
  },
  promoButton: {
    pl: [1, null, 0],
    pt: [0, null, 2],
  },
}
