import {
  launchImageLibraryAsync,
  MediaTypeOptions,
  ImagePickerOptions,
  ImagePickerResult,
} from 'expo-image-picker'

export const useImagePicker = (namedParameters?: ImagePickerOptions) => {
  return async (): Promise<ImagePickerResult> => {
    return await launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      allowsEditing: true,
      allowsMultipleSelection: false,
      aspect: [4, 3],
      quality: 1,
      base64: true,
      ...namedParameters,
    })
  }
}
