import {formatCurrency} from '@sincersoft/core'
import {DripsyCustomTheme, View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LabelValue} from 'common/components/LabelValue'
import {useThemeSxStyles} from 'common/hooks/useThemeSxStyles'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {ContinueShopCartOrderButton} from 'shop_cart/components/ContinueShopCartOrderButton'
import {ShopCartOrderButton} from 'shop_cart/components/ShopCartOrderButton'

type Props = {
  isDisabled: boolean
  isLoading: boolean
  isShopCartEnabled: boolean
  onPressOrder: () => void
  onPressRoute: () => void
  totalPrice?: number
}

const ShopCartContentPriceAndButtonComponent: React.FC<Props> = ({
  totalPrice,
  onPressOrder,
  onPressRoute,
  isDisabled,
  isLoading,
  isShopCartEnabled,
}) => {
  const {t} = useTranslation()
  const sxStyles = useThemeSxStyles(applyStyles)

  return (
    <View sx={sxStyles.rightPart}>
      <LabelValue
        label={t('shopCart.totalPrice')}
        value={formatCurrency(totalPrice ?? 0, 'EUR', {
          locale: 'sk-SK',
          style: 'currency',
          maximumFractionDigits: 2,
        })}
        labelStyle={sxStyles.priceLabel}
        valueStyle={sxStyles.priceValue}
        wrapperStyle={sxStyles.priceWrapper}
      />
      <View sx={sxStyles.buttonsWrapper}>
        <ContinueShopCartOrderButton
          onPressButton={onPressRoute}
          isLoading={isLoading}
          disabled={isDisabled}
          isShopCartEnabled={isShopCartEnabled}
        />
        <ShopCartOrderButton
          onPressButton={onPressOrder}
          isLoading={isLoading}
          isShopCartEnabled={isShopCartEnabled}
        />
      </View>
    </View>
  )
}

export const ShopCartContentPriceAndButton = React.memo(
  ShopCartContentPriceAndButtonComponent
)

const applyStyles = (theme: DripsyCustomTheme): ObjectsOfSxProps => ({
  rightPart: {
    flex: 1,
    flexDirection: 'column',
  },
  priceWrapper: {
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  priceLabel: {
    ...theme.text.body1Bold,
    color: 'grayColor700',
    alignSelf: 'center',
    pr: 3,
  },
  priceValue: {
    color: 'primaryColor400',
    width: 150,
    textAlign: 'right',
    ...theme.text.h5Bold,
  },
  buttonsWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttonWrapper: {
    alignItems: 'flex-end',
    paddingBottom: [1, 4, 5],
    paddingTop: [2, 5, 7],
    pr: [0, 2],
  },
})
