import {ReducerUtils} from '@sincersoft/core'
import {GRID_REDUCER_STATE_ID} from '@sincersoft/fe-grid'

export const ADMIN_ORDER_INSTANCE_REDUCER_STATE_ID = 'adminOrder'
export const ADMIN_USER_ORDER_ITEM_INSTANCE_REDUCER_STATE_ID =
  'adminUserOrderItem'
export const ADMIN_USER_ORDER_ITEM_EMPTY_INSTANCE_REDUCER_STATE_ID =
  'adminUserOrderItemEmpty'
export const ADMIN_USER_ORDER_CUSTOM_ITEMS_INSTANCE_REDUCER_STATE_ID =
  'adminUserOrderCustomItems'
export const USER_ORDER_INSTANCE_REDUCER_STATE_ID = 'userOrder'
export const USER_ORDER_ITEM_INSTANCE_REDUCER_STATE_ID = 'userOrderItem'
export const USER_ORDER_ITEM_EMPTY_INSTANCE_REDUCER_STATE_ID =
  'userOrderItemEmpty'
export const USER_ORDER_CUSTOM_ITEMS_INSTANCE_REDUCER_STATE_ID =
  'userOrderCustomItems'

export const ADMIN_ORDER_INSTANCE_REDUCER_STATE_GRID_ID =
  ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    ADMIN_ORDER_INSTANCE_REDUCER_STATE_ID
  )

export const ADMIN_ORDER_ITEM_INSTANCE_REDUCER_STATE_GRID_ID =
  ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    ADMIN_USER_ORDER_ITEM_INSTANCE_REDUCER_STATE_ID
  )

export const ADMIN_ORDER_CUSTOM_ITEM_INSTANCE_REDUCER_STATE_GRID_ID =
  ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    ADMIN_USER_ORDER_CUSTOM_ITEMS_INSTANCE_REDUCER_STATE_ID
  )

export const OrderStatus = {
  IN_STOCK: 'in_stock',
  IN_PROGRESS: 'in_progress',
  BAD_QUALITY: 'bad_quality',
  UNAVAILABLE: 'unavailable',
} as const

export const OrderStatusOptions = [
  {name: OrderStatus.IN_STOCK},
  {name: OrderStatus.BAD_QUALITY},
  {name: OrderStatus.IN_PROGRESS},
  {name: OrderStatus.UNAVAILABLE},
]
