import {CompositeScreenProps} from '@react-navigation/native'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {View} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  ADMIN_USER_INSTANCE_REDUCER_STATE_GRID_ID,
  ADMIN_USER_INSTANCE_REDUCER_STATE_ID,
} from '../user_constants'
import {BreadcrumbItem} from 'common/components/BreadcrumbItem'
import {Breadcrumbs} from 'common/components/Breadcrumbs'
import {useFilterButtons} from 'common/hooks/useFilterButtons'
import {useRefetchQuery} from 'common/hooks/useRefetchQuery'
import {Api} from 'config/app_config'
import {ConfigFilterButtons} from 'grid/grid_types'
import {Header} from 'header/components/Header'
import {AuthorizedLayout} from 'layout/components/AuthorizedLayout'
import {
  AuthorizedScreenProps,
  ObjectsOfSxProps,
  UserStackParamList,
} from 'layout/layout_types'
import {UserGrid} from 'user/components/UserGrid'
import {getDataConfigForCustomers} from 'user/user_helpers'

type Props = CompositeScreenProps<
  NativeStackScreenProps<UserStackParamList, 'UserScreen'>,
  AuthorizedScreenProps
>

const config: ConfigFilterButtons[] = [
  {
    id: 'unconfirmed',
    color: 'primaryColor500',
    activeColor: 'primaryColor500',
    title: 'filterButtons.user.unconfirmed',
    defaultActive: false,
    condition: [
      {
        userStatus: 'unconfirmed',
      },
    ],
  },
  {
    id: 'confirmed',
    color: 'primaryColor500',
    activeColor: 'primaryColor500',
    title: 'filterButtons.user.confirmed',
    defaultActive: false,
    condition: [
      {
        userStatus: 'confirmed',
      },
    ],
  },
]

export const UserScreen: React.FC<Props> = ({navigation}) => {
  const {t} = useTranslation()

  const {renderFilterButtons, getFilterCondition} = useFilterButtons(
    config,
    true,
    ADMIN_USER_INSTANCE_REDUCER_STATE_ID
  )
  const filterCondition = getFilterCondition()

  const handlePressItem = (id: string) => {
    navigation.navigate('UserDetailScreen', {id})
  }

  useRefetchQuery([
    {key: ADMIN_USER_INSTANCE_REDUCER_STATE_GRID_ID, isGrid: true},
  ])

  const customersDataServiceConfig = getDataConfigForCustomers()

  return (
    <View sx={sxStyles.container}>
      <Header />
      <AuthorizedLayout
        title={t('routes.user')}
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbItem label={t('routes.user')} isLast />
          </Breadcrumbs>
        }
      >
        {renderFilterButtons()}
        <UserGrid
          dataServiceUrl={Api.user}
          dataServiceConfig={{
            ...customersDataServiceConfig,
            ...(filterCondition.length > 0
              ? {
                  params: {
                    ...customersDataServiceConfig.params,
                    where: {
                      ...customersDataServiceConfig.params.where,
                      or: [...filterCondition],
                    },
                  },
                }
              : undefined),
          }}
          stateId={ADMIN_USER_INSTANCE_REDUCER_STATE_ID}
          onPressItem={handlePressItem}
          placeholder={t('user.placeholder')}
          customPagination
        />
      </AuthorizedLayout>
    </View>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
  },
}
