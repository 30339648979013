import {ReducerUtils} from '@sincersoft/core'
import {GRID_REDUCER_STATE_ID} from '@sincersoft/fe-grid'

import {ChartColorFn} from 'new_order/new_order_types'
import Theme from 'theme/app_theme.json'
import {UserPriceListType} from 'user/user_constants'

export const QUICK_PRODUCT_INSTANCE_REDUCER_STATE_ID = 'quickProduct'

export const AMOUNT = 'amount'
export const UNIT_OF_MEASURE = 'unitOfMeasure'

export const QUICK_PRODUCT_INSTANCE_REDUCER_STATE_GRID_ID =
  ReducerUtils.getInstanceReducerId(
    GRID_REDUCER_STATE_ID,
    QUICK_PRODUCT_INSTANCE_REDUCER_STATE_ID
  )

export const DATASET_ITEM_COLORS: Record<UserPriceListType, ChartColorFn> = {
  priceListStandard: () => Theme.colors.primaryColor300,
  priceListActionPrimary: (opacity = 1) => `rgba(0,0,102,${opacity})`,
  priceListActionSecondary: (opacity = 1) => `rgba(0,102,0,${opacity})`,
}
