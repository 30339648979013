import {FormFields} from 'register/register_constants'

export const PROFILE_FORM_FIELDS: FormFields[] = [
  [
    {id: 'fullName', label: 'user.name'},
    {id: 'phone', label: 'user.phone', keyboardType: 'phone-pad'},
  ],
  [
    {
      id: 'email',
      label: 'user.email',
      keyboardType: 'email-address',
      isDisabled: true,
    },
    {id: 'createdAt', label: 'user.date', isDisabled: true, isDate: true},
  ],
  [
    {
      id: 'userStatus',
      label: 'user.state',
      isDisabled: true,
      isSelect: true,
      translatePrefix: 'user',
    },
  ],
]

export const CHANGE_PASSWORD_FORM_FIELDS: FormFields = [
  {id: 'oldPassword', label: 'profile.oldPassword', isPassword: true},
  {id: 'newPassword', label: 'profile.newPassword', isPassword: true},
  {id: 'againPassword', label: 'profile.againPassword', isPassword: true},
]
