import {Text, View} from 'dripsy'
import React from 'react'

import {ObjectsOfSxProps} from 'layout/layout_types'

interface Props {
  title?: string
  children?: React.ReactNode
}

export const GridHeader: React.FC<Props> = ({title, children}) => {
  return (
    <View sx={sxStyles.container}>
      {title ? (
        <Text sx={sxStyles.title} variant={'h4Bold'}>
          {title}
        </Text>
      ) : null}
      {children}
    </View>
  )
}
const sxStyles: ObjectsOfSxProps = {
  container: {
    flexDirection: ['column', null, 'row'],
    pl: 1,
    py: 1,
  },
  title: {
    color: 'primaryColor700',
    fontSize: [5, null, 7],
  },
}
