import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {
  DeleteProductPayload,
  UpdateOrderAmountPayload,
  UpdateOrderUnitOfMeasurePayload,
} from 'new_order/new_order_types'

export interface NewOrderProductItem {
  amount?: number
  unitOfMeasure: string
}

export interface NewOrderProducts {
  [index: string | number]: NewOrderProductItem
}

export interface NewOrderState {
  isLoading: boolean
  products: NewOrderProducts
  reset: boolean
}

const initialState: NewOrderState = {
  isLoading: false,
  products: {},
  reset: false,
}

export const newOrderSlice = createSlice({
  name: 'newOrder',
  initialState,
  reducers: {
    setProductAmount: (
      state,
      action: PayloadAction<UpdateOrderAmountPayload>
    ) => {
      state.products = {
        ...state.products,
        [action.payload.id]: {
          amount: action.payload.amount,
          unitOfMeasure: action.payload.unitOfMeasure,
        },
      }
    },
    setProductUnitOfMeasure: (
      state,
      action: PayloadAction<UpdateOrderUnitOfMeasurePayload>
    ) => {
      if (state.products[action.payload.id]) {
        state.products[action.payload.id].unitOfMeasure =
          action.payload.unitOfMeasure
      }
    },
    resetProducts: (state) => {
      state.products = {}
      state.reset = true
    },
    setReset: (state, action: PayloadAction<boolean>) => {
      state.products = {}
      state.reset = action.payload
    },
    deleteProduct: (state, action: PayloadAction<DeleteProductPayload>) => {
      if (state.products[action.payload.id]) {
        delete state.products[action.payload.id]
      }
    },
  },
})
