import {ReducerUtils} from '@sincersoft/core'
import {
  extractGridKey,
  extractKey,
  QueryClientContext,
  useApiMutation,
} from '@sincersoft/fe-core'
import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {Text, View} from 'dripsy'
import React, {useCallback} from 'react'

import {IconButton} from 'common/components/IconButton'
import {SnackbarHelpers} from 'common/snackbar_helpers'
import {Api} from 'config/app_config'
import {ApiError, ResponseSuccess} from 'error/error_helper'
import {CellRendererParams} from 'grid/grid_types'
import {ObjectsOfSxProps} from 'layout/layout_types'
import {
  getDataConfigForCart,
  getDataConfigForDeleteShopCartItem,
  getDataConfigForShopCartItemsCount,
} from 'shop_cart/shop_cart_helpers'
import {shopCartSlice} from 'shop_cart/shop_cart_slice'
import {ShopCartItem} from 'shop_cart/shop_cart_types'
import {useAppDispatch} from 'store/redux_hooks'

export const ShopCartActionCellRenderer: React.FC<CellRendererParams> = ({
  data,
  isSmall,
}) => {
  const queryClient = useQueryClient({context: QueryClientContext})
  const dispatch = useAppDispatch()

  const handleSuccessDelete = (response: ResponseSuccess) => {
    const shopCartGridKey = extractGridKey(Api.cartItem)
    const allData =
      queryClient.getQueryData<ShopCartItem[]>(shopCartGridKey) ?? []
    const updatedData = ReducerUtils.deleteObjectInArray(
      allData,
      data.id,
      'id',
      ''
    )
    dispatch(
      shopCartSlice.actions.deleteErrorState({id: data.id, deleteAllById: true})
    )
    queryClient.setQueryData(shopCartGridKey, updatedData)
    const configForShopCartItemsCount = getDataConfigForShopCartItemsCount()
    const shopCartItemsCountKey = extractKey({
      params: configForShopCartItemsCount.params,
      url: configForShopCartItemsCount.url,
    })
    queryClient.invalidateQueries(shopCartItemsCountKey)
    const configForShopCart = getDataConfigForCart()
    const shopCartKey = extractKey({
      url: configForShopCart.url,
    })
    queryClient.invalidateQueries(shopCartKey)
    queryClient.invalidateQueries(shopCartItemsCountKey)
    dispatch(SnackbarHelpers.getSnackBarSuccess(response.messageCode))
  }

  const handleError = (error: AxiosError<ApiError>) => {
    dispatch(SnackbarHelpers.getSnackBarError(error))
  }

  const configForDeleteShopCartItem = getDataConfigForDeleteShopCartItem({
    id: data.id,
  })

  const {mutate, isLoading} = useApiMutation(
    extractKey({
      data: configForDeleteShopCartItem.data,
      method: configForDeleteShopCartItem.method,
      url: configForDeleteShopCartItem.url,
    }),
    configForDeleteShopCartItem.data,
    {
      reactMutationOptions: {
        onSuccess: handleSuccessDelete,
        onError: handleError,
      },
      axiosConfig: {
        ...configForDeleteShopCartItem,
      },
    },
    true
  )

  const handleDeleteCartItem = useCallback(() => {
    mutate()
  }, [mutate])

  const renderButtons = () => (
    <IconButton
      appearance='ghost'
      size='small'
      status='danger'
      iconName={'delete'}
      left
      onPress={handleDeleteCartItem}
      disabled={isLoading}
    />
  )

  return isSmall ? (
    <View sx={sxStyles.container}>{renderButtons()}</View>
  ) : (
    <Text sx={sxStyles.container}>{renderButtons()}</Text>
  )
}

const sxStyles: ObjectsOfSxProps = {
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}
