import {DateUtils} from '@sincersoft/core'
import {View, Text, SxProp} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ObjectsOfSxProps} from 'layout/layout_types'

interface NewOrderCategoryProps {
  children?: React.ReactNode
  isColumn?: boolean
  isDate?: boolean
  label: string
  numberOfLinesForValue?: number
  value?: string | number
  wrapperStyle?: SxProp
  labelStyle?: SxProp
  valueStyle?: SxProp
  translatePrefix?: string
}

const LabelValueComponent: React.FC<NewOrderCategoryProps> = ({
  children,
  isColumn,
  isDate,
  label,
  numberOfLinesForValue,
  value,
  wrapperStyle,
  labelStyle,
  valueStyle,
  translatePrefix,
}) => {
  const {t} = useTranslation()
  const sxStyles = applyStyles(isColumn)
  return (
    <View sx={{...sxStyles.wrapper, ...wrapperStyle}}>
      <Text sx={{...sxStyles.text, ...labelStyle}} variant={'caption'}>
        {t(label)}
      </Text>
      {value ? (
        <Text
          sx={{...sxStyles.text, ...valueStyle}}
          numberOfLines={numberOfLinesForValue || 1}
          variant={'caption'}
        >
          {isDate
            ? DateUtils.format(+value, DateUtils.DATE_FORMAT)
            : translatePrefix
            ? t(`${translatePrefix}.${value}`)
            : value}
        </Text>
      ) : null}
      {children && !value ? children : null}
    </View>
  )
}

export const LabelValue = React.memo(LabelValueComponent)

const applyStyles = (isColumn?: boolean): ObjectsOfSxProps => ({
  wrapper: {
    flexDirection: isColumn ? 'column' : 'row',
    justifyContent: 'space-between',
  },
  text: {
    color: 'grayColor600',
  },
})
