import {NavigationProp, useNavigation, useRoute} from '@react-navigation/native'
import {View, Image} from 'dripsy'
import React from 'react'
import {Pressable} from 'react-native'

import {Icon} from 'common/components/Icon'
import {isWeb} from 'layout/layout_constants'
import {AuthorizedNavigationProp, ObjectsOfSxProps} from 'layout/layout_types'
import {useAppSelector} from 'store/redux_hooks'

interface HeaderLeftProps {
  hasBackButton: boolean
  isAdmin: boolean
}

export const HeaderLeft: React.FC<HeaderLeftProps> = ({
  hasBackButton,
  isAdmin,
}) => {
  const sxStyles = applyStyles({hasBackButton, isAdmin})
  const navigation = useNavigation<AuthorizedNavigationProp>()
  const isLoggedIn = useAppSelector((state) => state.login.isLoggedIn)
  const loginUser = useAppSelector((state) => state.login.loginUser)
  const route = useRoute()

  const handlePressBack = () => {
    if (route.name === 'RequestRegisterScreen') {
      navigation.navigate('LoginScreen')
    } else if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      let currentNavigation: NavigationProp<any> = navigation
      const defaultHomeScreen =
        isLoggedIn && loginUser ? 'NavAuthorizedHomeScreen' : 'LoginScreen'
      while (currentNavigation.getParent()) {
        currentNavigation = currentNavigation.getParent()
      }
      currentNavigation.reset({
        index: 0,
        routes: [{name: defaultHomeScreen}],
      })
    }
  }

  const handlePressImage = () => {
    if (isAdmin) {
      navigation.navigate('NavAdminOrderScreen', {screen: 'AdminOrderScreen'})
    } else {
      navigation.navigate('NavNewOrderScreen', {screen: 'NewOrderScreen'})
    }
  }

  return (
    <View sx={sxStyles.headerLeft}>
      {hasBackButton ? (
        <Icon onPress={handlePressBack} name={'arrow-back'} />
      ) : (
        <Pressable accessibilityRole={'button'} onPress={handlePressImage}>
          <Image
            source={
              isWeb ? require('assets/logo.svg') : require('assets/logo.png')
            }
            sx={sxStyles.image}
            resizeMode={'contain'}
          />
        </Pressable>
      )}
    </View>
  )
}

const applyStyles = (props: HeaderLeftProps): ObjectsOfSxProps => ({
  headerLeft: {
    alignSelf: 'center',
    justifyContent: 'flex-start',
    minWidth: props.hasBackButton ? [40, null, 40] : [35, null, 45],
  },
  name: {
    color: 'black',
  },
  image: {
    height: [35, null, 45],
    width: [35, null, 45],
  },
})
