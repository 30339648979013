import {View, Image, Text} from 'dripsy'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {isWeb} from 'layout/layout_constants'
import {ObjectsOfSxProps} from 'layout/layout_types'

export const RegisterIntro: React.FC = () => {
  const sxStyles = applyStyles()
  const {t} = useTranslation()

  return (
    <View sx={sxStyles.container}>
      <View sx={sxStyles.imgWrapper}>
        <Image
          source={
            isWeb ? require('assets/logo.svg') : require('assets/logo.png')
          }
          sx={sxStyles.image}
          resizeMode={'contain'}
        />
      </View>
      <Text sx={sxStyles.titleText} variant={'body1'}>
        {t('register.titleText')}
      </Text>
      <Text sx={sxStyles.title} variant={'h4Bold'}>
        {t('register.title')}
      </Text>
      <Text sx={sxStyles.information} variant={'body1'}>
        {t('register.icoInformation')}
      </Text>
    </View>
  )
}

const applyStyles = (): ObjectsOfSxProps => ({
  container: {
    display: 'flex',
    // height: '100%',
    // TODO: fix height of this container, or rework component
    maxHeight: [360, null, 300],
    minHeight: [315, null, 300],
    flexDirection: 'column' as const,
  },
  image: {
    height: '100%',
    width: '100%',
    maxWidth: [230, null, 240],
    marginTop: [3, null, 5],
  },
  imgWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    maxHeight: [70, null, 80],
  },
  title: {
    color: 'primaryColor700',
    paddingTop: [4, null, 6],
    fontSize: [5, null, 7],
    alignSelf: 'center',
  },
  titleText: {
    color: 'primaryColor600',
    paddingTop: [4, null, 5],
    alignSelf: 'center',
    textAlign: 'center' as const,
    paddingX: 4,
  },
  information: {
    fontFamily: 'Montserrat-Bold',
    my: 2,
    color: 'primaryColor600',
    paddingTop: [4, null, 5],
    alignSelf: 'center',
    textAlign: 'center' as const,
    paddingX: 4,
  },
})
